import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Article, ArticleService , PageService , Page  } from 'src/app/rest';
import { ArticleR } from 'src/app/rest/model/articleR';
import { Constant } from '../../_constants/constant';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { faClock, faArrowUp, faArrowDown, faSortDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { LoadingPagesService } from '../../_services/loading-pages.service';
import { Meta , Title } from '@angular/platform-browser';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  faClock = faClock;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faSortDown = faSortDown;
  faSearch = faSearch;

  @Input() Article: Article = {
    idArticle: '',
    resume: '',
    motsCle: '',
    tag: '',
    commentaire: '',
    parent: '',
    lien: '',
    etatArticle: null,
    autorisationCommentaire: false,
    order: null,
    metaTiltle: '',
    metaDescription: '',
    metaDetails: '',
    resumeEn: '',
    motsCleEN: '',
    tagEN: '',
    metaTiltleEn: '',
    metaDescriptionEn: '',
    metaDetailsEN: '',
    utilisateurCreation: '',
    utilisateurModification: '',
    dateCreation: null,
    dateModification: null,
    titres: [{
      contenu: '',
      contenuEN: ''
    }]
  };

  link;
  ActualitesPage;
  ActualitesTitres;
  isFrench: boolean;
  tags = [];
  tagsEN = [];
  isWorking: boolean;

  articlePub: ArticleR[];
  sortArticle;
  search;
  IsMobile = false;
  Apiroot: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    public loadingPagesService: LoadingPagesService,
    private pageService: PageService,
    private meta: Meta,
    private title: Title
  ) { this.search = ''; }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.isWorking = false;
    this.Apiroot = Constant.ApiRoot + '/';
    this.loadingPagesService.setPageStatus(true);
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    if (window.innerWidth < 768) {
      this.IsMobile = true;
    }
    this.route.paramMap.subscribe((params) => {
      this.link = params.get('link');
      if (this.link !== undefined && this.link !== null) {
        this.isWorking = true;
        this.articleService.getByURL(this.link).subscribe(async data => {
          this.Article = data;
          this.Article.fichier = this.Article.fichier.filter(item => !(item.isBackground));
          this.setMetaArticle(this.Article);
          if (this.Article.tag !== undefined && this.Article.tag !== null && this.Article.tag !== '') {
            this.tags = this.Article.tag.substr(0, this.Article.tag.length - 1).split('*');
          }
          if (this.Article.tagEN !== undefined && this.Article.tagEN !== null && this.Article.tagEN !== '') {
            this.tagsEN = this.Article.tagEN.substr(0, this.Article.tagEN.length - 1).split('*');
          }
          this.isWorking = false;
        }, () => {
          this.isWorking = false;
          this.router.navigate(['/Error/NotFound']);
        });
      } else {
        this.link = undefined;
        this.route.queryParams
          .subscribe(ps => {
            if (ps.tag !== undefined) {
              this.search = '[' + ps.tag + ']';
              this.Search();
            } else {
              this.isWorking = true;
              this.articleService.getFast().subscribe(data => {
                this.articlePub = data;
                this.articlePub.forEach(e => {
                  e.title = this.decodeHtmlEntity(this.DecodeHTML(e.title));
                  e.titleEn = this.decodeHtmlEntity(this.DecodeHTML(e.titleEn));
                  e.filePath = Constant.ApiRoot + '/' + e.filePath;
                });
                this.pageService.getByCategorieAndModele('Actualites', 'Actualites').subscribe(dt => {
                   this.ActualitesPage = dt[0];
                   this.ActualitesTitres = dt[0].titres;
                   this.setMeta(this.ActualitesPage);
                }, () => {
                  console.log('erreur log');
                });
                this.setMeta(this.ActualitesPage);
                this.isWorking = false;
              }, err => {
                this.isWorking = false;
                console.log(err);
              });
            }
          }
          );

      }
    });
  }

  async setMeta(art) {
    if (this.isFrench && art !== undefined) {
      let motsCles = '';
      if (art.motsCle !== undefined) {
        motsCles = art.motsCle.replace(/\*/gi, ',');
      }
      this.title.setTitle(art.metaTiltle);
     
      this.meta.updateTag({ property: 'og:title', content: art.metaTiltle });
      this.meta.updateTag({ name: 'description', content: art.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: art.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'http://localhost:4200/article' });
     
    } else if (!this.isFrench && art !== undefined)  {
      this.title.setTitle(art.metaTiltleEN);
      let motsCles = '';
      if (art !== undefined && art.motsCleEN !== undefined) {
        motsCles = art.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: art.metaTiltle });
      this.meta.updateTag({ name: 'description', content: art.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: art.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'http://localhost:4200/article' });
    }
  }
  async setMetaArticle(art) {
    if (this.isFrench) {
      let motsCles = '';
      if (art !== undefined && art.motsCle !== undefined) {
        motsCles = art.motsCle.replace(/\*/gi, ',');
      }
      this.title.setTitle(art.metaTiltle);
      this.meta.updateTag({ name: 'description', content: art.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:image', content: this.GetImage(art.fichier[0].filePath)});
      this.meta.updateTag({ property: 'og:description', content: art.metaDescription });
      this.meta.updateTag({ property: 'og:title', content: art.metaTiltle });
    } else {
      this.title.setTitle(art.metaTiltleEN);
      let motsCles = '';
      if (art !== undefined && art.motsCleEN !== undefined) {
        motsCles = art.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ name: 'description', content: art.metaDescriptionEN });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:image', content: art.fichier[0].filePath});
      this.meta.updateTag({ property: 'og:description', content: art.metaDescription });
      this.meta.updateTag({ property: 'og:title', content: art.metaTiltle });
    }
  }
  DecodeHTML(html: string) {
    return html.replace(/<[^>]+>/g, '');
  }

  decodeHtmlEntity(str) {
    // tslint:disable-next-line: only-arrow-functions
    return str.replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec);
    });
  }

  deleteSpaceEncoding(str) {
    return str.replace(/&nbsp;/g, ' ');
  }

  goto(id) {
    this.router.navigate(['article/' + id]);
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
  GetImage(path: string) {
    return `${Constant.ApiRoot}/${path}`;
  }

  sort(s) {
    if (s === 'up') {
      this.articlePub = this.articlePub.sort((a, b) => new Date(a.dateModification).getTime() - new Date(b.dateModification).getTime());
    } else {
      this.articlePub = this.articlePub.sort((a, b) => new Date(b.dateModification).getTime() - new Date(a.dateModification).getTime());
    }
  }

  Search() {
    if (this.search !== '') {
      const isTag = (this.search[0] === '[' && this.search[this.search.length - 1] === ']');
      let txt = this.search;
      if (isTag) {
        txt = this.search.slice(0, -1);
        txt = txt.substring(1);
      }
      if (this.isFrench === true) {
        this.isWorking = true;
        this.articleService.searchFr(txt, isTag).subscribe(data => {
          this.articlePub = data;
          this.articlePub.forEach(e => {
            e.title = this.decodeHtmlEntity(this.DecodeHTML(e.title));
            e.titleEn = this.decodeHtmlEntity(this.DecodeHTML(e.titleEn));
            e.filePath = Constant.ApiRoot + '/' + e.filePath;
          });
          this.isWorking = false;
        }, err => {
          this.isWorking = false;
        });
      } else {
        this.isWorking = true;
        this.articleService.searchEn(txt, isTag).subscribe(data => {
          this.articlePub = data;
          this.articlePub.forEach(e => {
            e.title = this.decodeHtmlEntity(this.DecodeHTML(e.title));
            e.titleEn = this.decodeHtmlEntity(this.DecodeHTML(e.titleEn));
            e.filePath = Constant.ApiRoot + '/' + e.filePath;
          });
          this.isWorking = false;
        }, err => {
          this.isWorking = false;
        });
      }
    } else {
      this.ngOnInit();
    }
  }

  gotoTag(tg) {
    this.router.navigate(['/article'], { queryParams: { tag: tg } });
  }
}
