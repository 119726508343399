import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-emploi-carrousel',
  templateUrl: './emploi-carrousel.component.html',
  styleUrls: ['./emploi-carrousel.component.scss']
})
export class EmploiCarrouselComponent implements OnInit {
  @Input() ParagrapheArrayOE = [];
  @Input() url;
  IsMobile = false;

  constructor() { }

  ngOnInit(): void {
    if (window.innerWidth < 768) {
      this.IsMobile = true;
    }
  }

}
