
<nav class=" container-fluid navbar navbar-expand-lg navbar-light static-top"  >
  <div id="navbarstyle" class="navbarstyle navbar navbar-expand-sm " [ngStyle]="{'border-bottom': (isScrolling) ? '3px solid #2ca5dd' : '', 'margin-left': (mobile) ? '-4%' : '-1.2%','background-color': (isScrolling) ? 'white' : 'transparent'}">
  <a class="navbar-brand " [ngStyle]="{'margin-top': (mobile) ? '-2%' : ''}" style="cursor: pointer" href="">
    <img [ngStyle]="{'display': (isScrolling || isErrorPage || mobile) ? '' : 'none'}" data-src="assets/images/LogoAiventu.png" width="100px" height="55px" class="w3-round-small lazyload" alt="Aiventu">
    <img [ngStyle]="{'display': (!isScrolling && !isErrorPage && !mobile) ? '' : 'none'}" data-src="assets/images/aiventu_logo_blanc.png" width="100px" height="55px" class="w3-round-small lazyload" alt="Aiventu">

  </a>
  <button class="navbar-toggler navbar-toggle collapsed" type="button" data-toggle="collapse"
    data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarToggler">
    <ul class="navbar-nav ml-auto mt-lg-0">
      <li class="nav-item" *ngFor="let item of menuTree; let i = index">
        <div class="nav-item-menu" [ngClass]="{'dropdown':item.children.length > 0}">
          <a class="dropbtn" [href]="" routerLink="{{item.url}}" routerLinkActive="active" [ngStyle]="{'color': (isScrolling || mobile) ? '#1279bf' : 'white'}" >{{item.title | translate}}</a>
          <div class="dropdown-content" [ngStyle]="{'height': dropdownheight +'px'}" *ngIf="item.children.length>0 && item.url === '/produits'">
            <a *ngFor="let child of NosSolution" routerLink="produits/{{child.lien}}" [href]=""
              routerLinkActive="active" [innerHTML]="child.titres[0].contenu | safeHtml"></a>
              
          </div>
        </div>
      </li>
      <li>
        <a *ngIf="button" [href]="" class="btn item text-white" routerLinkActive="active"  routerLink="{{button.href}}">{{button.title | translate}}</a>
      </li>
    </ul>
      <a class="button-redirection" href="#temoignage"></a>
  </div>
</div>
</nav>

