<div >
  <div class="text-center">
    <h1 *ngIf="isFrench" [innerHTML]="title[0].contenu | safeHtml"></h1>
    <h1 *ngIf="!isFrench" [innerHTML]="title[0].contenuEN | safeHtml"></h1>
    <app-hr *ngIf="hr"></app-hr>
  </div>
  <carousel class="device" style="margin-left: 10%; width: 80%; z-index: 100 !important;"  [cellWidth]="50" [cellsToShow]="cellsToShow" [autoplayInterval]="3000"  [arrows]="true" [arrowsOutside]="arrowsOutside" [transitionDuration]="1000" [overflowCellsLimit]="overflowCellsLimit" [pauseOnHover]="true" [autoplay]="true" [loop]="true"  >
    <div class="carousel-cell slide"
    *ngFor="let card of valeursCards; let i = index">
    <div class="slide"><img (click)="redirect()" class="lazyload" [attr.data-src]="GetImage(valeursCards[i].fichier[0].filePath)" alt="{{valeursCards[i].fichier[0].alt}}"> </div>
    </div>
  </carousel>
</div>