import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationMailService, UtilisateurService } from 'src/app/rest';
import { User } from 'src/app/rest/model/user';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';
import { UserService } from 'src/app/shared/_services/user.service';
import { Md5 } from 'ts-md5/';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading = false;
  username: string;
  password: string;
  email: string;
  rememberMe: boolean;
  returnUrl: string;

  @ViewChild('changePwd', { static: false }) closebutton;

  constructor(private router: Router,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private userService: UserService,
              private utilisateur: UtilisateurService,
              private settings: ConfigurationMailService,
              public translate: TranslateService,
              public loadingPagesService: LoadingPagesService
  ) {
    this.returnUrl = 'back/utilisateur';
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('fr');
  }

  ngOnInit() {
    this.loadingPagesService.setPageStatus(true);
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl;
    });

    if (this.userService.isLoggedIn()) {
      this.router.navigate(['back/dashboard']);
    }
  }

  loginUser() {
    if (this.username.length > 0 && this.password.length > 0) {

      this.loading = true;
      const md = new Md5();
      this.utilisateur.login(this.username, md.appendStr(this.password).end().toString())
        .subscribe(
          data => {
            if (data != null) {
              localStorage.setItem('MyApp_Auth', JSON.stringify(data[0].id));
              localStorage.setItem('userName', data[0].userName);
              localStorage.setItem('MyApp_token', JSON.stringify(data[1].token.value));
              if (this.returnUrl === '' || this.returnUrl == null || this.returnUrl === undefined) {
                // setting new item
                this.router.navigate(['back/dashboard']);
              } else {
                this.router.navigate([this.returnUrl]);
              }
            } else if (data === undefined) { } else {
              this.toastr.error('Adresse e-mail et/ou mot de passe incorrect', 'Erreur',
                {
                  timeOut: 5000,
                  progressBar: true,
                  closeButton: true
                }
              );
            }
          },
          error => {
            this.toastr.error('Erreur est survenue lors de la connexion', 'Erreur',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
          });
      setTimeout(() => {
        this.loading = false;
        this.username = '';
        this.password = '';
      },
        3000);
    } else {
      this.toastr.error('Les champs doivent être remplis', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    }
  }

  reset() {
    this.router.navigate(['/back/reset']);
  }

  SendRestPwd() {
    if (this.email === '') {
      this.toastr.error('Les champs doivent être remplis', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else if (this.validateEmail(this.email) === false) {
      this.toastr.error('Adresse électronique invalide !!', 'Erreur', {
        timeOut: 5000,
        progressBar: true,
        closeButton: true
      });
    } else {
      this.utilisateur.getUserByEmail(this.email)
        .subscribe(
          data => {
            if (data !== null) {
              const newPwd = this.generateRandomPass();
              const md = new Md5();
              data.password = md.appendStr(newPwd).end().toString();
              this.utilisateur.editPass(data.id, data).subscribe(
                res => {
                  /*this.utilisateur.sendEmailUser(
                    this.email,
                    data.userName, newPwd, 'Identifiants :', 'SITE ANDEX- Nouveau Mot de passe').*/
                    const user: User = {
                      userName : data.userName,
                      email : this.email,
                      password : newPwd,
                      link : window.location.origin + '/login'
                    };
                    this.settings.resetPWD(user).
                    subscribe(() => {
                    });
                    this.closebutton.nativeElement.click();
                    this.toastr.success('Prière de vérifier votre boite mail pour avoir le nouveau mot de passe', 'success',
                    {
                      timeOut: 5000,
                      progressBar: true,
                      closeButton: true
                    });
                }
              );
            } else {
              this.toastr.error('Cet email n\'existe pas !!', 'Erreur',
                {
                  timeOut: 5000,
                  progressBar: true,
                  closeButton: true
                });
            }
          },
          error => {
            this.toastr.error('Erreur est survenue lors de la connexion', 'Erreur',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
          });
    }
  }

  generateRandomPass() {
    return Math.random().toString(36).slice(-8);
  }

  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}
