import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';

@Component({
  selector: 'app-fenetre-pop-up',
  templateUrl: './fenetre-pop-up.component.html',
  styleUrls: ['./fenetre-pop-up.component.scss'],
})
export class FenetrePopUpComponent implements OnInit {

  @Input() TitleArrayFPU = [];
  @Input() ParagrapheArrayFPU = [];
  @Input() hr: boolean;
  isFrench: boolean;

  constructor(
    @Optional() public dialogRef: MatDialogRef<FenetrePopUpComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    // push GTM data layer for every visited page
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  customEvent() {
    localStorage.setItem('AcceptCookies', new Date().getTime().toString());
  }

  cancel() {
    this.dialogRef.close();
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
