import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { PageService } from 'src/app/rest/api/page.service';
import { CategorieService } from 'src/app/rest/api/categorie.service';
import { ArticleService, Categorie, Page } from 'src/app/rest';
import { HostListener } from '@angular/core';
import { GlobalLanguageEventService } from 'src/app/shared/_services/global-language-event.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-entreprise',
  templateUrl: './entreprise.component.html',
  styleUrls: ['./entreprise.component.scss'],
})
export class EntrepriseComponent implements OnInit {
  CTitreArrayOE = [];
  CParagrapheArrayOE = [];
  PublicationsArrayRS = [
    {
      image: 'assets/images/linkedin.PNG',
      titre: 'VU SUR LINKEDIN',
      body:
        // tslint:disable-next-line: max-line-length
        ' <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6722542886950318080" height="50px" width="50px" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>  ',
      link: 'https://www.linkedin.com/company/aiventu/',
    },
    {
      image: 'assets/images/Twitter.PNG',
      titre: 'VU SUR TWITTER',
      body:
        // tslint:disable-next-line: max-line-length
        ' <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6722542886950318080" height="50px" width="50px" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>  ',
      link: 'https://twitter.com/aiventu_group',
    },
    {
      image: 'assets/images/Twitter.PNG',
      titre: 'VU SUR FACEBOOK',
      body:
        // tslint:disable-next-line: max-line-length
        ' <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6722542886950318080" height="50px" width="50px" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>  ',
      link: 'https://www.facebook.com/AiventuGroup/',
    },
  ];
  innerHeight: any;
  category = '';
  page = '';
  CategorieList = [];
  TitleDescription = '';
  ParagrapheArrayDescription = [];
  DetailsArrayDescription = [];
  BackgroundImageDescription = 'assets/images/postuler.jpg';
  ImageProfil = '';
  ImageDescription = '';
  CbackgroundImageURLLR = 'assets/images/andexrecrute.png';
  PageList = [];
  isAcceptCookies: boolean;
  innerHeightLP;
  innerWidth;
  button = {
    title: 'Nous Contacter',
    href: 'nous-contacter'
  };
  state: any;
  isFrench: boolean;

  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private route: ActivatedRoute,
    // tslint:disable-next-line: no-shadowed-variable
    private PageService: PageService,
    // tslint:disable-next-line: no-shadowed-variable
    private CategorieService: CategorieService,
    private title: Title,
    private meta: Meta,
    public loadingPagesService: LoadingPagesService,
    private articleService: ArticleService
  ) {
    this.isAcceptCookies = true;
    this.CategorieService.getCategories().subscribe((data) => {
      this.CategorieList = data;
      if (this.CategorieList.length !== 0 && this.category === null) {
        this.category = this.CategorieList[0].nom;
      }
      if (this.page != null) {
        this.GetPages(
          this.CategorieList.find(
            (i) =>
              i.nom.replace(' ', '').toUpperCase() === this.page.toUpperCase()
          )
        );
      } else if (this.page == null && this.category != null) {
        this.GetPages(
          this.CategorieList.find(
            (i) => i.nom.toUpperCase() === this.category.toUpperCase()
          )
        );
      }
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.verifyAcceptCookies();
    this.route.paramMap.subscribe((params) => {
      this.category = params.get('category');
      this.page = params.get('page');
      // this.CategorieService.getCategories().subscribe((data) => {
      //   this.CategorieList = data;
      //   if (this.page != null) {
      //     this.GetPages(
      //       this.CategorieList.find(
      //         (i) =>
      //           i.nom.replace(' ', '').toUpperCase() === this.page.toUpperCase()
      //       )
      //     );
      //   } else if (this.page == null && this.category != null) {
      //     this.GetPages(
      //       this.CategorieList.find(
      //         (i) => i.nom.toUpperCase() === this.category.toUpperCase()
      //       )
      //     );
      //   }
      // });
    });
    this.innerHeight = window.innerHeight;
    this.innerHeightLP = this.innerHeight;
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
    this.innerHeightLP = this.innerHeight - 70;
    if (event.target.innerWidth< 1200 && event.target.innerWidth > 768) {
      this.innerHeight = 370;
    
    }
  }

  verifyAcceptCookies() {
    if (localStorage.getItem('AcceptCookies') !== null) {
      this.isAcceptCookies = true;
    } else {
      this.isAcceptCookies = false;
    }
  }

  AcceptCookies() {
    localStorage.setItem('AcceptCookies', new Date().getTime().toString());
    this.isAcceptCookies = true;
  }

  setMeta(page: Page) {
    if (this.isFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com' });
    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com' });
    }
  }

  GetPages(categorie: Categorie) {
    this.articleService.getLastN(2).subscribe(dt => {
      this.PageService.getByCategory(categorie.categorieId).subscribe((data) => {
        data.forEach(
          (dat) => {
            dat.paragraphes = dat.paragraphes.sort((a, b) =>
              a.order > b.order ? 1 : -1
            );
            dat.titres = dat.titres.sort((a, b) =>
              a.order > b.order ? 1 : -1
            );

          });
        this.PageList = data;
        this.PageList.forEach(element => {
          if (element.modele.name === 'offres d\'emplois') {
            element.paragraphes[2] = {
              body: this.decodeHtmlEntity(this.DecodeHTML(dt[0].resume)).substring(0, 154) + ' ...',
              bodyEN: this.decodeHtmlEntity(this.DecodeHTML(dt[0].resumeEn)).substring(0, 154) + ' ...',
              dateCreation: dt[0].dateCreation,
              dateModification: dt[0].dateModification,
              fichier: [{
                alt: dt[0].alt,
                altEn: dt[0].altEn,
                filePath: dt[0].filePath,
                name: dt[0].filePath,
                type: 'image'
              }],
              id: '00000000-0000-0000-0000-000000000000',
              link: dt[0].lien,
              name: 'Article',
              nameEN: 'News',
              order: 1,
              titre: this.decodeHtmlEntity(this.DecodeHTML(dt[0].title)),
              titreEN: this.decodeHtmlEntity(this.DecodeHTML(dt[0].titleEn))
            };
            element.paragraphes[4] = {
              body: this.decodeHtmlEntity(this.DecodeHTML(dt[1].resume)).substring(0, 154) + ' ...',
              bodyEN: this.decodeHtmlEntity(this.DecodeHTML(dt[1].resumeEn)).substring(0, 154) + ' ...',
              dateCreation: dt[1].dateCreation,
              dateModification: dt[1].dateModification,
              fichier: [{
                alt: dt[1].alt,
                altEn: dt[1].altEn,
                filePath: dt[1].filePath,
                name: dt[1].filePath,
                type: 'image'
              }],
              id: '00000000-0000-0000-0000-000000000000',
              link: dt[1].lien,
              name: 'Article',
              nameEN: 'News',
              order: 1,
              titre: this.decodeHtmlEntity(this.DecodeHTML(dt[1].title)),
              titreEN: this.decodeHtmlEntity(this.DecodeHTML(dt[1].titleEn))
            };
          }
        });
        this.loadingPagesService.setPageStatus(true);
      });
    });
  }

  ContactUs(): void {
    this.state = 4;
    if (document.getElementById(this.button.href) == null) {
      const element: HTMLElement = document.getElementsByClassName(
        'btn'
      )[0] as HTMLElement;
      element.click();
    }
  }

  DecodeHTML(html: string) {
    if (html === undefined) {
      return '';
    }
    return html.replace(/<[^>]+>/g, '');
  }

  decodeHtmlEntity(str) {
    if (str === undefined) {
      return '';
    }
    // tslint:disable-next-line: only-arrow-functions
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
