import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategorieService } from 'src/app/rest';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { Constant } from '../../_constants/constant';
import { LoadingPagesService } from '../../_services/loading-pages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser'; 
import { PageService , Page  } from 'src/app/rest';

@Component({
  selector: 'app-nos-solution-details',
  templateUrl: './nos-solution-details.component.html',
  styleUrls: ['./nos-solution-details.component.scss']
})
export class NosSolutionDetailsComponent implements OnInit {
  isWorking: boolean;
  NosSolutions;
  NosSolutionsPage;
  NosSolutionsTitres;
  NosSolutionsModele;
  tabs = [];
  @Input() hr: boolean;
  isFrench: boolean;
  Width: number;
  height: number;
  CardHeight: number;
  link;
  cat;
  verif = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private pageService: PageService,
    public loadingPagesService: LoadingPagesService, private title: Title,
    private meta: Meta,
  ) { }

  ngOnInit(): void {
   // this.setMeta(this.NosSolutionsPage);
  console.log("Ssss",this.NosSolutionsPage)
    window.scrollTo(0, 0);
    this.loadingPagesService.setPageStatus(true);
    this.hr = true;
    this.Width = window.innerWidth;
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });


    /**************************************/

    this.route.paramMap.subscribe((params) => {
      this.link = params.get('link');
      this.cat = params.get('categorie');
      if (this.cat !== undefined && this.cat !== null) {
        this.pageService.getURL(this.cat).subscribe(dt => {
          if (dt.length !== 0) {
            if (this.link !== undefined && this.link !== null) {
              this.isWorking = true;
              // tslint:disable-next-line: no-shadowed-variable
              this.pageService.getURL(this.link).subscribe(dt => {
                this.NosSolutions = dt[0].paragraphes.sort((a, b) =>
                  a.order > b.order ? 1 : -1
                );
                this.NosSolutionsTitres = dt[0].titres;
                this.NosSolutionsModele = dt[0].modele;
                this.NosSolutionsPage = dt[0];
                //console.log(this.NosSolutionsPage);
                this.height = window.innerHeight - 70;
                this.CardHeight = this.height / 2.7;
                this.isWorking = false;
              }, () => {
                this.isWorking = false;
              });
            }
          } else {
            this.router.navigate(['/Error/NotFound']);
          }
          this.isWorking = false;

        }, () => {
          this.isWorking = false;
        });

      } else if (this.link !== undefined && this.link !== null) {
        this.isWorking = true;
        this.pageService.getURL(this.link).subscribe(dt => {
          if (dt.length !== 0) {
            this.NosSolutions = dt[0].paragraphes.sort((a, b) =>
              a.order > b.order ? 1 : -1
            );
            this.NosSolutionsTitres = dt[0].titres;
            this.NosSolutionsModele = dt[0].modele;
            this.NosSolutionsPage = dt[0];
            this.height = window.innerHeight - 70;
            this.CardHeight = this.height / 2.7;
            this.isWorking = false;
          } else {
            this.router.navigate(['/Error/NotFound']);
          }

        }, () => {
          this.isWorking = false;
        });

      }

    });
  }
  getString(tab: any, index: number) {
    if (tab === null || tab === undefined) {
      return '';
    } else { return tab[index]; }
  }
  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
  GetImage(path: string) {
    return 'url(' + Constant.ApiRoot + '/' + path + ')';
  }



  setMeta(page: Page) {
    console.log("S",page)
    if (this.isFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });

    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
    }
  }
}
