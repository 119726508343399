import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'src/app/shared/_constants/constant';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss'],
})
export class AnimationComponent implements OnInit {
  @Input() title = [];
  @Input() values = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;
  @Input() imageURL = [];

  isFrench: boolean;
  StartAnimation = [0, 0, 0, 0,0];
  Duration = [5000, 5000, 5000, 5000,5000];
  setline = -1;
  Image: string;
  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
  ) { }

  ngOnInit(): void {
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.Image = Constant.ApiRoot + '/' + this.imageURL[0].filePath;
    if (window.innerWidth > 1200) {
      this.height = 700;
    }
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      this.height = 360;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    if (window.innerWidth > 1200) {
      this.height = 700;
    }
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      this.height = 360;
    }
}

  getInt(numbr: string) {
    return Number(numbr);
  }
  setligne(i) {
    this.setline = i;
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
