import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { ConditionsUtilisationComponent } from './conditions-utilisation/conditions-utilisation.component';



@NgModule({
  declarations: [FooterComponent, HeaderComponent, MentionsLegalesComponent, ConditionsUtilisationComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent
  ]
})
export class LayoutModule { }
