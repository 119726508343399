
import {
  Component,
  Input,
  OnInit,
  HostListener
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
@Component({
  selector: 'app-front-navbar',
  templateUrl: './front-navbar.component.html',
  styleUrls: ['./front-navbar.component.scss'],
})
export class FrontNavbarComponent implements OnInit {
  @Input() logoURL = '';
  @Input() menuTree = [];
  @Input() NosSolution;
  @Input() button = {
    title: '',
    href: '',
  };
  @Input() category;
  state: any;
  contact = false;
  widthTot;
  isScrolling = false;
  mobile = true;
  categoryparam = '';
  isErrorPage = false;
  dropdownheight: number;
  Url: any;
  constructor(
    private translate: TranslateService,
    private globalLanguageService: GlobalLanguageEventService,
    private route: ActivatedRoute,
    private router: Router) {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.Url = event.url;
      if (event.url !== '/') {
        this.isScrolling = true;
      } else {
        this.isScrolling = false;
      }
    });

  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.Url = event.url;
      if (event.url !== '/') {
        this.isScrolling = true;
      } else {
        this.isScrolling = false;
      }

    });
    this.dropdownheight = this.menuTree[2].children.length * 36 ;
    if (window.innerWidth < 767) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.widthTot = window.innerWidth + 10;
    this.route.paramMap.subscribe((params) => {
      this.category = params.get('category');
    });
  }

  redirect() {
    this.router.navigate(['/']);
  }

  ContactUs(): void {
    if (document.getElementById(this.button.href) == null) {
      const element: HTMLElement = document.getElementsByClassName('btn')[0] as HTMLElement;
      element.click();
    }
  }
  @HostListener('window:scroll', ['$event']) scrolling() {
    const max = document.documentElement.scrollHeight;
    if (document.documentElement.scrollTop > 10 && this.Url === '/') {
      this.isScrolling = true;
    } else if (document.documentElement.scrollTop < 10 && this.Url === '/') {
      this.isScrolling = false;
    } else {
      this.isScrolling = true;
    }
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    this.globalLanguageService.setLanguage(lg);
  }


}
