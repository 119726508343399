<div *ngFor="let page of PageList" style="background-color: #f8f9fa; margin-bottom: 5%;" >
  <app-page-daccueil (mouseover)="setMeta(page)" (click)="setMeta(page)"
    *ngIf="page.titres.length!=0 && page.paragraphes.length!=0 && page.modele.name=='page d\'accueil'"
    [EPageAccueilTitres]="page.titres" [EPageAccueilParagraphes]="page.paragraphes"
    [backgroundImageURLAccueil]="page.fichier" [height]="innerHeightLP" [width]="innerWidth" [hr]="true">
  </app-page-daccueil>

  <app-landing-page (mouseover)="setMeta(page)" (click)="setMeta(page)"
    *ngIf="page.titres.length!=0 && page.paragraphes.length!=0 && page.modele.name=='landing page'"
    [TitleArray]="page.titres" [ParagrapheArray]="page.paragraphes" [backgroundImageURL]="page.fichier"
    [height]="innerHeight" [width]="innerWidth" [hr]="true">
  </app-landing-page>

  <div class="animation-chiffre" (mouseover)="setMeta(page)" (click)="setMeta(page)"
    *ngIf="page.titres.length!=0 && page.paragraphes.length!=0 && page.modele.name=='animation'">
    <app-animation (click)="setMeta(page)" [title]="page.titres" [height]="innerHeight" [values]="page.paragraphes"
      [imageURL]="page.fichier" [hr]="true" [width]="innerWidth"></app-animation>
  </div>

  <app-parallax-scrolling (mouseover)="setMeta(page)" (click)="setMeta(page)"
    *ngIf="page.titres.length!=0 && page.paragraphes.length!=0 && page.modele.name=='parallax scrolling'"
    [title]="page.titres" [valeursCards]="page.paragraphes" [height]="innerHeight" [imageURL]="page.fichier"
    [width]="innerWidth" [hr]="true">
  </app-parallax-scrolling>

  <app-fenetres-informatives (mouseover)="setMeta(page)" (click)="setMeta(page)" [Bloc]="false"
    *ngIf="page.titres.length!=0 && page.paragraphes.length!=0 && page.modele.name=='fenetres informatives'"
    [TitreArrayFI]="page.titres" [ParagrapheArrayFI]="page.paragraphes" [height]="innerHeight" [hr]="true"
    [width]="innerWidth">
  </app-fenetres-informatives>

  <app-offres-emplois id="jobs" (mouseover)="setMeta(page)" (click)="setMeta(page)"
    *ngIf="page.titres.length!=0 && page.paragraphes.length!=0  && page.modele.name=='offres d\'emplois'"
    [TitreArrayOE]="page.titres" [ParagrapheArrayOE]="page.paragraphes" [height]="innerHeight" [hr]="true"
    [width]="innerWidth"></app-offres-emplois>

    <div class="site-wrap" *ngIf="isAcceptCookies == false">
      <div class="card">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12" style="margin-top: 1.5%;" translate>CookieTxt</div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style="margin-top: 1%;">
            <button class="btn" style="margin-left:3%;" (click)="AcceptCookies()"> <a [href]="" translate> accept </a></button>
            <button class="btn" style="margin-left:1%;" data-toggle="modal" data-target="#myModal"><a [href]="" translate>EnSavoirPlus</a></button>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Modal -->
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
      #myModal>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div *ngFor="let page of PageList">
              <app-fenetre-pop-up
                *ngIf="page.titres.length!=0 && page.paragraphes.length!=0 && page.modele.name=='fenetre pop up'"
                (click)="verifyAcceptCookies()" data-dismiss="modal" [TitleArrayFPU]="page.titres"
                [ParagrapheArrayFPU]="page.paragraphes" [hr]="true"></app-fenetre-pop-up>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Modal-->

</div>