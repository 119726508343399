/**
 * SiteAndexBack API
 * API de Base
 *
 * OpenAPI spec version: v1
 * Contact: amahmoudi@aiventu.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Categorie } from '../model/categorie';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { Constant } from 'src/app/shared/_constants/constant';


@Injectable()
export class CategorieService {

    protected basePath = Constant.ApiRoot;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    // tslint:disable-next-line: max-line-length
    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCategorie(id: string, observe?: 'body', reportProgress?: boolean): Observable<Categorie>;
    public deleteCategorie(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Categorie>>;
    public deleteCategorie(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Categorie>>;
    public deleteCategorie(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteCategorie.');
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Categorie>(`${this.basePath}/api/categorie/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategorie(id: string, observe?: 'body', reportProgress?: boolean): Observable<Categorie>;
    public getCategorie(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Categorie>>;
    public getCategorie(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Categorie>>;
    public getCategorie(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCategorie.');
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Categorie>(`${this.basePath}/api/categorie/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCategories(observe?: 'body', reportProgress?: boolean): Observable<Array<Categorie>>;
    public getCategories(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Categorie>>>;
    public getCategories(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Categorie>>>;
    public getCategories(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        // if (token != undefined) {
        //     headers = headers.set('Authorization', token);
        // }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Categorie>>(`${this.basePath}/api/categorie`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param categorie
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCategorie(categorie?: Categorie, observe?: 'body', reportProgress?: boolean): Observable<Categorie>;
    public postCategorie(categorie?: Categorie, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Categorie>>;
    public postCategorie(categorie?: Categorie, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Categorie>>;
    public postCategorie(categorie?: Categorie, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Categorie>(`${this.basePath}/api/categorie`,
            categorie,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param categorie
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putCategorie(id?: string, categorie?: Categorie, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public putCategorie(id?: string, categorie?: Categorie, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public putCategorie(id?: string, categorie?: Categorie, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public putCategorie(id?: string, categorie?: Categorie, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/categorie`,
            categorie,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
