import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from '../../_constants/constant';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';

@Component({
  selector: 'app-emploi',
  templateUrl: './emploi.component.html',
  styleUrls: ['./emploi.component.scss']
})
export class EmploiComponent implements OnInit {

  @Input() Job = {
    body: '',
    bodyEN: '',
    dateCreation: null,
    dateModification: null,
    fichier: [],
    id: '',
    link: '',
    name: '',
    nameEN: '',
    order: -1,
    titre: '',
    titreEN: '',
    utilisateurCreation: ''
  };
  @Input() url;
  isIndex: boolean;
  isFrench: boolean;
  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.isIndex = this.router.url.indexOf('carrieres') === -1;
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
  }

  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
