import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatTableDataSource } from '@angular/material/table';
import {
  faAddressBook,
  faCheckCircle,
  faEnvelope,
  faFolder,
  faPaperPlane,
  faPencilAlt,
  faSave,
  faTimesCircle,
  faTrash,
  faUndo,
  faUsers,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { Mail, MailService, UtilisateurService, ConfigurationMailService } from 'src/app/rest';
import { ConfigurationMail } from 'src/app/rest/model/configurationMail';
import { NoReplay } from 'src/app/rest/model/noReplay';
import { MatChipInputEvent } from '@angular/material/chips';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';
import { CRMService } from 'src/app/rest/api/cRM.service';

@Component({
  selector: 'app-parametres',
  templateUrl: './parametres.component.html',
  styleUrls: ['./parametres.component.scss']
})
export class ParametresComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('AddMail', { static: false }) AddMail;
  @ViewChild('DelMail', { static: false }) DeleteMail;
  @ViewChild('UpdMail', { static: false }) UpdateMail;
  buttvisible = false;
  faPencil = faPencilAlt;
  faEnveloppe = faEnvelope;
  faAdressBook = faAddressBook;
  faFolder = faFolder;
  faUsers = faUsers;
  faPaperPlane = faPaperPlane;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faTrash = faTrash;
  faUndo = faUndo;
  faFloppy = faSave;
  faInfoCircle = faInfoCircle;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  isLoading = true;
  displayedColumns: string[] = ['Nom', 'E-Mail', 'Actif', 'Catégorie', 'Actions'];
  dataSource = new MatTableDataSource();
  element: Mail[];
  tagsCcContact: any[] = [];
  tagsCcContactAdmin: any[] = [];
  tagsToContactAdmin: any[] = [];

  tagsCcCandidature: any[] = [];
  tagsCcCandidatureAdmin: any[] = [];
  tagsToCandidatureAdmin: any[] = [];

  name: string;
  mail: string;
  isActif: boolean;
  // categorie: Mail.CategorieEnum;
  isWorking: boolean;
  currentUser;
  delMail: Mail;
  updMail: Mail;
  Mailtest: Mail;

  configMail: ConfigurationMail;
  verifNoReplay: boolean;
  isSaving: boolean;
  selectedItem: any = '';
  inputChanged: any = '';
  wikiItems: any[] = [];

  // Parameters
  hote: string;
  port: number;
  email: string;
  password: string;
  enableSSL: boolean;

  objetContact: string;
  msgContact: string;

  objetContactEN: string;
  msgContactEN: string;

  objetContactAdmin: string;
  msgContactAdmin: string;

  objetCond: string;
  msgCond: string;

  objetCondAdmin: string;
  msgCondAdmin: string;

  userNewObjet: string;
  userNewMsg: string;
  userRecObjet: string;
  userRecMsg: string;

  apiLink: string;
  crmLink: string;
  clientSecret: string;
  clientId: string;
  tenantId: string;
  entityName: string;

  contactObjetAdminEn: string;
  contactMsgAdminEn: string;
  condObjetEn: string;
  condMsgEn: string;
  condObjetAdminEn: string;
  condMsgAdminEn: string;

  Contact: string[] = [
    'name',
    'object',
    'email',
    'message',
    'phone',
    'entreprise',
    'job'
  ];

  User: string[] = [
    'username',
    'email',
    'password',
    'link'
  ];

  Condidature: string[] = [
    'name',
    'email',
    'phone',
    'message'
  ];

  tooltipContacts =
    '- name : Nom & Prénom du candidat '
    + '\n' + '- object : Objet du candidat'
    + '\n' + '- email : L\'adresse mail du candidat'
    + '\n' + '- message : Message envoyé par le candidat'
    + '\n' + '- phone : N° téléphone du candidat'
    + '\n' + '- entreprise : Société du candidat'
    + '\n' + '- job : Fonction du candidat';

  tooltipCondidate =
    '- name : Nom & Prénom du candidat '
    + '\n' + '- email : E-mail du candidat'
    + '\n' + '- phone : N° téléphone du candidat'
    + '\n' + '- message : Message envoyé par le candidat';

  tooltipUser =
    '- username : Nom de l\'utilisateur '
    + '\n' + '- email : L\'adresse mail de l\'utilisateur'
    + '\n' + '- password : Mot de passe de l\'utilisateur '
    + '\n' + '- link : Le lien pour accéder au site ';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    sanitize: false,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Saisissez le message',
    translate: 'no',
    // defaultParagraphSeparator: 'p',
    // defaultFontName: 'Arial',

    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    // sanitize: true
  };

  formControlValue = '';
  MailSettings: Mail[];

  myfun() {
    this.buttvisible = !this.buttvisible;
  }
  findChoices(searchText: string) {
    return ['John', 'Jane', 'Jonny'].filter(item =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  getChoiceLabel(choice: string) {
    return `@${choice} `;
  }

  constructor(
    private mailService: MailService,
    private toastr: ToastrService,
    private utilisateur: UtilisateurService,
    private settings: ConfigurationMailService,
    public loadingPagesService: LoadingPagesService,
    private crmService: CRMService
  ) { }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.hote = '';
    this.port = 0;
    this.email = '';
    this.password = '';
    this.enableSSL = true;
    this.selectable = true;
    this.removable = true;
    this.addOnBlur = true;
    this.objetContact = '';
    this.msgContact = '';

    this.apiLink = '';
    this.crmLink = '';
    this.clientSecret = '';
    this.clientId = '';
    this.tenantId = '';
    this.entityName = '';

    this.objetContactEN = '';
    this.msgContactEN = '';
    this.contactObjetAdminEn = '';
    this.contactMsgAdminEn = '';
    this.condObjetEn = '';
    this.condMsgEn = '';
    this.condObjetAdminEn = '';
    this.condMsgAdminEn = '';

    this.objetContactAdmin = '';
    this.msgContactAdmin = '';


    this.objetCond = '';
    this.msgCond = '';

    this.objetCondAdmin = '';
    this.msgCondAdmin = '';

    this.userNewObjet = '';
    this.userNewMsg = '';
    this.userRecObjet = '';
    this.userRecMsg = '';

    this.verifNoReplay = false;
    this.isSaving = false;
    this.currentUser = [];

    this.tagsCcContact = [];
    this.tagsCcContactAdmin = [];
    this.tagsToContactAdmin = [];

    this.tagsCcCandidature = [];
    this.tagsCcCandidatureAdmin = [];
    this.tagsToCandidatureAdmin = [];




    this.getSettings();

    this.getUser();
    this.load_data();
  }

  setItem(element) {
    this.delMail = element;
  }

  getSettings() {
    this.settings.get().subscribe(data => {
      this.configMail = data;
      this.hote = this.configMail.hote;
      this.port = this.configMail.port;
      this.email = this.configMail.email;
      this.password = this.configMail.password;
      this.enableSSL = this.configMail.enableSSL;

      this.objetContact = this.configMail.contactObjet;
      this.msgContact = this.configMail.contactMsg;

      this.objetContactEN = this.configMail.contactObjetEn;
      this.msgContactEN = this.configMail.contactMsgEn;
      this.contactObjetAdminEn = this.configMail.contactObjetAdminEn;
      this.contactMsgAdminEn = this.configMail.contactMsgAdminEn;
      this.condObjetEn = this.configMail.condObjetEn;
      this.condMsgEn = this.configMail.condMsgEn;
      this.condObjetAdminEn = this.configMail.condObjetAdminEn;
      this.condMsgAdminEn = this.configMail.condMsgAdminEn;

      this.objetContactAdmin = this.configMail.contactObjetAdmin;
      this.msgContactAdmin = this.configMail.contactMsgAdmin;

      this.objetCond = this.configMail.condObjet;
      this.msgCond = this.configMail.condMsg;

      this.objetCondAdmin = this.configMail.condObjetAdmin;
      this.msgCondAdmin = this.configMail.condMsgAdmin;

      this.userNewObjet = this.configMail.userNewObjet;
      this.userNewMsg = this.configMail.userNewMsg;
      this.userRecObjet = this.configMail.userRecObjet;
      this.userRecMsg = this.configMail.userRecMsg;

      this.apiLink = this.configMail.apiLink;
      this.crmLink = this.configMail.crmLink;
      this.clientSecret = this.configMail.clientSecret;
      this.clientId = this.configMail.clientId;
      this.tenantId = this.configMail.tenantId;
      this.entityName = this.configMail.entityName;

      this.objetContactEN = this.configMail.contactObjetEn;
      this.msgContactEN = this.configMail.contactMsgEn;
      this.contactObjetAdminEn = this.configMail.contactObjetAdminEn;
      this.contactMsgAdminEn = this.configMail.contactMsgAdminEn;
      this.condObjetEn = this.configMail.condObjetEn;
      this.condMsgEn = this.configMail.condMsgEn;
      this.condObjetAdminEn = this.configMail.condObjetAdminEn;
      this.condMsgAdminEn = this.configMail.condMsgAdminEn;
    });
  }

  saveNoReplay() {
    this.configMail.hote = this.hote;
    this.configMail.port = this.port;
    this.configMail.email = this.email;
    this.configMail.password = this.password;
    this.configMail.enableSSL = this.enableSSL;
    this.configMail.apiLink = this.apiLink;
    this.configMail.crmLink = this.crmLink;
    this.configMail.clientId = this.clientId;
    this.configMail.clientSecret = this.clientSecret;
    this.configMail.tenantId = this.tenantId;
    this.configMail.entityName = this.entityName;

    this.configMail.contactObjetEn = this.objetContactEN;
    this.configMail.contactMsgEn = this.msgContactEN;
    this.configMail.contactObjetAdminEn = this.contactObjetAdminEn;
    this.configMail.contactMsgAdminEn = this.contactMsgAdminEn;
    this.configMail.condObjetEn = this.condObjetEn;
    this.configMail.condMsgEn = this.condMsgEn;
    this.configMail.condObjetAdminEn = this.condObjetAdminEn;
    this.configMail.condMsgAdminEn = this.condMsgAdminEn;

    this.isSaving = true;
    this.settings.put(this.configMail.id, this.configMail).subscribe(data => {
      this.toastr.success('', 'Succès',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.isSaving = false;
    }, error => {
      this.toastr.error(error, 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.isSaving = false;
    });
  }
  removeTags(item: any, type: string): void {
    if (type === 'CcContact') {
      const index = this.tagsCcContact.indexOf(item);
      if (index >= 0) {
        this.tagsCcContact.splice(index, 1);
      }
    }
    if (type === 'CcContactAdmin') {
      const index = this.tagsCcContactAdmin.indexOf(item);
      if (index >= 0) {
        this.tagsCcContactAdmin.splice(index, 1);
      }
    }
    if (type === 'ToContactAdmin') {
      const index = this.tagsToContactAdmin.indexOf(item);
      if (index >= 0) {
        this.tagsToContactAdmin.splice(index, 1);
      }
    }
    if (type === 'CcCandidature') {
      const index = this.tagsCcCandidature.indexOf(item);
      if (index >= 0) {
        this.tagsCcCandidature.splice(index, 1);
      }
    }
    if (type === 'ToCandidatureAdmin') {
      const index = this.tagsToCandidatureAdmin.indexOf(item);
      if (index >= 0) {
        this.tagsToCandidatureAdmin.splice(index, 1);
      }
    }
    if (type === 'CcCandidatureAdmin') {
      const index = this.tagsCcCandidatureAdmin.indexOf(item);
      if (index >= 0) {
        this.tagsCcCandidatureAdmin.splice(index, 1);
      }
    }

  }
  addTags(event: MatChipInputEvent, type: string): void {
    const input = event.input;
    const value = event.value;
    if (type === 'CcContact') {
      if ((value || '').trim()) {
        this.tagsCcContact.push({ name: value.trim() });
      }
      if (input) {
        input.value = '';
      }
    }
    if (type === 'CcContactAdmin') {
      if ((value || '').trim()) {
        this.tagsCcContactAdmin.push({ name: value.trim() });
      }
      if (input) {
        input.value = '';
      }
    }
    if (type === 'ToContactAdmin') {
      if ((value || '').trim()) {
        this.tagsToContactAdmin.push({ name: value.trim() });
      }
      if (input) {
        input.value = '';
      }
    }
    if (type === 'CcCandidature') {
      if ((value || '').trim()) {
        this.tagsCcCandidature.push({ name: value.trim() });
      }
      if (input) {
        input.value = '';
      }
    }
    if (type === 'ToCandidatureAdmin') {
      if ((value || '').trim()) {
        this.tagsToCandidatureAdmin.push({ name: value.trim() });
      }
      if (input) {
        input.value = '';
      }
    }
    if (type === 'CcCandidatureAdmin') {
      if ((value || '').trim()) {
        this.tagsCcCandidatureAdmin.push({ name: value.trim() });
      }
      if (input) {
        input.value = '';
      }
    }
  }


  saveContact() {
    if (this.objetContact !== '' && this.msgContact !== '' && this.objetContactAdmin !== '' && this.msgContactAdmin !== '') {
      this.configMail.contactObjet = this.objetContact;
      this.configMail.contactMsg = this.msgContact;

      this.configMail.contactObjetAdmin = this.objetContactAdmin;
      this.configMail.contactMsgAdmin = this.msgContactAdmin;

      this.configMail.contactObjetEn = this.objetContactEN;
      this.configMail.contactMsgEn = this.msgContactEN;
      this.configMail.contactObjetAdminEn = this.contactObjetAdminEn;
      this.configMail.contactMsgAdminEn = this.contactMsgAdminEn;
      this.configMail.condObjetEn = this.condObjetEn;
      this.configMail.condMsgEn = this.condMsgEn;
      this.configMail.condObjetAdminEn = this.condObjetAdminEn;
      this.configMail.condMsgAdminEn = this.condMsgAdminEn;

      this.isSaving = true;
      let tagCcContact = '';
      let tagCcContactAdmin = '';
      let tagToContactAdmin = '';
      let emailtester = true;

      if (this.tagsCcContact.length !== 0) {
        // tslint:disable-next-line: no-shadowed-variable
        this.tagsCcContact.forEach((element) => {
          tagCcContact = tagCcContact + element.name + '*';
          emailtester = emailtester && this.validateEmail(element.name);
        });
      }
      if (this.tagsCcContactAdmin.length !== 0) {
        // tslint:disable-next-line: no-shadowed-variable
        this.tagsCcContactAdmin.forEach((element) => {
          tagCcContactAdmin = tagCcContactAdmin + element.name + '*';
          emailtester = emailtester && this.validateEmail(element.name);
        });
      }
      if (this.tagsToContactAdmin.length !== 0) {
        // tslint:disable-next-line: no-shadowed-variable
        this.tagsToContactAdmin.forEach((element) => {
          tagToContactAdmin = tagToContactAdmin + element.name + '*';
          emailtester = emailtester && this.validateEmail(element.name);
        });
      }
      if (emailtester) {
        this.mailService.get().subscribe(data => {
          this.Mailtest = data[0];
          const UpdatedMail: Mail = {
            id: this.Mailtest.id,
            emailCcContact: tagCcContact,
            emailCcContactAdmin: tagCcContactAdmin,
            emailToContactAdmin: tagToContactAdmin,
            emailCcCandidature: data[0].emailCcCandidature,
            emailCcCandidatureAdmin: data[0].emailCcCandidatureAdmin,
            emailToCandidatureAdmin: data[0].emailToCandidatureAdmin,
            utilisateurCreation: data[0].utilisateurCreation,
            utilisateurModification: this.currentUser.userName,
            dateCreation: data[0].dateCreation,
            dateModification: new Date(),
          };
          this.mailService.put(UpdatedMail.id, UpdatedMail).subscribe(() => {

            this.ngOnInit();
            // this.UpdateMail.nativeElement.click();
            // this.isWorking = false;
          }, error => {
            this.toastr.error(error, 'Erreur ajout email',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
            // this.isWorking = false;
          });

        }, error => {
          this.toastr.error(error, 'Erreur ajout données',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          // this.isWorking = false;
        });

        this.settings.put(this.configMail.id, this.configMail).subscribe(() => {
          this.toastr.success('', 'Données ont été mis à jour avec succès',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          this.isSaving = false;
        }, error => {
          this.toastr.error(error, 'Erreur',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          this.isSaving = false;
        });
      } else {
        this.toastr.error('', 'Veuillez entrer une adresse mail valide',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.isSaving = false;
      }
    } else {
      this.toastr.error('', 'Veuillez verifier les champs vides',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.isSaving = false;
    }
  }

  async saveCond() {

    if (this.objetCond !== '' && this.msgCond !== '' && this.objetCondAdmin !== '' && this.msgCondAdmin !== '') {
      this.configMail.condObjet = this.objetCond;
      this.configMail.condMsg = this.msgCond;

      this.configMail.condObjetAdmin = this.objetCondAdmin;
      this.configMail.condMsgAdmin = this.msgCondAdmin;
      this.configMail.contactObjetEn = this.objetContactEN;
      this.configMail.contactObjetAdminEn = this.contactObjetAdminEn;
      this.configMail.contactMsgAdminEn = this.contactMsgAdminEn;
      this.configMail.condObjetEn = this.condObjetEn;
      this.configMail.condMsgEn = this.condMsgEn;
      this.configMail.condObjetAdminEn = this.condObjetAdminEn;
      this.configMail.condMsgAdminEn = this.condMsgAdminEn;
      this.isSaving = true;

      let tagCcCandidature = '';
      let tagCcCandidatureAdmin = '';
      let tagToCandidatureAdmin = '';
      let emailtester = true;

      if (this.tagsCcCandidature.length !== 0) {
        // tslint:disable-next-line: no-shadowed-variable
        this.tagsCcCandidature.forEach((element) => {
          tagCcCandidature = tagCcCandidature + element.name + '*';
          emailtester = emailtester && this.validateEmail(element.name);
        });
      }
      if (this.tagsCcCandidatureAdmin.length !== 0) {
        // tslint:disable-next-line: no-shadowed-variable
        this.tagsCcCandidatureAdmin.forEach((element) => {
          tagCcCandidatureAdmin = tagCcCandidatureAdmin + element.name + '*';
          emailtester = emailtester && this.validateEmail(element.name);
        });
      }
      if (this.tagsToCandidatureAdmin.length !== 0) {
        // tslint:disable-next-line: no-shadowed-variable
        this.tagsToCandidatureAdmin.forEach((element) => {
          tagToCandidatureAdmin = tagToCandidatureAdmin + element.name + '*';
          emailtester = emailtester && this.validateEmail(element.name);
        });
      }
      if (emailtester) {
        await this.mailService.get().subscribe(data => {
          this.Mailtest = data[0];
          const UpdatedMail: Mail = {
            id: data[0].id,
            emailCcContact: data[0].emailCcContact,
            emailCcContactAdmin: data[0].emailCcContactAdmin,
            emailToContactAdmin: data[0].emailToContactAdmin,
            emailCcCandidature: tagCcCandidature,
            emailCcCandidatureAdmin: tagCcCandidatureAdmin,
            emailToCandidatureAdmin: tagToCandidatureAdmin,
            utilisateurCreation: data[0].utilisateurCreation,
            utilisateurModification: this.currentUser.userName,
            dateCreation: data[0].dateCreation,
            dateModification: new Date(),
          };
          this.mailService.put(UpdatedMail.id, UpdatedMail).subscribe(() => {
            this.ngOnInit();
            // this.UpdateMail.nativeElement.click();
            // this.isWorking = false;
          }, error => {
            this.toastr.error(error, 'Erreur ajout email',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
            this.isWorking = false;
          });


        }, error => {
          this.toastr.error(error, 'Erreur ajout mail',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          // this.isWorking = false;
        });

        this.settings.put(this.configMail.id, this.configMail).subscribe(() => {
          this.toastr.success('', 'Données ont été mis à jour avec succès',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          this.isSaving = false;
        }, error => {
          this.toastr.error(error, 'Erreur ajout données',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          this.isSaving = false;
        });
      } else {
        this.toastr.error('', 'Veuillez entrer une adresse mail valide',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.isSaving = false;
      }
    } else {
      this.toastr.error('', 'Veuillez verifier les champs vides',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.isSaving = false;
    }
  }

  saveUsers() {
    this.configMail.userNewMsg = this.userNewMsg;
    this.configMail.userNewObjet = this.userNewObjet;
    this.configMail.userRecMsg = this.userRecMsg;
    this.configMail.userRecObjet = this.userRecObjet;
    this.configMail.apiLink = this.apiLink;
    this.configMail.crmLink = this.crmLink;
    this.configMail.clientId = this.clientId;
    this.configMail.clientSecret = this.clientSecret;
    this.configMail.tenantId = this.tenantId;
    this.configMail.entityName = this.entityName;

    this.configMail.contactObjetEn = this.objetContactEN;
    this.configMail.contactMsgEn = this.msgContactEN;
    this.configMail.contactObjetAdminEn = this.contactObjetAdminEn;
    this.configMail.contactMsgAdminEn = this.contactMsgAdminEn;
    this.configMail.condObjetEn = this.condObjetEn;
    this.configMail.condMsgEn = this.condMsgEn;
    this.configMail.condObjetAdminEn = this.condObjetAdminEn;
    this.configMail.condMsgAdminEn = this.condMsgAdminEn;

    this.isSaving = true;
    this.settings.put(this.configMail.id, this.configMail).subscribe(() => {
      this.toastr.success('', 'Succès',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.isSaving = false;
    }, error => {
      this.toastr.error(error, 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.isSaving = false;
    });
  }

  testerMail() {
    this.verifNoReplay = true;
    const noReplay: NoReplay = {
      host: this.hote,
      port: this.port,
      userName: this.email,
      password: this.password,
      enableSSL: this.enableSSL
    };

    this.settings.testNoRepaly('amahmoudi@aiventu.com', noReplay).subscribe(data => {
      if (data === '') {
        this.toastr.success('La connexion a été effectuée avec succès', 'Succès',
          {
            timeOut: 0,
            extendedTimeOut: 0,
            progressBar: true,
            closeButton: true
          });
      } else {
        this.toastr.error('Veuillez vérifier les parmètres des connexions', 'Erreur',
          {
            timeOut: 0,
            extendedTimeOut: 0,
            progressBar: true,
            closeButton: true
          });
      }
      this.verifNoReplay = false;
    }, error => {
      this.verifNoReplay = false;
      console.log(error);
    });
  }

  testerCRM() {
    this.isSaving = true;
    this.crmService.testSettings(this.clientSecret, this.crmLink, this.clientId, this.tenantId, this.apiLink, this.entityName)
      .subscribe(() => {
        this.toastr.success('La connexion a été effectuée avec succès', 'Succès',
          {
            timeOut: 0,
            extendedTimeOut: 0,
            progressBar: true,
            closeButton: true
          });
        this.isSaving = false;
      }, () => {
        this.toastr.error('Veuillez vérifier les parmètres des connexions', 'Erreur',
          {
            timeOut: 0,
            extendedTimeOut: 0,
            progressBar: true,
            closeButton: true
          });
        this.isSaving = false;
      });
  }

  Delete() {
    this.isWorking = true;
    this.mailService._delete(this.delMail.id).subscribe(() => {
      this.DeleteMail.nativeElement.click();
      this.toastr.info('l\'adresse email est supprimée', 'Information',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.isWorking = false;
      this.ngOnInit();
    }, error => {
      this.toastr.error(error, 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.isWorking = false;
    });
  }

  // setUpdate(element: Mail) {
  //   this.updMail = element;
  //   this.name = element.name;
  //   this.mail = element.mail;
  //   this.isActif = element.isActif;
  //   if (element.categorie.toString() === '0') {
  //     this.categorie = 'Condidature';
  //   } else {
  //     this.categorie = 'Contact';
  //   }

  // }

  // Update() {
  //   this.isWorking = true;
  //   this.mailService.getMailCat(this.mail, this.categorie).subscribe(data => {
  //     if (data.length === 0) {
  //       this.updMail.name = this.name;
  //       this.updMail.mail = this.mail;
  //       this.updMail.isActif = this.isActif;
  //       this.updMail.categorie = this.categorie;
  //       this.updMail.dateModification = new Date();
  //       this.updMail.utilisateurModification = this.currentUser.userName;
  //       this.mailService.put(this.updMail.id, this.updMail).subscribe(() => {
  //         this.toastr.success('', 'Succès',
  //           {
  //             timeOut: 5000,
  //             progressBar: true,
  //             closeButton: true
  //           });
  //         this.ngOnInit();
  //         this.UpdateMail.nativeElement.click();
  //         this.isWorking = false;
  //       }, error => {
  //         this.toastr.error(error, 'Erreur',
  //           {
  //             timeOut: 5000,
  //             progressBar: true,
  //             closeButton: true
  //           });
  //         this.isWorking = false;
  //       });
  //     }
  //   }, error => {
  //     this.toastr.error(error, 'Erreur',
  //       {
  //         timeOut: 5000,
  //         progressBar: true,
  //         closeButton: true
  //       });
  //     this.isWorking = false;
  //   });
  // }

  resetForm() { }

  getUser() {
    if (localStorage.getItem('MyApp_Auth') !== undefined) {
      const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
      this.utilisateur.getUtilisateur(userId).subscribe(data => {
        this.currentUser = data;
      });
    }
  }

  Save() {
    // if (this.name === '') {
    //   this.toastr.error('Veuillez renseigner le champs nom', 'Erreur',
    //     {
    //       timeOut: 5000,
    //       progressBar: true,
    //       closeButton: true
    //     });
    // } else if (this.validateEmail(this.mail) === false) {
    //   this.toastr.error('l\'adresse email est invalide', 'Erreur',
    //     {
    //       timeOut: 5000,
    //       progressBar: true,
    //       closeButton: true
    //     });
    // } else {
    //   this.isWorking = true;
    //   const AdresseMail: Mail = {
    //     id: Guid.create().toString(),
    //     name: this.name,
    //     mail: this.mail,
    //     isActif: this.isActif,
    //     categorie: this.categorie,
    //     utilisateurCreation: this.currentUser.userName,
    //     utilisateurModification: this.currentUser.userName,
    //     dateCreation: new Date(),
    //     dateModification: new Date()
    //   };
    //   this.mailService.getMailCat(this.mail, this.categorie).subscribe(data => {
    //     if (data.length === 0) {
    //       this.mailService.postAdmin(AdresseMail).subscribe(() => {
    //         this.toastr.success('', 'Succès',
    //           {
    //             timeOut: 5000,
    //             progressBar: true,
    //             closeButton: true
    //           });
    //         this.ngOnInit();
    //         this.AddMail.nativeElement.click();
    //         this.isWorking = false;
    //       }, (error) => {
    //         this.toastr.error(error, 'Erreur',
    //           {
    //             timeOut: 5000,
    //             progressBar: true,
    //             closeButton: true
    //           });
    //         this.isWorking = false;
    //       });
    //     } else {
    //       this.isWorking = false;
    //       this.toastr.error('La combinaison Email et Catégorie déjà existe', 'Erreur',
    //         {
    //           timeOut: 5000,
    //           progressBar: true,
    //           closeButton: true
    //         });
    //     }
    //   }, error => {
    //     this.toastr.error(error, 'Erreur',
    //       {
    //         timeOut: 5000,
    //         progressBar: true,
    //         closeButton: true
    //       });
    //     this.isWorking = false;
    //   });
    // }
  }

  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public load_data() {
    this.isLoading = true;
    this.dataSource.data = [];

    this.mailService.get().subscribe((data) => {
      if (data !== undefined) {
        this.element = data;
        this.isLoading = false;
        this.dataSource.data = this.element;
        ///////////////////////////////////////
        const tabTagsCcContact = this.element[0].emailCcContact.split('*');
        // tslint:disable-next-line: no-shadowed-variable
        tabTagsCcContact.forEach((element) => {
          if (element !== '') {
            this.tagsCcContact.push({ name: element.trim() });
          }
        });
        //////////////////////////////////////
        const tabTagsCcContactAdmin = this.element[0].emailCcContactAdmin.split('*');
        // tslint:disable-next-line: no-shadowed-variable
        tabTagsCcContactAdmin.forEach((element) => {
          if (element !== '') {
            this.tagsCcContactAdmin.push({ name: element.trim() });
          }
        });
        //////////////////////////////////////
        const tabTagsToContactAdmin = this.element[0].emailToContactAdmin.split('*');
        // tslint:disable-next-line: no-shadowed-variable
        tabTagsToContactAdmin.forEach((element) => {
          if (element !== '') {
            this.tagsToContactAdmin.push({ name: element.trim() });
          }
        });
        //////////////////////////////////////
        const tabTagsCcCandidature = this.element[0].emailCcCandidature.split('*');
        // tslint:disable-next-line: no-shadowed-variable
        tabTagsCcCandidature.forEach((element) => {
          if (element !== '') {
            this.tagsCcCandidature.push({ name: element.trim() });
          }
        });
        /////////////////////////////////////////////
        const tabTagsToCandidatureAdmin = this.element[0].emailToCandidatureAdmin.split('*');
        // tslint:disable-next-line: no-shadowed-variable
        tabTagsToCandidatureAdmin.forEach((element) => {
          if (element !== '') {
            this.tagsToCandidatureAdmin.push({ name: element.trim() });
          }
        });
        ////////////////////////////////////////////////
        const tabTagsCcCandidatureAdmin = this.element[0].emailCcCandidatureAdmin.split('*');
        // tslint:disable-next-line: no-shadowed-variable
        tabTagsCcCandidatureAdmin.forEach((element) => {
          if (element !== '') {
            this.tagsCcCandidatureAdmin.push({ name: element.trim() });
          }
        });
        ///////////////////////////////////////////////
      }
    },
      () => {
        this.isLoading = false;
        this.toastr.error('', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });

  }



}

