import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-vueensemble',
  templateUrl: './vueensemble.component.html',
  styleUrls: ['./vueensemble.component.scss']

})
export class VueensembleComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  constructor(public sanitizer: DomSanitizer, public loadingPagesService: LoadingPagesService) { }

  ngOnInit() {
    this.loadingPagesService.setPageStatus(true);
    this.urlSafe = this.sanitizer
    .bypassSecurityTrustResourceUrl
    ('https://datastudio.google.com/embed/reporting/d6c9476d-fb8d-4231-8939-d5fbe3623abe/page/efpgC');
    //('https://datastudio.google.com/embed/reporting/db724519-26aa-4f36-a3a1-51539c6f2d20/page/eThQC');
  }

}
