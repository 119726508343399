  <div (mouseover)="setMeta(NosSolutionsPage)" (click)="setMeta(NosSolutionsPage)" [ngStyle]="{'margin-bottom': (IsMobile) ? '60%' : '8%'}">
    <div class="text-center">
        <h2 *ngIf="isFrench && NosSolutionsTitres !== undefined" [innerHTML]="NosSolutionsTitres[0].contenu | safeHtml"></h2>
        <h2 *ngIf="!isFrench && NosSolutionsTitres !== undefined" [innerHTML]="NosSolutionsTitres[0].contenuEN | safeHtml"></h2>
        <app-hr *ngIf="hr" ></app-hr> 
    </div>
    <div class="row size" style="height: 100%;">
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12"  *ngFor="let paragraphe of NosSolutions; let i=index ">
            <div class="car">

                <figure class="imghvr-slide-up" [ngStyle]="{'background-image': GetImage(paragraphe.fichier[0].filePath)} " style="background-size: cover;background-repeat: no-repeat;background-position: center;">
                    <div class="flip-card">

                        <div alt="Card image cap" class="card-img-top" [style.min-height]="CardHeight+'px'"></div>
                        <div  style="background: rgba(7, 88, 161, 0.8); padding-bottom: 1%;">
                        
                        <h3 *ngIf="isFrench" style="padding-top: 4%;" [innerHTML]="paragraphe.titre | safeHtml"></h3>
                        <h3 *ngIf="!isFrench" style="padding-top: 4%;" [innerHTML]="paragraphe.titreEN | safeHtml"></h3>
                        <a [href]="" routerLink="{{paragraphe.link}}" routerLinkActive="active" class="nav-link active a1" translate>VoirDetails</a>
                        </div>
                        <figcaption style="background: rgba(7, 88, 161, 0.8);">
                            <h3 *ngIf="isFrench" [innerHTML]="paragraphe.titre | safeHtml"></h3>
                            <h3 *ngIf="!isFrench" [innerHTML]="paragraphe.titreEN | safeHtml"></h3>
                            <p *ngIf="isFrench" [innerHTML]="paragraphe.body | safeHtml"></p>
                            <p *ngIf="!isFrench" [innerHTML]="paragraphe.bodyEN | safeHtml"></p>
                            <a [href]="" routerLink="{{paragraphe.link}}" routerLinkActive="active"
                                class="nav-link active a2" translate>VoirDetails</a>
                        </figcaption>

                    </div>
                </figure>
                   
            </div>
        </div>
    </div>
    </div>



