<div (mouseover)="setMeta(NosReferencesPage)" (click)="setMeta(NosReferencesPage)" style="margin-bottom: 5%;" *ngIf="!isWorking">
    <div class="text-center">
        <h2 *ngIf="isFrench && NosReferencesTitres !== undefined"
            [innerHTML]="NosReferencesTitres[0].contenu | safeHtml"></h2>
        <h2 *ngIf="!isFrench && NosReferencesTitres !== undefined"
            [innerHTML]="NosReferencesTitres[0].contenuEN | safeHtml"></h2>
        <app-hr *ngIf="hr"></app-hr>
    </div>
    <div class="container-fluid" style="margin-top: 6%;">
        <div class="card">
            <div class="card-header">
                <ul class="nav nav-tabs  nav-fill card-header-tabs pull-right" id="myTab" role="tablist">
                    <li class="nav-item" *ngFor="let Reference of NosReferences; let i = index;"
                        [ngClass]="{active: i==0}">
                        <a *ngIf="isFrench" class="nav-link textwhite" id="home-tab" data-toggle="tab"
                            href="#{{tabs[i]}}" role="tab" aria-selected="true"
                            [innerHTML]="Reference.titre | safeHtml"></a>
                        <a *ngIf="!isFrench" class="nav-link textwhite" id="home-tab" data-toggle="tab"
                            href="#{{tabs[i]}}" role="tab" aria-selected="true"
                            [innerHTML]="Reference.titreEN | safeHtml"></a>
                    </li>

                </ul>
            </div>

            <div class="card-body">
                <div class="tab-content" id="myTabContent">
                    <div *ngFor="let Reference of NosReferences; let i = index;" class="tab-pane fade show"
                        id="{{tabs[i]}}" role="tabpanel" [attr.aria-labelledby]="tabs[i]" [ngClass]="{active: i==0}">
                        <img *ngIf="Reference.fichier.length != 0" class="col-12 lazyload"
                            [attr.data-src]="GetImage(Reference.fichier[0].filePath)" alt="{{Reference.fichier[0].alt}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>