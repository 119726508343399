import { Component, Input, OnInit } from '@angular/core';
import { Constant } from '../../_constants/constant';

@Component({
  selector: 'app-paragraphes',
  templateUrl: './paragraphes.component.html',
  styleUrls: ['./paragraphes.component.scss']
})
export class ParagraphesComponent implements OnInit {
@Input() CTitleArrayCI = [];
@Input() CParagrapheArrayCI = [];
@Input() height: any;
@Input() width: any;
@Input() hr: boolean;
  constructor() { }

  ngOnInit(): void {
    this.height = this.height - 70;
  }

  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }

}
