import { Component, Input, OnInit } from '@angular/core';
import { Constant } from '../../_constants/constant';
import { Meta, Title } from '@angular/platform-browser'; 
import { Page  } from 'src/app/rest';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { LoadingPagesService } from '../../_services/loading-pages.service';

@Component({
  selector: 'app-image-video-texte',
  templateUrl: './image-video-texte.component.html',
  styleUrls: ['./image-video-texte.component.scss']
})
export class ImageVideoTexteComponent implements OnInit {
  @Input() STitleSR = [];
  @Input() SParagrapheSR = [];
  @Input() NosSolutionsPage;
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;
  @Input() IsFrench: boolean;

  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    public loadingPagesService: LoadingPagesService
  ) { }

  ngOnInit(): void {
    this.height = this.height - 70;
    this.setMeta(this.NosSolutionsPage);
  }

  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }
  setMeta(page: Page) {
    if (this.IsFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ name: 'description', content: page.metaDescriptionEN });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
    }
  }
  
  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    this.IsFrench = (lg === 'fr');
  }
}
