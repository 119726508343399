<!-- Error Page -->
<div class="text-center">
    <br>
    <br>
    <br>
    <h1 style="font-size: 220px;font-weight: 500;line-height: 1.2;font-family: inherit;">401</h1>
    <h3>Accès non autorisé</h3>
    <h4><a href="">Retour vers la page d'accueil</a></h4>
    <h4><a href="#" onclick="window.history.go(-1); return false;">Retour vers la page dernière</a></h4>
</div>
<!-- Error Page -->