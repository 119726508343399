import { Component, OnInit } from '@angular/core';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './accessdenied.component.html',
  styleUrls: ['./accessdenied.component.scss']
})
export class AccessdeniedComponent implements OnInit {

  constructor(public loadingPagesService: LoadingPagesService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadingPagesService.setPageStatus(true);
  }

}
