import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationMailService } from 'src/app/rest';
import { CRMService } from 'src/app/rest/api/cRM.service';
import { Contacts } from 'src/app/rest/model/contacts';
import { LoadingPagesService } from '../../_services/loading-pages.service';
import { PageService , Page  } from 'src/app/rest';
import { Meta, Title } from '@angular/platform-browser';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';

@Component({
  selector: 'app-formulaire',
  templateUrl: './formulaire.component.html',
  styleUrls: ['./formulaire.component.scss']
})
export class FormulaireComponent implements OnInit {
  @Input() TitleArrayF = [];
  @Input() ParagrapheArrayF = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;

  // paramètres du formulaire
  @Input() contact: Contacts = {
    name: '',
    objet: '',
    email: '',
    body: '',
    phone: '',
    entreprise: '',
    job: ''
  };

  isWorking: boolean;
  verificationRobot = false;
  heightTitle: any;
  heightLocalisation: any;
  urlSafe;
  NousContacterPage ;
  NousContacterTitres ;
  IsFrench;

  constructor(
    public sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private configuration: ConfigurationMailService,
    private translate: TranslateService,
    public loadingPagesService: LoadingPagesService,
    public crmService: CRMService,
    private pageService: PageService,
    public globalEventService: GlobalLanguageEventService,
    private meta: Meta,
    private title: Title
  ) {
    this.resetForm();
  }

  ngOnInit(): void {
    this.isWorking = true;
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.loadingPagesService.setPageStatus(true);
    this.pageService.getByCategorieAndModele('Nous Contacter', 'Nous Contacter').subscribe(dt => {
      this.NousContacterPage = dt[0];
      this.NousContacterTitres = dt[0].titres;
      this.setMeta(this.NousContacterPage);
      this.isWorking = false;
    }, () => {
      console.log('erreur log');
    });
    window.scrollTo(0, 0);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.095585362941!2d10.' +
      '241002215573713!3d36.84018677314676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3' +
      'm3!1m2!1s0x12fd34922c7f37c3%3A0xe144b265ae0e9855!2sAiventu!5e0!3m2!1sfr!2stn!4v1627305085091!5m2!1sfr!2stn');
    this.heightTitle = this.height / 3;
    this.heightLocalisation = 2 * this.height / 3; 
    this.resetForm();
  }

  resetForm() {
    this.contact.name = '';
    this.contact.objet = '';
    this.contact.email = '';
    this.contact.body = '';
    this.contact.phone = '';
    this.contact.entreprise = '';
    this.contact.job = '';
    this.verificationRobot = false;
  }
  
  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    this.IsFrench = (lg === 'fr');
  }

  onSubmit(e) {
    if (e.form.valid === true
      && this.verificationRobot) {
      this.isWorking = true;
      this.configuration.sendContact(this.contact, this.isFrench()).subscribe(() => {
        this.crmService.postCRM(this.contact.name,
          this.contact.email,
          this.contact.phone,
          this.contact.entreprise,
          this.contact.job,
          this.contact.objet,
          this.contact.body).subscribe(() => {});
        this.toastr.success(this.translate.instant('MsgEnvSucc'), this.translate.instant('Succes'),
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.isWorking = false;
        this.resetForm();
      }, (() => {
        this.toastr.error(this.translate.instant('ErreurEnvMsg'), this.translate.instant('Erreur'),
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.isWorking = false;
      }));
    } else {
      this.toastr.error(this.translate.instant('ChampsObligatoire'), this.translate.instant('Erreur'),
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    }
  }

  checkRecaptcha(event) {
    this.verificationRobot = event;
  }
  setMeta(page: Page) {
    if (this.IsFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com/nous-contacter' });

    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
    }
  }

  isFrench(): boolean {
    const lg = localStorage.getItem('language');
    if (lg === undefined || lg === null) {
      return true;
    }
    return (lg === 'fr');
  }

}
