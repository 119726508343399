import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  urlSafe: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    public loadingPagesService: LoadingPagesService
  ) {
    this.loadingPagesService.setPageStatus(true);
    this.urlSafe = this.sanitizer
    .bypassSecurityTrustResourceUrl
    ('https://datastudio.google.com/embed/reporting/e50d8455-1bb7-4b1e-90d0-fc4c77754817/page/VdpgC');
    //('https://datastudio.google.com/embed/reporting/9b19f712-6b24-4932-8126-db19f87b3ce3/page/jfhQC');
  }
}
