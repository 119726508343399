<div (mouseover)="setMeta(NosSolutionsPage)" (click)="setMeta(NosSolutionsPage)" style="margin-bottom: 5%;" >
    <div *ngIf="isFrench && SuiteInn4 !== undefined" class="paragraph-body-first" [innerHTML]="SuiteInn4[0].body | safeHtml"></div>
    <div *ngIf="!isFrench && SuiteInn4 !== undefined" class="paragraph-body-first" [innerHTML]="SuiteInn4[0].bodyEN | safeHtml"></div>
    <div class="text-center" >
        <h2 *ngIf="isFrench && SuiteInn4Titres !== undefined" [innerHTML]="SuiteInn4Titres[0].contenu | safeHtml"></h2>
        <h2 *ngIf="!isFrench && SuiteInn4Titres !== undefined" [innerHTML]="SuiteInn4Titres[0].contenuEN | safeHtml"></h2>
        <app-hr *ngIf="hr" ></app-hr> 
    </div>

    <div   *ngIf="SuiteInn4 !== undefined" class="row size">
        <div  class="col-lg-4 col-md-6 col-sm-4 col-xs-12"  *ngFor="let paragraphe of SuiteInn4 | slice:1:SuiteInn4.length; let i=index ">
            <div class="car">

                <figure *ngIf="paragraphe.fichier[0].filePath !== undefined" class="imghvr-slide-up" [ngStyle]="{'background-image': GetImage(paragraphe.fichier[0].filePath)}" style="background-size: cover;background-repeat: no-repeat;background-position: center;">
                    <div class="flip-card">

                        <div alt="Card image cap" class="card-img-top" [style.min-height]="CardHeight+'px'"></div>
                        <div  style="background: rgba(7, 88, 161, 0.8); padding-bottom: 1%;">
                        
                        <h3 style="padding-top: 4%;" [innerHTML]="paragraphe.titre | safeHtml"></h3>
                        <a routerLink="{{paragraphe.link}}" [href]="" routerLinkActive="active" class="nav-link active a2" translate>VoirDetails</a>
                        </div>
                        <figcaption style="background: rgba(7, 88, 161, 0.8);">
                            <h3 [innerHTML]="paragraphe.titre | safeHtml"></h3>
                            <p *ngIf="isFrench" [innerHTML]="paragraphe.body | safeHtml"></p>
                            <p *ngIf="!isFrench" [innerHTML]="paragraphe.bodyEN | safeHtml"></p>
                            <a routerLink="{{paragraphe.link}}" routerLinkActive="active" [href]="" class="nav-link active a2" translate>VoirDetails</a>
                        </figcaption>

                    </div>
                </figure>
                   
            </div>
        </div>
    </div>
    </div>



