import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArticlesComponent } from './articles/articles.component';
import { CreerarticleComponent } from './creerarticle/creerarticle.component';
import { ModifierarticleComponent } from './modifierarticle/modifierarticle.component';

const routes: Routes = [
  { path: '', component: ArticlesComponent },
  { path: 'creerarticle', component: CreerarticleComponent },
  { path: 'modifierarticle', component: ModifierarticleComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArticlesRoutingModule { }
