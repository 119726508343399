<nav class="navbar navbar-light bg-white navbar-expand-sm toolbar">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-4"
    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbar-list-4">
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" [href]="" style="color: darkblue; font: bold; font-size: 18;"
          id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img data-src="{{imgURL}}" class="lazyload" alt="toolbar" style=" width: 50px; height: 50px; border-radius: 50%;  object-fit: cover;">
          {{userName}}
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
          <span *ngFor="let item of dropdownMenu">
            <a *ngIf="item.url" [href]="" class="dropdown-item" [routerLink]="item.url"
              routerLinkActive="active">{{item.title}}</a>
            <a *ngIf="item.url==''" class="dropdown-item" [href]="" (click)="item.action()">{{item.title}}</a>
          </span>
          <a [href]="" class="dropdown-item" style="background-color: yellow;"></a>
        </div>
      </li>
    </ul>
  </div>
</nav>