import { Component, Input, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Constant } from '../../_constants/constant';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';

@Component({
  selector: 'app-lien-de-redirection',
  templateUrl: './lien-de-redirection.component.html',
  styleUrls: ['./lien-de-redirection.component.scss']
})
export class LienDeRedirectionComponent implements OnInit {

  @Input() CbackgroundImageURLLR = [];
  @Input() CTitreArrayLR = [];
  @Input() CParagrapheArrayLR = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;

  Image;
  isFrench: boolean;
  IsMobile = false;

  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.height = this.height - 70;
    this.Image = Constant.ApiRoot + '/' + this.CbackgroundImageURLLR[0].filePath;
    if (window.innerWidth < 768 ) {
      this.IsMobile = true ;
    }
  }

  GetImage(path: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }

}
