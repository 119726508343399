import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ActivityService } from './api/activity.service';
import { ArticleService } from './api/article.service';
import { CategorieService } from './api/categorie.service';
import { CommentaireService } from './api/commentaire.service';
import { ConfigurationMailService } from './api/configurationMail.service';
import { FichierService } from './api/fichier.service';
import { FormulaireService } from './api/formulaire.service';
import { GoogleAnayticsAPIService } from './api/googleAnayticsAPI.service';
import { JWTService } from './api/jWT.service';
import { MailService } from './api/mail.service';
import { ModeleService } from './api/modele.service';
import { PageService } from './api/page.service';
import { ParagrapheService } from './api/paragraphe.service';
import { TitreService } from './api/titre.service';
import { UtilisateurService } from './api/utilisateur.service';
import { HistoriqueService } from './api/historique.service';
import { CRMService } from './api/cRM.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        ActivityService,
        ArticleService,
        CategorieService,
        CommentaireService,
        ConfigurationMailService,
        FichierService,
        FormulaireService,
        GoogleAnayticsAPIService,
        JWTService,
        MailService,
        ModeleService,
        PageService,
        ParagrapheService,
        TitreService,
        UtilisateurService,
        HistoriqueService,
        CRMService]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: Configuration, useFactory: configurationFactory }]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ApiModule,
                @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
                'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
