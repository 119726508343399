import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}
// loading element container to transition
// const loadingElement = document.querySelector('.loader');
platformBrowserDynamic().bootstrapModule(AppModule)
  // .then(() => loadingElement.classList.add('loaded'))
  // remove the loading element after the transition is complete to prevent swallowed clicks
  // .then(() => setTimeout(() => loadingElement.remove(), 1000))
  .catch(err => console.error(err));

