<figure class="imghvr-slide-up" style="background-image: url({{Job.fichier[0].filePath}}); background-size: 100% 100%;object-fit: cover;">
    <div class="flip-card">
        <div alt="Card image cap" class="card-img-top" [style.min-height]="CardHeight+'px'" ></div>
        <div  style="background: rgba(7, 88, 161, 0.8); padding-bottom: 1%;">
        <h3 *ngIf="isFrench" style="padding-top: 4%;" [innerHTML]="Job.titre | safeHtml"></h3>
        <h3 *ngIf="!isFrench" style="padding-top: 4%;" [innerHTML]="Job.titreEN | safeHtml"></h3>
        <a [href]="" routerLink="{{Job.link}}" routerLinkActive="active" class="nav-link active a1" translate>VoirDetails</a>
        </div>
        <figcaption style="background: rgba(7, 88, 161, 0.8);">
            <h3 *ngIf="isFrench" [innerHTML]="Job.titre | safeHtml"></h3>
            <h3 *ngIf="!isFrench" [innerHTML]="Job.titreEN | safeHtml"></h3>
            <p *ngIf="isFrench" [innerHTML]="Job.body| safeHtml"></p>
            <p *ngIf="!isFrench" [innerHTML]="Job.bodyEN| safeHtml"></p>
            <a [href]="" routerLink="{{Job.link}}" routerLinkActive="active"
                class="nav-link active a2" translate>VoirDetails</a>
        </figcaption>
    </div>
</figure>
