<div class="site-section">
    <div class=" text-center">
        <div class="block-heading-1 ">
            <h1 *ngIf="isFrench" [innerHTML]="TitreArrayFI[0].contenu | safeHtml"></h1>
            <h1 *ngIf="!isFrench" [innerHTML]="TitreArrayFI[0].contenuEN | safeHtml"></h1>
            <app-hr *ngIf="hr"></app-hr>
        </div>
    </div>
    <div *ngIf="!Bloc" class="bg-light block-2"  [style.width]="width+'px'"
        style="margin-top: 3%;">
        <div class="row" [style.width]="width+'px'" style="width: 72%; margin: auto;">
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10"
                style="margin-left:auto !important; margin-right:auto !important;">
                <app-service-carrousel [ParagrapheArrayS]="ParagrapheArrayFI"></app-service-carrousel>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="Bloc" style="margin-right: 2%;">
        <div *ngFor="let paragraphe of ParagrapheArrayFI" class="col-lg-4 col-md-16 col-sm-12 col-xs-12">
            <div style="background-image: linear-gradient(rgba(7, 88, 161, 0.8),rgba(7, 88, 161, 0.2)),url({{paragraphe.fichier[0].filePath}});  background-size: cover;background-repeat: no-repeat;background-position: center;">
                <div class="card-body" style="margin-bottom: 2%; margin-top: 2%;">
                    <h3 class="cardC" *ngIf="isFrench" [innerHTML]="paragraphe.titre | safeHtml"></h3>
                    <h3 class="cardC" *ngIf="!isFrench" [innerHTML]="paragraphe.titreEN | safeHtml"></h3>
                    <p *ngIf="isFrench" [innerHTML]="paragraphe.body| safeHtml"></p>
                    <p *ngIf="!isFrench" [innerHTML]="paragraphe.bodyEN| safeHtml"></p>
                    <a routerLink="{{paragraphe.link}}" routerLinkActive="active" [href]=""
                        class="nav-link active a2 hoverLink" translate>VoirDetails</a>
                </div>
            </div>
        </div>
    </div>
</div>