<div (mouseover)="setMeta(NosSolutionsPage)" (click)="setMeta(NosSolutionsPage)" [style.min-height]="height+'px'" [ngStyle]="{'margin-bottom': (IsMobile) ? '50%' : '15%'}">
    <div class="text-center">
        <h2 *ngIf="IsFrench" [innerHTML]="STitleArrayCRM[0].contenu | safeHtml"></h2>
        <h2 *ngIf="!IsFrench" [innerHTML]="STitleArrayCRM[0].contenuEN | safeHtml"></h2>
        <app-hr *ngIf="hr"></app-hr>
    </div>
    <div *ngIf="IsFrench && SParagrapheCRM[0].body !== ''" class="text-center paragraph-body-first" [innerHTML]="SParagrapheCRM[0].body | safeHtml">
    </div>
    <div *ngIf="!IsFrench && SParagrapheCRM[0].bodyEN !== ''" class="text-center paragraph-body-first" [innerHTML]="SParagrapheCRM[0].bodyEN | safeHtml">
    </div>
    <div class="container-fluid">
        <div *ngIf="SParagrapheCRM[1].bodyEN !== '' || SParagrapheCRM[1].body !== '' " class="row paragraph-container-Video">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12" style="float: right;">
                <img *ngIf="SParagrapheCRM[1].fichier.length != 0 && SParagrapheCRM[1].fichier[0].type ==='image'"
                    class="image-container lazyload" [src]="GetImage(SParagrapheCRM[1].fichier[0].filePath)"
                    alt="{{SParagrapheCRM[1].fichier[0].alt}}">

                <video *ngIf="SParagrapheCRM[1].fichier.length != 0 && SParagrapheCRM[1].fichier[0].type ==='video'"
                    controls class="image-container">
                    <source [src]="GetImage(SParagrapheCRM[1].fichier[0].filePath)" type="video/mp4"
                        class="image-container">
                </video>
            </div>
            <div class="col-lg-6 col-md-6 order-first order-md-last col-sm-12 col-xs-12 col-12" style="float: left;">
                <div class="title-first">
                    <h3 *ngIf="IsFrench" [innerHTML]="SParagrapheCRM[1].titre | safeHtml"></h3>
                    <h3 *ngIf="!IsFrench" [innerHTML]="SParagrapheCRM[1].titreEN | safeHtml"></h3>
                </div>
                <div *ngIf="IsFrench" class="text-black-first" [innerHTML]="SParagrapheCRM[1].body | safeHtml"></div>
                <div *ngIf="!IsFrench" class="text-black-first" [innerHTML]="SParagrapheCRM[1].bodyEN | safeHtml"></div>
            </div>

        </div>
        <div *ngIf="SParagrapheCRM[2].bodyEN !== '' || SParagrapheCRM[2].body !== '' " class="row paragraph-container">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                <div *ngIf="IsFrench" class="title-second">
                    <h3 [innerHTML]="SParagrapheCRM[2].titre | safeHtml"></h3>
                </div>
                <div *ngIf="IsFrench" class="text-black-second" [innerHTML]="SParagrapheCRM[2].body | safeHtml"></div>
                <div *ngIf="!IsFrench" class="title-second">
                    <h3 [innerHTML]="SParagrapheCRM[2].titreEN | safeHtml"></h3>
                </div>
                <div *ngIf="!IsFrench" class="text-black-second" [innerHTML]="SParagrapheCRM[2].bodyEN | safeHtml">
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                <img *ngIf="SParagrapheCRM[2].fichier.length != 0 && SParagrapheCRM[2].fichier[0].type ==='image'"
                    class="image-container lazyload" [attr.data-src]="GetImage(SParagrapheCRM[2].fichier[0].filePath)"
                    alt="{{SParagrapheCRM[2].fichier[0].alt}}">

                <video *ngIf="SParagrapheCRM[2].fichier.length != 0 && SParagrapheCRM[2].fichier[0].type ==='video'"
                    controls class="image-container">
                    <source [attr.data-srcset]="GetImage(SParagrapheCRM[2].fichier[0].filePath)" type="video/mp4"
                        class="image-container">
                </video>
            </div>
        </div>

    </div>
    <div *ngIf="SParagrapheCRM[3].bodyEN !== '' || SParagrapheCRM[3].body !== '' " class="text-center paragraph-body justify-content-center card-deck3">
        <div *ngIf="IsFrench" [innerHTML]="SParagrapheCRM[3].body | safeHtml"> </div>
        <div *ngIf="!IsFrench " [innerHTML]="SParagrapheCRM[3].bodyEN | safeHtml"> </div>
        <p>
            <a *ngIf="IsFrench && ShowButton && SParagrapheCRM[3].titre !== '' " [href]="" class="btn text-center"
                routerLink="{{SParagrapheCRM[3].link}}" routerLinkActive="active"
                [innerHTML]="SParagrapheCRM[3].titre | safeHtml"></a>
            <a *ngIf="!IsFrench && ShowButton && SParagrapheCRM[3].titreEN !== '' " [href]="" class="btn text-center"
                routerLink="{{SParagrapheCRM[3].link}}" routerLinkActive="active"
                [innerHTML]="SParagrapheCRM[3].titreEN | safeHtml"></a>
        </p>
    </div>




</div>