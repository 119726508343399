import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageService, Page} from 'src/app/rest';
import { Meta, Title } from '@angular/platform-browser'; 
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { Constant } from '../../_constants/constant';
import { LoadingPagesService } from '../../_services/loading-pages.service';


@Component({
  selector: 'app-nos-references',
  templateUrl: './nos-references.component.html',
  styleUrls: ['./nos-references.component.scss']
})
export class NosReferencesComponent implements OnInit {

  isWorking: boolean;
  NosReferences;
  NosReferencesTitres;
  NosReferencesPage;
  tabs = [];
  @Input() hr: boolean;
  isFrench: boolean;
  Width: number;
  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private pageService: PageService,
    private title: Title,
    private meta: Meta,
    public loadingPagesService: LoadingPagesService
  ) { }

  ngOnInit(): void {

    window.scrollTo(0, 0);
    this.loadingPagesService.setPageStatus(true);
    this.hr = true;
    this.Width = window.innerWidth;
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.isWorking = true;
    this.pageService.getByCategorieAndModele('Nos References', 'nos references').subscribe(dt => {
      this.NosReferences = dt[0].paragraphes.sort((a, b) =>
        a.order > b.order ? 1 : -1
      );
      this.NosReferencesTitres = dt[0].titres;
      this.NosReferencesPage = dt[0];
      this.setMeta(this.NosReferencesPage);
      this.NosReferences.forEach(element => {
        element.titre = this.decodeHtmlEntity(this.DecodeHTML(this.deleteSpaceEncoding(element.titre)));
        element.titreEN = this.decodeHtmlEntity(this.DecodeHTML(this.deleteSpaceEncoding(element.titreEN)));
        this.tabs.push(element.titre.replace(/ /g, '_'));
      });
      this.isWorking = false;
    }, () => {
      this.isWorking = false;
    });


  }
  getString(tab: any, index: number) {
    if (tab === null || tab === undefined) {
      return '';
    } else { return tab[index]; }
  }

  DecodeHTML(html: string) {
    if (html === undefined) {
      return '';
    }
    return html.replace(/<[^>]+>/g, '');
  }

  decodeHtmlEntity(str) {
    if (str === undefined) {
      return '';
    }
    // tslint:disable-next-line: only-arrow-functions
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  }

  deleteSpaceEncoding(str) {
    return str.replace(/&nbsp;/g, '');
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }
  setMeta(page: Page) {
    if (this.isFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com/nos-references' });
    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com/nos-references' });
    }
  }

}
