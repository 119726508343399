/**
 * SiteAndexBack API
 * API de Base
 *
 * OpenAPI spec version: v1
 * Contact: amahmoudi@aiventu.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DatesR } from '../model/datesR';
import { Page } from '../model/page';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { Constant } from 'src/app/shared/_constants/constant';


@Injectable()
export class PageService {

    protected basePath = Constant.ApiRoot;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(id: string, observe?: 'body', reportProgress?: boolean): Observable<Page>;
    public _delete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Page>>;
    public _delete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Page>>;
    public _delete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling _delete.');
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Page>(`${this.basePath}/api/Page/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(observe?: 'body', reportProgress?: boolean): Observable<Array<Page>>;
    public get(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Page>>>;
    public get(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Page>>>;
    public get(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Page>>(`${this.basePath}/api/Page`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

       /**
     * 
     * 
     * @param CategoryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByCategory(CategoryId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Page>>;
    public getByCategory(CategoryId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Page>>>;
    public getByCategory(CategoryId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Page>>>;
    public getByCategory(CategoryId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (CategoryId !== undefined && CategoryId !== null) {
            queryParameters = queryParameters.set('CategoryId', <any>CategoryId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Page>>(`${this.basePath}/api/Page/GetByCategory`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

     /**
     * 
     * 
     * @param CatName 
     * @param ModelName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByCategorieAndModele(CatName?: string, ModelName?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Page>>;
    public getByCategorieAndModele(CatName?: string, ModelName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Page>>>;
    public getByCategorieAndModele(CatName?: string, ModelName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Page>>>;
    public getByCategorieAndModele(CatName?: string, ModelName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (CatName !== undefined && CatName !== null) {
            queryParameters = queryParameters.set('CatName', <any>CatName);
        }
        if (ModelName !== undefined && ModelName !== null) {
            queryParameters = queryParameters.set('ModelName', <any>ModelName);
        }

        let headers = this.defaultHeaders;

        // var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        // if (token != undefined) {
        //     headers = headers.set('Authorization', token);
        // }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Page>>(`${this.basePath}/api/Page/GetByCategorieAndModele`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param ModelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByModele(id: string, ModelId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Page>>;
    public getByModele(id: string, ModelId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Page>>>;
    public getByModele(id: string, ModelId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Page>>>;
    public getByModele(id: string, ModelId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getByModele.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ModelId !== undefined && ModelId !== null) {
            queryParameters = queryParameters.set('ModelId', <any>ModelId);
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Page>>(`${this.basePath}/api/Page/GetByModele/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDate(observe?: 'body', reportProgress?: boolean): Observable<Array<DatesR>>;
    public getDate(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DatesR>>>;
    public getDate(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DatesR>>>;
    public getDate(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DatesR>>(`${this.basePath}/api/Page/Date`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param m 
     * @param y 
     * @param day 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPageByDate(m: number, y: number, day: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Page>>;
    public getPageByDate(m: number, y: number, day: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Page>>>;
    public getPageByDate(m: number, y: number, day: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Page>>>;
    public getPageByDate(m: number, y: number, day: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (m === null || m === undefined) {
            throw new Error('Required parameter m was null or undefined when calling getPageByDate.');
        }

        if (y === null || y === undefined) {
            throw new Error('Required parameter y was null or undefined when calling getPageByDate.');
        }

        if (day === null || day === undefined) {
            throw new Error('Required parameter day was null or undefined when calling getPageByDate.');
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Page>>(`${this.basePath}/api/Page/Date/get/${encodeURIComponent(String(m))}/${encodeURIComponent(String(y))}/${encodeURIComponent(String(day))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param url 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getURL(url: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Page>>;
    public getURL(url: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Page>>>;
    public getURL(url: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Page>>>;
    public getURL(url: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (url === null || url === undefined) {
            throw new Error('Required parameter url was null or undefined when calling getURL.');
        }

        let headers = this.defaultHeaders;
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Page>>(`${this.basePath}/api/Page/URL/${encodeURIComponent(String(url))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get_1(id: string, observe?: 'body', reportProgress?: boolean): Observable<Page>;
    public get_1(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Page>>;
    public get_1(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Page>>;
    public get_1(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling get_1.');
        }

        let headers = this.defaultHeaders;

        // var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        // if (token != undefined) {
        //     headers = headers.set('Authorization', token);
        // }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Page>(`${this.basePath}/api/Page/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPage(page?: Page, observe?: 'body', reportProgress?: boolean): Observable<Page>;
    public postPage(page?: Page, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Page>>;
    public postPage(page?: Page, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Page>>;
    public postPage(page?: Page, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Page>(`${this.basePath}/api/Page`,
            page,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public put(id: string, page?: Page, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public put(id: string, page?: Page, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public put(id: string, page?: Page, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public put(id: string, page?: Page, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling put.');
        }


        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/Page/${encodeURIComponent(String(id))}`,
            page,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
