import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Page, UtilisateurService } from 'src/app/rest';
import { GlobalLanguageEventService } from 'src/app/shared/_services/global-language-event.service';
import { PageService } from 'src/app/rest/api/page.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  logo = {
    url: 'assets/images/LogoAiventu.png',
    alt: 'Aiventu'
  };

  frontMenuTree;
  frontButton;

  backMenuTree = [{
    title: 'TABLEAU DE BORD',
    url: '/back/dashboard',
    img: 'assets/images/icones/dashboard.png',
    children: []
  }, {
    title: 'VUE D\'ENSEMBLE',
    url: '/back/vueensemble',
    img: 'assets/images/icones/vueensemble.png',
    children: []
  }, {
    title: 'PAGES',
    url: '/back/pages',
    img: 'assets/images/icones/pages.png',
    children: [{
      title: 'Créer une page',
      url: '/back/pages/creerpage'
    }, {
      title: 'Voir toutes les pages',
      url: '/back/pages'
    }, {
      title: 'Catégories',
      url: '/back/pages/categorie'
    }, {
      title: 'Modèles',
      url: '/back/pages/modele'
    }]
  }, {
    title: 'ARTICLES',
    url: '/back/articles',
    img: 'assets/images/icones/article.png',
    children: [{
      title: 'Créer un article',
      url: '/back/articles/creerarticle'
    }, {
      title: 'Voir tous les articles',
      url: '/back/articles'
    }]
  }, {
    title: 'UTILISATEURS',
    url: '/back/utilisateur',
    img: 'assets/images/icones/user.png',
    children: []
  }, {
    title: 'PARAMETRES',
    url: '/back/parametres',
    img: 'assets/images/icones/settings.png',
    children: []
  }];

  backCopyright = 'Copyright AIVENTU';

  backToolbarDropdownMenu = [{
    title: 'Changer mon profil',
    url: '/back/modifutilisateur'
  }, {
    title: 'Changer mot de passe',
    url: '/back/modifpwd'
  }, {
    title: 'Me déconnecter',
    url: '',
    action: () => { this.Disconnect(); }
  }];
  backToolbarImage = 'assets/images/account_circle-24px.png';

  @Input() isFrontOfficeLayout = false;
  @Input() isBackOfficeLayout = false;
  backUsername = '';
  years: any;
  lienNosSolution: any;
  isWorking: boolean;
  NosSolution: Page[];
  isMobile: boolean;

  constructor(
    private globalLanguageService: GlobalLanguageEventService,
    private router: Router,
    private pageService: PageService,
    private utilisateurService: UtilisateurService,
    private translate: TranslateService
  ) {


  }

  ngOnInit() {
    this.getLanguage();
    if (window.innerWidth < 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.isWorking = true;
    this.pageService.getByCategory('e866f4a9-c1d3-3cdf-42fc-307cd56c2bc6').subscribe((data) => {

      if (data !== undefined && data !== null) {

        this.NosSolution = data;
        this.NosSolution = this.NosSolution.sort((a, b) => a.order > b.order ? 1 : -1);
        this.NosSolution.shift();

      }
      this.isWorking = false;
    }, () => {
      this.isWorking = false;
    });
    this.setData();
    const auth = JSON.parse(localStorage.getItem('MyApp_Auth'));

    if (auth !== null) {
      const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
      if (userId !== '') {
        this.utilisateurService.getUtilisateur(userId)
          .subscribe(
            data => {
              if (data != null) {
                this.backUsername = data.userName;
              }
            },
            error => {
              // this.toastr.error('impossible de se connecter', 'Erreur',
              //   {
              //     timeOut: 5000,
              //     progressBar: true,
              //     closeButton: true
              //   });
            });
      }

    }
    this.years = new Date().getFullYear().toString();
    this.backCopyright = this.years + ' Copyright AIVENTU';
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    this.globalLanguageService.setLanguage(lg);

  }
  setData() {

    this.frontMenuTree = [
      {
        title: this.translate.instant('Accueil'),
        url: '/',
        children: []
      }, {
        title: this.translate.instant('QuiSommesNous'),
        url: '/Nos-Services',
        children: [
        ]
      },
      {
        title: this.translate.instant('NosSolutions'),
        url: '/produits',
        children: [
          {
            title: 'Dynamics 365',
            url: 'produits/dynamics365'
          },
          {
            title: 'Dynamics 365 HR',
            url: 'produits/dynamicshr'
          },
          {
            title: 'Dynamics 365 BC',
            url: 'produits/dynamicsbc'
          },
          {
            title: 'Dynamics 365 CRM',
            url: 'produits/dynamicscrm'
          },
          {
            title: 'Microsoft 365',
            url: 'produits/nos-solution-microsoft'
          },
          {
            title: 'Suite inn4',
            url: 'produits/suiteinn4'
          }
        ]
      },
      {
        title: this.translate.instant('NosReferences'),
        url: '/nos-references',
        children: []
      },
      {
        title: this.translate.instant('Actualites'),
        url: '/article',
        children: []
      },
      {
        title: this.translate.instant('Carrieres'),
        url: '/carrieres',
        children: []
      }
    ];

    this.frontButton = {
      title: this.translate.instant('Contact'),
      href: 'nous-contacter'
    };
  }
  Disconnect() {
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
