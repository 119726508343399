import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-re-captcha',
  templateUrl: './re-captcha.component.html',
  styleUrls: ['./re-captcha.component.scss']
})
export class ReCaptchaComponent implements OnInit {

  @Output() verificationRobot: EventEmitter<boolean> = new EventEmitter(false);
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  margin: boolean;

  constructor() { }

  ngOnInit(): void {
    this.addRecaptchaScript();
    window.innerWidth > 600 ? this.margin = true : this.margin = false;
  }

  addRecaptchaScript() {
    // tslint:disable-next-line: no-string-literal
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    };
    this.insertReCaptcha(document, 'script', 'recaptcha-jssdk', this);
  }
  insertReCaptcha(d, s, id, obj) {
    // tslint:disable-next-line: prefer-const
    // tslint:disable-next-line: one-variable-per-declaration
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { obj.renderReCaptcha(); return; }
    js = d.createElement(s); js.id = id;
    js.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
    fjs.parentNode.insertBefore(js, fjs);
  }

  renderReCaptcha() {
    // tslint:disable-next-line: no-string-literal
    if (window['grecaptcha'] !== undefined) {
      // tslint:disable-next-line: no-string-literal
      window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        sitekey: '6LcouEwbAAAAALtWB0et07tCqy2smH0vI1FpFWsd',
        callback: (response) => {
          this.verificationRobot.emit(true);
        },
        'expired-callback': (response) => {
          this.verificationRobot.emit(false);
        }
      });

    }
  }
}
