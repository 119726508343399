<div class="site-section" [ngStyle]="{'margin-bottom': IsMobile ? '5%' : '5%'}">
    <div class="bg-light block-2" [style.width]="width+'px'">
        <div class="block-heading-1 " style=" padding-bottom:10vh">
            <h1 *ngIf="isFrench" class="text-center" [innerHTML]="TitreArrayOE[0].contenu | safeHtml"></h1>
            <h1 *ngIf="!isFrench" class="text-center" [innerHTML]="TitreArrayOE[0].contenuEN | safeHtml"></h1>
            <app-hr *ngIf="hr"></app-hr>
        </div>
        <div class="row" style="margin-left: 3%;" [style.width]="width+'px'">
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10"
                style="margin-left:auto !important; margin-right:auto !important;">
                <app-emploi-carrousel [url]="url" [ParagrapheArrayOE]="ParagrapheArrayOE"></app-emploi-carrousel>
            </div>
        </div>
    </div>
    <br />
    <div *ngIf="url === ''" class="button-container">
        <a [href]="" class="button-redirection" routerLink="{{url}}offres" routerLinkActive="active"
            translate>EnSavoirPlus</a>
    </div>
    <div class="justify-content-center full" style="padding-top: 3%;">
        <div class="card-deck" [ngStyle]="{'margin-bottom': category === 'carrieres'? '5%' : ''}">
            <h2 *ngIf="isFrench" [innerHTML]="ParagrapheArrayOE[ParagrapheArrayOE.length-1].titre | safeHtml"></h2>
            <h2 *ngIf="!isFrench" [innerHTML]="ParagrapheArrayOE[ParagrapheArrayOE.length-1].titreEN | safeHtml"></h2>
            <div *ngIf="isFrench" class="offre-paragraph"
                [innerHTML]="ParagrapheArrayOE[ParagrapheArrayOE.length-1].body | safeHtml"></div>
            <div *ngIf="!isFrench" class="offre-paragraph"
                [innerHTML]="ParagrapheArrayOE[ParagrapheArrayOE.length-1].bodyEN | safeHtml"></div>
            <br />
            <a [href]="" class="btn text-center bg-white " routerLink="{{url}}candidature-spontanee" routerLinkActive="active"
                translate>
                EnvCV
            </a>
        </div>
    </div>
</div>