<div class="site-section">
    <div class="block-2">
        <div class="block-heading-1 ">
            <h1 class="text-center" [innerHTML]="TitreArrayRS[0].contenu | safeHtml"></h1>
            <app-hr *ngIf="hr" ></app-hr> 
        </div>

        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 reseau-carousel">
                <carousel style="z-index: 0;" [height]="450" [cellWidth]="'100%'" [cellsToScroll]="2" [dots]="true"
                    [arrows]="false">
                    <div class="carousel-cell"
                        *ngFor="let paragraphe of ParagrapheArrayRS | slice:1:ParagrapheArrayRS.length-1; index as i">
                        <div class="carousel-top">
                            <app-articles [Article]="ParagrapheArrayRS[i]"></app-articles>
                        </div>
                        <div class="carousel-bottom">
                            <app-articles [Article]="ParagrapheArrayRS[i+1]"></app-articles>
                        </div>
                    </div>
                </carousel>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div class="publication">
                    <div class="title" [innerHTML]="TitreArrayRS[1].contenu | safeHtml"></div>
                    <div class="public-recent" id="pub">
                        <div class="public-element" *ngFor="let paragraphe of PublicationsArrayRS ">
                            <h4 [innerHTML]="paragraphe.titre | safeHtml"></h4>
                            <!-- <div class="text-black" [innerHTML]="paragraphe.body | safeHtml"></div> -->
                            <img alt="rs" class="public-image lazyload" data-src="{{paragraphe.image}}" >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>