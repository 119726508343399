<div *ngIf="isWorking">
    <br />
    <br />
    <br />
    <br />
    <div class="row content">
        <img alt="Loading" class="loading lazyload" width="10%" height="10%"
            data-src="data:image/gif;base64,R0lGODlhggBoAPEAAPiYKQB5wgCo7gAAACH5BAkEAAMAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAggBoAAAC/pyPqcvtD2MTtNq
            LM5W8+w8O2khW4YmmUslm6gu/7WzGaIDn+s73eEij2W6+ovEHCs4AzKbzCY0yQ8dqD6hkSbfcJtUKRn6y2q4Z+g1bseTR+e0FqcHsdgYOT8+NdfsF/6a359PnVwF4J
            jjIU2gogGimuKjTaAjZJTkp5uGIccmVqVnp97kVOjlqVyp1upjathrVOvhKFosmp3mV1Glx+zS7V5v16xQ8N6xUHPehuzvWe7gMcKyWHDRNnetMyRv9OF0ddk2TLU7
            nHW2+zb3Z8U2x3tyeQ74Uzt5u3yLvQV+frle/Dv/ccYAHbtm5NQE7DeRQMMC+MgrzcZtY4qGE/ogYSWiMwLGho48QQkL7RvKBSU7wUjpY+a4lvnn/OrqZ6a+gTQ0uG
            8A8KLMiTXo77+AkqFOkpaMQk55Ux3SjU5Yoo4KcGrOq0Jw1lZKyWhIrUK3FFlYp6gmsSrErgpa16AztH7Uv2UZA2JPBz7Zkf5k9ItcXXZ92IeAdrLfwg8NbkXZ9KhD
            xgr133fqFqyuwtMZNH1OFylmq56yg3w7V51WVZAWUDVu+9ZdPalirE7Re/DpW7CKa49VGcNsBY9Ncic629ftA8Am5V+0mdJxYcgPLGQy/fPpidGXTB1RfcB02ZlHbs
            XX/riC87vGoypc7r1h481LPn32OHPrq6LGliLEXRw2ZQ/Dtx1d/4mUXl3v35BcWgZX1deB/2gU40oDGUbgUg2s56BqE6yGYmYL8WAjgfQJqWBeHuHnoHHuuiEgRcY5
            daGKFKBKmonws0uciLTBmROKENWYoY2c0koZfkaIdyV8vQ5yAXgIIPVkfIz+SQGWWWiIw5ZZePtnll2LKAM+YZqaAUAEAIfkECQQABgAsAAAAAIIAaACC+Jgp+cJmA
            HnCOI/JAKjumdz3AAAAAAAAA/5outz+MMpJq7046827/2AojmRphkWqrmzrpmcsM0Rt33iu1zM2/MCgcEj8cXbIpK13ETif0Kh06jwqr7mAdsvter9aDnVMflqxaAJ
            gzW673/C1uEyXntPXuH7Pntf/And4SXyFcH6AdIKDO4aOfRuJf4uMOY+PiJJjlJU3l46ZmlOcnTWfhqGiUaSlp4WpqmYbpUiufLCxgbO0OrZ7uLGsnb56wKrClcRxx
            qLIjMqHkbmju7w40G/Mms6D2G7aktx43m3gieJp5JAa09Qa1pbqAOaA6Gjy89LtUPZY+PSTqsEzJQ9gnX55CurbV0XgwH8LGSJUApEdQ1nvBtqomP7hIsYMGjcqtOh
            xIqGRHT3qyhiSIwaVK0GGVIPypUqTtWo2uekQnsudJXta+2kBJs5GOovyZKmRaAWjQnk5pQCV6cOkT5fKbImVqlYMM2mqM6goKq2pE6pubdo17dcLYdFKUAt2ptwId
            OHabTv3rYW4fPH6rQB4bMR9R3sFhpD3717DJC8mjgc5ZVCrPhc/aEz4MTmyZSZf0+yAM4XCnw+3E+2JdAPTE1B7A02GtcjKNi+vvYobqGSzrVwzgC1BNjbam4APE76
            AeATj0JBTsU2wt1Lddblaz4pdr/bUkSUqT8ZcgXMI0JVJd7c783av3R1/n616GnWx4C3/xjy0vKSB8w+kR8x6doz3jH8AOiCgLwSuYmA3CA52mmf0hYfYg+NEGF9n8
            x1XXy733cWYhLFR6KGFq2GYjob7tdffe25tOGGH0X0YjIr3sCgef1LpeCGPZ/mYIpDBwdiXjCXSqJ6Nx+Doj5D2OZmQkYIhWdxMYGSp5RYN8iOlEkxc12J2GoVZQRF
            opgnEfWa2+UFYbsa5wQt01qmCnHjmqeeefPbppwgJAAAh+QQJBAAGACwAAAAAggBoAIL4mCn7yngAecIAqO45uO6Iu90AAAAAAAAD/mi63P4wykmrvTjrzbv/YCiOZ
            GmeaKqubOtGRCzPdG3H71TsfO//wB1nQCwaj8gkMScROJ/QqHTqHCqvWGJgy+16v+Ath0ouP63Z9BHAbrvf8Dh7bK5L0eq8fM9v0+2AAnh5aX2GcX+BdYOEWIePfhu
            KgIyNSpCQiZNklZZImI+am1Odnkagh6KjUaWmRKiGqqtnG66OsHyys4K1tpe4e7qzra7AwZK7pL2+n8aIyMmsy8ynznDCq8Sm1tfQ0bQa1M3ckRrf0uHi1eRz3ufan
            uzlGedQ8Jby7eb1VdPq+QCwjbrXCKDATQQJGXT3LaGefAcnOVSzcB+/iYUgMoyG/jFLRXr8eKVT90qjxXodb8mLqCjllY8YQorMQLIIzAsyXf5auTGZziQ3LeT0Jy5
            ohaEjSRqlgJRmzQFLJzTF8BSqSZAXiVKL2iTkz3HsWAb6uuZqTK9amXGNMPVC1bUQ2lp4axYn2qT/6gq967Qm3AdyK9DlefJdWl9/HQSmMDhsz11k1zku3PCwrcQNF
            k9oTE4sJcvF9B7lS/UpZgaaJXDm5tlOZJuimZJ2azq21NlzaxPGihL0Nttds+ItCpwtbsG6J/M2PHxr8bjHGSfv/HiY73jPAUffPJ119WzX8WVXvF11d2utF4UvOD5
            z+Qir0X8fuF5he9TvIcR3lt7MouuSu50lXF9K3bdAavCdx998CNX3UIB2DViaXwYqgKB+ChrTXxn/WQXhXhLSRuGHo4WY24jKCdhbc2pVaMCFD+ynIYMSOUiRizA6I
            CMwG3JiY0YkymYicihSRxlHP3qEY34xZsgjjS0lqVKKEa5IoDphZKklFz1S0SETxg0pXU1gQhDEmWjy8GWZbFJww5twytDmnHTWaeedeOap55589slmAgAh+QQJBAA
            GACwAAAAAggBoAIL4mCn5wGIAecIAqO5ty/OmzecAAAAAAAAD/mi63P4wykmrvTjrzbv/YCiOZGmeaKqubOu+cLwRdG3feE7LUOH/wKBw6OMMjsikcsk88h6CqHRKr
            VqjxqZ2ewx4v+CweOzlXM9oaZbLVgLe8Lh8Tn+b0/jqus2v+/9wd3mDAnt8bICJdIKEeIaHW4qSgRuNg4+QTZOTjJZnmJlLm5KdnlagoUmjiqWmVKipR6uJra5qG7G
            Rs3+1toW4uZq7fr22sLHDxJW+p8DBosmLy8yvzs+q0XPFrsep2drT1Lca19DflBri1eTl2Od24erdoe/oGepT85n18On4WNba8QOwzZQ+SAMLejp4KGE8cQz78FNoK
            WIbh/7+WUQ0/vEhtY1cMN7794tdO1kdM+IDqasexUYstYjEQLJkhpNIZl6oGVOYS4/MejLRaYFnwHJEKxg1eTIphaU3cQ5wOgEqBqlTU47UePQaVQlWL2D9GiGshbF
            aaZIUau7dS0Js3aTdubbrM7IQzFZA+1OlPLvB8EKpy1Tg3KKEo+IU7EAvBb5ugfqK6y6yX4iAczFu4HgC5HNvL2VGdlhp4qtSNzPoLOHzt9B5KOcs/fS02NS0q9o+i
            7vv1pWjveUGu3tvb8u//xZGOrxs8cfHQUs2Fpxe87zPPUd/PZ1b9X3XB3Nd7jV84+ytt2eD7eg7QvOc0Udwvb67QfcN4a+WD4F+nDT2aciGkm9qjadYU/otwNp86v1
            n30L4SUQgXQaitliCCizYX4PJAIiGgFlNiFiFt10oomkk8mYicgUCR95dGBqg4QP+dfhgRRFeFOOMDtQ4jIef5MjRibWlaNyK0l32kZAh7cgfjVKRIeWUXwB5BYhPn
            GckdDhl2QARYIb5A5ZelumADmimWYOZbLbp5ptwxinnnHTWaeedeGaQAAAh+QQJBAAGACwAAAAAggBoAIL4mCn4qzoAecIAqO4gq+mKvd8AAAAAAAAD/mi63P4wykm
            rvTjrzbv/YCiOZGmeaKqubOu+cCzPNEfceK7v/F03haBwSCwag5yBcslsOp/KH0NArVqv2Cw1Ce16lYGweEwum8McrXpd5X7fTYB8Tq/b7/I0e491w/94gYJzenyGA
            n5/b4OMd4WHe4mKXo2VhBuQhpKTUJaWj5lqm5xOnpWgoVmjpEymjaipV6usSq6MsLFtG7SUtoK4uYi7vJ2+gcC5s7TGx5jBqsPEpcyOzs+y0dKt1HbIscqs3N3W17o
            a2tPilxrl2Ofo2+p55O3gpPLrGe1W9pz48+z2bckG7x8Ab6n6TTKIMJRCRQzplXsI6F/DTBThRAwo/jDjIosSr3n8slGfQGHv4NUCyXHfyF74LkJ62aUkhpMoM6hcY
            vMCTprFYoZ8BvRJTws/CaI7WiFpSpVMKTjVuXNA1AlTMVS1ytJkR6XarkrIemGr2AhkLZjtevNk0XTyZB56G4etT7dgpZ2FkLbCWqEt6+UltvdBXwp/4w4NRjee4sA
            TB/Mq7ODwhMTq5GqSvMwuUrxPC3puCprqTsoNLEvALE4zn8Y8R0strbUq6im0y9qWjTW32t2AvbrkHI73WN9+gT8WLjj0UuNokSNWnnlxMuL3oPOVfpl6a+vfsPvTb
            pj7au/cXEcSv5B8ZfMRWKcHn5A9RPep4UOQT009lRvYKwXX1lfOhYUfbgSaBtWBC6gWH3r90eeQfRUJeFeCtZ3GoAIO7gchM/6tASBXFn6GoW4alkjaib+luNyAwxW
            o14YGdPgAfyBKiBGFGtFoowM4GhOiKDx+pOJsLCa30xlMNinGkFqMKEWD+t1Y1ZQKHKHllkJIieWXPYQpJg5flmnmmWimqeaabLbp5ptwxilnmgkAACH5BAkEAAUAL
            AAAAACCAGgAgviYKQB5wgCo7kuZzUO77wAAAAAAAAAAAAP+WLrc/jDKSau9OOvNu/9gKI5kaZ5oqq5s675wLM90bd8Uoe987/86nGJALBqPyCSRI2g6n9CotCksBK7
            YrHbLvTKn4HATQC6bz+g0mdNtu7FfsRyqrtvL7Ld+G5/774BpeXuEAX1+coGKeBuFhYeIYYuLg45vkJFTk4qVlm2YmVGbgZ2eXKChT6OApaZaqKljq3atrnAbsZKzd
            bW2hri5mrtqvbawscPEjb6nwMGiyWjFrsep0dLLzK/Oz6rXZtOm1aHf4Nnatxrd0OUA4Z7jme1r5+i/6uve7e+W8ZHz7uqh84cIID9HBP/MO/iIWz6DArUlnANRg70
            sExMtjMj+LKOYihkupsuQT185hoQ86trH0ZdKMCAxiPTicF3MCzPvkSwpi6XFmS+F+QwJtGa3mxZyBpWCtIJSo8+aUniKj6fUCVR3Wt34U+RSdidbGoMa7KqErBh49
            gzb9eJXOlyJeiWby2wEtBfUCrALAa8FvXwf+K0AOK7MolVLBnYwmELhoYfnJn5oGCdirYorJ72cVu3iBo0nPGYr1y1dZJqdcs7rOfXU1X9bQ7YsGTPl2Ztrd96KW7V
            u1rxJRzY92aZrrLAJyxZOm7ht471f/44d/BvKPW9NWhdL7bS142eTO16+va297E4+MwgtYXT50ue9kwN/V7xo8teu60G/9v2C8PjFHUVfX/a1h180+l0inzwDClZgB
            O7lx504C/7TIGMPQhAhghPCU2FBF4KW4QMbJpOgG/ztFeJ6IzpQ4jAnfvKhQtEhN51y1Ulo3kAzUrTiAuxBqFYVQWo4pBBKJKlkESlWUQUQUEa5g5NUVmnllVhmqeW
            WXHbp5ZdghinmmC0kAAAh+QQFBAAGACwAAAAAggBoAIL4mCn5sUMAecIMe8EAqO4Rq+0AAAAAAAAD/mi63P4wykmrvTjrzbv/YCiOZGmeaKqubOu+cCzPdG3feF4Xf
            O//wCBPNygaj8ikssghOJ/QqHTqDFiv2Kx2a+UIvuCweEz+NqnotBPAbrvf8DjbW67bzRu1firv+9t0d4JiZ3uGBH+JcYGDjYWHeoqSgBuNlgKPkGmTk4yXdpmaVJy
            Snp9loaJSpIqmp2OpqlCsia6vYbGya7R+trd4GrpovL2Vv6h5wnzEcr6/ubrMzcbHsMnKUdKL1NW419hP2nDOt9Cy4m/kr+aq6G7qp+yi7pQa3dbB4LP0APCf8pr49
            eN2D9M3fQL9XQIIKSHBewwPObRXEExEQxMzVLR4/hBcRgwbgWXQt4+eQksX93y8ENJgPpKI+J101BHbSgstU0aS+bCbTjU3K+SsqSwohaEvSRqdgHQkzJgme1b7uYk
            nxY1Uh1nVGDLrqK0guxIVtlRCUwxPobqbOcjrsqhXK7pdBZal2KQI6+K86xRm2QhnL6T9CyGwhcF6hfJF+5TwA8MVEMPlinVstMRHFwtujJmp5sOcJ4etjNdjZ7OfI
            4deK/XY3GynAaemIJl13IKvS9qmLNfyudiFZ0+ojY6toNzhgD8WLoG4OON3kO8SbZd0X6XKHUCmvbp462e+22VvsH149+ffy4WfN55B+ebntUEHtT5g+wXvIziXn35
            dgv2G9ymQHwT7STNfHdKp5d1tEP0nUYAGDPhAgcwciExpNkEooQMUEmMhGQk6ph1z+sVnYH/xOIiRhiQSaGKFKP6jokosWseYXzX2hqEyXPTo4xUf4nOdPkQsYeSRT
            Myohw46COHkkz0wKeWUVFZp5ZVYZqnlllx26eWXYIYp5pgmJAAAIfkECQQABgAsAAAAAIIAaACC+JgpAHnCAKju+86GkMHgmdz3AAAAAAAAA/5outz+MMpJq704682
            7/2AojmRpnmiqrmzrvnAsz3Rt33heF3zv/8AgT0coGo/IpLLIETif0Kh06hxYr9isdmvlBL7gsHhM/jap6LQTwG673/A421uu280btX4q7/vbdHeCYmd7hgJ/iXGBg
            42Fh3qKkoAbjZYBj5Bpk5OMl3aZmlSckp6fZaGiUqSKpqdjqapQrImur2Gxsmu0fra3eBq6aLy9lb+oecJ8xHK+v7m6zM3Gx7DJylHSi9TVuNfYT9pwzrfQsuJv5K/
            mquhu6qfsou6UGt3WweCz9ADwn/Ka+PXjdg/TN30C/V0CCCkhwXsMDzm0VxBMREMTM1S0eP4QXEYMG4Fl0LePnkJLF/d8vBDSYD6SiPiddNQR20oLLVNGkvmwm041N
            yvkrKksKIWhL0kanYB0JMyYJntW+7mJJ8WNVIdZ1Rgy66itILsSFbZUQlMMT6G6mznI67KoVyu6XQWWpdikCOvivOsUZtkIZy+k/QshsIXBeoXyRfuU8APDFRDD5Yp
            1bLTERxcLboyZqebDnCeHrYzXY2eznyOHXiv12NxspwGnpiCZddyCr0vapizX8rnYhWdPqI2OraDc4YA/Fi6BuDjjd5DvEm2XdF+lyh1Apr26eOtnvttlb7B9ePfn3
            8uFnzeeQfnm57VBB7U+YPsF7yM4l59+XYL9hvcpkB8E+0kzXx3SqeXdbRD9J1GABgz4QIHMHIhMaTZBKKEDFBJjIRkJOqYdc/rFZ2B/8TiIkYYkEmhihSj+o6JKLFr
            HmF819oahMlz06OMVH+JznT5ELGHkkUzMqIcOOgjh5JM9MCnllFRWaeWVWGap5ZZcdunll2CGKeaYJiQAACH5BAkEAAYALAAAAACCAGgAgviYKQB5wgx7wQCo7iOx7
            f3ivQAAAAAAAAP+aLrc/jDKSau9OOvNu/9gKI5kaZ5oqq5s675wLM90bd94ru+8R/zAoHBI/BWOyKRyyTxyBNCodEqtQjmDrHbL7XqzgLB4TC6bw5yAes1uu99q7Hd
            OB5/vePQGzu+v5XWBXHmEZml+iG6AgoyFjmKHiZIBi4yBj4+Rk4iVlnSYjpqbfZ2eX6CFoqNwpaZdqISqq4obrpeweLKzbK22Wri5e7t8vb4DwHe6w5S1xqfIhsLLt
            BrOz9BkysPFvthl2rvctt7Z0tO8zdaD5JDm53Hp6r/sehrvbeKu9PUZ9+jV8rbsAwBuVj5TAwuuOugpobt3DC05tOcPHsCAdugpHBX+sdG+jZs6CprYryKzixhJYjB
            5MgPGeRofnhN5KybFijTrqLzAMuenjzKn+Zyz00LPeAGLVjiKMinQm/6GXmMHcpJUL0opMHX58tjTkjiRyss6YSuGrl5tgo0qVh1ZCWYvoH0bIa6FuV9Xmrz6Ki/Pv
            W2t0YVgtwJetXrDNh3r1yjgxW4bL33M9eXgB4UpHKYadBnfdYj/Kq6cUrJWyme7XnaQecJmclUlfRZouixquaprw719N3dox6NTW9Zdl7dh35yh3psNM/na5YGdrW7
            QWsJrb7ETMc/oPDFbyIKJEzauGTnsztuiG5vOoHqE69iyc1LfTTxm8q7NY0cfjv6DOPus4WedfvHxZ5B/+gBInYDvEQiNfH5sl1Z3on1HmlO/TRYcbsNleNqGvXVII
            XAWCleah7aBeJyI5ykHEYIIKdgegxDA96CBC8HYkIwLuFdjV00EKSQST1hh5JFX6GhJDzcU4eSTQDAp5ZRUVmnllVhmqeWWXHbp5ZdghinmmGSakAAAIfkECQQABQA
            sAAAAAIIAaACC+JgpAHnCAKjuS5nNjNb2AAAAAAAAAAAAA/5Yutz+MMpJq7046827/2AojmRpnmiqrmzrvnAsz3Rt33iu77xH/MCgcEj86QbIpHLJbCI5gqh0Sq1ao
            4CsdsvterOcgHhMLpvPYuh1zcZ+33DwBk2vj9XtPDXO94btgGZ4eoR9hlp/gYoBg4R5h4eJi4CNjmyQhpKTdZWWV5h9mptonZ5VoHyio4Ibpo+ocKqrZKWuUrCxc7N
            0tbYCuG+yu4ytvp/AfrrDrBrGx8hcwru9ttBd0rPUrtbRysu0xc573Ije32nh4rfkchrnZdqm7O0Z7+DN6lPzANir8Z77+o36ZymguXMEHRl0Zw8dvnxu2AnclLDQv
            ImTKupZWP6vIbGHEDli8PgxA8R1Eg9+0/gqJcOGLNuIvEAy5qWLKpfZXDPTQs10+XpW+AkyKM6X9nY+I4dxkVIrQikQNXny19GOMIGqizphKoaqVl1iTapVHFcJXi+
            APRshrYW1V0d6fHoqLs25ZZ2xheC2AlyxcrMW3WrXJ97BZgsPPUz15N4HfSn8ZZpzGN1xgO8KbhxSsVTGX6s+dhB5wmRuTRVd1ue5K2i1olujff02dmbDm0M7lt2Wt
            l/blJG+W40y+NjheY2NblBawmlrqQMRj2g8MFnEennz9S0ZOOrK05L7Ws6geYTn0KJTEl9NO2Tupr1DB5+N/Tb3pOE7l5+evnQ/+/Lgx5x+5/GHjHp2TBdWdZpdx5l
            Rty2WG2y7RfjZhLVVyCBuDurWmYWuYfibht8JhxCAAAlYHoEQoHegfwOhWJCKC5jXYlVHOKHjjk/I6EgPNxQh5JBAAGnkkUgmqeSSTDbp5JNQRinllFRWaeWVWJqQA
            AAh+QQFBAAFACwAAAAAggBoAIL4mCkAecIAqO4Jqe2Uw+IAAAAAAAAAAAAD/li63P4wykmrvTjrzbv/YCiOZGmeaKqubOu+cCzPdG3feK7vfJ8TwKBwSCwCOYOkcsl
            sOpOAqHRKrVqjnIB2y+16v1qOYEwum8/o8XXNlmbB8Hh4k67bye289S3vd8V3gWd6hG4bfohbgIKMAoWFfIl9i42Bj4SRknGUlXaXepmaYJydaZ95oaJepKWDp2ypq
            lysrWWvsIeycLS1ardXsboBvL2/wLnCq3S9psZVwbrEtc7PyMmzy8yu1IYa18oa2mjcU9Cy0q3k3Rnff9nieOoA5qropfLz1u32nfj0ovwq+dP3LWCjgd7aKXoHz5G
            8f5oMMkLITuGccA3jqYMo/kmiIIoYLF7MkFEjOY6JPFp6SPCayjsgL4gcxhBeTAszX3pimdCizjo3K+SsKS4ohaEYSxqdgJRkSYcbWyb72SxqT4VUx/Gs6JOotqUSm
            mJ4CvWkVGFZt5m9us8rM7ARxF4gCxeCXAt0t4YUmdZM3Qd3K+S1yhWr22J6ZfI9PC0xzsVJM/51EJjC4LWF20ZuOLlB5QmXuaFE1NeWY6GQnSo9fTT12KedGXyWEJr
            aaD+lTYo+G41xOtZMXc+FDTyscLzECe/tutlm8bjHBSfHvNxw86LP7Ua3PH0324K+72UHvB10d9u8z4XvN55yedrnnd2etF5ge8/vI9SWn75ecv2D98mWHwT7GTOfH
            Ln5opxizKkmWYALzKZffAb2B9B/E0GogIQEUvjLgZtg+JGGBXD4QIEfWhiRiCst+FiDr63mImowDleSDkbkqGMQSDzh449J+CDkkEQWaeSRSCap5JJMNunkk1BGKeW
            UVFZp5QQJAAAh+QQJBAAFACwAAAAAggBoAIL4mCkAecIAqO4ztu7F4PEAAAAAAAAAAAAD/li63P4wykmrvTjrzbv/YCiOZGmeaKqubOu+cCzPdG3feK7vfJ8TwKBwS
            CwCOYOkcslsOpOAqHRKrVqjnIB2y+16v1qOYEwum8/o8XXNlmbB8Hh4k67bye289S3vd8V3gWd6hG4bfohbgIKMAoWFfIl9i42Bj4SRknGUlXaXepmaYJydaZ95oaJ
            epKWDp2ypqlysrWWvsIeycLS1ardXsboBvL2/wLnCq3S9psZVwbrEtc7PyMmzy8yu1IYa18oa2mjcU9Cy0q3k3Rnff9nieOoA5qropfLz1u32nfj0ovwq+dP3LWCjg
            d7aKXoHz5G8f5oMMkLITuGccA3jqYMo/kmiIIoYLF7MkFEjOY6JPFp6SPCayjsgL4gcxhBeTAszX3pimdCizjo3K+SsKS4ohaEYSxqdgJRkSYcbWyb72SxqT4VUx/G
            s6JOotqUSmmJ4CvWkVGFZt5m9us8rM7ARxF4gCxeCXAt0t4YUmdZM3Qd3K+S1yhWr22J6ZfI9PC0xzsVJM/51EJjC4LWF20ZuOLlB5QmXuaFE1NeWY6GQnSo9fTT12
            KedGXyWEJraaD+lTYo+G41xOtZMXc+FDTyscLzECe/tutlm8bjHBSfHvNxw86LP7Ua3PH0324K+72UHvB10d9u8z4XvN55yedrnnd2etF5ge8/vI9SWn75ecv2D98m
            WHwT7GTOfHLn5opxizKkmWYALzKZffAb2B9B/E0GogIQEUvjLgZtg+JGGBXD4QIEfWhiRiCst+FiDr63mImowDleSDkbkqGMQSDzh449J+CDkkEQWaeSRSCap5JJMN
            unkk1BGKeWUVFZp5QQJAAAh+QQFBAAEACwAAAAAggBoAIL4mCkAecIAqO5myPIAAAAAAAAAAAAAAAAD/ki63P4wykmrvTjrzbv/YCiOZGmeaKqubOu+cCzPdG3feK7
            vfO/Xg6BwSCwag4CkcslsOpOcgHRKrVqvUo5gy+16v+Dtc0xWRrHodHYTbru55bjzrK5Xte/8V843b+yAU3h6hAJ9fXSBdYOFeYd8iYppjI1uj3KRkliUlWGXcZmaV
            pyde59koaJUpKVdp6h/qmisrWKvT6myAbS1t7ixuqNstZ6+TbmyvK3Gx8DBq8PEpsx+Gs/CGtJg1EvIqsql3NUZ13fR2nDiAN6i4J3q687l7pXw7Jr0jfby1/mF+9b
            KCTqHzpC6e5L8EQJITuCabAXTiUOoSKEehhgcPswQ/lEiN4qBLDo6yO+ZyDcYL2jcRRBdSgsrT1oiGdChzDYvK8RsqS0nhZ0QO/qcAJRjR4MTSwa7WSxpTYFMt9Fsa
            JOntKESimI4ivSjUl1Rp3l9Os8qMawRtF7gihaCWgtsp2bUGNZL2wdvK8R1ShWq2V5yVdL9uywwzMFBI951kJfC3rF9yyYuuLhB4wmPqYEEVNeVYZ2IjQr9/DP01qO
            VGVyWkJnZZjudPWr+moxwONJETa9FjTurbri8+c6tOtll77S/9QaHPNxv8Z7H3SZ3vHw22X6230XHOx1zdde0v2Wvt51xd9bfjb1eNF5fecvnI7RWH75d+3/vVceHM
            N/XYXo1sdkinGDEiaZYfgusJl96/tWHz30LIaiAgvwxeMt/k0B4kYQEUPhAfxc6mJCGIw14WIGnjWYiaCju1tEPQBwh44xBwGjjjTjmqOOOPPbo449ABinkkEQWaeS
            RSCZJQwIAIfkEBQQABQAsAAAAAIIAaACC+JgpAHnCAKjukNj3k9n3AAAAAAAAAAAAA/5Yutz+MMpJq7046827/2AojmRpnmiqrmzrvnAsz3Rt33iu73zv14OgcEgsG
            oOApHLJbDqTnIB0Sq1ar1KOYMvter/g7XNMVkax6HR2E267ueW486yuV7Xv/FfON2/sgFN4eoQCfX10gXWDhXmHfImKaYyNbo9ykZJYlJVhl3GZmlacnXufZKGiVKS
            lXaeof6porK1ir0+psgG0tbe4sbqjbLWevk25srytxsfAwavDxKbMfhrPwhrSYNRLyKrKpdzVGdd30dpw4gDeouCd6uvO5e6V8Oya9I328tf5hfvWygk6h86QunuS/
            BECSE7gmmwF04lDqEihHoYYHD7MEP5RIjeKgSw6Osjvmcg3GC9o3EUQXUoLK09aIhnQocw2LyvEbKktJ4WdEDv6nACUY0eDE0sGu1ksaU2BTLfRbGiTp7ShEopiOIr
            0o1JdUad5fTrPKjGsEbRe4IoWgloLbKdm1BjWS9sHbyvEdUoVqtleclXS/bssMMzBQSPedZCXwt6xfcsmLri4QeMJj6mBBFTXlWGdiI0K/fwz9NajlRlclpCZ2WY7n
            T1q/pqMcDjSRE2vRY07q264vPnOrTrZZe+0v/UGhzzcb/Gex90md7x8Ntl+tt9Fxzsdc3XXtL9lr7edcXfW3429XjReX3nL5yO0Vh++Xft/71XHhzDf12Z6NbHZIpx
            gxImmWH4LrCZfev7Vh899CyGogIL8MXjLf5NAeJGEBVD4QH8XOpiQhiMNeFiBp41mImgo7tbRDzUQIOOMNNZo440ywqjjjjz26OOPQAYp5JBEFmnkkUgmqeSSTDZJQ
            wIAIfkECQQABAAsAAAAAIIAaACC+JgpAHnCAKjuquL5AAAAAAAAAAAAAAAAA/5Iutz+MMpJq7046827/2AojmRpnmiqrmzrvnAsz3Rt33iu73zv14OgcEgsGoOApHL
            JbDqTnIB0Sq1ar1KOYMvter/g7XNMVkax6HR2E267ueW486yuV7Xv/FfON2/sgFN4eoQCfX10gXWDhXmHfImKaYyNbo9ykZJYlJVhl3GZmlacnXufZKGiVKSlXaeof
            6porK1ir0+psgG0tbe4sbqjbLWevk25srytxsfAwavDxKbMfhrPwhrSYNRLyKrKpdzVGdd30dpw4gDeouCd6uvO5e6V8Oya9I328tf5hfvWygk6h86QunuS/BECSE7
            gmmwF04lDqEihHoYYHD7MEP5RIjeKgSw6Osjvmcg3GC9o3EUQXUoLK09aIhnQocw2LyvEbKktJ4WdEDv6nACUY0eDE0sGu1ksaU2BTLfRbGiTp7ShEopiOIr0o1JdU
            ad5fTrPKjGsEbRe4IoWgloLbKdm1BjWS9sHbyvEdUoVqtleclXS/bssMMzBQSPedZCXwt6xfcsmLri4QeMJj6mBBFTXlWGdiI0K/fwz9NajlRlclpCZ2WY7nT1q/pq
            McDjSRE2vRY07q264vPnOrTrZZe+0v/UGhzzcb/Gex90md7x8Ntl+tt9Fxzsdc3XXtL9lr7edcXfW3429XjReX3nL5yO0Vh++Xft/71XHhzDf12F6NbHZIpxgxImmW
            H4LrCZfev7Vh899CyGogIL8MXjLf5NAeJGEBFD4QH8XOpiQhiMNeFiBp41mImgo7tbRD0AcIeOMQcBo44045qjjjjz26OOPQAYp5JBEFmnkkUgmSUMCACH5BAUEAAM
            ALAAAAACCAGgAAAL+nI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6jgH+DwwKhz5M4IhMKpfMI0YAjUqn1CqUiM3+jM2u13mxisdRrXnI/aqVT7Kbe
            o5vL+s6sv3OC+TytF2Np+fGF+f35xUoOEZ4ZnjYlKhoxWjm+LgUKQlHmWV5mZSpKcXZSffZFSp6RUrkeRqQqsraavqKGaY6OSvkehorustbawuKm7sZPGdBfGtxXJU
            M1Pv5qxmtXMHMZvxcdg0wfVkt+Q0+rD2uWB7+mC64fs7srge/rH3H3b33zX44n1cv2z0wzvR5u9bvz783ASkMJFjB4MFoCe0sHMQvHrH+i2QaTngIK183jxJAclyU0
            d7Ak2JIRjAp8plLCDALSpz5oGZEifsQarTFUpdPlfeCQkspcGXMYzgd6KTAsyfFn6+MIptKFN3SXE0bPJ0QtSuDrxLCInX40OoUsQvIRjA7NGnRrbLOfkxLF5jdknh
            tGmSrwC0EuFjlavWrD3ACwQ8IJ6tYR+2ovS/77rxJmaZlqDwVI2DswHEwyGskT3xM1Vdea5lzbgbbubXT12Vjx0WrFPFI2V5pv7VdGPdc3TJ5j/U9GDjqrPJWkzPeF
            nlj5aNTU3OuDnpg6aGp7yINCPs77Yu5NxD93bo48fTIfzbPAP0s8F9Mr7p9N/flv+5QD4A+79186rXDHkD9GfBffAGyQh8iBTJ04AAJLiAfgwP68yBG+PGlH2eYbVh
            Zh7BJxEOJJp6IYooqrshiiy6+CGOMMs5IY4023ohjjjpeUAAAIfkEBQQAAwAsAAAAAIIAaAAAAv6cj6nL7Q+jnLTai7PevPsPhuJIluaJpurKtu4Lx/JM1/aN5/qOA
            f4PDAqHPkzgiEwql8wjRgCNSqfUKpSIzf6Mza7XebGKx1Gtecj9qpVPspt6jm8v6zqy/c4L5PK0XY2n58YX5/fnFSg4RnhmeNiUqGjFaOb4uBQpCUeZZXmZlKkpxdl
            J99kVKnpFSuR5GpCqytpq+ooZpjo5K+R6Giu6y1trC4qbuxk8Z0F8a3FclQzU+/mrGa1cwcxm/Fx2DTB9WS35DT6sPa5YHv6YLrh+zuyuB7+sfcfdvffNfjifVy/bP
            TDO9Hm71u/PvzcBKQwkWMHgwWgJ7SwcxC8esf6LZBpOeAgrXzePEkByXJTR3sCTYkhGMCnymUsIMAtKnPmgZkSJ+xBqtMVSl0+V94JCSylwZcxjOB3opMCzJ8Wfr4w
            im0oU3dJcTRs8nRC1K4OvEsIidfjQ6hSxC8hGMDs0adGtss5+TEsXmN2SeG0aZKvALQS4WOVq9asPcALBDwgnq1hH7ai9L/vuvEmZpmWoPBUjYOzAcTDIayRPfEzVV
            15rmXNuBtu5tdPXZWPHRasU8UjZXmm/tV0Y91zdMnmP9T0YOOqs8laTM94WeWPlo1NTc64OemDpoanvIg0I+zvti7k3EP3dujjx9Mh/Ns8A/SzwX0yvun039+W/7lA
            PgD7v3XzqtcMeQP0Z8F98AbJCHyIFMnTgAAkuIB+DA/rzIEb48aUfZ5htWFmHsEnEQ4kmnohiiiquyGKLLr4IY4wyzkhjjTbeiGOOOl5QAAAh+QQFBAADACwAAAAAg
            gBoAAAC/pyPqcvtD6OctNqLs968+w+G4kiW5omm6sq27gvH8kzX9o3n+o4B/g8MCoc+TOCITCqXzCNGAI1Kp9QqlIjN/ozNrtd5sYrHUa15yP2qlU+ym3qOby/rOrL
            9zgvk8rRdjafnxhfn9+cVKDhGeGZ42JSoaMVo5vi4FCkJR5lleZmUqSnF2Un32RUqekVK5HkakKrK2mr6ihmmOjkr5HoaK7rLW2sLipu7GTxnQXxrcVyVDNT7+asZr
            VzBzGb8XHYNMH1ZLfkNPqw9rlge/pguuH7O7K4Hv6x9x929981+OJ9XL9s9MM70ebvW78+/NwEpDCRYweDBaAntLBzELx6x/otkGk54CCtfN48SQHJclNHewJNiSEY
            wKfKZSwgwC0qc+aBmRIn7EGq0xVKXT5X3gkJLKXBlzGM4HeikwLMnxZ+vjCKbShTd0lxNGzydELUrg68SwiJ1+NDqFLELyEYwOzRp0a2yzn5MSxeY3ZJ4bRpkq8AtB
            LhY5Wr1qw9wAsEPCCerWEftqL0v++68SZmmZag8FSNg7MBxMMhrJE98TNVXXmuZc24G27m109dlY8dFqxTxSNleab+1XRj3XN0yeY/1PRg46qzyVpMz3hZ5Y+WjU1N
            zrg56YOmhqe8iDQj7O+2LuTcQ/d26OPH0yH82zwD9LPBfTK+6fTf35b/uUA+APu/dfOq1wx5A/RnwX3wBskIfIgUydOAACS4gH4MD+vMgRvjxpR9nmG1YWYewScRDi
            SaeiGKKKq7IYosuvghjjDLOSGONNt6IY446XlAAACH5BAUEAAMALAAAAACCAGgAAAL+nI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6jgH+DwwKhz5
            M4IhMKpfMI0YAjUqn1CqUiM3+jM2u13mxisdRrXnI/aqVT7Kbeo5vL+s6sv3OC+TytF2Np+fGF+f35xUoOEZ4ZnjYlKhoxWjm+LgUKQlHmWV5mZSpKcXZSffZFSp6R
            UrkeRqQqsraavqKGaY6OSvkehorustbawuKm7sZPGdBfGtxXJUM1Pv5qxmtXMHMZvxcdg0wfVkt+Q0+rD2uWB7+mC64fs7srge/rH3H3b33zX44n1cv2z0wzvR5u9b
            vz783ASkMJFjB4MFoCe0sHMQvHrH+i2QaTngIK183jxJAclyU0d7Ak2JIRjAp8plLCDALSpz5oGZEifsQarTFUpdPlfeCQkspcGXMYzgd6KTAsyfFn6+MIptKFN3SX
            E0bPJ0QtSuDrxLCInX40OoUsQvIRjA7NGnRrbLOfkxLF5jdknhtGmSrwC0EuFjlavWrD3ACwQ8IJ6tYR+2ovS/77rxJmaZlqDwVI2DswHEwyGskT3xM1Vdea5lzbgb
            bubXT12Vjx0WrFPFI2V5pv7VdGPdc3TJ5j/U9GDjqrPJWkzPeFnlj5aNTU3OuDnpg6aGp7yINCPs77Yu5NxD93bo48fTIfzbPAP0s8F9Mr7p9N/flv+5QD4A+79186
            rXDHkD9GfBffAGyQh8iBTJ04AAJLiAfgwP68yBG+PGlH2eYbVhZh7BJxEOJJp6IYooqrshiiy6+CGOMMs5IY4023ohjjjpeUAAAIfkEBQQAAwAsAAAAAIIAaAAAAv6
            cj6nL7Q+jnLTai7PevPsPhuJIluaJpurKtu4Lx/JM1/aN5/qOAf4PDAqHPkzgiEwql8wjRgCNSqfUKpSIzf6Mza7XebGKx1Gtecj9qpVPspt6jm8v6zqy/c4L5PK0X
            Y2n58YX5/fnFSg4RnhmeNiUqGjFaOb4uBQpCUeZZXmZlKkpxdlJ99kVKnpFSuR5GpCqytpq+ooZpjo5K+R6Giu6y1trC4qbuxk8Z0F8a3FclQzU+/mrGa1cwcxm/Fx
            2DTB9WS35DT6sPa5YHv6YLrh+zuyuB7+sfcfdvffNfjifVy/bPTDO9Hm71u/PvzcBKQwkWMHgwWgJ7SwcxC8esf6LZBpOeAgrXzePEkByXJTR3sCTYkhGMCnymUsIM
            AtKnPmgZkSJ+xBqtMVSl0+V94JCSylwZcxjOB3opMCzJ8Wfr4wim0oU3dJcTRs8nRC1K4OvEsIidfjQ6hSxC8hGMDs0adGtss5+TEsXmN2SeG0aZKvALQS4WOVq9as
            PcALBDwgnq1hH7ai9L/vuvEmZpmWoPBUjYOzAcTDIayRPfEzVV15rmXNuBtu5tdPXZWPHRasU8UjZXmm/tV0Y91zdMnmP9T0YOOqs8laTM94WeWPlo1NTc64OemDpo
            anvIg0I+zvti7k3EP3dujjx9Mh/Ns8A/SzwX0yvun039+W/7lAPgD7v3XzqtcMeQP0Z8F98AbJCHyIFMnTgAAkuIB+DA/rzIEb48aUfZ5htWFmHsEnEQ4kmnohiiiq
            uyGKLLr4IY4wyzkhjjTbeiGOOOl5QAAAh+QQFBAADACwAAAAAggBoAAAC/pyPqcvtD6OctNqLs968+w+G4kiW5omm6sq27gvH8kzX9o3n+o4B/g8MCoc+TOCITCqXz
            CNGAI1Kp9QqlIjN/ozNrtd5sYrHUa15yP2qlU+ym3qOby/rOrL9zgvk8rRdjafnxhfn9+cVKDhGeGZ42JSoaMVo5vi4FCkJR5lleZmUqSnF2Un32RUqekVK5HkakKr
            K2mr6ihmmOjkr5HoaK7rLW2sLipu7GTxnQXxrcVyVDNT7+asZrVzBzGb8XHYNMH1ZLfkNPqw9rlge/pguuH7O7K4Hv6x9x929981+OJ9XL9s9MM70ebvW78+/NwEpD
            CRYweDBaAntLBzELx6x/otkGk54CCtfN48SQHJclNHewJNiSEYwKfKZSwgwC0qc+aBmRIn7EGq0xVKXT5X3gkJLKXBlzGM4HeikwLMnxZ+vjCKbShTd0lxNGzydELU
            rg68SwiJ1+NDqFLELyEYwOzRp0a2yzn5MSxeY3ZJ4bRpkq8AtBLhY5Wr1qw9wAsEPCCerWEftqL0v++68SZmmZag8FSNg7MBxMMhrJE98TNVXXmuZc24G27m109dlY
            8dFqxTxSNleab+1XRj3XN0yeY/1PRg46qzyVpMz3hZ5Y+WjU1Nzrg56YOmhqe8iDQj7O+2LuTcQ/d26OPH0yH82zwD9LPBfTK+6fTf35b/uUA+APu/dfOq1wx5A/Rn
            wX3wBskIfIgUydOAACS4gH4MD+vMgRvjxpR9nmG1YWYewScRDiSaeiGKKKq7IYosuvghjjDLOSGONNt6IY446XlAAACH5BAUEAAMALAAAAACCAGgAAAL+nI+py+0Po
            5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6jgH+DwwKhz5M4IhMKpfMI0YAjUqn1CqUiM3+jM2u13mxisdRrXnI/aqVT7Kbeo5vL+s6sv3OC+TytF2Np+fGF+f
            35xUoOEZ4ZnjYlKhoxWjm+LgUKQlHmWV5mZSpKcXZSffZFSp6RUrkeRqQqsraavqKGaY6OSvkehorustbawuKm7sZPGdBfGtxXJUM1Pv5qxmtXMHMZvxcdg0wfVkt+
            Q0+rD2uWB7+mC64fs7srge/rH3H3b33zX44n1cv2z0wzvR5u9bvz783ASkMJFjB4MFoCe0sHMQvHrH+i2QaTngIK183jxJAclyU0d7Ak2JIRjAp8plLCDALSpz5oGZ
            EifsQarTFUpdPlfeCQkspcGXMYzgd6KTAsyfFn6+MIptKFN3SXE0bPJ0QtSuDrxLCInX40OoUsQvIRjA7NGnRrbLOfkxLF5jdknhtGmSrwC0EuFjlavWrD3ACwQ8IJ
            6tYR+2ovS/77rxJmaZlqDwVI2DswHEwyGskT3xM1Vdea5lzbgbbubXT12Vjx0WrFPFI2V5pv7VdGPdc3TJ5j/U9GDjqrPJWkzPeFnlj5aNTU3OuDnpg6aGp7yINCPs
            77Yu5NxD93bo48fTIfzbPAP0s8F9Mr7p9N/flv+5QD4A+79186rXDHkD9GfBffAGyQh8iBTJ04AAJLiAfgwP68yBG+PGlH2eYbVhZh7BJxEOJJp6IYooqrshiiy6+C
            GOMMs5IY4023ohjjjpeUAAAIfkEBQQAAwAsAAAAAIIAaAAAAv6cj6nL7Q+jnLTai7PevPsPhuJIluaJpurKtu4Lx/JM1/aN5/qOAf4PDAqHPkzgiEwql8wjRgCNSqf
            UKpSIzf6Mza7XebGKx1Gtecj9qpVPspt6jm8v6zqy/c4L5PK0XY2n58YX5/fnFSg4RnhmeNiUqGjFaOb4uBQpCUeZZXmZlKkpxdlJ99kVKnpFSuR5GpCqytpq+ooZp
            jo5K+R6Giu6y1trC4qbuxk8Z0F8a3FclQzU+/mrGa1cwcxm/Fx2DTB9WS35DT6sPa5YHv6YLrh+zuyuB7+sfcfdvffNfjifVy/bPTDO9Hm71u/PvzcBKQwkWMHgwWg
            J7SwcxC8esf6LZBpOeAgrXzePEkByXJTR3sCTYkhGMCnymUsIMAtKnPmgZkSJ+xBqtMVSl0+V94JCSylwZcxjOB3opMCzJ8Wfr4wim0oU3dJcTRs8nRC1K4OvEsIid
            fjQ6hSxC8hGMDs0adGtss5+TEsXmN2SeG0aZKvALQS4WOVq9asPcALBDwgnq1hH7ai9L/vuvEmZpmWoPBUjYOzAcTDIayRPfEzVV15rmXNuBtu5tdPXZWPHRasU8Uj
            ZXmm/tV0Y91zdMnmP9T0YOOqs8laTM94WeWPlo1NTc64OemDpoanvIg0I+zvti7k3EP3dujjx9Mh/Ns8A/SzwX0yvun039+W/7lAPgD7v3XzqtcMeQP0Z8F98AbJCH
            yIFMnTgAAkuIB+DA/rzIEb48aUfZ5htWFmHsEnEQ4kmnohiiiquyGKLLr4IY4wyzkhjjTbeiGOOOl5QAAAh+QQJBAADACwAAAAAggBoAAAC/pyPqcvtD6OctNqLs96
            8+w+G4kiW5omm6sq27gvH8kzX9o3n+o4B/g8MCoc+TOCITCqXzCNGAI1Kp9QqlIjN/ozNrtd5sYrHUa15yP2qlU+ym3qOby/rOrL9zgvk8rRdjafnxhfn9+cVKDhGe
            GZ42JSoaMVo5vi4FCkJR5lleZmUqSnF2Un32RUqekVK5HkakKrK2mr6ihmmOjkr5HoaK7rLW2sLipu7GTxnQXxrcVyVDNT7+asZrVzBzGb8XHYNMH1ZLfkNPqw9rlg
            e/pguuH7O7K4Hv6x9x929981+OJ9XL9s9MM70ebvW78+/NwEpDCRYweDBaAntLBzELx6x/otkGk54CCtfN48SQHJclNHewJNiSEYwKfKZSwgwC0qc+aBmRIn7EGq0x
            VKXT5X3gkJLKXBlzGM4HeikwLMnxZ+vjCKbShTd0lxNGzydELUrg68SwiJ1+NDqFLELyEYwOzRp0a2yzn5MSxeY3ZJ4bRpkq8AtBLhY5Wr1qw9wAsEPCCerWEftqL0
            v++68SZmmZag8FSNg7MBxMMhrJE98TNVXXmuZc24G27m109dlY8dFqxTxSNleab+1XRj3XN0yeY/1PRg46qzyVpMz3hZ5Y+WjU1Nzrg56YOmhqe8iDQj7O+2LuTcQ/
            d26OPH0yH82zwD9LPBfTK+6fTf35b/uUA+APu/dfOq1wx5A/RnwX3wBskIfIgUydOAACS4gH4MD+vMgRvjxpR9nmG1YWYewScRDiSaeiGKKKq7IYosuvghjjDLOSGO
            NNt6IY446XlAAACH5BAkEAAMALAAAAACCAGgAAAL+hI+pa+MPo5y02otzZbwfDYbiSA7euZTqymro+7Xy3MIwjeeh/er+P+GhgESg8FRM5o4epVPG7DynqiiHihVZG
            dluZpvyiilgxfgcKSfQbJM61h6/4XHvvEEK6Pf8vv+vVyIwSFhoeIg4eAdQAuj4uCeYOEmpeNcImeknWdlpuIipKRrA6WkKmjcqWmraiTqiukrS2voqEqvJSjtpG4K
            bqbuL2AvyCxks/HmZagyInExIrNHs+AwtIJ1B7Tx7nZiNsf1nDQ1+Ib7Z7X1oboHeR57cXvHOFy88T1Efqb5emD9hX6B+/izNCVXv3i6AEgSSIliQYQSHCmlJhEARo
            r/+iw8yjiio7CCzhBrXcXTgUQTIf8tgCaxYq+WtlyW9nRyQMsTKaDJ90fy4E1vPYj9VBr2ZE0RQoSJd7oN5aui0ojqPStVGVanVpjOf1ryGNKuGpWG9At1ZluTZlWn
            fQfXUFt1bV1fDic1Atu65uxjycvVp1ihave74XvD7BqHbr+UI0zNsAbEaxXIZy3OsD3IFyWUoi5tbKe5ny/gwB9RMgTMYz9tAUxLdmvRC0w1RT1C9hTU117xoT7QtA
            bcV3c14f/ONEXgE4VGIGzM+DHlH5RCYM3H+Czo76SipP7B+BDsu7SETj1y8FiTs3bItcsfp3QF4IeJjkWf5l2jgqoNj80/dr1V/5jmllmBsvZfUWFsN2FWB/B3oH1Y
            AKijgZOdVll5ECMY3wHw81KfKfTxFaNeEeC1oIYHoGajehib2hWJnF46W4Ubv1UHDTTjOoOOONdzoIws9BlkFkESWMOSRIxQAACH5BAkEAAQALAAAAACCAGgAgviYK
            fq8WAB5wgCo7gAAAAAAAAAAAAAAAAP+GLrc/orISau9OOvNewZgKI5kCXpoqq4sYb5w2M507cV4ae/8nv+ynnCIAgKJyOTF+FM6lczcczqM4qhYnxWW7c62XK84BX6
            Nz52yCc3+qEnteOUNZwnu+Lx+z7+3BoCBgoOEhYB0Iy19i4x4f4aQkYeIQSuNl3yPkpuDlJUqmKGOLJylgZ4ndqKhmqabqACKq5itrpGwsrONtbaGuKq6jLy9hL+Ww
            cKkxLeouciZysu+zcDPe8PSk57O1nnY2cag3dfR2Z3Ux+Pe5ean6OLqoyvtxe8p8evz9ILh9/h+7Oj1Q/EPoL592ihxU/dN2kAPBQU0XPawQ8SJxCpyuBj+sJ3GDRw
            PIvyoIaQKhO62VYuHsRfJDCZToEyIaOG4lrZeYoiJYuYAnRd4evAJ1ILQDkTtESyI01XRCkc5JFWZDl9TU08pRN0wVeFKhh3NZZ2wVUPXml9vhgWnFCLTtQ7bWnwrc
            t9YCWUznKVjs9vVUncJ5MWw901fa385BR58obCaw88Sv5K7ke7JmYsty8RMGaTmnpypwrMKl2Lnkp+HhvZalWXpjKdhpka6Gm1rsHUFxt45W2ptvmn9vna5O2hvrr8
            NB0c8PGdxo8fNJn+8PHJzp8+hRtc7vQxkZJIlZf4Xnplof+SvY82udTvh7mC+BysPaTzp3B7Zk3XfGP5zFvm60DfNeUulh59Y+uHFnwWOeVcdeOoBlqBgC1bQYHwPz
            hehYhMyxqB/VgA4i4CF2OfagWwR6JaBl6FkIm4tjtRhhRRc+F+GAW44mYpynBFYj0T8CKQQQg7JQ5FG2oBkkjQsyWQLTj65QpRSkjFhlTQkAAAh+QQJBAAEACwAAAA
            AggBoAIL4mCn4mSkAecIAqO4AAAAAAAAAAAAAAAAD/ki63P4wykmrvTLozbv/GiaOZCkBaKqubIuacCyfbm2/c66bd9/uwGDFR0wJj0hGsZhsCpdEp1QH9U2vsWoPy
            yVpb93w5WsTmyfkWknAbrvf8Di7NKjb7/i8vp52reWAgXMke4WGdn0/JIKMcXSHkHmJLH+NlgKPkZoDkyuVl4yZm5CdKp+ggaKjhqVGi6ihhKukrQCnsI6ys6y1t7h
            vqruSva+/csHCd7W2xcZwyMl8xCPOx7rReMu+1dDR2s3Vbd3J39ThwNfYiNMi5+gj6sOt287jwuXt7uLp8fgY+vvgxVPG7h9ATPzU+btwEKHAgdLmgTtnb9dCCw0rz
            rpY/iFjQmwcKXh8CDHkhJEiIK6TaA6gxlUmJaDEoDJiKXrGXo6KGWHmhZqcCjI8qHMTTwg+LQA9+iBphaVCMRL96C1qx6kkBzJ14JQCVJb5XFIlZ1Uk1pQ1tzboOuH
            rzYnhimpSy4CtBLedcP6SG4nuArsR8E7Si4svLbAGxWbtV/bkWZppG8t8/DMy4qGK0ar0qwAwBMGJCMMyfIgzAc8PQPcRjYo0r8tSM0PeLLknZaWW37bU57qQadQOV
            KdhDar3nt+3n+bOC5fb2Hu1kSb3unxw83rPLUZvOr1t9dDXc2bfuJ1r97vfV4ffOx5m+bXnA6cfvr5w+53v68b/PJ8MefFLxumBnGyV0QbbVQTiZqBuYfF2n1H5/bV
            fav198Z8lAcrDYGIOLqZQhJ1NGFyFWlzYSIbZgHiaiA0I5199oz04l4rAtUhiFWfk+IBpOh7BY49B/AjkDkIOmUORRsqAZJIwLMlkCU4+OUKUUmIAwpVYblDlllxKm
            QAAIfkECQQABQAsAAAAAIIAaACC+Jgp+J0rAHnCAKjuSJjMAAAAAAAAAAAAA/5Yutz+MDZCq704U8m7/2AYCWRpnmhKimzrvo0qzyVs37hD72l+B8CgcEgsAl28ZM0
            HAzif0Kh06kQqk8wmdcuttq7YrKtLnlrBNPG4zPay0Dt1q90+w1VyFp1tv6PyIntlfX4mgCGCZISFK4cfiV2LjC4DlZaXmJmalZBckoWUm6KjnJ1Un36hpKuYpqdfj
            H8trLSWrmawsYaztbS3Uqh3qr2kv1HBcMPEosZQyGjKy5rNT89g0dKt1ADWV9jZttvdSt/gA9vcubqNLOab6ONh7e6Z8Orr5eD2b+tL8/SX9ono508EQG3U4vHIl01
            gCILsDB4Ml/CeLobSHIKAKP4A4zKNHzh6JAbSg0heE8+JsxhrZK+SHU7+mwiTg0yJKWtKuBkiZamK/Ai6rKVzBMShvlYG7YeUVVEIPEH4VAl04FGUNJVaFYr14NMHU
            T9M/arj6kyvWh+axZm1qlquZwGSjbG2p8+5DMJ6GJt2Y12pd/uG/Cs2sFu/cNmiPTw4sd2cgk0S3mu4mcI4XeVGjjm5A1/Gkh0Dhgyas+jCpC2znJSZHt4Fej1XNnY
            5TWt3rxXE5vBZ9VJ8t83lLrBbQm/aq0EF17fZZmfes3/VntF01fDiEY5LT55qecPmO58bj35ruozqxcAbPU05NfLfF71nVA9VfHbyrszjkf+RPp1Y+xBoVx53wvBHk
            n9lsSebe9vB15KBLyFIl4LQMTigg6zF5ZqEeQH4gID5EZgMhERxCJuHDoBoin49kJhUac5ROJ6FIWKonIa4magbig2o2AmLsuAonI7E8ciAj5AAeQJ6o1xnZB5Oyuj
            IAlEyNWUHVQJ3pQRZxrdlBF0++CUEYWY4pgNl3nhmA0a06WYQSu6y5pwxWknnnf9JeWUCACH5BAkEAAUALAAAAACCAGgAgviYKQB5wgF5wgCo7v3esgAAAAAAAAAAA
            AP+WLrc/jDKSau9OOvNu/+gJIxkaZ7oGK6sF7xwLM/023pEru987+ecmnAIu3UAyKRyyWwig8TozMhxWq9JqHQboG6w4KaWG/Vqwujshrw1Z9LpMVvoxsDR8jmtfrm
            H83oyfBZ+YICBRRsDi4yNjo+Qi4VYh4hdipGZmpKTVpWIHJuikJ2ea5Z7mKOrA6VOn4GhrKuuYqeogqqzm7VMsHqyu7y9Sr9zwcKZxMW3uIkaycPLAMZsyNGP00/Nz
            pfQ2JHa1NzO1+CM4tVk5uet2upc7Ofp5Ljy4PQa3TH32PkZ+55laJftXT1U/aL9wxDQhi6C7qbBa/OQ4MILDb0NhIj+zqC+hgmTXbSQMaSwkRVKVmyHkoLKbxwjLps
            oxeSulhNeboyJU4JODDE5STxoyeasnhF+XggqkxjNMivneQQIMiq+qQyrwuSIFIJSC0y7PvhaISxWjFp3cj1LMi3QoGIdkKVgdujHgEZZxW0wd0LdmURBWfXHNqXbp
            XALuzwMNrFdqngHK1Sck3FZx4Dv7stLi7JPy3QxOw0cS7JIz0lB+xXd6ykRzqP2Mugr4e9ozd1gi5K9gHYE261JAzN9ErVX1bVZ13I9RLe0zJA3E79pfCzy38pdMac
            z/Wh1udchAF8u/Fh3vd/5hn8wXnt5a+c7P84aeStE3gp8i89eanuYDeea4FeAfuzx14l/qdhnUXqzredAe/29t058sTHYm4MNQHighPFQuJuF+WHIgIaTIDiFh8/dF
            l1uKAYI4oAiLkBiISbmoiBLLxL4oIElckjRjVLNh1Z9at2XY4xeCKhjHUoiSUWTRA4SAZTSSSkBlSxaOeWRUWrZwA9ghqlDjfx4aSZ9VZ6ppggptOmmCmvGKeecdNY
            JQgIAIfkECQQABAAsAAAAAIIAaACC+Jgp+Ks7AHnCAKjuAAAAAAAAAAAAAAAAA/5Iutz+MMpJKxw46807tmAojiThnWhWrmxrpXDnzvQc32rNCnzv/8Agr4TD6XbCp
            HJIKt4Igah0Sq1aA6WlNkh0wgiAsHhMLpsB2a2aOfJ+z/B4OL3edt0nsHxPpteXd3gyfIRzJH92TYIeeoV7fohCgYsajY5xkJFAk5Qfl4+HmkmcnZafZpmiPaSUpqd
            9oaqbip2Vr3CpsqyLrreGI7JctLWevmW5qruCvb7Iosp4zLfOmtBu0q/UkdZe2KfaiNxO3p/gf+JF5JfmdehGxsexwavDxOqO7GvuT/CwwPM+9sW4VyifGoFv+okxm
            KgNMRUKF8oDiDAFQUIMtVREcf6RT0ZA9Wp1BPUPIBsRDyFGRDNx3sY8K1mWNPmSUcyPSmoOWolzVMhSN1sG08lhpJyekn62CjqTolJeTEWYpOcwpVFMQnU9XRY1xNS
            TIVIWi4hUWNWHV3FlTbY1WlcQXwUQ3ZD2TNlZZ+29tRB3ri2ea5+1vba3Qt/B3QpTOJxXpOIJjFGKrYsqcDXE4x5LiBx2suYInEGIHUA5XlOXmNN9hhD6hWfAp4emf
            gdb6le/Ksla3jabX22vt3sPXP2gdYXRpf3Znop7rMK7P5qTJu7AOAXk1BtYn4D9N9zgjYF65wtestXsDLZL6K47ttbwS8cbLt/5vPzF9EW/br+cprrwhPwBx9x/FqG
            3gHoRsPfcbuEQyJGBCiB4wX4LuscWfFDdB1l+rtkX4HcDYsiVhptxeByF/UAXkIMwkQiaidehCI+KVJmHFoQESPiAgikyeA6LNrnIGozcyWgMjWDp52GF/TkloltCF
            kfkekY242M7QO70IXkh2qhXlNVNmWCV01ypT5ZF4aijAzzOaOZBaNJ1xZx0RoGkXHFWcsSd0u3JZ545+CnooAogR+ihexqK6KI2TMbooywglwAAOw==" />
    </div>
    <br />
    <br />
</div>
<div *ngIf="link !== undefined && !isWorking" >
    <div style="margin-left: 2%; margin-right: 3%;margin-bottom: 5%;">
        <br />
        <br />
        <br />
        <nav style="margin: 20px;" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="" translate>Accueil</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0);" (click)="goto('')" translate>Article</a></li>
                <!-- <li class="breadcrumb-item active" aria-current="page" *ngIf="isFrench">
                    {{decodeHtmlEntity(DecodeHTML(Article.titres[0].contenu))}}</li>
                <li class="breadcrumb-item active" aria-current="page" *ngIf="!isFrench">
                    {{decodeHtmlEntity(DecodeHTML(Article.titres[0].contenuEN))}}</li> -->
            </ol>
        </nav>
        <div class="card-body" *ngIf="isFrench">
            <div id="accordion">
                <div [innerHTML]="Article.titres[0].contenu  | safeHtml"></div>
                <span style="font-size: 75%;">Publié par : <b>{{Article.utilisateurCreation}}</b> le :
                    {{Article.dateCreation | date:'dd/MM/yyyy - HH:mm'}}</span>
                &nbsp; <span style="font-size: 75%;" *ngIf="Article.dateCreation != Article.dateModification">| Modifié
                    par
                    :
                    <b>{{Article.utilisateurModification}}</b> le {{Article.dateModification | date:'dd/MM/yyyy -
                    HH:mm'}}</span>
                        <div [innerHTML]="Article.resume | safeHtml"></div>

                        <div *ngIf=" Article.fichier.length > 0" class="container ">
                            <div class="row  align-items-center">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" id="slider">
                                    <div id="myCarousel" class="carousel slide shadow">
                                        <!-- main slider carousel items -->
                                        <div class="carousel-inner">
                                            <div *ngFor="let images of Article.fichier; let i = index;" [ngClass]="{active: i==0}" class="carousel-item" data-slide-number= index style="text-align: center;">
                                                 <img  class="imageslide " data-src="{{GetImage(Article.fichier[i].filePath)}}" alt="{{Article.fichier[i].alt}}"  >
                                            </div>


                        
                                            <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Previous</span>
                                            </a>
                                            <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Next</span>
                                            </a>
                        
                                        </div>
                                        <!-- main slider carousel nav controls -->
                        
                        
                                        <ul class="carousel-indicators list-inline mx-auto border px-2">

                                            <li *ngFor="let images of Article.fichier; let i = index;" class="list-inline-item " [ngClass]="{active: i==0}">
                                                <a  [id]="'carousel-selector-'+ i" [ngClass]="{active: i==0}"  attr.data-slide-to="{{i}}" data-target="#myCarousel">
                                                   <img *ngIf="!Article.fichier[i].isBackground" data-src="{{GetImage(Article.fichier[i].filePath)}}" alt="{{Article.fichier[i].alt}}" width="80px" height="60px">
                                                </a>
                                            </li>

                                           
                                        </ul>
                                    </div>
                                </div>
                        
                            </div>
                            <!--/main slider carousel-->
                        </div>
  
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngFor="let paragraphe of Article.paragraphes">
                        <div [innerHTML]="paragraphe.titre | safeHtml"></div>
                        <div [innerHTML]="paragraphe.body | safeHtml"></div>
                    </div>
                </div>
                <hr />
                <div>
                    <p>Tags</p>
                    <span *ngFor="let tg of tags">
                        <button (click)="gotoTag(tg)" type="button" 
                            style="font-weight: 400;font-size: 20px; border-radius: 5px; margin-left: 5px; margin-bottom: 5px; border:none;"
                            class="badge badge-info"> {{tg}} </button> &nbsp;
                    </span>
                </div>
                <br />
                <br />
                <br />
            </div>
        </div>
        <div class="card-body" *ngIf="!isFrench">
            <div id="accordion">
                <div [innerHTML]="Article.titres[0].contenuEN  | safeHtml"></div>
                <span style="font-size: 75%;">Published by : <b>{{Article.utilisateurCreation}}</b> at :
                    {{Article.dateCreation | date:'dd/MM/yyyy - HH:mm'}}</span>
                &nbsp; <span style="font-size: 75%;" *ngIf="Article.dateCreation != Article.dateModification">| Modified
                    by
                    :
                    <b>{{Article.utilisateurModification}}</b> at {{Article.dateModification| date:'dd/MM/yyyy -
                    HH:mm'}}</span>
                <div [innerHTML]="Article.resumeEn | safeHtml"></div>
                <div *ngIf=" Article.fichier.length > 0" class="container ">
                    <div class="row  align-items-center">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" id="slider">
                            <div id="myCarousel" class="carousel slide shadow">
                                <!-- main slider carousel items -->
                                <div class="carousel-inner">
                                    <div *ngFor="let images of Article.fichier; let i = index;" [ngClass]="{active: i==0}" class="carousel-item" data-slide-number= index style="text-align: center;">
                                         <img  class="imageslide " data-src="{{GetImage(Article.fichier[i].filePath)}}" alt="{{Article.fichier[i].alt}}"  >
                                    </div>


                
                                    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                
                                </div>
                                <!-- main slider carousel nav controls -->
                
                
                                <ul class="carousel-indicators list-inline mx-auto border px-2">

                                    <li *ngFor="let images of Article.fichier; let i = index;" class="list-inline-item " [ngClass]="{active: i==0}">
                                        <a  [id]="'carousel-selector-'+ i" [ngClass]="{active: i==0}"  attr.data-slide-to="{{i}}" data-target="#myCarousel">
                                           <img *ngIf="!Article.fichier[i].isBackground" data-src="{{GetImage(Article.fichier[i].filePath)}}" alt="{{Article.fichier[i].alt}}" width="80px" height="60px">
                                        </a>
                                    </li>

                                   
                                </ul>
                            </div>
                        </div>
                
                    </div>
                    <!--/main slider carousel-->
                </div>
                
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngFor="let paragraphe of Article.paragraphes">
                        <div [innerHTML]="paragraphe.titreEN | safeHtml"></div>
                        <div [innerHTML]="paragraphe.bodyEN | safeHtml"></div>
                    </div>
                </div>
                <hr />
                <div>
                    <p>Tags</p>
                    <div>
                        <span *ngFor="let tg of tagsEN"
                            style="font-weight: 400;font-size: 20px; border-radius: 5px; margin-left: 5px;"
                            class="badge badge-info"> {{tg}} </span> &nbsp;
                    </div>
                </div>
                <br />
                <br />
                <br />
            </div>
        </div>
    </div>
</div>
<div *ngIf="link === undefined && !isWorking" (mouseover)="setMeta(ActualitesPage)" (click)="setMeta(ActualitesPage)" [ngStyle]="{'margin-left': (IsMobile) ? '3%' : '3%', 'margin-right': (IsMobile) ? '4%' : '4%','margin-bottom': (!IsMobile) ? '15%' : '30%'}" >
    <div class="row text-center">
            <h2 *ngIf="isFrench && ActualitesTitres !== undefined" [innerHTML]="ActualitesTitres[0].contenu | safeHtml"></h2>
            <h2 *ngIf="!isFrench && ActualitesTitres !== undefined" [innerHTML]="ActualitesTitres[0].contenuEN | safeHtml"></h2>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-hr></app-hr>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <div style="margin-left: 20px;" translate>
                <!-- DatePublication <fa-icon [icon]="faSortDown" class="fa"
                    style="color: #0d79bf; font-size: 1em;"></fa-icon> -->
                <a class="btn btn-default" data-toggle="collapse" href="#collapseExample" role="button"
                    aria-expanded="false" aria-controls="collapseExample" translate>
                    DatePublication
                </a>
            </div>
            
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="input-group mb-3" >
                <input [(ngModel)]="search" type="text" class="form-control" placeholder="{{'Rechercher' | translate}}"
                    aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                    <button type="button" (click)="Search()" class="input-group-text" id="basic-addon2">
                        <fa-icon [icon]="faSearch" class="fa" style="color: #0d79bf; font-size: 1em;"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="collapse" id="collapseExample"  style="margin-left: 35px;">
            <div class="card card-body">
                <button type="button" class="btn btn-light" (click)="sort('up')" translate>
                    <fa-icon [icon]="faArrowUp" class="fa-2x" style="color: #0d79bf; font-size: 1em;"></fa-icon> TriAncien
                </button>
                <br />
                <button type="button" class="btn btn-light" (click)="sort('down')" translate>
                    <fa-icon [icon]="faArrowDown" class="fa" style="color: #0d79bf; font-size: 1em;"></fa-icon> TriRecent
                </button>
            </div>
        </div>
    </div>
    <br />
    <div style="margin-left: 5px; margin-right: 5px;" class="row">
        <div class="col-md-4" style="margin-bottom: 2%;" *ngFor="let art of articlePub">
            <div class="">
                <a href="javascript:void(0);" (click)="goto(art.lien)" class="">

                    <img *ngIf="art.filePath !== Apiroot " data-src="{{art.filePath}}" class="lazyload images" alt="{{isFrench ? art.alt : art.altEn}}" />
                    <img *ngIf="art.filePath === Apiroot " data-src="assets/images/page404.jpg" class="lazyload images" alt="pas d'image" />
                    <br />
                    <br />
                    <div class="hr-container text-center"><span class="hr-line"></span></div>
                    <br />
                    <div class="">
                        <p class="" *ngIf="isFrench">{{art.title}}</p>
                        <p class="" *ngIf="!isFrench">{{art.titleEn}}</p>
                    </div>
                    <fa-icon [icon]="faClock" class="fa-2x" style="color: #0d79bf; font-size: 1.4em;">
                    </fa-icon> {{art.dateModification | date:'dd/MM/yyyy' }}
                </a>
            </div>
        </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
</div>