<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;text-align:center;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><strong><span style='font-size:20px;font-family:"Arial",sans-serif;color:#0070C0;'>Conditions g&eacute;n&eacute;rales d&apos;utilisation</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;text-align:center;'><strong><span style='font-size:20px;font-family:"Arial",sans-serif;color:#0070C0;'>du site Aiventu&nbsp;</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Pr&eacute;ambule&nbsp;</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&apos;utilisation sont&nbsp;conclues entre :</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>- le g&eacute;rant du site internet, ci-apr&egrave;s d&eacute;sign&eacute; &laquo;&nbsp;l&rsquo;&Eacute;diteur&nbsp;&raquo;,</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>- toute personne souhaitant acc&eacute;der au site et &agrave; ses services, ci-apr&egrave;s appel&eacute; &laquo;&nbsp;l&rsquo;Utilisateur&nbsp;&raquo;.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Article 1 &ndash; Principes</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&apos;utilisation <span style="color:black;">ont pour objet l&apos;encadrement juridique de l&rsquo;utilisation du site</span> Aiventu et de ses services.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>Le site Internet https://www.aiventu.com/ est un service de :&nbsp;</span></p>
<ul style="list-style-type: disc;margin-left:26px;">
    <li><span style='font-family:"Arial",sans-serif;'>La soci&eacute;t&eacute; Aiventu</span></li>
    <li><span style='font-family:"Arial",sans-serif;'>Situ&eacute;e&nbsp;1 Cours Albert Thomas 69003 Lyon</span></li>
    <li><span style='font-family:"Arial",sans-serif;'>Adresse URL du site :&nbsp;https://www.aiventu.com/</span></li>
    <li><span style='font-family:"Arial",sans-serif;'>E-mail :&nbsp;contact@aiventu.com</span></li>
    <li><span style='font-family:"Arial",sans-serif;'>Num&eacute;ro de t&eacute;l&eacute;phone :&nbsp;+33 4 44 88 00 71</span></li>
</ul>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>Les conditions g&eacute;n&eacute;rales d&apos;utilisation doivent &ecirc;tre accept&eacute;es par tout Utilisateur, et son acc&egrave;s au site vaut acceptation de ces conditions.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Article 2&nbsp;- &Eacute;volution et dur&eacute;e des CGU</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&apos;utilisation sont conclues pour une dur&eacute;e ind&eacute;termin&eacute;e. <span style="color:black;">Le contrat produit ses effets &agrave; l&apos;&eacute;gard de l&apos;Utilisateur &agrave; compter du d&eacute;but de l&rsquo;utilisation du service.</span></span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Le site&nbsp;Aiventu&nbsp;se r&eacute;serve le droit de modifier les clauses de ces conditions g&eacute;n&eacute;rales d&rsquo;utilisation &agrave; tout moment et sans justification.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Article 3 - Acc&egrave;s au site</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Tout Utilisateur ayant acc&egrave;s &agrave;&nbsp;internet peut acc&eacute;der gratuitement et depuis n&rsquo;importe o&ugrave; au site&nbsp;Aiventu. Les frais support&eacute;s par l&rsquo;Utilisateur pour y acc&eacute;der (connexion internet, mat&eacute;riel informatique, etc.) ne sont pas &agrave; la charge de l&rsquo;&Eacute;diteur.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Le site peut &ecirc;tre interrompu ou suspendu par l&rsquo;&Eacute;diteur, notamment &agrave; l&rsquo;occasion d&rsquo;une maintenance, sans obligation de pr&eacute;avis ou de justification.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Article 4 &nbsp;- Responsabilit&eacute;s&nbsp;</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>La responsabilit&eacute; de l&apos;&Eacute;diteur ne peut &ecirc;tre engag&eacute;e en cas de d&eacute;faillance, panne, difficult&eacute; ou interruption de fonctionnement, emp&ecirc;chant l&apos;acc&egrave;s au site ou &agrave; une de ses fonctionnalit&eacute;s.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Le mat&eacute;riel de connexion au site utilis&eacute;e&nbsp;est sous l&apos;enti&egrave;re responsabilit&eacute; de l&apos;Utilisateur qui doit prendre toutes les mesures appropri&eacute;es pour prot&eacute;ger le&nbsp;mat&eacute;riel et les&nbsp;donn&eacute;es notamment d&apos;attaques virales par Internet. L&apos;utilisateur est&nbsp;par ailleurs le seul responsable des sites et donn&eacute;es qu&apos;il consulte.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#5D637A;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>L&apos;&Eacute;diteur ne pourra &ecirc;tre tenu responsable en cas de poursuites judiciaires &agrave; l&apos;encontre de l&apos;Utilisateur&nbsp;:</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>- &nbsp;Du fait de l&apos;usage du site ou de tout service accessible <em>via</em> Internet ;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>- &nbsp;Du fait du non-respect par l&apos;Utilisateur des pr&eacute;sentes conditions g&eacute;n&eacute;rales.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#5D637A;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>L&apos;&Eacute;diteur n&apos;est pas responsable des dommages caus&eacute;s &agrave; l&apos;Utilisateur, &agrave; des tiers et/ou &agrave; l&apos;&eacute;quipement de l&apos;Utilisateur du fait de sa&nbsp;connexion ou de son&nbsp;utilisation du site et l&apos;Utilisateur&nbsp;renonce&nbsp;&agrave; toute action contre l&apos;&Eacute;diteur&nbsp;de ce fait.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Si l&apos;&Eacute;diteur venait &agrave; faire l&apos;objet d&apos;une proc&eacute;dure amiable ou judiciaire &agrave; raison de l&apos;utilisation du site par&nbsp;l&apos;Utilisateur, il pourra se retourner contre lui&nbsp;pour obtenir indemnisation de tous les pr&eacute;judices, sommes, condamnations et frais qui pourraient d&eacute;couler de cette proc&eacute;dure.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#5D637A;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Article 5 &nbsp;- Propri&eacute;t&eacute; intellectuelle</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Tous les documents techniques, produits, photographies, textes, logos, dessins, vid&eacute;os, etc.,&nbsp;sont soumis &agrave; des droits d&apos;auteur et sont prot&eacute;g&eacute;s par le Code de la propri&eacute;t&eacute; intellectuelle.&nbsp;Lorsqu&apos;ils sont&nbsp;remis &agrave; nos clients, ils demeurent la propri&eacute;t&eacute; exclusive de</span><span style='font-family:"Arial",sans-serif;color:red;'>&nbsp;</span><span style='font-family:"Arial",sans-serif;'>l&rsquo;Editeur, seul<span style="color:black;">&nbsp;titulaire des droits de propri&eacute;t&eacute; intellectuelle sur ces documents, qui doivent lui &ecirc;tre rendus &agrave; sa demande.</span></span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Nos clients s&apos;engagent &agrave; ne faire aucun usage de ces documents, susceptible de porter atteinte aux droits de propri&eacute;t&eacute; industrielle ou intellectuelle du fournisseur et s&apos;engagent &agrave; ne les divulguer &agrave; aucun tiers, en dehors d&apos;une autorisation expresse et pr&eacute;alable donn&eacute;e par l&apos;Editeur.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Article 6 &nbsp;- Liens hypertextes</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>La mise en place par l&apos;Utilisateur&nbsp;de tous liens hypertextes vers tout ou partie du site est strictement interdite, sauf autorisation pr&eacute;alable et &eacute;crite de l&apos;&Eacute;diteur, sollicit&eacute;e par courriel &agrave; l&apos;adresse suivante&nbsp;:&nbsp;contact@Aiventu.com.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>L&apos;&Eacute;diteur est libre de refuser cette autorisation sans avoir &agrave; justifier de quelque mani&egrave;re que ce soit sa d&eacute;cision. Dans le cas o&ugrave; l&apos;&Eacute;diteur accorderait son autorisation, celle-ci n&apos;est dans tous les cas que temporaire et pourra &ecirc;tre retir&eacute;e &agrave; tout moment, sans obligation de justification &agrave; la charge de l&apos;&Eacute;diteur.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Dans tous les cas, tout lien devra &ecirc;tre retir&eacute; sur simple demande de l&apos;&Eacute;diteur.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Toute information accessible <em>via</em> un lien vers d&apos;autres sites n&apos;est pas sous le contr&ocirc;le de l&apos;&Eacute;diteur qui d&eacute;cline toute responsabilit&eacute; quant &agrave; leur contenu.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Article 7&nbsp;- Protection des donn&eacute;es personnelles</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:#0070C0;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><u><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Donn&eacute;es collect&eacute;es&nbsp;</span></u></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>Les donn&eacute;es &agrave; caract&egrave;re personnel qui sont collect&eacute;es sur ce site&nbsp;sont les suivantes&nbsp;:</span></p>
<ul style="list-style-type: undefined;margin-left:26px;">
    <li><strong><span style='font-family:"Arial",sans-serif;color:#333333;'>Formulaires (contact, candidature, candidature spontan&eacute;e) :&nbsp;</span></strong><span style='font-family:"Arial",sans-serif;'>lorsque l&apos;utilisateur remplit<span style="color:red;">&nbsp;</span>un de ces trois formulaires du site web, celui-ci enregistre, notamment, son nom, son pr&eacute;nom, son adresse mail, le message fourni par l&rsquo;utilisateur, sa fonction, sa soci&eacute;t&eacute;, son num&eacute;ro de t&eacute;l&eacute;phone et sa localisation ;</span></li>
    <li><strong><span style='font-family:"Arial",sans-serif;color:#333333;'>Communication :&nbsp;</span></strong><span style='font-family:"Arial",sans-serif;'>lorsque le site web&nbsp;est utilis&eacute;&nbsp;pour communiquer avec d&apos;autres membres, les donn&eacute;es concernant les communications de l&apos;utilisateur font l&apos;objet d&apos;une conservation temporaire ;</span></li>
    <li><strong><span style='font-family:"Arial",sans-serif;color:#333333;'>Cookies :</span></strong><span style='font-family:"Arial",sans-serif;'>&nbsp;les cookies sont utilis&eacute;s, dans le cadre de l&apos;utilisation du site. L&apos;utilisateur a la possibilit&eacute; de d&eacute;sactiver les cookies &agrave; partir des param&egrave;tres de son navigateur.</span></li>
</ul>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><u><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Utilisation des donn&eacute;es personnelles&nbsp;</span></u></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Les donn&eacute;es personnelles collect&eacute;es aupr&egrave;s des utilisateurs ont pour objectif la mise &agrave; disposition des services du site web, leur am&eacute;lioration et le maintien d&apos;un environnement s&eacute;curis&eacute;. Plus pr&eacute;cis&eacute;ment, les utilisations sont les suivantes&nbsp;:</span></p>
<ul style="list-style-type: undefined;margin-left:26px;">
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Utilisation du site web&nbsp;par l&apos;utilisateur&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Gestion du fonctionnement et optimisation du site web&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>V&eacute;rification des donn&eacute;es transmises par l&apos;utilisateur&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Proposition &agrave; l&apos;utilisateur de la possibilit&eacute; de communiquer avec d&apos;autres utilisateurs du site web &agrave; travers notre blog&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Pr&eacute;vention et d&eacute;tection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des incidents de s&eacute;curit&eacute;&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Gestion des &eacute;ventuels litiges avec les utilisateurs&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:#333333;'>E</span><span style='font-family:"Arial",sans-serif;color:black;'>nvoi d&apos;informations commerciales et publicitaires, en fonction des pr&eacute;f&eacute;rences de l&apos;utilisateur.<br>&nbsp;<br>&nbsp;</span></li>
</ul>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><u><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Partage des donn&eacute;es personnelles avec des tiers</span></u></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Les donn&eacute;es personnelles peuvent &ecirc;tre partag&eacute;es avec des soci&eacute;t&eacute;s tierces, dans les cas suivants&nbsp;:</span></p>
<ul style="list-style-type: undefined;margin-left:26px;">
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Lorsque l&apos;Utilisateur publie, dans les zones de commentaires libres de notre blog, des informations accessibles au public&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Lorsque&nbsp;l&apos;Utilisateur autorise le site web d&apos;un tiers &agrave; acc&eacute;der &agrave; ses donn&eacute;es&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Lorsque&nbsp;le site web&nbsp;recourt aux services de prestataires pour fournir l&apos;assistance utilisateurs. Ces prestataires disposent d&apos;un acc&egrave;s limit&eacute; aux donn&eacute;es de l&apos;utilisateur, dans le cadre de l&apos;ex&eacute;cution de ces prestations, et ont une obligation contractuelle de les utiliser en conformit&eacute; avec les dispositions de la r&eacute;glementation applicable en mati&egrave;re protection des donn&eacute;es &agrave; caract&egrave;re personnel&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Si la loi l&apos;exige, le site web&nbsp;peut effectuer la transmission de donn&eacute;es pour donner suite aux r&eacute;clamations pr&eacute;sent&eacute;es contre le site web&nbsp;et se conformer aux proc&eacute;dures administratives et judiciaires&nbsp;;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Si le site web&nbsp;est impliqu</span><span style='font-family:"Arial",sans-serif;'>&eacute;<span style="color:black;">&nbsp;dans une op&eacute;ration de fusion, acquisition, cession d&apos;actifs ou proc&eacute;dure de redressement judiciaire,&nbsp;</span>il pourra &ecirc;tre<span style="color:black;">&nbsp;amen&eacute; &agrave; c&eacute;der ou partager tout ou partie de ses actifs, y compris les donn&eacute;es &agrave; caract&egrave;re personnel. Dans ce cas, les utilisateurs seraient inform&eacute;s, avant que les donn&eacute;es &agrave; caract&egrave;re personnel ne soient transf&eacute;r&eacute;es &agrave; une tierce partie.<br>&nbsp;<br>&nbsp;</span></span></li>
</ul>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><u><span style='font-family:"Arial",sans-serif;color:#0070C0;'>S&eacute;curit&eacute; et confidentialit&eacute;</span></u></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Le site web&nbsp;met en &oelig;uvre des mesures organisationnelles, techniques, logicielles et physiques en mati&egrave;re de s&eacute;curit&eacute; du num&eacute;rique pour prot&eacute;ger les donn&eacute;es personnelles contre les alt&eacute;rations, destructions et acc&egrave;s non autoris&eacute;s. Toutefois, il est &agrave; signaler qu&apos;internet n&apos;est pas un environnement compl&egrave;tement s&eacute;curis&eacute; et le site web&nbsp;ne peut pas garantir la s&eacute;curit&eacute; de la transmission ou du stockage des informations sur internet.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'><br> <strong><u><span style="color:#0070C0;">Mise en &oelig;uvre des droits des utilisateurs</span></u></strong></span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>En application de la r&eacute;glementation applicable aux donn&eacute;es &agrave; caract&egrave;re personnel, les utilisateurs disposent des droits suivants, qu&apos;ils peuvent exercer en faisant leur demande &agrave; l&apos;adresse suivante :&nbsp;</span><a href="mailto:contact@aiventu.com"><span style='font-family:"Arial",sans-serif;'>contact@aiventu.com</span></a><span style='font-family:"Arial",sans-serif;color:black;'>.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: undefined;margin-left:44px;">
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Le droit de rectification&nbsp;: si les donn&eacute;es &agrave; caract&egrave;re personnel d&eacute;tenues par le site web&nbsp;sont inexactes, ils peuvent demander la mise &agrave; jour des informations.</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Le droit de suppression des donn&eacute;es : les utilisateurs peuvent demander la suppression de leurs donn&eacute;es &agrave; caract&egrave;re personnel, conform&eacute;ment aux lois applicables en mati&egrave;re de protection des donn&eacute;es.&nbsp;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Le droit &agrave; la limitation du traitement : les utilisateurs peuvent demander au site web de limiter le traitement des donn&eacute;es personnelles conform&eacute;ment aux hypoth&egrave;ses pr&eacute;vues par le RGPD.&nbsp;</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:black;'>Le droit de s&rsquo;opposer au traitement des donn&eacute;es : les utilisateurs peuvent s&rsquo;opposer &agrave; ce que ses donn&eacute;es soient trait&eacute;es conform&eacute;ment aux hypoth&egrave;ses pr&eacute;vues par le RGPD. &nbsp;</span></li>
</ul>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><u><span style='font-family:"Arial",sans-serif;color:black;'><span style="text-decoration:none;">&nbsp;</span></span></u></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><u><span style='font-family:"Arial",sans-serif;color:black;'><span style="text-decoration:none;">&nbsp;</span></span></u></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><u><span style='font-family:"Arial",sans-serif;color:black;'><span style="text-decoration:none;">&nbsp;</span></span></u></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><u><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Evolution de la pr&eacute;sente clause</span></u></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Le site web se r&eacute;serve le droit d&apos;apporter toute modification &agrave; la pr&eacute;sente clause relative &agrave; la protection des donn&eacute;es &agrave; caract&egrave;re personnel &agrave; tout moment. Si une modification est apport&eacute;e &agrave; la pr&eacute;sente clause de protection des donn&eacute;es &agrave; caract&egrave;re personnel, le site web s&apos;engage &agrave; publier la nouvelle version sur son site. Le site web informera &eacute;galement les utilisateurs de la modification par messagerie &eacute;lectronique, dans un d&eacute;lai minimum de 15 jours avant la date d&apos;effet.&nbsp;</span><span style='font-family:"Arial",sans-serif;color:black;'>Si l&apos;utilisateur n&apos;est pas d&apos;accord avec les termes de la nouvelle r&eacute;daction de la clause de protection des donn&eacute;es &agrave; caract&egrave;re personnel, il a la possibilit&eacute; de nous contacter.&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Article 8 &nbsp;- Cookies</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;color:black;'>Le site&nbsp;</span><span style='font-family:"Arial",sans-serif;'>Aiventu<span style="color:black;">&nbsp;peut collecter automatiquement des informations standards. Toutes les informations collect&eacute;es indirectement ne seront utilis&eacute;es que pour suivre le volume, le type et la configuration du trafic utilisant ce site, pour en d&eacute;velopper la conception et l&apos;agencement et &agrave; d&apos;autres fins administratives et de planification et plus g&eacute;n&eacute;ralement pour am&eacute;liorer le service que nous vous offrons.&nbsp;</span></span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><strong><span style='font-family:"Arial",sans-serif;color:#0070C0;'>Article 9&nbsp;- Loi applicable</span></strong></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>Les pr&eacute;sentes conditions g&eacute;n&eacute;rales d&apos;utilisation sont soumises &agrave; l&apos;application du droit fran&ccedil;ais.&nbsp;</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><span style='font-family:"Arial",sans-serif;'>Si les parties n&apos;arrivent pas &agrave; r&eacute;soudre un&nbsp;litige&nbsp;&agrave; l&apos;amiable, le litige sera soumis &agrave; la comp&eacute;tence des tribunaux fran&ccedil;ais.</span></p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'>&nbsp;</p>
<p style='margin:0cm;line-height:12.0pt;font-size:14px;font-family:"Times New Roman",serif;'><br>&nbsp;&nbsp;</p>