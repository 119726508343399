import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { faListOl, faUpload, faEye } from '@fortawesome/free-solid-svg-icons';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import {
  CategorieService, ArticleService, ParagrapheService, UtilisateurService,
  FichierService, TitreService, ActivityService, Titre, Paragraphe, Article, Activity, HistoriqueService, Fichier
} from 'src/app/rest';
import { Historique } from 'src/app/rest/model/historique';
import { Constant } from 'src/app/shared/_constants/constant';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-modifierarticle',
  templateUrl: './modifierarticle.component.html',
  styleUrls: ['./modifierarticle.component.scss']
})
export class ModifierarticleComponent implements OnInit {
  @ViewChild('myModal', { static: false }) preview;
  @ViewChild('exampleModalLong', { static: false }) save;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  faUpload = faUpload;
  faList = faListOl;
  faEye = faEye;

  config: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text ...',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  configtitre: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '2rem',
    minHeight: '5rem',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  apiKeys: { [key: string]: string };
  catItems: any;

  idCat = '';
  titre = '';
  sousTitre = '';
  resume = '';
  body = '';
  motCle = '';
  tag = '';
  description = '';
  commentaire = '';
  utilisateurCreation = '';
  utilisateurModif = '';
  dateCreation;
  dateModif;
  estPublie = false;
  Docu: boolean;
  isFrench: boolean;
  url;
  metaTitle;
  metaDescription;
  metaDetails;
  baliseEntete;
  taillePolice;
  currentPath;
  currentUser;
  etat;
  lien: boolean;
  isUpdating = false;
  fileToUpload: any[];
  modeles;
  polices;
  mc: any[] = [];
  tags: any[] = [];
  mcEN: any[] = [];
  tagsEN: any[] = [];
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private id: string;
  private items: any;

  // Paragraphes
  public paragraphes: any[] = [{
    idpar: '',
    titre: '',
    body: '',
    titreEN: '',
    bodyEN: ''
  }];

  resumeEN = '';
  titreEN = '';

  metaTiltleEN = '';
  metaDescriptionEN = '';
  metaDetailsEN = '';

  displayedColumns: string[] = ['utilisateur', 'dateAction', 'Actions'];
  dataSource = new MatTableDataSource();
  isLoading;
  currentHistorique: Historique;

  photoCoverture: Fichier;
  alt: string;
  altEN: string;
  src;
  nb_imagebackground: any;

  constructor(
    private categorie: CategorieService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private article: ArticleService,
    private paragrapheService: ParagrapheService,
    private utilisateur: UtilisateurService,
    private fichier: FichierService,
    private titreService: TitreService,
    private activity: ActivityService,
    private historiqueService: HistoriqueService,
    public loadingPagesService: LoadingPagesService
  ) {
    this.alt = '';
    this.altEN = '';
    this.paragraphes.splice(0, 1);
    this.route.queryParams.subscribe(
      params => {
        // tslint:disable-next-line: no-string-literal
        this.id = params['idArticle'];
        if (this.id === undefined) {
          this.router.navigate(['/back/articles']);
        }
        this.getPhoto(this.id);
        this.getArticle(this.id);
        this.getParagraphes(this.id);
        this.getHistory(this.id);

        // this.getCategorie();
      });
  }

  ngOnInit() {
    this.loadingPagesService.setPageStatus(true);
    this.currentHistorique = {
      id: '',
      idArticle: '',
      article: {},
      commentaire: '',
      utilisateur: '',
      dateAction: new Date()
    };
    this.idCat = '';
    this.titre = '';
    this.sousTitre = '';
    this.resume = '';
    this.body = '';
    this.motCle = '';
    this.tag = '';
    this.description = '';
    this.commentaire = '';
    this.utilisateurCreation = '';
    this.utilisateurModif = '';
    this.dateCreation = new Date();
    this.dateModif = new Date();
    this.estPublie = false;
    this.lien = false;
    this.url = '';
    this.metaTitle = '',
      this.metaDescription = '';
    this.metaDetails = '';
    this.modeles = '';
    this.polices = '';
    this.baliseEntete = 'h1';
    this.taillePolice = 20;

    this.mc = [];
    this.tags = [];
    this.mcEN = [];
    this.tagsEN = [];
    this.selectable = true;
    this.removable = true;
    this.addOnBlur = true;
    this.currentPath = window.location.origin + '/';
    this.currentUser = [];
    this.etat = 'Brouillon';
    this.fileToUpload = [];
    this.resumeEN = '';
    this.metaTiltleEN = '';
    this.metaDescriptionEN = '';
    this.metaDetailsEN = '';

    this.Docu = true;
    this.isFrench = true;
    this.getUser();
  }

  getPhoto(id) {
    this.fichier.getByArticle(id).subscribe(data => {
      if (data != null) {
        if (data.length > 0) {
          // tslint:disable-next-line: no-shadowed-variable
          data.forEach(element => {
            const parag = {
              name: element.name,
              file: element.filePath,
              type: element.type,
              IsBackground: element.isBackground,
              alt : element.alt,
              altEN : element.altEN,
              paragraphe: ''
            };
            this.fileToUpload.push(parag);
          });
        }
      }


    });
  }

  handleFileInput(file) {
    // tslint:disable-next-line: variable-name
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4'];
    if (file !== undefined) {
      if (allowed_types.indexOf(file.item(0).type) === -1) {
        this.toastr.error('Ce type de fichier est introuvable', 'Erreur');
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (file.item(0).type === 'video/mp4') {
          const f = {
            name: file.item(0).name,
            type: 'video',
            // tslint:disable-next-line: no-angle-bracket-type-assertion
            src: (<FileReader> e.target).result,
            IsBackground: file.item(0).IsBackground,
            paragraphe: ''
          };

          this.fileToUpload.push(f);
        } else {
          const image = new Image();
          image.src = e.target.result;
          image.onload = rs => {
            const imgBase64Path = e.target.result;
            const f = {
              name: file.item(0).name,
              type: 'image',
              src: imgBase64Path,
              IsBackground: file.item(0).IsBackground,
              paragraphe: ''
            };
            this.fileToUpload.push(f);
            const entete = f.src.substr(0, f.src.indexOf(',') + 1);
            const nf = f.src.replace(entete, '');
            this.fichier.saveFile(
              { name: f.name, file: nf, path: 'Files/' + f.name.replace(/[^a-zA-Z0-9.]/g, '') }
            ).subscribe(() => { });
          };
        }
      };
      reader.readAsDataURL(file.item(0));
    }
  }

  removeFile(file) {
    const index = this.fileToUpload.indexOf(file);
    if (index >= 0) {
      this.fileToUpload.splice(index, 1);
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.paragraphes, event.previousIndex, event.currentIndex);
  }

  getParagrapheNumber(id) {
    let i = 0;
    let r = -1;
    // tslint:disable-next-line: no-shadowed-variable
    this.paragraphes.forEach(element => {
      if (element.idpar === id) {
        r = i;
      }
      i++;
    });
    return r;
  }

  getParagraphes(id) {
    this.paragrapheService.getByArticle(id).subscribe(data => {
      // tslint:disable-next-line: no-shadowed-variable
      data.forEach(element => {
        this.paragraphes.push({
          idpar: element.id,
          titre: element.titre, // .replace(/<[^>]+>/g, ''),
          body: element.body,
          titreEN: element.titreEN,
          bodyEN: element.bodyEN
        });
      });
    });
  }

  getHistory(id) {
    this.isLoading = true;
    this.dataSource.data = [];
    this.historiqueService.getByArticle(id).subscribe(data => {
      this.dataSource.data = data;
      this.isLoading = false;
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getArticle(id) {
    this.article.get_1(id).subscribe(data => {
      this.items = data;
      if (this.items === undefined) {
        this.router.navigate(['/back/articles']);
      }

      if (this.items.titres[0].contenu !== undefined) {
        this.titre = this.items.titres[0].contenu; // .replace(/<[^>]+>/g, '');
      }

      if (this.items.titres[0].contenuEN !== undefined) {
        this.titreEN = this.items.titres[0].contenuEN; // .replace(/<[^>]+>/g, '');
      }

      // this.idCat = this.items.categorie.categorieId;
      this.sousTitre = this.items.sousTitre;
      this.resume = this.items.resume;
      this.body = this.items.body;
      this.motCle = this.items.motsCle;
      this.tag = this.items.tag;
      this.metaTitle = this.items.metaTiltle;
      this.metaDescription = this.items.metaDescription;
      this.metaDetails = this.items.metaDetails;
      this.description = this.items.description;
      this.commentaire = this.items.commentaire;
      this.estPublie = this.items.estPublie;
      this.url = this.items.lien;
      this.etat = this.getEtat(this.items.etatArticle);
      this.resumeEN = this.items.resumeEn;
      this.metaTiltleEN = this.items.metaTiltleEn;
      this.metaDescriptionEN = this.items.metaDescriptionEn;
      this.metaDetailsEN = this.items.metaDetailsEN;
      this.utilisateurCreation = this.items.utilisateurCreation;
      this.utilisateurModif = this.items.utilisateurModification;
      this.dateCreation = this.items.dateCreation;
      this.dateModif = this.items.dateModification;
      // tslint:disable-next-line: prefer-const
      let tabTags = this.items.tag.split('*');
      // tslint:disable-next-line: no-shadowed-variable
      tabTags.forEach(element => {
        if (element !== '') {
          this.tags.push({ name: element.trim() });
        }
      });
      const tabMotCle = this.items.motsCle.split('*');
      // tslint:disable-next-line: no-shadowed-variable
      tabMotCle.forEach(element => {
        if (element !== '') {
          this.mc.push({ name: element.trim() });
        }
      });

      const tabTagsEN = (this.items.tagEN !== undefined ? this.items.tagEN.split('*') : []);
      // tslint:disable-next-line: no-shadowed-variable
      tabTagsEN.forEach((element) => {
        if (element !== '') {
          this.tagsEN.push({ name: element.trim() });
        }
      });

      const tabMotCleEN = (this.items.motsCleEN !== undefined ? this.items.motsCleEN.split('*') : []);
      // tslint:disable-next-line: no-shadowed-variable
      tabMotCleEN.forEach((element) => {
        if (element !== '') {
          this.mcEN.push({ name: element.trim() });
        }
      });
    },
      () => {
      });
  }

  getEtat(etat) {
    if (etat === 0) {
      return 'Brouillon';
    }

    if (etat === 1) {
      return 'EnAttenteDeLecture';
    }

    if (etat === 2) {
      return 'Prive';
    }

    if (etat === 3) {
      return 'Publie';
    }
  }

  getCategorie() {
    this.categorie.getCategories().subscribe(data => {
      this.catItems = data;
      // tslint:disable-next-line: no-shadowed-variable
    }, error => {
      this.toastr.error(error, 'Erreur');
    });
  }

  async UpdateTitle() {
    if (this.items.titres[0] !== undefined) {
      this.titreService.getByArticle(this.id).subscribe(async data => {
        const title = data[0];
        title.contenu = this.titre;
        title.utilisateurModification = this.currentUser.userName;
        title.dateModification = new Date();
        title.contenuEN = this.titreEN;
        await this.titreService.put(title.id, title).subscribe(async () => { },
          () => { });
      });
    } else {
      const dt = new Date();
      const titreArticle: Titre = {
        id: Guid.create().toString(),
        contenu: this.titre,
        idArticle: this.id,
        contenuEN: this.titreEN,
        utilisateurCreation: this.currentUser.userName,
        utilisateurModification: this.currentUser.userName,
        dateCreation: dt,
        dateModification: dt
      };
      this.titreService.post(titreArticle).subscribe(async () => { }, () => {
        console.log('title : Not ok');
      });
    }
  }

  async SaveParagaphes() {
    let i = 0;
    // tslint:disable-next-line: no-shadowed-variable
    this.paragraphes.forEach(async element => {
      const paragrapheArticle: Paragraphe = {
        id: element.idpar,
        titre: element.titre,
        body: element.body,
        titreEN: element.titreEN,
        bodyEN: element.bodyEN,
        idArticle: this.id,
        order: i,
        utilisateurCreation: this.currentUser.userName,
        utilisateurModification: this.currentUser.userName,
        dateCreation: new Date(),
        dateModification: new Date()
      };
      i++;
      await this.paragrapheService.post(paragrapheArticle).subscribe(() => { },
        // tslint:disable-next-line: no-shadowed-variable
        error => {
          console.log(error, 'paragraphe : not ok');
        });
    });
  }

  async deleteFiles(idArticle) {
    this.fichier.deleteByArticle(idArticle).subscribe(async data => {

    }, (error) => {
      console.log(error);
    });
  }

  setPhoto() {
    this.src = Constant.ApiRoot + '/' + this.photoCoverture.filePath;
  }

  async SaveHistory(ida, user, cmntr) {
    const hist: Historique = {
      id: Guid.create().toString(),
      idArticle: ida,
      commentaire: cmntr,
      utilisateur: user,
      dateAction: new Date()
    };

    this.historiqueService.post(hist).subscribe(() => { }, () => {
      console.log('Historique : Erreur');
    });
  }

  async Update() {
    const user = this.currentUser.userName;

    if (this.checkartricle() && this.url !== '' ) {
      console.log(this.checkartricle());
      this.isUpdating = true;
      this.article.getByURL(this.url).subscribe(async link => {
        if (link.idArticle === '00000000-0000-0000-0000-000000000000' || link.idArticle === this.id) {
          await this.UpdateTitle();
          await this.deleteFiles(this.id);

          this.paragrapheService.deleteByArticle(this.id).subscribe(() => {
            let motCles = '';
            let tags = '';
            let motClesEN = '';
            let tagsEN = '';

            if (this.mc.length !== 0) {
              // tslint:disable-next-line: no-shadowed-variable
              this.mc.forEach(element => {
                motCles = motCles + element.name + '*';
              });
            }

            if (this.tags.length !== 0) {
              // tslint:disable-next-line: no-shadowed-variable
              this.tags.forEach(element => {
                tags = tags + element.name + '*';
              });
            }

            if (this.mcEN.length !== 0) {
              // tslint:disable-next-line: no-shadowed-variable
              this.mcEN.forEach((element) => {
                motClesEN = motClesEN + element.name + '*';
              });
            }

            if (this.tagsEN.length !== 0) {
              // tslint:disable-next-line: no-shadowed-variable
              this.tagsEN.forEach((element) => {
                tagsEN = tagsEN + element.name + '*';
              });
            }

            const dt = new Date();
            this.SaveHistory(this.id, this.currentUser.userName, this.commentaire);
            const art: Article = {
              idArticle: this.id,
              resume: this.resume,
              resumeEn: this.resumeEN,
              motsCle: motCles,
              motsCleEN: motClesEN,
              tag: tags,
              tagEN: tagsEN,
              commentaire: this.commentaire,
              estPublie: this.estPublie,
              lien: this.url,
              etatArticle: this.etat,
              autorisationCommentaire: this.items.autorisationCommentaire,
              metaTiltle: this.metaTitle,
              metaDescription: this.metaDescription,
              metaDetails: this.metaDetails,
              metaTiltleEn: this.metaTiltleEN,
              metaDescriptionEn: this.metaDescriptionEN,
              metaDetailsEN: this.metaDetailsEN,
              utilisateurCreation: this.items.utilisateurCreation,
              utilisateurModification: this.currentUser.userName,
              dateCreation: this.items.dateCreation,
              dateModification: dt
            };

            this.article.put(this.id, art).subscribe(async () => {
              // tslint:disable-next-line: no-shadowed-variable
              this.activity.getByArticle(this.id).subscribe(data => {
                if (data.length > 0) {
                  const activite = this.getActivityByStatut(data, '2');
                  if (activite != null) {
                    activite.dateModification = new Date();
                    activite.utilisateurModification = user;
                    this.activity.put(activite.idActivity, activite).subscribe(() => {
                      // tslint:disable-next-line: no-shadowed-variable
                    }, error => {
                      console.log(error);
                    });
                  } else {
                    // Add Activity
                    const act: Activity = {
                      idActivity: Guid.create().toString(),
                      description: 'Modification Article',
                      statut: 'Modifié',
                      idArticle: this.id,
                      utilisateurCreation: user,
                      utilisateurModification: user,
                      dateCreation: new Date(),
                      dateModification: new Date(),
                    };

                    this.activity.post(act).subscribe(() => { }, error => {
                      console.log(error);
                    });
                  }
                } else {
                  // add Activity
                  const act: Activity = {
                    idActivity: Guid.create().toString(),
                    description: 'Modification Article',
                    statut: 'Modifié',
                    idArticle: this.id,
                    utilisateurCreation: user,
                    utilisateurModification: user,
                    dateCreation: new Date(),
                    dateModification: new Date(),
                  };
                  this.activity.post(act).subscribe(() => { }, error => {
                    console.log(error);
                  });
                }
              });
              this.toastr.success('', 'Succès',
                {
                  timeOut: 5000,
                  progressBar: true,
                  closeButton: true
                });
              await this.SaveParagaphes();
              this.fileToUpload.forEach(element => {
                if (element.paragraphe === '') {
                  // Article
                  const fileArticle = {
                    idFile: Guid.create().toString(),
                    type: element.type,
                    name: element.name,
                    filePath: 'Files/' + element.name.replace(/[^a-zA-Z0-9.]/g, ''),
                    alt: element.alt,
                    altEN: element.altEN,
                    idArticle: this.id,
                    IsBackground: element.IsBackground,
                    utilisateurCreation: this.currentUser.userName,
                    utilisateurModification: this.currentUser.userName,
                    dateCreation: new Date(),
                    dateModification: new Date()
                  };
                  this.fichier.post(fileArticle).subscribe(() => {
                  },
                    () => {
                      this.isUpdating = false;
                      this.toastr.error('Erreur survenue lors le chargement des fichiers', 'Erreur',
                        {
                          timeOut: 5000,
                          progressBar: true,
                          closeButton: true
                        });
                    });
                }
              });
              this.isUpdating = true;
              this.preview.nativeElement.click();
              this.save.nativeElement.click();
              this.router.navigate(['/back/articles']);
              // tslint:disable-next-line: no-shadowed-variable
            }, error => {
              console.log(error);
            });
          });
        } else {
          this.isUpdating = false;
          this.toastr.error('Le lien déjà existe', 'Erreur',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
        }
      });
    } else  {
      if (this.url === '') {
        this.toastr.error('Merci de renseigner un lien validé', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      } else 
      {
        this.toastr.error('Merci d\'ajouter une seule photo de couverture', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      }

    }
  }

  checkartricle()
  {
    this.nb_imagebackground = 0 ;
    this.fileToUpload.forEach(element => {
      if (element.IsBackground)
      {
        this.nb_imagebackground++;
      }
    });
    if (this.nb_imagebackground === 1) {
      return true;
    } else {
      return false;
    }
  }

  getActivityByStatut(data, statut: string) {
    if (data.length > 0) {
      const table: Activity[] = data;
      let act: Activity;
      // tslint:disable-next-line: no-shadowed-variable
      table.forEach(element => {
        if (element.statut.toString() === statut) {
          act = element;
        }
      });
      return act;
    } else {
      return null;
    }
  }

  getUser() {
    if (localStorage.getItem('MyApp_Auth') !== undefined) {
      const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
      this.utilisateur.getUtilisateur(userId).subscribe(data => {
        this.currentUser = data;
      });
    }
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.mc.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: any): void {
    const index = this.mc.indexOf(fruit);
    if (index >= 0) {
      this.mc.splice(index, 1);
    }
  }

  addTags(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeTags(fruit: any): void {
    const index = this.tags.indexOf(fruit);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }


  setDialog(b) {
    this.Docu = b;
  }

  setLangauage(lg) {
    this.isFrench = lg;
  }

  changeEtat() {
    if (this.estPublie === true) {
      this.etat = 'Publie';
    } else {
      this.etat = 'Brouillon';
    }
  }

  getLink() {
    if (this.lien === true) {
      if (this.titre !== '') {
        let link = this.titre.replace(/<[^>]+>/g, '');
        link = link.replace(/[^a-zA-Z0-9]/g, '-');

        this.article.getURL(link).subscribe(data => {
          const list: Article[] = data;
          if (list.length === 0) {
            this.url = link;
          } else {
            const dt = new Date();
            const nb = dt.getHours().toString()
              + '' + dt.getMinutes().toString()
              + '' + dt.getSeconds().toString()
              + '' + dt.getMilliseconds().toString();
            this.url = link + nb;
          }
        });

      }
    }
  }

  AddParagraphe() {
    this.paragraphes.push({
      idpar: Guid.create().toString(),
      titre: '',
      body: '',
      titreEN: '',
      bodyEN: ''
    });
  }

  removeParagraphe(i) {
    this.paragraphes.splice(i, 1);
  }
  selParagraphe(event) {
  }

  addTagsEN(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tagsEN.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeTagsEN(fruit: any): void {
    const index = this.tagsEN.indexOf(fruit);
    if (index >= 0) {
      this.tagsEN.splice(index, 1);
    }
  }

  addEN(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.mcEN.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeEN(fruit: any): void {
    const index = this.mcEN.indexOf(fruit);
    if (index >= 0) {
      this.mcEN.splice(index, 1);
    }
  }

  MatTableTranslate() {
    this.paginator._intl.itemsPerPageLabel = 'Lignes par page';
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.lastPageLabel = 'Dernière page';
    this.paginator._intl.previousPageLabel = 'Page précédente';
    this.paginator._intl.firstPageLabel = 'Première page';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 / ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
  }

  setHistory(history: Historique) {
    this.currentHistorique = history;
  }

  linkFormat() {
    this.url = this.url.replace(/[^a-zA-Z0-9]/g, '-');
  }
}
