import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { faListOl, faUpload, faEye } from '@fortawesome/free-solid-svg-icons';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import {
  ArticleService, UtilisateurService,
  TitreService, ParagrapheService, ActivityService, Paragraphe, Article, Titre, Activity, HistoriqueService, Fichier, FichierService
} from 'src/app/rest';
import { Historique } from 'src/app/rest/model/historique';
import { Constant } from 'src/app/shared/_constants/constant';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-creerarticle',
  templateUrl: './creerarticle.component.html',
  styleUrls: ['./creerarticle.component.scss']
})
export class CreerarticleComponent implements OnInit {
  @ViewChild('myModal', { static: false }) preview;
  @ViewChild('exampleModalLong', { static: false }) save;

  faUpload = faUpload;
  faList = faListOl;
  faEye = faEye;

  config: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text ...',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  configtitre: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '2rem',
    minHeight: '5rem',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };

  apiKeys: { [key: string]: string };
  catItems: any;

  idCat = '';
  titre = '';
  sousTitre = '';
  resume = '';
  body = '';
  motCle = '';
  tag = '';
  description = '';
  commentaire = '';
  utilisateurCreation = '';
  dateCreation;
  estPublie = false;
  Docu: boolean;
  isFrench: boolean;
  url;
  metaTitle;
  metaDescription;
  metaDetails;
  baliseEntete;
  taillePolice;
  currentPath;
  currentUser;
  etat;
  lien: boolean;

  modeles;
  polices;
  mc: any[] = [];
  tags: any[] = [];
  mcEN: any[] = [];
  tagsEN: any[] = [];
  fileToUpload: any[];
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // Paragraphes
  public paragraphes: any[] = [{
    idpar: Guid.create().toString(),
    titre: '',
    body: '',
    titreEN: '',
    bodyEN: ''
  }];

  isUpdating = false;

  resumeEN = '';
  titreEN = '';

  metaTiltleEN = '';
  metaDescriptionEN = '';
  metaDetailsEN = '';

  photoCoverture: Fichier;
  alt: string;
  altEN: string;
  src;
  nb_imagebackground: number;

  constructor(
    private fichier: FichierService,
    private article: ArticleService,
    private toastr: ToastrService,
    private utilisateur: UtilisateurService,
    private router: Router,
    private titreService: TitreService,
    private paragrapheService: ParagrapheService,
    private activityService: ActivityService,
    private historiqueService: HistoriqueService,
    public loadingPagesService: LoadingPagesService
  ) {
  }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.photoCoverture = undefined;
    this.altEN = '';
    this.alt = '';
    this.idCat = '';
    this.titre = '';
    this.sousTitre = '';
    this.resume = '';
    this.body = '';
    this.motCle = '';
    this.tag = '';
    this.description = '';
    this.commentaire = '';
    this.estPublie = false;
    this.lien = false;
    this.url = '';
    this.metaTitle = '',
      this.metaDescription = '';
    this.metaDetails = '';
    this.modeles = '';
    this.polices = '';
    this.baliseEntete = 'h1';
    this.taillePolice = 20;

    this.mc = [];
    this.tags = [];
    this.mcEN = [];
    this.tagsEN = [];
    this.fileToUpload = [];
    this.selectable = true;
    this.removable = true;
    this.addOnBlur = true;
    this.currentPath = window.location.origin + '/';
    this.currentUser = [];
    this.etat = 'Brouillon';

    this.resumeEN = '';
    this.metaTiltleEN = '';
    this.metaDescriptionEN = '';
    this.metaDetailsEN = '';

    this.Docu = true;
    this.isFrench = true;
    this.dateCreation = new Date();
    this.getUser();
  }

  setPhoto() {
    this.src = Constant.ApiRoot + '/' + this.photoCoverture.filePath;
  }

  handleFileInput(file) {
    // tslint:disable-next-line: variable-name
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4'];
    if (file !== undefined) {
      if (allowed_types.indexOf(file.item(0).type) === -1) {
        this.toastr.error('Ce type de fichier est introuvable', 'Erreur');
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (file.item(0).type === 'video/mp4') {
          const f = {
            name: file.item(0).name,
            type: 'video',
            // tslint:disable-next-line: no-angle-bracket-type-assertion
            src: (<FileReader> e.target).result,
            IsBackground: file.item(0).IsBackground,
            paragraphe: ''
          };

          this.fileToUpload.push(f);
        } else {
          const image = new Image();
          image.src = e.target.result;
          image.onload = rs => {
            const imgBase64Path = e.target.result;
            const f = {
              name: file.item(0).name,
              type: 'image',
              src: imgBase64Path,
              IsBackground: file.item(0).IsBackground,
              paragraphe: ''
            };
            this.fileToUpload.push(f);
            const entete = f.src.substr(0, f.src.indexOf(',') + 1);
            const nf = f.src.replace(entete, '');
            this.fichier.saveFile(
              { name: f.name, file: nf, path: 'Files/' + f.name.replace(/[^a-zA-Z0-9.]/g, '') }
            ).subscribe(() => { });
          };
        }
      };
      reader.readAsDataURL(file.item(0));
    }
  }

  removeFile(file) {
    const index = this.fileToUpload.indexOf(file);
    if (index >= 0) {
      this.fileToUpload.splice(index, 1);
    }
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.paragraphes, event.previousIndex, event.currentIndex);
  }

  getUser() {
    if (localStorage.getItem('MyApp_Auth') !== undefined) {
      const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
      this.utilisateur.getUtilisateur(userId).subscribe(data => {
        this.currentUser = data;
        this.utilisateurCreation = this.currentUser.userName;
      });
    }
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.mc.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: any): void {
    const index = this.mc.indexOf(fruit);
    if (index >= 0) {
      this.mc.splice(index, 1);
    }
  }

  addTags(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeTags(fruit: any): void {
    const index = this.tags.indexOf(fruit);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  async SaveHistory(ida, user, cmntr) {
    const hist: Historique = {
      id: Guid.create().toString(),
      idArticle: ida,
      commentaire: cmntr,
      utilisateur: user,
      dateAction: new Date()
    };

    this.historiqueService.post(hist).subscribe(() => { }, () => {
      console.log('Historique : Erreur');
    });
  }

  async SaveParagaphes(ida) {
    let i = 0;
    this.paragraphes.forEach(element => {

      const paragrapheArticle: Paragraphe = {
        id: element.idpar,
        titre: element.titre,
        body: element.body,
        titreEN: element.titreEN,
        bodyEN: element.bodyEN,
        idArticle: ida,
        order: i,
        utilisateurCreation: this.currentUser.userName,
        utilisateurModification: this.currentUser.userName,
        dateCreation: new Date(),
        dateModification: new Date()
      };
      i++;

      this.paragrapheService.post(paragrapheArticle).subscribe(() => { },
        error => {
          console.log(error);
        });
    });
  }

  publier() {
    if (this.titre === '' || this.titre === undefined || this.titre === null) {
      this.preview.nativeElement.click();
      this.save.nativeElement.click();
      this.toastr.error('Merci de renseigner le titre de l\'article', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else if (this.url === '') {
      this.toastr.error('Merci de renseigner un lien validé', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else if (this.checkartricle()) {
      this.isUpdating = true;
      this.article.getByURL(this.url).subscribe(link => {
        if (link.idArticle === '00000000-0000-0000-0000-000000000000') {
          let motCles = '';
          let tags = '';
          let motClesEN = '';
          let tagsEN = '';
          if (this.mc.length !== 0) {
            this.mc.forEach(element => {
              motCles = motCles + element.name + '*';
            });
          }

          if (this.tags.length !== 0) {
            this.tags.forEach(element => {
              tags = tags + element.name + '*';
            });
          }

          if (this.mcEN.length !== 0) {
            this.mcEN.forEach((element) => {
              motClesEN = motClesEN + element.name + '*';
            });
          }

          if (this.tagsEN.length !== 0) {
            this.tagsEN.forEach((element) => {
              tagsEN = tagsEN + element.name + '*';
            });
          }

          const dt = new Date();
          const ida = Guid.create().toString();
          const idt = Guid.create().toString();
          const art: Article = {
            idArticle: ida,
            resume: this.resume,
            motsCle: motCles,
            tag: tags,
            commentaire: this.commentaire,
            estPublie: true,
            // parent: string,
            lien: this.url,
            etatArticle: this.etat,
            autorisationCommentaire: true,
            // order: 0,
            metaTiltle: this.metaTitle,
            metaDescription: this.metaDescription,
            metaDetails: this.metaDetails,
            resumeEn: this.resumeEN,
            motsCleEN: motClesEN,
            tagEN: tagsEN,
            metaTiltleEn: this.metaTiltleEN,
            metaDescriptionEn: this.metaDescriptionEN,
            metaDetailsEN: this.metaDetailsEN,
            utilisateurCreation: this.currentUser.userName,
            utilisateurModification: this.currentUser.userName,
            dateCreation: dt,
            dateModification: dt
          };

          const titreArticle: Titre = {
            id: idt,
            contenu: this.titre,
            idArticle: ida,
            contenuEN: this.titreEN,
            utilisateurCreation: this.currentUser.userName,
            utilisateurModification: this.currentUser.userName,
            dateCreation: dt,
            dateModification: dt
          };

          this.article.postArticle(art).subscribe(() => {
            // Ajout images
              this.fileToUpload.forEach(element => {
                if (element.paragraphe === '') {
                  // Article
                  const fileArticle = {
                    idFile: Guid.create().toString(),
                    type: element.type,
                    name: element.name,
                    filePath: 'Files/' + element.name.replace(/[^a-zA-Z0-9.]/g, ''),
                    alt: element.alt,
                    altEN: element.altEN,
                    idArticle: ida,
                    IsBackground: element.IsBackground,
                    utilisateurCreation: this.currentUser.userName,
                    utilisateurModification: this.currentUser.userName,
                    dateCreation: new Date(),
                    dateModification: new Date()
                  };
                  this.fichier.post(fileArticle).subscribe(() => {
                  },
                    () => {
                      this.isUpdating = false;
                      this.toastr.error('Erreur survenue lors le chargement des fichiers', 'Erreur',
                        {
                          timeOut: 5000,
                          progressBar: true,
                          closeButton: true
                        });
                    });
                }
              });
            // Add Activity
              const act: Activity = {
              idActivity: Guid.create().toString(),
              description: 'Création Article',
              statut: 'Brouillon',
              idArticle: ida,
              utilisateurCreation: this.currentUser.userName,
              utilisateurModification: this.currentUser.userName,
              dateCreation: new Date(),
              dateModification: new Date(),
            };
              this.activityService.post(act).subscribe(() => { }, error => {
              console.log(error);
            });
              this.toastr.success('', 'Succès',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
              this.titreService.post(titreArticle).subscribe(async () => {
              await this.SaveParagaphes(ida);
              await this.SaveHistory(ida, this.currentUser.userName, this.commentaire);
              this.isUpdating = true;
              this.preview.nativeElement.click();
              this.save.nativeElement.click();
              this.router.navigate(['/back/articles']);
            },
              error => {
                this.isUpdating = true;
                console.log(error);
              });


          },
            (err) => {
              this.toastr.error('', 'Erreur',
                {
                  timeOut: 5000,
                  progressBar: true,
                  closeButton: true
                });
            });
        } else {
          this.isUpdating = false;
          this.toastr.error('Le lien déjà existe', 'Erreur',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
        }
      });
    } else {
      if (!this.checkartricle()){
        this.toastr.error('Merci d\'ajouter une seule photo de couverture', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      }
    }
  }

  setDialog(b) {
    this.Docu = b;
  }

  changeEtat() {
    if (this.estPublie === true) {
      this.etat = 'Publie';
    } else {
      this.etat = 'Brouillon';
    }
  }
  checkartricle()
  {
    this.nb_imagebackground = 0 ;
    this.fileToUpload.forEach(element => {
      if (element.IsBackground)
      {
        this.nb_imagebackground++;
      }
    });
    if (this.nb_imagebackground === 1) {
      return true;
    } else {
      return false;
    }
  }

  getLink() {
    if (this.lien === true) {
      if (this.titre !== '') {
        let link = this.titre.replace(/<[^>]+>/g, '');
        link = link.replace(/[^a-zA-Z0-9]/g, '-');

        this.article.getURL(link).subscribe(data => {
          const list: Article[] = data;
          if (list.length === 0) {
            this.url = link;
          } else {
            const dt = new Date();
            const nb = dt.getHours().toString()
              + '' +
              dt.getMinutes().toString()
              + '' +
              dt.getSeconds().toString()
              + '' +
              dt.getMilliseconds().toString();
            this.url = link + nb;
          }
        });

      }
    }
  }

  AddParagraphe() {
    this.paragraphes.push({
      idpar: Guid.create().toString(),
      titre: '',
      body: '',
      titreEN: '',
      bodyEN: ''
    });
  }

  removeParagraphe(i) {
    this.paragraphes.splice(i, 1);
  }


  addTagsEN(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tagsEN.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeTagsEN(fruit: any): void {
    const index = this.tagsEN.indexOf(fruit);
    if (index >= 0) {
      this.tagsEN.splice(index, 1);
    }
  }

  addEN(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.mcEN.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeEN(fruit: any): void {
    const index = this.mcEN.indexOf(fruit);
    if (index >= 0) {
      this.mcEN.splice(index, 1);
    }
  }

  setLangauage(lg) {
    this.isFrench = lg;
  }

  linkFormat() {
    this.url = this.url.replace(/[^a-zA-Z0-9]/g, '-');
  }
}
