import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser'; 
import { Page  } from 'src/app/rest';
import { Constant } from '../../_constants/constant';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent implements OnInit {
  @Input() TitleDescription = [];
  @Input() ParagrapheArrayDescription = [];
  @Input() BackgroundImageDescription = [];
  @Input() OffresPage;

  isFrench: boolean;
  Image;
  constructor(
    private translate: TranslateService,
    private title: Title,
    private meta: Meta,
    public globalEventService: GlobalLanguageEventService
    ) {}

  ngOnInit(): void {
    this.ParagrapheArrayDescription.sort((a, b) => a.order - b.order);
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.Image = Constant.ApiRoot + '/' + this.BackgroundImageDescription[0].filePath;
  }

  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
  setMeta(page: Page) {
    if (this.isFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ name: 'description', content: page.metaDescriptionEN });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
    }
  }
}
