import { Component, Input, OnInit,AfterViewInit , SimpleChanges} from '@angular/core';
import { Constant } from '../../_constants/constant';
import { Meta, Title } from '@angular/platform-browser'; 
import { Page  } from 'src/app/rest';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { LoadingPagesService } from '../../_services/loading-pages.service';


@Component({
  selector: 'app-video-texte',
  templateUrl: './video-texte.component.html',
  styleUrls: ['./video-texte.component.scss']
})
export class VideoTexteComponent implements OnInit {
  @Input() STitleArrayCRM = [];
  @Input() SParagrapheCRM = [];
  @Input() NosSolutionsPage;
  @Input() Video = '';
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;
  @Input() IsFrench: boolean;
  ShowButton = false;
  IsMobile = false;
  constructor(
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    public loadingPagesService: LoadingPagesService
) { }

   // tslint:disable-next-line: use-lifecycle-interface
   ngOnChanges(changes: SimpleChanges) {
    this.setMeta(this.NosSolutionsPage);
  }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.setMeta(this.NosSolutionsPage);
    window.scrollTo(0, 0);
    this.height = this.height - 70;
    if (this.SParagrapheCRM[3].link !== null && this.SParagrapheCRM[3].link !== undefined && this.SParagrapheCRM[3].link !== '') {
      this.ShowButton = true;
    }
    if (window.innerWidth < 768) {
      this.IsMobile = true;
    }
  }


  GetImage(path: string) {
    return `${Constant.ApiRoot}/${path}`;
  }
  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    this.IsFrench = (lg === 'fr');
  }
  setMeta(page: Page) {
    if (this.IsFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
    
    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
    }
  }

}
