import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-carrousel',
  templateUrl: './service-carrousel.component.html',
  styleUrls: ['./service-carrousel.component.scss']
})
export class ServiceCarrouselComponent implements OnInit {
  @Input() ParagrapheArrayS = [];
  IsMobile = false;
  cellwidth: number;
  constructor() { }

  ngOnInit(): void {

    if (window.innerWidth < 768) {
      this.IsMobile = true;
      this.cellwidth = 280 ;
    } else {
      this.cellwidth = 350 ;
    }

  }

}
