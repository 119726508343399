/**
 * SiteAndexBack API
 * API de Base
 *
 * OpenAPI spec version: v1
 * Contact: amahmoudi@aiventu.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Utilisateurs } from '../model/utilisateurs';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { Constant } from 'src/app/shared/_constants/constant';


@Injectable()
export class UtilisateurService {

    protected basePath = Constant.ApiRoot;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param newPwd 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePwd(id: string, newPwd: string, observe?: 'body', reportProgress?: boolean): Observable<Utilisateurs>;
    public changePwd(id: string, newPwd: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateurs>>;
    public changePwd(id: string, newPwd: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateurs>>;
    public changePwd(id: string, newPwd: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling changePwd.');
        }

        if (newPwd === null || newPwd === undefined) {
            throw new Error('Required parameter newPwd was null or undefined when calling changePwd.');
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Utilisateurs>(`${this.basePath}/api/Utilisateur/ChangePwd/${encodeURIComponent(String(id))}/${encodeURIComponent(String(newPwd))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUtilisateur(id: string, observe?: 'body', reportProgress?: boolean): Observable<Utilisateurs>;
    public deleteUtilisateur(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateurs>>;
    public deleteUtilisateur(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateurs>>;
    public deleteUtilisateur(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUtilisateur.');
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Utilisateurs>(`${this.basePath}/api/Utilisateur/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param utilisateur 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editPass(id: string, utilisateur?: Utilisateurs, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public editPass(id: string, utilisateur?: Utilisateurs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public editPass(id: string, utilisateur?: Utilisateurs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public editPass(id: string, utilisateur?: Utilisateurs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling editPass.');
        }


        let headers = this.defaultHeaders;

        /*var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }*/

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/Utilisateur/NewPassword/${encodeURIComponent(String(id))}`,
            utilisateur,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminByMail(email: string, observe?: 'body', reportProgress?: boolean): Observable<Utilisateurs>;
    public getAdminByMail(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateurs>>;
    public getAdminByMail(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateurs>>;
    public getAdminByMail(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getAdminByMail.');
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Utilisateurs>(`${this.basePath}/api/Utilisateur/${encodeURIComponent(String(email))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param user 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminByUser(user: string, observe?: 'body', reportProgress?: boolean): Observable<Utilisateurs>;
    public getAdminByUser(user: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateurs>>;
    public getAdminByUser(user: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateurs>>;
    public getAdminByUser(user: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling getAdminByUser.');
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Utilisateurs>(`${this.basePath}/api/Utilisateur/User/${encodeURIComponent(String(user))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserByEmail(email: string, observe?: 'body', reportProgress?: boolean): Observable<Utilisateurs>;
    public getUserByEmail(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateurs>>;
    public getUserByEmail(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateurs>>;
    public getUserByEmail(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getUserByEmail.');
        }

        let headers = this.defaultHeaders;

        /*var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }*/

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Utilisateurs>(`${this.basePath}/api/Utilisateur/GetUserByEmail/${encodeURIComponent(String(email))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUtilisateur(id: string, observe?: 'body', reportProgress?: boolean): Observable<Utilisateurs>;
    public getUtilisateur(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateurs>>;
    public getUtilisateur(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateurs>>;
    public getUtilisateur(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUtilisateur.');
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Utilisateurs>(`${this.basePath}/api/Utilisateur/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUtilisateurs(observe?: 'body', reportProgress?: boolean): Observable<Array<Utilisateurs>>;
    public getUtilisateurs(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Utilisateurs>>>;
    public getUtilisateurs(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Utilisateurs>>>;
    public getUtilisateurs(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Utilisateurs>>(`${this.basePath}/api/Utilisateur`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Login 
     * @param Pass 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public login(Login: string, Pass: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public login(Login: string, Pass: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public login(Login: string, Pass: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public login(Login: string, Pass: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (Login === null || Login === undefined) {
            throw new Error('Required parameter Login was null or undefined when calling login.');
        }

        if (Pass === null || Pass === undefined) {
            throw new Error('Required parameter Pass was null or undefined when calling login.');
        }

        let headers = this.defaultHeaders;

        //var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        //if (token != undefined) {
        //    headers = headers.set('Authorization', token);
        //}

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Array<any>>(`${this.basePath}/api/Utilisateur/${encodeURIComponent(String(Login))}/${encodeURIComponent(String(Pass))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param utilisateur 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAdmin(utilisateur?: Utilisateurs, observe?: 'body', reportProgress?: boolean): Observable<Utilisateurs>;
    public postAdmin(utilisateur?: Utilisateurs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateurs>>;
    public postAdmin(utilisateur?: Utilisateurs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateurs>>;
    public postAdmin(utilisateur?: Utilisateurs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Utilisateurs>(`${this.basePath}/api/Utilisateur`,
            utilisateur,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param utilisateur 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putUtilisateur(id: string, utilisateur?: Utilisateurs, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public putUtilisateur(id: string, utilisateur?: Utilisateurs, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public putUtilisateur(id: string, utilisateur?: Utilisateurs, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public putUtilisateur(id: string, utilisateur?: Utilisateurs, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling putUtilisateur.');
        }


        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/Utilisateur/${encodeURIComponent(String(id))}`,
            utilisateur,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param mail 
     * @param user 
     * @param pwd 
     * @param msg 
     * @param subject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendEmailUser(mail: string, user: string, pwd: string, msg: string, subject: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public sendEmailUser(mail: string, user: string, pwd: string, msg: string, subject: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public sendEmailUser(mail: string, user: string, pwd: string, msg: string, subject: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public sendEmailUser(mail: string, user: string, pwd: string, msg: string, subject: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mail === null || mail === undefined) {
            throw new Error('Required parameter mail was null or undefined when calling sendEmailUser.');
        }

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling sendEmailUser.');
        }

        if (pwd === null || pwd === undefined) {
            throw new Error('Required parameter pwd was null or undefined when calling sendEmailUser.');
        }

        if (msg === null || msg === undefined) {
            throw new Error('Required parameter msg was null or undefined when calling sendEmailUser.');
        }

        if (subject === null || subject === undefined) {
            throw new Error('Required parameter subject was null or undefined when calling sendEmailUser.');
        }

        let headers = this.defaultHeaders;

        /*var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }*/

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/Utilisateur/SendEmail/${encodeURIComponent(String(mail))}/${encodeURIComponent(String(user))}/${encodeURIComponent(String(pwd))}/${encodeURIComponent(String(msg))}/${encodeURIComponent(String(subject))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateCurrentToken(token?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public validateCurrentToken(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public validateCurrentToken(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public validateCurrentToken(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
        if (token != undefined) {
            headers = headers.set('Authorization', token);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/api/Utilisateur/ValidateCurrentToken`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
