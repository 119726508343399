/**
 * SiteAndexBack API
 * API de Base
 *
 * OpenAPI spec version: v1
 * Contact: amahmoudi@aiventu.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Activity } from './activity';
import { Categorie } from './categorie';
import { Commentaire } from './commentaire';
import { Fichier } from './fichier';
import { Modele } from './modele';
import { Paragraphe } from './paragraphe';
import { Titre } from './titre';
import { Historique } from './historique';

export interface Article { 
    idArticle?: string;
    resume?: string;
    motsCle?: string;
    tag?: string;
    // description?: string;
    // idCat?: string;
    // categorie?: Categorie;
    modele?: Modele;
    commentaire?: string;
    estPublie?: boolean;
    parent?: string;
    lien?: string;
    etatArticle?: Article.EtatArticleEnum;
    autorisationCommentaire?: boolean;
    commentaireUsr?: Array<Commentaire>;
    fichier?: Array<Fichier>;
    titres?: Array<Titre>;
    paragraphes?: Array<Paragraphe>;
    activities?: Array<Activity>;
    historiques?: Array<Historique>;
    order?: number;
    metaTiltle?: string;
    metaDescription?: string;
    metaDetails?: string;
    resumeEn?: string;
    motsCleEN?: string;
    tagEN?: string;
    metaTiltleEn?: string;
    metaDescriptionEn?: string;
    metaDetailsEN?: string;
    utilisateurCreation?: string;
    utilisateurModification?: string;
    dateCreation?: Date;
    dateModification?: Date;
}
export namespace Article {
    export type EtatArticleEnum = 'Brouillon' | 'EnAttenteDeLecture' | 'Prive' | 'Publie';
    export const EtatArticleEnum = {
        Brouillon: 'Brouillon' as EtatArticleEnum,
        EnAttenteDeLecture: 'EnAttenteDeLecture' as EtatArticleEnum,
        Prive: 'Prive' as EtatArticleEnum,
        Publie: 'Publie' as EtatArticleEnum
    };
}
