import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageService } from 'src/app/rest';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { LoadingPagesService } from '../../_services/loading-pages.service';

@Component({
  selector: 'app-dynamics-crm',
  templateUrl: './dynamics-crm.component.html',
  styleUrls: ['./dynamics-crm.component.scss']
})
export class DynamicsCrmComponent implements OnInit {

  isWorking: boolean;
  DynamicsCRM;
  DynamicsCRMTitres;
  @Input() hr: boolean;
  isFrench: boolean;
  Width: number;
  height: number;
  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private pageService: PageService,
    public loadingPagesService: LoadingPagesService
  ) { }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.hr = true ;
    this.Width = window.innerWidth;
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.isWorking = true;
    this.pageService.getByCategorieAndModele('Dynamics 365 CRM', 'image vidéo texte').subscribe(dt => {
      this.DynamicsCRM = dt[0].paragraphes.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
      this.DynamicsCRMTitres = dt[0].titres;
      this.height = window.innerHeight;
      this.Width = window.innerWidth;
      this.isWorking = false;
    }, () => {
      this.isWorking = false;
    });

  }
  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
