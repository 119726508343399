import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from '../../_constants/constant';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { Meta, Title } from '@angular/platform-browser';
import {  Page } from 'src/app/rest';

@Component({
  selector: 'app-fenetres-informatives',
  templateUrl: './fenetres-informatives.component.html',
  styleUrls: ['./fenetres-informatives.component.scss']
})
export class FenetresInformativesComponent implements OnInit {

  @Input() TitreArrayFI = [];
  @Input() ParagrapheArrayFI = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;
  @Input() Bloc: boolean;
  CardHeight: any;
  category = '';
  isFrench: boolean;
  @Input() Meta: any;

  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private sanitizer: DomSanitizer,  
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    // this.setMeta(this.Meta);
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.ParagrapheArrayFI.forEach(element => {
      if (element.fichier.length !== 0) {
        element.fichier[0].filePath = `${Constant.ApiRoot}/${element.fichier[0].filePath}`;
      }
    });
    this.height = this.height - 70;
    this.CardHeight = this.height / 2.2;
    this.route.paramMap.subscribe((params) => {
      this.category = params.get('category');
    });
  }

  GetImage(path: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }

  setMeta(page: Page) {
    // if (this.isFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
    // } else {
    //   this.title.setTitle(page.metaTiltleEN);
    //   let motsCles = '';
    //   if (page !== undefined && page.motsCleEN !== undefined) {
    //     motsCles = page.motsCleEN.replace(/\*/gi, ',');
    //   }
    //   this.meta.updateTag({ name: 'description', content: page.metaDescriptionEN });
    //   this.meta.updateTag({ name: 'keywords', content: motsCles });
    // }
  }


}
