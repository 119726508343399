import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PageService, Page  } from 'src/app/rest';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { LoadingPagesService } from '../../_services/loading-pages.service';

@Component({
  selector: 'app-qui-sommes-nous',
  templateUrl: './qui-sommes-nous.component.html',
  styleUrls: ['./qui-sommes-nous.component.scss']
})
export class QuiSommesNousComponent implements OnInit {

  isWorking: boolean;
  nosServicePage;
  nosCertificationsPage;
  isFrench: boolean;
  link;
  Details: boolean;

  NosSolutionsTitres;
  NosSolutionsModele;
  NosSolutions;
  Width: number;
  height: number;
  CardHeight: number;
  IsMobile = false;
  NosSolutionsPage ;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private pageService: PageService,
    private title: Title,
    private meta: Meta,
    public loadingPagesService: LoadingPagesService
  ) { }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    window.scrollTo(0, 0);
    if (window.innerWidth < 768) {
      this.IsMobile = true;
    }
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      this.IsMobile = true;
    }

    this.route.paramMap.subscribe((params) => {
      this.link = params.get('link');
      this.Details = this.link !== null;
      if (this.Details) {
        this.isWorking = true;
        this.pageService.getURL(this.link).subscribe(data => {
          if (data.length === 0) {
            this.router.navigate(['/Error/NotFound']);
            return;
          }
          this.NosSolutions = data[0].paragraphes.sort((a, b) =>
            a.order > b.order ? 1 : -1
          );
          this.NosSolutionsTitres = data[0].titres;
          this.NosSolutionsModele = data[0].modele;
          this.NosSolutionsPage = data[0] ;
          this.height = window.innerHeight - 70;
          this.CardHeight = this.height / 2.7;
          this.isWorking = false;
        });
      } else {
        this.isWorking = true;
        this.pageService.getByCategorieAndModele('Qui-Sommes-Nous', 'fenetres informatives').subscribe(data => {
          this.pageService.getByCategorieAndModele('Qui-Sommes-Nous', 'paragraphes').subscribe(dt => {
            this.nosServicePage = data[0];
            this.setMeta(this.nosServicePage);
            this.nosServicePage.paragraphes.forEach((element, index) => {
              if (element.titre === '' && element.body === '') {
                this.nosServicePage.paragraphes.splice(index, 1);
              }
            });
            this.nosCertificationsPage = dt[0];
            this.isWorking = false;
          }, () => {
            this.isWorking = false;
          });
        }, () => {
          this.isWorking = false;
        });
      }
    });   
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
  setMeta(page: Page) {
    if (this.isFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com/Nos-Service' });
    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com/Nos-Service' });
    }
  }
}
