import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatchHeightDirective } from './_directives/match-height.directive';
import { ParallaxDirective } from './_directives/parallax.directive';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';
import { AnimationComponent } from './components/animation/animation.component';
import { BackNavbarComponent } from './components/back-navbar/back-navbar.component';
import { BackToolbarComponent } from './components/back-toolbar/back-toolbar.component';
import { BlocsComponent } from './components/blocs/blocs.component';
import { CardComponent } from './components/card/card.component';
import { ChiffreComponent } from './components/chiffre/chiffre.component';
import { EmploiComponent } from './components/emploi/emploi.component';
import { FenetrePopUpComponent } from './components/fenetre-pop-up/fenetre-pop-up.component';
import { FenetresInformativesComponent } from './components/fenetres-informatives/fenetres-informatives.component';
import { FormulaireComponent } from './components/formulaire/formulaire.component';
import { FrontNavbarComponent } from './components/front-navbar/front-navbar.component';
import { HrComponent } from './components/hr/hr.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LocalisationComponent } from './components/localisation/localisation.component';
import { OffresEmploisComponent } from './components/offres-emplois/offres-emplois.component';
import { PageDAccueilComponent } from './components/page-d-accueil/page-d-accueil.component';
import { ParallaxScrollingComponent } from './components/parallax-scrolling/parallax-scrolling.component';
import { SlideShowComponent } from './components/slide-show/slide-show.component';
import { SolutionComponent } from './components/solution/solution.component';
// tslint:disable-next-line: max-line-length
import { SuperpositionEntreImageEtTexteComponent } from './components/superposition-entre-image-et-texte/superposition-entre-image-et-texte.component';
import { WidgetsComponent } from './components/widgets/widgets.component';
import { TextesImageComponent } from './components/textes-image/textes-image.component';
import { VideoTexteComponent } from './components/video-texte/video-texte.component';
import { ImageVideoTexteComponent } from './components/image-video-texte/image-video-texte.component';
import { ParagraphesComponent } from './components/paragraphes/paragraphes.component';
import { DescriptionComponent } from './components/description/description.component';
import { FormPostulerComponent } from './components/form-postuler/form-postuler.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { EmploiCarrouselComponent } from './components/emploi-carrousel/emploi-carrousel.component';
import { LienDeRedirectionComponent } from './components/lien-de-redirection/lien-de-redirection.component';
import { ReseauSociauxComponent } from './components/reseau-sociaux/reseau-sociaux.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CandidatureSpontaneeComponent } from './components/candidature-spontanee/candidature-spontanee.component';
import { ReCaptchaComponent } from './components/re-captcha/re-captcha.component';
import { FrontContactNavbarComponent } from './components/front-contact-navbar/front-contact-navbar.component';
import { ServiceComponent } from './components/service/service.component';
import { ServiceCarrouselComponent } from './components/service-carrousel/service-carrousel.component';
import { TranslateModule } from '@ngx-translate/core';
import { QuiSommesNousComponent } from './components/qui-sommes-nous/qui-sommes-nous.component';
import { NosReferencesComponent } from './components/nos-references/nos-references.component';
import { CarrieresComponent } from './components/carrieres/carrieres.component';
import { ListoffresComponent } from './components/listoffres/listoffres.component';
import { NosSolutionComponent } from './components/nos-solution/nos-solution.component';
import { NosSolutionMicrosoftComponent } from './components/nos-solution-microsoft/nos-solution-microsoft.component';
import { Suiteinn4Component } from './components/suiteinn4/suiteinn4.component';
import { MicrosoftDynamicsComponent } from './components/microsoft-dynamics/microsoft-dynamics.component';
import { DynamicsHrComponent } from './components/dynamics-hr/dynamics-hr.component';
import { DynamicsBcComponent } from './components/dynamics-bc/dynamics-bc.component';
import { DynamicsCrmComponent } from './components/dynamics-crm/dynamics-crm.component';
import { NosSolutionDetailsComponent } from './components/nos-solution-details/nos-solution-details.component';

// tslint:disable-next-line: max-line-length


@NgModule({
  declarations: [
    SafeHtmlPipe,
    ParallaxDirective,
    MatchHeightDirective,
    SlideShowComponent,
    WidgetsComponent,
    ChiffreComponent,
    HrComponent,
    CardComponent,
    BlocsComponent,
    FenetrePopUpComponent,
    FenetresInformativesComponent,
    LandingPageComponent,
    AnimationComponent,
    PageDAccueilComponent,
    ParallaxScrollingComponent,
    FrontNavbarComponent,
    SuperpositionEntreImageEtTexteComponent,
    SolutionComponent,
    OffresEmploisComponent,
    EmploiComponent,
    FormulaireComponent,
    LocalisationComponent,
    BackNavbarComponent,
    BackToolbarComponent,
    TextesImageComponent,
    VideoTexteComponent,
    ImageVideoTexteComponent,
    ParagraphesComponent,
    EmploiCarrouselComponent,
    LienDeRedirectionComponent,
    DescriptionComponent,
    FormPostulerComponent,
    EmploiCarrouselComponent,
    ReseauSociauxComponent,
    ArticlesComponent,
    CandidatureSpontaneeComponent,
    ReCaptchaComponent,
    FrontContactNavbarComponent,
    ServiceComponent,
    ServiceCarrouselComponent,
    QuiSommesNousComponent,
    NosReferencesComponent,
    CarrieresComponent,
    ListoffresComponent,
    NosSolutionComponent,
    NosSolutionMicrosoftComponent,
    Suiteinn4Component,
    MicrosoftDynamicsComponent,
    DynamicsHrComponent,
    DynamicsBcComponent,
    DynamicsCrmComponent,
    NosSolutionDetailsComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    IvyCarouselModule,
    MaterialModule,
    FlexLayoutModule,
    AngularEditorModule,
    DragDropModule,
    TranslateModule
  ],
  exports: [
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AngularEditorModule,
    DragDropModule,
    SafeHtmlPipe,
    ParallaxDirective,
    MatchHeightDirective,
    SlideShowComponent,
    WidgetsComponent,
    ChiffreComponent,
    HrComponent,
    CardComponent,
    BlocsComponent,
    FenetrePopUpComponent,
    FenetresInformativesComponent,
    LandingPageComponent,
    AnimationComponent,
    PageDAccueilComponent,
    ParallaxScrollingComponent,
    FrontNavbarComponent,
    SuperpositionEntreImageEtTexteComponent,
    SolutionComponent,
    OffresEmploisComponent,
    EmploiComponent,
    FormulaireComponent,
    BackNavbarComponent,
    BackToolbarComponent,
    TextesImageComponent,
    VideoTexteComponent,
    ImageVideoTexteComponent,
    ParagraphesComponent,
    EmploiCarrouselComponent,
    LienDeRedirectionComponent,
    DescriptionComponent,
    FormPostulerComponent,
    EmploiCarrouselComponent,
    ReseauSociauxComponent,
    ArticlesComponent,
    CandidatureSpontaneeComponent,
    FrontContactNavbarComponent,
    ReCaptchaComponent,
    TranslateModule
  ],
  providers: [DatePipe]
})
export class SharedModule { }
