import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  isLoggedIn() {
    return (localStorage.getItem('MyApp_token') != null && localStorage.getItem('MyApp_Auth') != null && !this.isTokenExpired());
  }

  private isTokenExpired() {
    const helper = new JwtHelperService();
    let isExpired = true;
    let myRawToken;
    if (localStorage.getItem('MyApp_token')) {
      myRawToken = localStorage.getItem('MyApp_token').replace(/\"/gi, '');
    } else {
      myRawToken = '';
    }
    try {
      isExpired = helper.isTokenExpired(myRawToken);
      return isExpired;
    } catch (error) {
      console.log(error);
      return true;
    }
  }
}
