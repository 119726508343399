import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from '../../_constants/constant';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';


@Component({
  selector: 'app-page-daccueil',
  templateUrl: './page-d-accueil.component.html',
  styleUrls: ['./page-d-accueil.component.scss']
})
export class PageDAccueilComponent implements OnInit {
  @Input() EPageAccueilTitres = [];
  @Input() EPageAccueilParagraphes = [];
  @Input() backgroundImageURLAccueil = [];
  @Input() widgets = [];
  EPageAccueilPage = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;

  mobile = false;
  isFrench: boolean;
  element: HTMLElement;

  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService
  ) { 
    if (window.innerWidth < 700) {
      this.mobile = true;
    }
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      this.height = 370;
    }
  }

  ngOnInit(): void {
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    if (window.innerWidth < 700) {
      this.mobile = true;
    }
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      this.height = 370;
    }

    for (let i = 0; i < this.EPageAccueilTitres.length; i++) {
      this.EPageAccueilPage.push({ titres: this.EPageAccueilTitres[i], paragraphes: this.EPageAccueilParagraphes[i] });
    }

    this.widgets = [
      {
        link: 'https://www.linkedin.com/company/aiventu/',
        imageURL: 'assets/images/icones/linkedin.png',
        size: '30',
        margintop: '20%',
      },
      {
        link: 'https://twitter.com/aiventu_group',
        imageURL: 'assets/images/icones/Twitter.png',
        size: '30',
        margintop: '20%',
      },
      {
        link: 'https://www.facebook.com/AiventuGroup/',
        imageURL: 'assets/images/icones/Facebook.png',
        size: '50',
        margin: '-20%',
        margintop: '-10%',

      },
    ];
 
    if (window.innerWidth < 600) {
      this.widgets[0].size = 20;
      this.widgets[1].size = 22;
      this.widgets[2].size = 35;

    }
    this.element = document.getElementById('next') as HTMLElement;
    this.element.click();
  }
  GetImage(path: string) {
    return `${Constant.ApiRoot}/${path}`;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

  if (event.target.innerWidth< 1200 && event.target.innerWidth > 768) {
    this.height = 370;

  }

}

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    if (lg === 'fr') {
      this.isFrench = true;
    } else {
      this.isFrench = false;
    }
  }
}
