<app-image-video-texte 
*ngIf="NosSolutionsTitres!== undefined && NosSolutions!==undefined && NosSolutionsModele.name=='image vidéo texte'" 
[STitleSR]="NosSolutionsTitres" [SParagrapheSR]="NosSolutions" [NosSolutionsPage]="NosSolutionsPage"  [height]="height" [hr]="true"  [IsFrench]="isFrench" [width]="Width"></app-image-video-texte>
<app-video-texte
*ngIf="NosSolutionsTitres!== undefined && NosSolutions!==undefined &&  NosSolutionsModele.name=='vidéo texte'"
[STitleArrayCRM]="NosSolutionsTitres" [SParagrapheCRM]="NosSolutions" [NosSolutionsPage]="NosSolutionsPage" [height]="height" [hr]="true" [width]="Width" [IsFrench]="isFrench"></app-video-texte>
<app-suiteinn4
*ngIf="NosSolutionsTitres!== undefined && NosSolutions!==undefined &&  NosSolutionsModele.name=='suiteinn4'"
[SuiteInn4Titres]="NosSolutionsTitres" [SuiteInn4]="NosSolutions" [NosSolutionsPage]="NosSolutionsPage"  [height]="height" [hr]="true" [Width]="Width" ></app-suiteinn4>

<app-nos-solution-microsoft
*ngIf="NosSolutionsTitres!== undefined && NosSolutions!==undefined &&  NosSolutionsModele.name=='nos solution microsoft'"
[NosSolutionsTitres]="NosSolutionsTitres" [NosSolutions]="NosSolutions" [NosSolutionsPage]="NosSolutionsPage"  [height]="height" [hr]="true" [Width]="Width" ></app-nos-solution-microsoft>




