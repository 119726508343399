import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constant } from '../../_constants/constant';


@Component({
  selector: 'app-superposition-entre-image-et-texte',
  templateUrl: './superposition-entre-image-et-texte.component.html',
  styleUrls: ['./superposition-entre-image-et-texte.component.scss']
})
export class SuperpositionEntreImageEtTexteComponent implements OnInit {

  @Input() TitleArrayS = [];
  @Input() ParagrapheArrayS = [];
  @Input() BackgroundImageS = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;
  Image;

  constructor(private router: Router) {
    if (this.router.url === '/#temoignage') {
      const element: HTMLElement = document.getElementsByClassName('button-redirection')[0] as HTMLElement;
      setTimeout(() => { }, 30000);
      element.click();
    }
  }

  ngOnInit(): void {
    this.height = this.height - 70;
    this.Image = Constant.ApiRoot + '/' + this.BackgroundImageS[0].filePath;
  }

  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }

}
