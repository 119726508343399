import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageService } from 'src/app/rest';
import { Constant } from '../../_constants/constant';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { LoadingPagesService } from '../../_services/loading-pages.service';
import { faClock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-listoffres',
  templateUrl: './listoffres.component.html',
  styleUrls: ['./listoffres.component.scss']
})
export class ListoffresComponent implements OnInit {
  faClock = faClock;
  Page;
  paragraphe: any[] = [];
  isWorking: boolean;
  loadPage: boolean;
  isFrench: boolean;
  idOffre;
  pageOffre;
  IsMobile = false;
  date: Date;
  constructor(
    private router: Router,
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private pageService: PageService,
    private route: ActivatedRoute,
    public loadingPagesService: LoadingPagesService,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (window.innerWidth < 768) {
      this.IsMobile = true;
    }
    this.loadingPagesService.setPageStatus(true);
    this.loadPage = false;
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.idOffre = undefined;
    this.route.paramMap.subscribe((params) => {
      this.idOffre = params.get('link');
    });
    this.getPage();
  }

   getPage() {
    this.isWorking = true;
    this.pageService.getByCategorieAndModele('Carrieres', 'offres d\'emplois').subscribe(dt => {
      this.Page = dt[0];
       this.Page.paragraphes.forEach(async element => {
        if (element.name !== undefined && element.name !== '' && element.name !== null) {
          element.titre = this.decodeHtmlEntity(this.DecodeHTML(element.titre));
          element.titreEN = this.decodeHtmlEntity(this.DecodeHTML(element.titreEN));
          if (element.fichier[0] !== undefined) {
            element.fichier[0].filePath = Constant.ApiRoot + '/' + element.fichier[0].filePath;
          }
          if (element.link === this.idOffre) {
            this.getPageOffre(element.link);
          }
          else {
           this.pageService.getURL(element.link).subscribe(data => {
             if(data !== undefined){
            element.dateModification = data[0].dateModification;
             }
            
          }, () => {
            console.log("erreur")
          });
          }
          this.paragraphe.push(element);

        }
      });
      this.paragraphe.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
      
      this.isWorking = false;
    });
  }
  getPageOffre(id) {
    this.loadPage = true;
    this.pageService.getURL(id).subscribe(data => {
      this.pageOffre = data[0];
      this.loadPage = false;
    }, () => {
      this.router.navigate(['/carrieres/offres']);
      this.loadPage = false;
    });
  }
  goto(link) { }

  DecodeHTML(html: string) {
    return html.replace(/<[^>]+>/g, '');
  }

  decodeHtmlEntity(str) {
    // tslint:disable-next-line: only-arrow-functions
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  }

  deleteSpaceEncoding(str) {
    return str.replace(/&nbsp;/g, ' ');
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
