<div (mouseover)="slideshow()" class="site-section" [style.min-height]="height+'px'" [style.width]="width+'px'">
  <div class="bg-light" >
    <h2  [innerHTML]="TitreArrayB[0].contenu | safeHtml"></h2>
    
    <app-hr *ngIf="hr" ></app-hr> 
    <p class="text-black" [innerHTML]="ParagrapheArrayB[0].body | safeHtml"></p>
  </div>
  <div (mouseover)="slideshow()" class="col-12">
    <div class="row" *ngIf=index style="padding-top: 7vh;">
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12" style="padding-right: 0; padding-left: 0;">
        <img alt="b" [attr.data-src]="GetImage(ParagrapheArrayB[index].fichier[1].filePath)" class="img1 lazyload">
        <img alt="b" [attr.data-src]="GetImage(ParagrapheArrayB[index].fichier[0].filePath)" class="img2 lazyload" >
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="padding-left: 0;padding-right: 0;">
        <div class="card">
          <h6 [innerHTML]="ParagrapheArrayB[index].titre | safeHtml"></h6>
          <hr class="hrr">
          <div class="text-white" [innerHTML]="ParagrapheArrayB[index].body | safeHtml"></div>
          <div class="dot-container">
            <div class="Dotts">
              <span id="dot{{i}}" *ngFor="let paragraphe of ParagrapheArrayB | slice:1; let i = index;" class="dot" [ngStyle]="{'background-color': (state==i) ? '#f7e721' : 'white'}"
                (click)="buttonaffichage(i)"></span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>