import { Component, OnInit , HostListener , Input } from '@angular/core';
import { PageService } from 'src/app/rest/api/page.service';
import { faEnvelope, faPhone , faMobile } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from 'src/app/shared/_services/global-language-event.service';
import { Page } from 'src/app/rest';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  plandesite = 'plandesite';
  contactInfo = 'contactInfo';
  td = 'td';
  year: any;
  IsMentions: boolean;
  IsCookies: boolean;
  IsCGU: boolean;
  page: any;
  frontMenuTree;

  @Input() button = {
    title: '',
    href: '',
  };
  @Input() menuTree = [];
  contact = false;
  // isScrolling = false;
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faMobile = faMobile;
  suppoertLang = ['en', 'fr'];
  Button;
  isWorking: boolean;
  NosSolution: Page[];
  isFrench: boolean;
  isMobile: boolean;

  constructor(
    private pageService: PageService,
    private router: Router,
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService) {
    this.IsCGU = false;
    this.IsCookies = false;
    this.IsMentions = false;
    this.translate.addLangs(this.suppoertLang);
    this.translate.setDefaultLang('en');


    // this.PageService.get_1('62F20D7A-EA78-C48D-490C-3291A3BEB5FE').subscribe((data) => {

    //   this.page = data ;

    // });
  }
  setData() {

      this.frontMenuTree = [
        {
          title: this.translate.instant('Accueil'),
          url: '/',
          children: []
        }, {
          title: this.translate.instant('QuiSommesNous'),
          url: '/Nos-Services',
          children: [
          ]
        },
        {
          title: this.translate.instant('NosSolutions'),
          url: '/produits',
          children: [
            {
              title: 'Dynamics 365',
              url: 'produits/dynamics365'
            }
          ]
        },
        {
          title: this.translate.instant('NosReferences'),
          url: '/nos-references',
          children: []
        },
        {
          title: this.translate.instant('Actualites'),
          url: '/article',
          children: []
        },
        {
          title: this.translate.instant('Carrieres'),
          url: '/carrieres',
          children: []
        }
      ];
  }

  @HostListener('window:scroll', ['$event']) scrolling() {
    const max = document.documentElement.scrollHeight;
    // pos max will give you the distance between scroll bottom and and bottom of screen in percentage.

  }

  ngOnInit() {
    this.Button = {
      title: this.translate.instant('Contact'),
      href: '/nous-contacter'
    };
    if (window.innerWidth < 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.isWorking = true;
    this.pageService.getByCategory('e866f4a9-c1d3-3cdf-42fc-307cd56c2bc6').subscribe((data) => {

      if (data !== undefined && data !== null) {
        this.NosSolution = data;
        this.NosSolution =  this.NosSolution.sort((a, b) => a.order > b.order ? 1 : -1);
        this.NosSolution.shift();

      }
      this.isWorking = false;
    }, () => {
      this.isWorking = false;
    });
    this.year = new Date().getFullYear();
    this.setData();
  }
  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }

  setLanguage(lg) {
    if (lg === 'fr') {
      this.isFrench = true;
      localStorage.setItem('language', 'fr');
    } else {
      this.isFrench = false;
      localStorage.setItem('language', 'en');
    }
    this.translate.use(lg);
    this.globalEventService.setLanguage(lg);
  }

  redirect() {
    this.router.navigate(['/']);
  }

  ContactUs(): void {
    // this.state = 4;
    if (document.getElementById('nous-contacter') == null) {
      const element: HTMLElement = document.getElementsByClassName(
        'btn'
      )[0] as HTMLElement;
      element.click();
    }
  }


}
