import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Constant } from '../../_constants/constant';

@Component({
  selector: 'app-textes-image',
  templateUrl: './textes-image.component.html',
  styleUrls: ['./textes-image.component.scss']
})
export class TextesImageComponent implements OnInit {
  @Input() STitleArrayDM = [];
  @Input() SParagrapheArrayDM = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;
  photoUrl: SafeResourceUrl;

  constructor() { }

  ngOnInit(): void {
    this.height = this.height - 70;
  }

  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }

}
