import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationMailService } from 'src/app/rest';
import { Condidatures } from 'src/app/rest/model/condidatures';
import { Jointe } from 'src/app/rest/model/jointe';

@Component({
  selector: 'app-form-postuler',
  templateUrl: './form-postuler.component.html',
  styleUrls: ['./form-postuler.component.scss']
})
export class FormPostulerComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    private configuration: ConfigurationMailService,
    private translate: TranslateService
  ) { }
  nom: string;
  prenom: string;
  email: string;
  phone: string ;
  motivation: string;
  cv: string;
  fichier: Jointe = {
    fileName: '',
    enteteFileName: '',
    file: ''
  };
  fichiersecond: Jointe = {
    fileName: '',
    enteteFileName: '',
    file: ''
  };
  fichierthird: Jointe = {
    fileName: '',
    enteteFileName: '',
    file: ''
  };
  verificationRobot: boolean;
  isWorking: boolean;

  ngOnInit(): void {
    this.isWorking = false;
    this.resetForm();
  }

  resetForm() {
    this.nom = '';
    this.prenom = '';
    this.email = '';
    this.motivation = '';
    this.phone = '';
    this.cv = '';
    this.verificationRobot = false;
  }

  handleFileInput(file) {
    this.cv = '';
    this.fichier.fileName = '';
    this.fichier.enteteFileName = '';
    this.fichier.file = '';
    const size = (file.item(0).size) / 1048576;
    // tslint:disable-next-line: variable-name
    const allowed_types = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword'
    ];
    if (file !== undefined) {
      if (file.length < 4) {
        for (let i = 0; i < file.length; i++) {
          if (allowed_types.indexOf(file.item(i).type) === -1) {
            this.toastr.error(this.translate.instant('TypeIntrouvable'), this.translate.instant('Erreur'));
            return false;
          } else if (((file.item(i).size) / 1048576) > 3) {
            this.toastr.error(this.translate.instant('TailleFichier'), this.translate.instant('Erreur'));
            return false;
          } else {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const code = reader.result.toString();
              this.cv = file.item(0).name;
              if (i === 0) {
                this.fichier.fileName = file.item(0).name;
                this.fichier.enteteFileName = code.substr(0, code.indexOf(',') + 1);
                this.fichier.file = code.substr(code.indexOf(',') + 1, code.length);
              }
              if (i === 1) {
                this.fichiersecond.fileName = file.item(1).name;
                this.fichiersecond.enteteFileName = code.substr(0, code.indexOf(',') + 1);
                this.fichiersecond.file = code.substr(code.indexOf(',') + 1, code.length);
              }
              if (i === 2) {
                this.fichierthird.fileName = file.item(2).name;
                this.fichierthird.enteteFileName = code.substr(0, code.indexOf(',') + 1);
                this.fichierthird.file = code.substr(code.indexOf(',') + 1, code.length);
              }
            };
            reader.readAsDataURL(file.item(i));
          }
        }
      } else {
        this.toastr.error(this.translate.instant('NbrMaxFichier'), this.translate.instant('Erreur'));
        file = '';
      }
    }
  }

  postuler() {
    if (this.nom !== ''
      && this.prenom !== ''
      && this.email !== ''
      && this.phone !== ''
      && this.motivation !== ''
      && this.verificationRobot
      && this.fichier.file !== '') {
      // tslint:disable-next-line: prefer-const
      let file: Jointe[] = [];
      file.push(this.fichier);
      if (this.fichiersecond.file !== '') {
        file.push(this.fichiersecond);
      }
      if (this.fichierthird.file !== '') {
        file.push(this.fichierthird);
      }
      const cond: Condidatures = {
        name: this.prenom + ' ' + this.nom,
        email: this.email,
        phone : this.phone,
        message: this.motivation,
        files: file
      };
      this.isWorking = true;
      this.configuration.sendCondidature(cond).subscribe(() => {
        this.toastr.success(this.translate.instant('MsgEnvSucc'), this.translate.instant('Succes'),
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.isWorking = false;
        this.resetForm();
      }, (() => {
        this.toastr.error(this.translate.instant('ErreurEnvMsg'), this.translate.instant('Erreur'),
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.isWorking = false;
      }));
    } else {
      this.toastr.error(this.translate.instant('ChampsObligatoire'), this.translate.instant('Erreur'),
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    }
  }

  checkRecaptcha(event) {
    this.verificationRobot = event;
  }
}
