import { Component, OnInit, Input, HostListener } from '@angular/core';
import { faEnvelope, faPhone, faMobile } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';


@Component({
  selector: 'app-front-contact-navbar',
  templateUrl: './front-contact-navbar.component.html',
  styleUrls: ['./front-contact-navbar.component.scss']
})
export class FrontContactNavbarComponent implements OnInit {
  @Input() userName: string;
  @Input() imgURL = '';
  @Input() dropdownMenu = [];
  isScrolling = false;
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faMobile = faMobile;
  isFrench: boolean;

  constructor(private translate: TranslateService, public globalEventService: GlobalLanguageEventService) { }


  ngOnInit(): void {
    this.getLanguage();
    this.userName = (localStorage.getItem('userName') !== undefined ? localStorage.getItem('userName') : '');
  }
  @HostListener('window:scroll', ['$event']) scrolling() {
    const max = document.documentElement.scrollHeight;
    // pos max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {

      this.isScrolling = true;

    } else {
      this.isScrolling = false;

    }
  }

  CheckUrl(item: string): boolean {

    if (item === '/') {
      return true;
    }

    return false;
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }

  setLanguage(lg) {
    if (lg === 'fr') {
      this.isFrench = true;
      localStorage.setItem('language', 'fr');
    } else {
      this.isFrench = false;
      localStorage.setItem('language', 'en');
    }
    this.translate.use(lg);
    this.globalEventService.setLanguage(lg);
  }
  mailto(emailAddress: string) {
    return 'mailto:' + emailAddress;
  }
  telto(tel: string) {
    return 'tel:' + tel;
  }
}
