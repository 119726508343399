import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageService , Page } from 'src/app/rest';
import { Meta, Title } from '@angular/platform-browser'; 
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { LoadingPagesService } from '../../_services/loading-pages.service';

@Component({
  selector: 'app-carrieres',
  templateUrl: './carrieres.component.html',
  styleUrls: ['./carrieres.component.scss']
})
export class CarrieresComponent implements OnInit {
  isWorking: boolean;
  isFrench: boolean;
  lien;
  offre;
  CbackgroundImageURLLR = 'assets/images/andexrecrute.png';
  IsMobile = false;
  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private pageService: PageService,
    private title: Title,
    private meta: Meta,
    public loadingPagesService: LoadingPagesService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadingPagesService.setPageStatus(true);
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    if (window.innerWidth < 768) {
      this.IsMobile = true;
    }
    this.getPages();
  }

  getPages() {
    this.isWorking = true;
    this.pageService.getByCategorieAndModele('Carrieres', 'lien de redirection').subscribe(data => {
      this.lien = data[0];
      this.setMeta(this.lien);
      this.pageService.getByCategorieAndModele('Carrieres', 'offres d\'emplois').subscribe(dt => {
        dt.forEach(
          (d) => {
            d.paragraphes = d.paragraphes.sort((a, b) =>
              a.order > b.order ? 1 : -1
            );
            if(d.paragraphes.length > 6)
            {
            d.paragraphes.splice(5,d.paragraphes.length-6);
            }

            d.titres = d.titres.sort((a, b) =>
              a.order > b.order ? 1 : -1
            );
          });
        this.offre = dt[0];
        this.isWorking = false;
      });
    });
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }

  setMeta(page: Page) {
    if (this.isFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com/carrieres' });
    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com/carrieres' });
    }
  }

}
