import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faArchive, faListOl, faPencilAlt, faUndo, faSortAlphaDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { Page, PageService, UtilisateurService, ActivityService, Activity, Categorie, CategorieService } from 'src/app/rest';
import { PagesElement } from 'src/app/shared/_interfaces/pages-element.interface';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  @ViewChild('userDel', { static: false }) Supprimer;
  @ViewChild('userRec', { static: false }) Recuperer;
  @ViewChild('PageDel', { static: false }) delPage;
  @ViewChild('PageRec', { static: false }) recep;
  @ViewChild('order', { static: false }) order;
  @ViewChild('userDelPage', { static: false }) DelPage;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = ['Check', 'Titre', 'Auteur', 'Statut', 'Categorie', 'Archive', 'Actions', 'Date'];
  dataSource = new MatTableDataSource();
  isLoading = true;
  items: PagesElement[];
  element: Page[];
  tablesource: Page[];
  faPencil = faPencilAlt;
  faSort = faSortAlphaDown;
  faList = faListOl;
  faArchive = faArchive;
  faUndo = faUndo;
  faTrash = faTrash;

  nbTotal: number;
  nbMiens: number;
  nbPublie: number;
  nbBrouillon: number;
  nbarchive: number;

  Pageid: any;
  Page: any;
  checked = false;
  color = 'primary';
  selectedItems;
  parentList: any[] = [];
  datesItems: any[] = [];
  IsMaj = false;
  selection = new SelectionModel<Page>(true, []);
  actions = 0;
  selectedDate = 'Toutes les dates';
  commentaire = 'Aucun Changement';
  parent = 'Aucun Changement';
  etat = 4;
  isDel: boolean;
  isRec: boolean;

  isUpdOrder: boolean;
  pages: Page[];

  currentUser;
  categorie: Categorie[];

  constructor(
    private toastr: ToastrService,
    private page: PageService,
    private router: Router,
    private utilisateur: UtilisateurService,
    private activity: ActivityService,
    private categorieService: CategorieService,
    public loadingPagesService: LoadingPagesService
  ) { }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.isUpdOrder = false;
    this.pages = [];
    this.checked = false;
    this.color = 'primary';
    this.parentList = [];
    this.datesItems = [];
    this.IsMaj = false;
    this.selection = new SelectionModel<Page>(true, []);
    this.actions = 0;
    this.selectedDate = 'Toutes les dates';
    this.commentaire = 'Aucun Changement';
    this.parent = 'Aucun Changement';
    this.etat = 4;
    this.isDel = false;
    this.isRec = false;
    this.getUser();
    this.load_data();
    this.getDates();
    this.MatTableTranslate();
    this.currentUser = [];
    this.getCat();
    this.dataSource.filterPredicate = (data: Page, filter: string) => {

      return data
      .titres[0]
      .contenu
      .trim()
      .replace('&#233;', 'e')
      .replace('è&#232;', 'e')
      .replace('&#200;', 'E')
      .replace('&#201;', 'E')
      .replace('é', 'e')
      .replace('è', 'e')
      .replace('É', 'E')
      .replace('È', 'E')
      .toLowerCase()
      .includes(filter.trim().replace('é', 'e').replace('è', 'e').replace('É', 'E').replace('È', 'E').toLowerCase());
    };



  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getCat() {
    this.categorieService.getCategories().subscribe(data => {
      this.categorie = data;
    });
  }

  setCat(value) {
    this.pages = [];
    if (value === '') {
      this.pages = this.element;
    } else {
      this.element.forEach(el => {
        if (el.categorieId === value) {
          this.pages.push(el);
        }
      });
    }
  }

  async ranger() {
    this.isUpdOrder = true;
    let i = 0;
    let j = 0;
    const leng = this.pages.length;
    this.pages.forEach(element => {
      element.order = i;
      i++;
      this.page.put(element.pageId, element).subscribe(data => {
        if (j === leng - 1) {
          this.isUpdOrder = false;
          this.load_data();
          this.order.nativeElement.click();
          this.toastr.success('', 'Succès',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
        }
        j++;
      }, () => {
        this.isUpdOrder = false;
        this.toastr.error('Erreur', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pages, event.previousIndex, event.currentIndex);
  }


  MatTableTranslate() {
    this.paginator._intl.itemsPerPageLabel = 'Lignes par page';
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.lastPageLabel = 'Dernière page';
    this.paginator._intl.previousPageLabel = 'Page précédente';
    this.paginator._intl.firstPageLabel = 'Première page';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 / ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  }

  public load_data() {
    this.isLoading = true;
    this.dataSource.data = [];
    this.page.get().subscribe((data) => {
      if (data !== undefined) {
        data.forEach(element => {
          if (element.titres[0] !== undefined) {
            element.titres = element.titres.sort((a, b) => a.order > b.order ? 1 : -1);
            element.titres[0].contenu = element.titres[0].contenu.replace(/<[^>]+>/g, '');
          }
        });
        const user: string = this.currentUser.userName;
        this.element = data;
        this.pages = data;
        this.isLoading = false;
        this.dataSource.data = this.element;
        this.items = this.element;
        this.nbTotal = 0;
        this.nbMiens = 0;
        this.nbPublie = 0;
        this.nbBrouillon = 0;
        this.nbarchive = 0;
        this.items.forEach(element => {
          this.nbTotal++;
          if (element.etatPage.toString() === '0') {
            this.nbBrouillon++;
          } else if (element.etatPage.toString() === '3') {
            this.nbPublie++;
          }
          if (element.utilisateurCreation && element.utilisateurCreation.toUpperCase() === user.toUpperCase()) {
            this.nbMiens++;
          }
          if (!element.estPublie) {
            this.nbarchive++;
          }
        });
      }
    },
      () => {
        this.isLoading = false;
        this.toastr.error('Erreur', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.paginator._changePageSize(this.paginator.pageSize);

  }

  public load_data_filtre(y, m, day) {
    this.isLoading = true;
    this.dataSource.data = [];
    this.page.getPageByDate(y, m, day).subscribe((data) => {
      if (data !== undefined) {
        data.forEach(element => {
          if (element.titres[0] !== undefined) {
            element.titres = element.titres.sort((a, b) => a.order > b.order ? 1 : -1);
            element.titres[0].contenu = element.titres[0].contenu.replace(/<[^>]+>/g, '');
          }
        });
        const user: string = this.currentUser.userName;
        this.element = data;
        this.isLoading = false;
        this.dataSource.data = this.element;
        this.items = this.element;
        this.nbTotal = 0;
        this.nbMiens = 0;
        this.nbPublie = 0;
        this.nbBrouillon = 0;
        this.nbarchive = 0;
        this.items.forEach(element => {
          this.nbTotal++;
          if (element.etatPage.toString() === '0') {
            this.nbBrouillon++;
          } else if (element.etatPage.toString() === '3') {
            this.nbPublie++;
          }
          if (element.utilisateurCreation.toUpperCase() === user.toUpperCase()) {
            this.nbMiens++;
          }
          if (!element.estPublie) {
            this.nbarchive++;
          }
        });
      }
    },
      error => {
        this.isLoading = false;
        this.toastr.error('test', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._changePageSize(this.paginator.pageSize);

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  SetId(element) {
    this.Pageid = element;
  }

  Archiver() {
    const user = this.currentUser.userName;
    this.Pageid.dateModification = new Date();
    this.Pageid.estPublie = false;
    this.Pageid.utilisateurModification = this.currentUser.userName;
    this.page.put(this.Pageid.pageId, this.Pageid).subscribe(() => {
      /****** */
      this.activity.getByPage(this.Pageid.pageId).subscribe(data => {
        if (data.length > 0) {
          const activite = this.getActivityByStatut(data, '3');
          if (activite != null) {
            activite.dateModification = new Date();
            activite.utilisateurModification = user;
            this.activity.put(activite.idActivity, activite).subscribe(() => {
            }, error => {
              console.log(error);
            });
          } else {
            // Add Activity
            const act: Activity = {
              idActivity: Guid.create().toString(),
              description: 'Archivage Page',
              statut: 'Archivé',
              pageId: this.Pageid.pageId,
              utilisateurCreation: user,
              utilisateurModification: user,
              dateCreation: new Date(),
              dateModification: new Date(),
            };

            this.activity.post(act).subscribe(() => {
            }, error => {
              console.log(error);
            });
          }
        } else {
          // Add Activity
          const act: Activity = {
            idActivity: Guid.create().toString(),
            description: 'Archivage Page',
            statut: 'Archivé',
            pageId: this.Pageid.pageId,
            utilisateurCreation: user,
            utilisateurModification: user,
            dateCreation: new Date(),
            dateModification: new Date(),
          };

          this.activity.post(act).subscribe(() => {
          }, error => {
            console.log(error);
          });
        }
      });

      /****** */
      this.toastr.success('Les données ont été archivé', 'Succès',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.load_data();
      this.Filter();
      this.Supprimer.nativeElement.click();
    },
      error => {
        this.toastr.error('Erreur est survenue lors de la connexion', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
  }

  Rec() {
    const user = this.currentUser.userName;
    this.Pageid.dateModification = new Date();
    this.Pageid.estPublie = true;
    this.Pageid.utilisateurModification = this.currentUser.userName;
    this.page.put(this.Pageid.pageId, this.Pageid).subscribe(() => {
      /****** */
      this.activity.getByPage(this.Pageid.pageId).subscribe(data => {
        if (data.length > 0) {
          const activite = this.getActivityByStatut(data, '1');
          if (activite != null) {
            activite.dateModification = new Date();
            activite.utilisateurModification = user;
            this.activity.put(activite.idActivity, activite).subscribe(() => {
            }, error => {
              console.log(error);
            });
          } else {
            // Add Activity
            const act: Activity = {
              idActivity: Guid.create().toString(),
              description: 'Publication Page',
              statut: 'Publié',
              pageId: this.Pageid.pageId,
              utilisateurCreation: user,
              utilisateurModification: user,
              dateCreation: new Date(),
              dateModification: new Date(),
            };

            this.activity.post(act).subscribe(() => {
            }, error => {
              console.log(error);
            });
          }
        } else {
          // Add Activity
          const act: Activity = {
            idActivity: Guid.create().toString(),
            description: 'Publication Page',
            statut: 'Publié',
            pageId: this.Pageid.pageId,
            utilisateurCreation: user,
            utilisateurModification: user,
            dateCreation: new Date(),
            dateModification: new Date(),
          };

          this.activity.post(act).subscribe(() => {
          }, error => {
            console.log(error);
          });
        }
      });

      /****** */
      this.toastr.success('La page est bien récupérée', 'Succès',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.load_data();
      this.Filter();
      this.Recuperer.nativeElement.click();
    },
      error => {
        this.toastr.error('Erreur est survenue lors de la connexion', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
  }

  Update(id) {
    // debugger;
    this.router.navigate(['back/pages/modifierpage'], { queryParams: { Pageid: id } });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Page): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  appliquer() {
    this.actions = this.actions * 1;
    if (this.actions === 1) {
      if (this.selection.selected.length > 0) {
        this.selectedItems = this.selection.selected;
        this.IsMaj = true;
        this.getParentList();
      } else {
        this.toastr.error('Prière de sélecionner une ou plusieurs pages');
      }
    } else if (this.actions === 2) {
      if (this.selection.selected.length === 0) {
        this.delPage.nativeElement.click();
      }
    }

  }

  appChange() {
    this.actions = this.actions * 1;
    if (this.actions === 2) {
      this.isDel = true;
    } else {
      this.isDel = false;
    }
    if (this.actions === 3) {
      this.isRec = true;
    } else {
      this.isRec = false;
    }
  }

  cancel() {
    this.selectedItems = [];
    this.IsMaj = false;
  }

  removePage(page) {
    this.selection.deselect(page);
    this.selectedItems = [];
    if (this.actions === 1) {
      if (this.selection.selected.length > 0) {
        this.selectedItems = this.selection.selected;
        this.IsMaj = true;
        this.getParentList();
      }
    } else if (this.actions === 2) {
      if (this.selection.selected.length === 0) {
        this.delPage.nativeElement.click();
      }
    }

    if (this.selection.selected.length === 0) {
      this.cancel();
    }
  }

  removeAll() {
    this.selection.selected.forEach(element => {
      this.selection.deselect(element);
    });
  }

  getDates() {
    this.page.getDate().subscribe(data => {
      const items = data;
      this.datesItems = [];
      items.forEach(element => {
        this.datesItems.push({ month: element.month, year: element.year, mmyy: this.getMMYYYY(element.month, element.year) });
      });
    },
      error => {
        console.log(error);
      });
  }

  applyFilterName(filterValue: string) {
    // filterValue = filterValue.trim(); // Remove whitespace
    // filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;

  }

  getMMYYYY(m, y) {
    let val = '';
    switch (m) {
      case 1:
        val += 'Janvier ';
        break;
      case 2:
        val += 'Février ';
        break;
      case 3:
        val += 'Mars ';
        break;
      case 4:
        val += 'Avril ';
        break;
      case 5:
        val += 'Mai ';
        break;
      case 6:
        val += 'Juin ';
        break;
      case 7:
        val += 'Juillet ';
        break;
      case 8:
        val += 'Août ';
        break;
      case 9:
        val += 'Septembre ';
        break;
      case 10:
        val += 'Octobre ';
        break;
      case 11:
        val += 'Novembre ';
        break;
      case 12:
        val += 'Décembre ';
        break;
    }
    val += y;
    return val;
  }

  Filter() {
    this.removeAll();
    this.selectedItems = [];
    this.IsMaj = false;
    const tabsplit = this.selectedDate.split('-');
    const mm = Number(tabsplit[0]);
    const yy = Number(tabsplit[1]);
    if (this.selectedDate === 'Toutes les dates') {
      this.load_data();
    } else {
      this.load_data_filtre(mm, yy, this.getEndMonth(mm, yy));
    }


  }
  Filtrer(filter) {
    this.isLoading = true;
    this.dataSource.data = [];
    const user: string = this.currentUser.userName;
    this.page.get().subscribe((data) => {
      if (data !== undefined) {
        data.forEach(element => {
          if (element.titres[0] !== undefined) {
            element.titres = element.titres.sort((a, b) => a.order > b.order ? 1 : -1);
            element.titres[0].contenu = element.titres[0].contenu.replace(/<[^>]+>/g, '');
          }

        });
        // les Miens
        if (filter === 1) {
          this.element = data.filter(i => i.utilisateurCreation && i.utilisateurCreation.toUpperCase() === user.toUpperCase());
          this.pages = data.filter(i => i.utilisateurCreation && i.utilisateurCreation.toUpperCase() === user.toUpperCase());
        } else if (filter === 2) {
          this.element = data.filter(i => i.etatPage.toString() === '3');
          this.pages = data.filter(i => i.etatPage.toString() === '3');

        } else if (filter === 3) {
          this.element = data.filter(i => i.etatPage.toString() === '0');
          this.pages = data.filter(i => i.etatPage.toString() === '0');

        } else if (filter === 4) {
          this.element = data.filter(i => !i.estPublie);
          this.pages = data.filter(i => !i.estPublie);

        } else if (filter === 5) {
          this.element.sort((a, b) => (a.titres[0].contenu.toUpperCase() < b.titres[0].contenu.toUpperCase() ? -1 : 1));
          this.pages.sort((a, b) => (a.titres[0].contenu.toUpperCase() < b.titres[0].contenu.toUpperCase() ? -1 : 1));
        }
        this.isLoading = false;
        this.dataSource.data = this.element;
        this.items = data;
        this.nbTotal = 0;
        this.nbMiens = 0;
        this.nbPublie = 0;
        this.nbBrouillon = 0;
        this.nbarchive = 0;
        this.items.forEach(element => {
          this.nbTotal++;
          if (element.etatPage.toString() === '0') {
            this.nbBrouillon++;
          } else if (element.etatPage.toString() === '3') {
            this.nbPublie++;
          }
          if (element.utilisateurCreation && element.utilisateurCreation.toUpperCase() === user.toUpperCase()) {
            this.nbMiens++;
          }
          if (!element.estPublie) {
            this.nbarchive++;
          }
        });
      }
    },
      () => {
        this.isLoading = false;
        this.toastr.error('Erreur', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


    // this.paginator._changePageSize(this.paginator.pageSize);
  }

  getEndMonth(m, y) {
    if (m === 2) {
      if (y % 4 === 0) {
        return 29;
      } else {
        return 28;
      }
    }

    if (m === 4 || m === 6 || m === 9 || m === 11) {
      return 30;
    } else {
      return 31;
    }
  }

  getParentList() {
    this.parentList = [];
    this.page.get().subscribe(data => {

      data.forEach(element => {
        if (this.pageExist(element.pageId) === false) {
          element.titres = element.titres.sort((a, b) => a.order > b.order ? 1 : -1);
          let title = ' ';
          if (element.titres[0] !== undefined) {
            element.titres = element.titres.sort((a, b) => a.order > b.order ? 1 : -1);
            title = element.titres[0].contenu;
            title = title.replace(/<[^>]+>/g, '');
          }
          const tab = {
            Pageid: element.pageId,
            titre: title
          };
          this.parentList.push(tab);
        }
      });
    });
  }

  pageExist(id) {
    let v = false;
    this.selectedItems.forEach(element => {
      if (element.Pageid === id) {
        v = true;
      }
    });
    return v;
  }

  miseAJour() {
    if (this.parent === 'Aucun Changement' && this.commentaire === 'Aucun Changement' && this.etat === 4) {
      this.toastr.warning('Aucun changement effecué sur les lignes sélectionnées', 'Attention');
    } else {
      this.selectedItems.forEach(element => {
        if (this.parent !== 'Aucun Changement') {
          element.parent = this.parent;
        }

        if (this.commentaire === 'Autoriser') {
          element.autorisationCommentaire = true;
        }
        if (this.commentaire === 'Refuser') {
          element.autorisationCommentaire = false;
        }

        if (this.etat !== 4) {
          element.etatPage = this.etat;
        }

        element.dateModification = new Date();
        element.utilisateurModification = this.currentUser.userName;
        const user = this.currentUser.userName;
        this.page.put(element.Pageid, element).subscribe(() => {
          /****** */
          this.activity.getByPage(this.Pageid.pageId).subscribe(data => {
            if (data.length > 0) {
              const activite = this.getActivityByStatut(data, '2');
              if (activite != null) {
                activite.dateModification = new Date();
                activite.utilisateurModification = user;
                this.activity.put(activite.idActivity, activite).subscribe(() => {
                }, error => {
                  console.log(error);
                });
              } else {
                // Add Activity
                const act: Activity = {
                  idActivity: Guid.create().toString(),
                  description: 'Modification Page',
                  statut: 'Modifié',
                  pageId: this.Pageid.pageId,
                  utilisateurCreation: user,
                  utilisateurModification: user,
                  dateCreation: new Date(),
                  dateModification: new Date(),
                };

                this.activity.post(act).subscribe(() => {
                }, error => {
                  console.log(error);
                });
              }
            } else {
              // Add Activity
              const act: Activity = {
                idActivity: Guid.create().toString(),
                description: 'Modification Page',
                statut: 'Modifié',
                pageId: this.Pageid.pageId,
                utilisateurCreation: user,
                utilisateurModification: user,
                dateCreation: new Date(),
                dateModification: new Date(),
              };

              this.activity.post(act).subscribe(() => {
              }, error => {
                console.log(error);
              });
            }
          });

          /****** */
          this.ngOnInit();
          this.Filter();
          this.toastr.success('Les mises à jours ont été effectuées', 'Succès');
        },
          error => {
            console.log('NOT OK : ' + error);
          });

      });

    }
  }

  getUser() {
    if (localStorage.getItem('MyApp_Auth') !== undefined) {
      const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
      this.utilisateur.getUtilisateur(userId).subscribe(data => {
        this.currentUser = data;
      });
    }
  }

  corbeille() {
    const user = this.currentUser.userName;
    this.selection.selected.forEach(element => {
      element.estPublie = false;
      element.utilisateurModification = this.currentUser.userName;
      element.dateModification = new Date();
      this.page.put(element.pageId, element).subscribe(() => {
        /****** */
        this.activity.getByPage(element.pageId).subscribe(data => {
          if (data.length > 0) {
            const activite = this.getActivityByStatut(data, '3');
            if (activite != null) {
              activite.dateModification = new Date();
              activite.utilisateurModification = user;
              this.activity.put(activite.idActivity, activite).subscribe(() => {
              }, error => {
                console.log(error);
              });
            } else {
              // Add Activity
              const act: Activity = {
                idActivity: Guid.create().toString(),
                description: 'Archivage Page',
                statut: 'Archivé',
                pageId: this.Pageid.pageId,
                utilisateurCreation: user,
                utilisateurModification: user,
                dateCreation: new Date(),
                dateModification: new Date(),
              };

              this.activity.post(act).subscribe(() => {
              }, error => {
                console.log(error);
              });
            }
          } else {
            // Add Activity
            const act: Activity = {
              idActivity: Guid.create().toString(),
              description: 'Archivage Page',
              statut: 'Archivé',
              pageId: this.Pageid.pageId,
              utilisateurCreation: user,
              utilisateurModification: user,
              dateCreation: new Date(),
              dateModification: new Date(),
            };

            this.activity.post(act).subscribe(() => {
            }, error => {
              console.log(error);
            });
          }
        });

        /****** */
        this.ngOnInit();
        this.delPage.nativeElement.click();
      });
      this.toastr.success('La page a été deplacée à l\'archive', 'Succès');
    });

    this.Filter();
  }

  recuperer() {
    const user = this.currentUser.userName;
    this.selection.selected.forEach(element => {
      element.estPublie = true;
      element.utilisateurModification = this.currentUser.userName;
      element.dateModification = new Date();
      this.page.put(element.pageId, element).subscribe(() => {
        /****** */
        this.activity.getByPage(element.pageId).subscribe(data => {
          if (data.length > 0) {
            const activite = this.getActivityByStatut(data, '1');
            if (activite != null) {
              activite.dateModification = new Date();
              activite.utilisateurModification = user;
              this.activity.put(activite.idActivity, activite).subscribe(() => {
              }, error => {
                console.log(error);
              });
            } else {
              // Add Activity
              const act: Activity = {
                idActivity: Guid.create().toString(),
                description: 'Publication Page',
                statut: 'Publié',
                pageId: element.pageId,
                utilisateurCreation: user,
                utilisateurModification: user,
                dateCreation: new Date(),
                dateModification: new Date(),
              };

              this.activity.post(act).subscribe(() => {
              }, error => {
                console.log(error);
              });
            }
          } else {
            // Add Activity
            const act: Activity = {
              idActivity: Guid.create().toString(),
              description: 'Publication Page',
              statut: 'Publié',
              pageId: this.Pageid.pageId,
              utilisateurCreation: user,
              utilisateurModification: user,
              dateCreation: new Date(),
              dateModification: new Date(),
            };

            this.activity.post(act).subscribe(() => {
            }, error => {
              console.log(error);
            });
          }
        });

        /****** */
        this.ngOnInit();
        this.recep.nativeElement.click();
      });
      this.toastr.success('La page a été deplacé à l\'archive', 'Succès');
    });

    this.Filter();
  }

  getActivityByStatut(data, statut: string) {
    if (data.length > 0) {
      const table: Activity[] = data;
      let act: Activity;
      table.forEach(element => {
        if (element.statut.toString() === statut) {
          act = element;
        }
      });
      return act;
    } else {
      return null;
    }
  }

  Delete() {
    if (this.Pageid.etatPage === 3) {
      this.toastr.error('la page est déjà publiée', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else {
      this.page._delete(this.Pageid.pageId).subscribe(() => {
        this.toastr.success('Succèes', 'Succèes',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.ngOnInit();
        this.DelPage.nativeElement.click();
      }, () => {
        this.toastr.error('Erreur', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
    }
  }
}
