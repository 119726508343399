import { Component, HostListener, Input, OnInit } from '@angular/core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-back-navbar',
  templateUrl: './back-navbar.component.html',
  styleUrls: ['./back-navbar.component.scss']
})
export class BackNavbarComponent implements OnInit {
  @Input() logo = {
    url: '',
    alt: ''
  };
  @Input() menuTree = [];
  @Input() copyright: string;

  faIconCaret = faCaretDown;
  isLargeScreen = true;
  isMediumScreen = false;
  sizeIcons = 30;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.fixSize();
  }

  constructor() {
  }

  ngOnInit(): void {
    this.fixSize();
  }

  fixSize() {
    if (window.innerWidth >= 700) {
      this.isLargeScreen = true;
      this.isMediumScreen = false;
      this.sizeIcons = 30;
    } else if (window.innerWidth >= 400) {
      this.isLargeScreen = false;
      this.isMediumScreen = true;
      this.sizeIcons = 30;
    } else {
      this.isLargeScreen = false;
      this.isMediumScreen = false;
      this.sizeIcons = 50;
    }
  }

  toggleSubMenu(idSubMenu: string) {
    const subMenu = document.getElementById(idSubMenu) as HTMLElement;
    subMenu.classList.toggle('show');
  }

}
