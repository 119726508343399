import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArticlesRoutingModule } from './articles-routing.module';
import { ArticlesComponent } from './articles/articles.component';
import { CreerarticleComponent } from './creerarticle/creerarticle.component';
import { ModifierarticleComponent } from './modifierarticle/modifierarticle.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { PagesRoutingModule } from '../pages/pages-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntl } from '@angular/material/paginator';


@NgModule({
  declarations: [ArticlesComponent, CreerarticleComponent, ModifierarticleComponent],
  imports: [
    CommonModule,
    ArticlesRoutingModule,
    SharedModule,

    RouterModule,
    PagesRoutingModule,
    BrowserModule,
    MatTableModule,
    MatSortModule
  ],
  providers: [
    { provide: MatPaginatorIntl }
  ]
})
export class ArticlesModule { }
