<div  (mouseover)="setMeta(NosSolutionsPage)" (click)="setMeta(NosSolutionsPage)" [style.min-height]="height+'px'" style="margin-bottom: 5%;" [style.width]="width+'px'">
    <div class="text-center" style="padding-top: 10vh;">
        <h2 *ngIf="IsFrench" [innerHTML]="STitleSR[0].contenu | safeHtml"></h2>
        <h2 *ngIf="!IsFrench" [innerHTML]="STitleSR[0].contenuEN | safeHtml"></h2>
        <app-hr *ngIf="hr" ></app-hr> 
    </div>
    <div class="container-fluid" style="padding-top: 8vh;">
        <div class="row paragraph-container" >
            <div *ngIf="IsFrench"  class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                <div class="title-first"> <h3 [innerHTML]="SParagrapheSR[0].titre | safeHtml"></h3></div>
                <div class="text-black-first" [innerHTML]="SParagrapheSR[0].body | safeHtml"></div>
            </div>
            <div *ngIf="!IsFrench"  class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                <div class="title-first"> <h3 [innerHTML]="SParagrapheSR[0].titreEN | safeHtml"></h3></div>
                <div class="text-black-first" [innerHTML]="SParagrapheSR[0].bodyEN | safeHtml"></div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                <img *ngIf="SParagrapheSR[0].fichier.length != 0" class="image-container lazyload"
                    [attr.data-src]="GetImage(SParagrapheSR[0].fichier[0].filePath)" alt="{{SParagrapheSR[0].fichier[0].alt}}" >
            </div>
        </div>
        <div class="row paragraph-container-Video" >
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12" style="float: right;">
                <img *ngIf="SParagrapheSR[1].fichier.length != 0 && SParagrapheSR[1].fichier[0].type ==='image'" class="image-container lazyload"
                    [attr.data-src]="GetImage(SParagrapheSR[1].fichier[0].filePath)" alt="{{SParagrapheSR[1].fichier[0].alt}}">

                    <video *ngIf="SParagrapheSR[1].fichier.length != 0 && SParagrapheSR[1].fichier[0].type ==='video'" controls class="image-container">
                        <source [attr.data-srcset]="GetImage(SParagrapheSR[1].fichier[0].filePath)" type="video/mp4" class="image-container">
                    </video>
            </div>
            <div *ngIf="IsFrench" class="col-lg-6 col-md-6 order-first order-md-last col-sm-12 col-xs-12 col-12" style="float: left;">
                <div class="title-second"> <h3 [innerHTML]="SParagrapheSR[1].titre | safeHtml"></h3></div>
                <div class="text-black-second" [innerHTML]="SParagrapheSR[1].body | safeHtml"></div>
            </div>
            <div *ngIf="!IsFrench" class="col-lg-6 col-md-6 order-first order-md-last col-sm-12 col-xs-12 col-12" style="float: left;">
                <div class="title-second"> <h3 [innerHTML]="SParagrapheSR[1].titreEN | safeHtml"></h3></div>
                <div class="text-black-second" [innerHTML]="SParagrapheSR[1].bodyEN | safeHtml"></div>
            </div>

        </div>
    </div>
    
</div>