<div class="site-section text-center py-3" id="footer">
  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left mt-5">

    <!-- Grid row -->
    <div class="row responsive">

      <!-- Grid column -->
      <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-12" style="justify-content: center;">

        <!-- Content -->

        <a class="navbar-brand position " style="cursor: pointer" (click)="redirect()" href="#home">
          <img data-src="assets/images/aiventu_logo_blanc.png" alt="Logo Aiventu" class="img-fluid lazyload"
            style="margin-top: -10%;">
        </a>
        <br>
        <a class="navbar-brand position " [href]="">
          <img data-src="assets/images/COMPETENCES AIVENTU.gif" alt="Logo Aiventu" class="lazyload img-fluid"
            style="margin-top: -10%;">
        </a>
        <br>
        <a class="btn item text-white" [href]="" routerLinkActive="active" routerLink="{{Button.href}}">{{Button.title |
          translate}}</a>
        <a class="button-redirection" href="#temoignage"></a>
        <p *ngIf="isMobile">
          <a (click)="setLanguage('fr')" [href]="">
            <img data-src="assets/images/fr.jpg" class="lazyload" alt=" Aiventu fr" title="Français" [style.width.px]="40"
              [style.height.px]="40"> </a>

          <a (click)="setLanguage('en')" [href]="">
            <img style="margin-top: 3%;margin-left: 10%;margin-right: 2%;" alt="Aiventu uk" class="lazyload"
              data-src="assets/images/uk.jpg" title="English" [style.width.px]="40" [style.height.px]="40"> </a>
        </p>

        <!-- <button (click)="setLanguage('en')" class="dropdown-item" type="button">
        <img src="assets/images/uk.jpg" alt="UK flag" width="25px" height="15px" />
        &nbsp;{{'Anglais' | translate}}
      </button> -->
        <!-- <button (click)="setLanguage('fr')" class="dropdown-item" type="button">
        <img src="assets/images/fr.jpg" alt="FR flag" width="25px" height="15px" />
        &nbsp;{{'Francais' | translate}}
      </button> -->
        <!-- <span >
        <img src="assets/images/fr.jpg" title="Texte en anglais" alt="FR flag" width="25px" height="15px" />
        &nbsp;{{'Francais' | translate}}
      </span> -->
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class=" lien col-lg-2 col-md-4 col-sm-12 col-xs-12 col-12">

        <!-- Links -->

        <h1 translate> plandesite </h1>

        <p *ngFor="let item of frontMenuTree">
          <span class="dot"></span><a [href]="" routerLink="{{item.url}}">{{item.title|translate}}</a>
        </p>



      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class=" lien no-title col-lg-3 col-md-4 col-sm-12 col-xs-12 col-12">

        <!-- Links -->

        <p *ngFor="let item of NosSolution">
          <span class="dot"></span><a routerLink="produits/{{item.lien}}" [href]=""
            [innerHTML]="item.titres[0].contenu | safeHtml"></a>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="social col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">

        <!-- Links fa fa-mobile -->
        <h1 translate> contactInfo </h1>
        <p>
          <fa-icon [icon]="faEnvelope" class="fa-1x"></fa-icon> Rue de la Feuille d’Érable, Immeuble Regency, Bloc C, 3ème étage, C3-5, Les Berges du Lac 2, 1053 Tunis.
        </p>
        <p><a href="tel:+21671860002">
            <fa-icon [icon]="faPhone" class="fa-1x"> </fa-icon> (+216) 71 860 002
          </a></p>
        <p> <a href="tel:+21658460614">
            <fa-icon [icon]="faMobile" class="fa-1x"> </fa-icon> (+216) 94 174 121
          </a></p>
        <p><a href="mailto:contact@aiventu.com">
            <fa-icon [icon]="faEnvelope" class="fa-1x"></fa-icon> contact@aiventu.com
          </a>
        </p>
        <p>
          <a href="https://www.facebook.com/AiventuGroup" target="_blank" rel="noopener">
            <img alt="facebook" data-src="assets/images/icones/Facebook.png" class="lazyload" [style.width.px]="40"
              [style.height.px]="40"> </a>


          <a href="https://www.linkedin.com/company/aiventu" target="_blank" rel="noopener">
            <img alt="linkedin" style="margin-top: 4%;margin-left: 10%;margin-right: 2%;" class="lazyload"
              data-src="assets/images/icones/linkedin.png" [style.width.px]="25" [style.height.px]="25"> </a>


          <a href="https://twitter.com/aiventu_group" target="_blank" rel="noopener">
            <img alt="twitter" style="margin-top: 4%;margin-left: 10%;;" class="lazyload"
              data-src="assets/images/icones/Twitter.png" [style.width.px]="25" [style.height.px]="25"> </a>
        </p>


      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    Tous les droits sont réservés | &copy; AIVENTU {{year}}.
  </div>
  <!-- Copyright -->
</div>