import { Component, Input, OnInit } from '@angular/core';
import { faGlobeAmericas, faHandshake, faTrophy, faUser } from '@fortawesome/free-solid-svg-icons';
import { Constant } from '../../_constants/constant';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parallax-scrolling',
  templateUrl: './parallax-scrolling.component.html',
  styleUrls: ['./parallax-scrolling.component.scss']
})
export class ParallaxScrollingComponent implements OnInit {

  @Input() title = [];
  @Input() valeursCards = [];
  @Input() imageURL = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;
  isFrench: boolean;
  Image;
  IsMobile = false;
  icons = [faHandshake, faTrophy, faGlobeAmericas, faUser];
  cellsToShow: number;
  arrowsOutside: boolean;
  overflowCellsLimit: number;

  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.height = this.height - 70;
    if (window.innerWidth < 768) {
      this.IsMobile = true;
      this.cellsToShow = 1;
      this.arrowsOutside = false;
      this.overflowCellsLimit = 0;
    } else {
      this.cellsToShow = 3;
      this.arrowsOutside = true;
      this.overflowCellsLimit = 2;
    }
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      this.IsMobile = true;
    }

  }

  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }

  redirect() {
    this.router.navigate(['/nos-references']);
  }
}
