/**
 * SiteAndexBack API
 * API de Base
 *
 * OpenAPI spec version: v1
 * Contact: amahmoudi@aiventu.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { Constant } from 'src/app/shared/_constants/constant';


@Injectable()
export class CRMService {

    protected basePath = Constant.ApiRoot;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param Nom 
     * @param Email 
     * @param Tel 
     * @param Entreprise 
     * @param Fonction 
     * @param Objet 
     * @param Msg 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCRM(Nom?: string, Email?: string, Tel?: string, Entreprise?: string, Fonction?: string, Objet?: string, Msg?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public postCRM(Nom?: string, Email?: string, Tel?: string, Entreprise?: string, Fonction?: string, Objet?: string, Msg?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public postCRM(Nom?: string, Email?: string, Tel?: string, Entreprise?: string, Fonction?: string, Objet?: string, Msg?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public postCRM(Nom?: string, Email?: string, Tel?: string, Entreprise?: string, Fonction?: string, Objet?: string, Msg?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Nom !== undefined && Nom !== null) {
            queryParameters = queryParameters.set('Nom', <any>Nom);
        }
        if (Email !== undefined && Email !== null) {
            queryParameters = queryParameters.set('Email', <any>Email);
        }
        if (Tel !== undefined && Tel !== null) {
            queryParameters = queryParameters.set('Tel', <any>Tel);
        }
        if (Entreprise !== undefined && Entreprise !== null) {
            queryParameters = queryParameters.set('Entreprise', <any>Entreprise);
        }
        if (Fonction !== undefined && Fonction !== null) {
            queryParameters = queryParameters.set('Fonction', <any>Fonction);
        }
        if (Objet !== undefined && Objet !== null) {
            queryParameters = queryParameters.set('Objet', <any>Objet);
        }
        if (Msg !== undefined && Msg !== null) {
            queryParameters = queryParameters.set('Msg', <any>Msg);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<string>(`${this.basePath}/api/CRM`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param clientSecret 
     * @param serviceUri 
     * @param clientId 
     * @param tenantId 
     * @param apiLink 
     * @param entityName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public testSettings(clientSecret?: string, serviceUri?: string, clientId?: string, tenantId?: string, apiLink?: string, entityName?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public testSettings(clientSecret?: string, serviceUri?: string, clientId?: string, tenantId?: string, apiLink?: string, entityName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public testSettings(clientSecret?: string, serviceUri?: string, clientId?: string, tenantId?: string, apiLink?: string, entityName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public testSettings(clientSecret?: string, serviceUri?: string, clientId?: string, tenantId?: string, apiLink?: string, entityName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (clientSecret !== undefined && clientSecret !== null) {
             queryParameters = queryParameters.set('clientSecret', <any>clientSecret);
         }
         if (serviceUri !== undefined && serviceUri !== null) {
             queryParameters = queryParameters.set('serviceUri', <any>serviceUri);
         }
         if (clientId !== undefined && clientId !== null) {
             queryParameters = queryParameters.set('clientId', <any>clientId);
         }
         if (tenantId !== undefined && tenantId !== null) {
             queryParameters = queryParameters.set('tenantId', <any>tenantId);
         }
         if (apiLink !== undefined && apiLink !== null) {
             queryParameters = queryParameters.set('apiLink', <any>apiLink);
         }
         if (entityName !== undefined && entityName !== null) {
             queryParameters = queryParameters.set('entityName', <any>entityName);
         }
 
         let headers = this.defaultHeaders;
 
         var token: string ='Bearer '+ localStorage.getItem('MyApp_token').replace(/\"/gi,'');
         if (token != undefined) {
             headers = headers.set('Authorization', token);
         }
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.post<any>(`${this.basePath}/api/CRM/TestSettings`,
             null,
             {
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

}
