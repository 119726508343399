<nav class="navbar navbar-light navbar-expand-sm toolbar "
  [ngStyle]="{'background-color': (isScrolling) ? '#2ca5dd' : 'white'}">

  <div class="collapse navbar-collapse" id="navbar-list-4">
    <ul class="navbar-nav">
      <li class="nav-item dropdown" id="drpbtn1">
        <div class="dropdown" style="margin-top: -10px;">
          <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown"
            [ngStyle]="{'color': (isScrolling) ? 'white' : '#1279BF'}" aria-haspopup="true" aria-expanded="false">
            <span [ngStyle]="{'display': (isFrench) ? '' : 'none'}">
              <img data-src="assets/images/fr.jpg" loading="lazy" class="lazyload" title="Texte en anglais" alt="FR flag" width="25px" height="15px" />
              &nbsp;{{'Francais' | translate}}
            </span>
            <span [ngStyle]="{'display': (!isFrench) ? '' : 'none'}">
              <img data-src="assets/images/uk.jpg" loading="lazy" class="lazyload" alt="UK flag" width="25px" height="15px" />
              &nbsp;{{'Anglais' | translate}}
            </span>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
            <button (click)="setLanguage('fr')" class="dropdown-item" type="button">
              <img data-src="assets/images/fr.jpg" loading="lazy" class="lazyload" alt="FR flag" width="25px" height="15px" />
              &nbsp;{{'Francais' | translate}}
            </button>
            <button (click)="setLanguage('en')" class="dropdown-item" type="button">
              <img data-src="assets/images/uk.jpg" loading="lazy" class="lazyload" alt="UK flag" width="25px" height="15px" />
              &nbsp;{{'Anglais' | translate}}
            </button>
          </div>
        </div>

      </li>
      <li class="nav-item dropdown" [ngStyle]="{'color': (isScrolling) ? 'white' : '#1279BF'}">
        <div class="sidebar-brand d-flex align-items-center justify-content-center" href="tel:+216 58 460 614">
          <a [ngStyle]="{'color': (isScrolling) ? 'white' : '#1279BF', 'display' : 'contents', 'text-decoration' : 'none'}"
            [href]="telto('+21658460614')">
            <fa-icon [icon]="faMobile" class="fa-1x" [ngStyle]="{'color': (isScrolling) ? 'white' : '#1279BF'}">
            </fa-icon> &nbsp; <span class="d-none d-lg-block d-print-block align-items-center justify-content-center">
              (+216) 94 174 121
            </span>
          </a>
          
          <a [ngStyle]="{'color': (isScrolling) ? 'white' : '#1279BF', 'display' : 'contents', 'text-decoration' : 'none'}"
          [href]="telto('+2167160002 ')">
              <fa-icon  style="margin-left: 20px;"  [icon]="faPhone" class="fa-1x" [ngStyle]="{'color': (isScrolling) ? 'white' : '#1279BF'}">
          </fa-icon> &nbsp;<span class="d-none d-lg-block d-print-block align-items-center justify-content-center">
               (+216) 71 860 002 
          </span>
        </a>
          
        </div>


      </li>
      <li class="nav-item dropdown" [ngStyle]="{'color': (isScrolling) ? 'white' : '#1279BF'}">

        <p><a [ngStyle]="{'color': (isScrolling) ? 'white' : '#1279BF', 'display' : 'flex', 'text-decoration' : 'none'}"
            [href]="mailto('contact@aiventu.com')">
            <fa-icon [icon]="faEnvelope" class="fa-1x" [ngStyle]="{'color': (isScrolling) ? 'white' : '#1279BF'}">
            </fa-icon> &nbsp; <span
              class="d-none d-lg-block d-print-block align-items-center justify-content-center">contact@aiventu.com</span>
          </a>
        </p>


      </li>
      <li class="nav-item facebook">


        <a href="https://www.facebook.com/AiventuGroup/" target="_blank" rel="noopener">
          <img  [ngStyle]="{'display': (isScrolling) ? '' : 'none'}" loading="lazy" alt="facebook Aiventu"  class="lazyload" data-src="assets/images/icones/Facebook.png" [style.width.px]="30" [style.height.px]="30">
          <img  [ngStyle]="{'display': (!isScrolling) ? '' : 'none'}" loading="lazy" alt="facebook Aiventu"   class="lazyload" data-src="assets/images/icones/Facebook-VF.png" [style.width.px]="30"
            [style.height.px]="30">

        </a>

      </li>
      <li class="nav-item linkedin">
        <a href="https://www.linkedin.com/company/aiventu/" target="_blank" rel="noopener">
          <img [ngStyle]="{'display': (isScrolling) ? '' : 'none'}"  loading="lazy" alt="linkedin Aiventu" class="lazyload" data-src="assets/images/icones/linkedin.png" [style.width.px]="20" [style.height.px]="20">
          <img [ngStyle]="{'display': (!isScrolling) ? '' : 'none'}"  loading="lazy" alt="linkedin Aiventu"  class="lazyload" data-src="assets/images/icones/linkedin- VF.png" [style.width.px]="20"
            [style.height.px]="20">

        </a>

      </li>
      <li class="nav-item twitter">
        <a href="https://twitter.com/aiventu_group" target="_blank" rel="noopener">
          <img [ngStyle]="{'display': (isScrolling) ? '' : 'none'}"  loading="lazy" alt="Twitter Aiventu"  class="lazyload" data-src="assets/images/icones/Twitter.png" [style.width.px]="20" [style.height.px]="20">
          <img [ngStyle]="{'display': (!isScrolling) ? '' : 'none'}"  loading="lazy"  alt="Twitter Aiventu" class="lazyload" data-src="assets/images/icones/Twitter-VF.png" [style.width.px]="20"
            [style.height.px]="20">

        </a>

      </li>
    </ul>
  </div>
</nav>