import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class LoadingPagesService {

  @Output()
  PagesIsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  public setPageStatus(pageIsLoaded: boolean) {
    this.PagesIsLoaded.emit(pageIsLoaded);
  }
}
