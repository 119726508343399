import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';


@Component({
  selector: 'app-offres-emplois',
  templateUrl: './offres-emplois.component.html',
  styleUrls: ['./offres-emplois.component.scss']
})
export class OffresEmploisComponent implements OnInit {

  @Input() TitreArrayOE = [];
  @Input() ParagrapheArrayOE = [];
  @Input() height;
  @Input() width;
  @Input() hr: boolean;

  category = '';
  isFrench: boolean;
  url;
  IsMobile = false;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    if (window.innerWidth < 1200 && window.innerWidth > 768) {
      this.IsMobile = true;
    }
    this.url = (this.router.url.indexOf('carrieres') === -1 ? 'carrieres/' : '');
    this.height = this.height - 70;
    this.route.paramMap.subscribe((params) => {
      this.category = params.get('category');
    });
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
