import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageService } from 'src/app/rest';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { LoadingPagesService } from '../../_services/loading-pages.service';

@Component({
  selector: 'app-dynamics-hr',
  templateUrl: './dynamics-hr.component.html',
  styleUrls: ['./dynamics-hr.component.scss']
})
export class DynamicsHrComponent implements OnInit {
  isWorking: boolean;
  DynamicsHR;
  DynamicsHRTitres;
  @Input() hr: boolean;
  isFrench: boolean;
  Width: number;
  height: number;
  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private pageService: PageService,
    public loadingPagesService: LoadingPagesService
  ) { }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.hr = true ;
    this.Width = window.innerWidth;
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.isWorking = true;
    this.pageService.getByCategorieAndModele('Dynamics HR', 'vidéo texte').subscribe(dt => {
      this.DynamicsHR = dt[0].paragraphes.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
      this.DynamicsHRTitres = dt[0].titres;
      this.height = window.innerHeight;
      this.Width = window.innerWidth;
      this.isWorking = false;
    }, () => {
      this.isWorking = false;
    });
  }
  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
