import { Component, Input, OnInit } from '@angular/core';
import { PageService, ParagrapheService } from 'src/app/rest';
import { HostListener } from '@angular/core';
import { Constant } from '../../_constants/constant';

@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.scss']
})
export class SlideShowComponent implements OnInit {

  @Input() backgroundImageURL = [];
  @Input() hr: boolean;
  Image;
  slidesIndex = ['01', '02'];
  @Input() set slideData(value: any[]) { // for pagination
    if (value.length > 0) {
      this.data = value;
      this.slidesIndex = [];
    }
    for (let i = 1; i < value.length + 1; i++) {
      if (i < 10) {
        this.slidesIndex.push('0' + i);
      } else {
        this.slidesIndex.push(i.toString());
      }
    }
  }

  currentIndex = 0;
  data = [];
  state = 0;

  constructor() { }

  ngOnInit(): void {
    this.Image = Constant.ApiRoot + '/' + this.backgroundImageURL[0].filePath;
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {

    if (this.currentIndex === 0) {
      this.delay(3000).then(() => {
        this.currentIndex = 1;
        this.state = 1;
      });

    } else {
      this.delay(3000).then(() => {
        this.currentIndex = 0;
        this.state = 0;
      });
    }


  }
  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));

  }

  showSlide(slideIndex: string) {
    this.currentIndex = parseInt(slideIndex, 0) - 1;
    this.state = parseInt(slideIndex, 0) - 1;

  }

  setPagination() {

    if (this.currentIndex === 0) {
      this.delay(3000).then(() => {
        this.currentIndex = 1;
        this.state = 1;
      });

    } else {
      this.delay(3000).then(() => {
        this.currentIndex = 0;
        this.state = 0;
      });
    }

  }

}
