<div class="site-section">
    <div class="popup" id="popup1">
        <div class="row">
            <div class="col-md-3 text-center">
                <h3 *ngIf="isFrench" class="titre" [innerHTML]="TitleArrayFPU[0].contenu | safeHtml"></h3>
                <h3 *ngIf="!isFrench" class="titre" [innerHTML]="TitleArrayFPU[0].contenuEN | safeHtml"></h3>
                <app-hr *ngIf="hr"></app-hr>
            </div>
            <div class="col-md-8">
                <div class="card">
                    <div *ngIf="isFrench" class=" paragraphe" [innerHTML]="ParagrapheArrayFPU[0].body | safeHtml">
                    </div>
                    <div *ngIf="!isFrench" class=" paragraphe" [innerHTML]="ParagrapheArrayFPU[0].bodyEN | safeHtml">
                    </div>
                    <h4 *ngIf="isFrench" [innerHTML]="ParagrapheArrayFPU[0].titre | safeHtml"></h4>
                    <h4 *ngIf="!isFrench" [innerHTML]="ParagrapheArrayFPU[0].titreEN | safeHtml"></h4>
                    <div class="row ">
                        <div class="col-md-6">
                            <button (click)="customEvent()" translate>acceptAll</button>
                        </div>
                        <div class="col-md-6">
                            <button (click)="cancel()" translate>interdire</button>
                        </div>
                    </div><br>
                    <h4 *ngIf="isFrench" [innerHTML]="ParagrapheArrayFPU[1].titre | safeHtml"></h4>
                    <h4 *ngIf="!isFrench" [innerHTML]="ParagrapheArrayFPU[1].titreEN | safeHtml"></h4>
                    <p *ngIf="isFrench" [innerHTML]="ParagrapheArrayFPU[1].body | safeHtml"></p>
                    <p *ngIf="!isFrench" [innerHTML]="ParagrapheArrayFPU[1].bodyEN | safeHtml"></p>
                    <br />
                    <h4 *ngIf="isFrench" [innerHTML]="ParagrapheArrayFPU[2].titre | safeHtml"></h4>
                    <h4 *ngIf="!isFrench" [innerHTML]="ParagrapheArrayFPU[2].titreEN | safeHtml"></h4>
                    <p *ngIf="isFrench" [innerHTML]="ParagrapheArrayFPU[2].body | safeHtml"></p>
                    <p *ngIf="!isFrench" [innerHTML]="ParagrapheArrayFPU[2].bodyEN | safeHtml"></p>
                    <br />
                </div>
            </div>
        </div>
    </div>
</div>