import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { EntrepriseComponent } from './entreprise/entreprise.component';
import { FrontOfficeRoutingModule } from './front-office-routing.module';


@NgModule({
  declarations: [EntrepriseComponent],
  imports: [
    CommonModule,
    FrontOfficeRoutingModule,
    LayoutModule,
    SharedModule
  ]
})
export class FrontOfficeModule { }
