<div class="block-counter">
  <div class="animation-container" #animationContainer>
    <svg *ngIf="!mobile" [style.width]="animationContainer.offsetWidth" [style.min-height]="animationContainer.offsetWidth">
      <circle [attr.cx]="circleSize" [attr.cy]="circleSize" [attr.r]="circleSize"></circle>
    </svg>

    <svg *ngIf="mobile" [style.width]="SVGWidth" [style.min-height]="SVGWidth">
      <circle [attr.cx]="circleX" [attr.cy]="circleY" [attr.r]="circleSize"></circle>
    </svg>
    <div class="number">
      <h2 class="unit" *ngIf="unit" [style.font-size.px]="getFontSize()">{{unit}}</h2>
      <div id="chiffreToAnimate_{{uniqueID}}" class="chiffre-animate" [style.font-size.px]="getFontSize()"></div>
      <h2 class="unit pourcentage" *ngIf="Index==4" [style.font-size.px]="getFontSize()">%</h2>
    </div>
  </div>
  <h6 *ngIf="isFrench" [innerHTML]="title | safeHtml"></h6>
  <h6 *ngIf="!isFrench" [innerHTML]="page.titreEN | safeHtml"></h6>
</div>