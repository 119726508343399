import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoadingPagesService } from './shared/_services/loading-pages.service';

import { UserService } from './shared/_services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private cookievalue: string;
  public isWorking: boolean;
  constructor(
    private cookieService: CookieService,
    private userService: UserService,
    private router: Router,
    public loadingPagesService: LoadingPagesService
    ) {
  }
  public ngOnInit(): void {
    this.isWorking = true;
    this.loadingPagesService.PagesIsLoaded.subscribe(() => {
      setTimeout(() => {
        this.isWorking = false;
      }, 100);
    });
    this.cookieService.set('cookie-name', 'cookie value');
    this.cookievalue = this.cookieService.get('cookie-name');
  }


  isFrontOfficeLayout(): boolean {
    return (!this.router.url.includes('back')
      && !this.router.url.includes('login')
      && !this.router.url.includes('error')
      && !this.router.url.includes('accessdenied'))
      || this.router.url.includes('index');
  }

  isBackOfficeLayout(): boolean {
    return (this.userService.isLoggedIn()
      && this.router.url.includes('back')
      && !this.router.url.includes('index')
      && !this.router.url.includes('login')
      && !this.router.url.includes('error')
      && !this.router.url.includes('accessdenied'));
  }
}
