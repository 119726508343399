<div class="container-fluid" [style.min-height]="height+'px'" [style.width]="width+'px'">
    <div class="row" style="height: 100%;" [ngStyle]="{'margin-top': IsMobile ? '' : '15vh'}">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
            <div *ngIf="CbackgroundImageURLLR.length != 0" class="card"
                style="background-image: linear-gradient(rgba(7,88,161, 0.8),rgba(7,88,161, 0.8)),url({{Image}});">
                <div *ngIf="isFrench" class="text-white card-title" [innerHTML]="CTitreArrayLR[0].contenu | safeHtml"></div>
                <div *ngIf="!isFrench" class="text-white card-title" [innerHTML]="CTitreArrayLR[0].contenuEN | safeHtml"></div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
            <div class="site-section" style="padding-right: 5%;">
                <div class="justify-content-center">
                    <div class="text-center" style="padding-top: 10vh;">
                        <h1 *ngIf="isFrench" [innerHTML]="CTitreArrayLR[1].contenu | safeHtml"></h1>
                        <h1 *ngIf="!isFrench" [innerHTML]="CTitreArrayLR[1].contenuEN | safeHtml"></h1>
                        <app-hr *ngIf="hr"></app-hr>
                    </div>

                    <div class="text-center" *ngFor="let paragraphe of CParagrapheArrayLR; let i=index "
                        style="padding-top: 5vh;line-height: 2;">
                        <p [innerHTML]="paragraphe.body | safeHtml"
                            *ngIf="i != CParagrapheArrayLR.length-1 && isFrench"></p>
                        <p [innerHTML]="paragraphe.bodyEN | safeHtml"
                            *ngIf="i != CParagrapheArrayLR.length-1 && !isFrench"></p>
                    </div>
                    <h2 *ngIf="isFrench" class="text-center"
                        [innerHTML]="CParagrapheArrayLR[CParagrapheArrayLR.length-1].body | safeHtml"></h2>
                    <h2 *ngIf="!isFrench" class="text-center"
                        [innerHTML]="CParagrapheArrayLR[CParagrapheArrayLR.length-1].bodyEN | safeHtml"></h2>
                </div>
            </div>
        </div>
    </div>
</div>