import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Constant } from '../../_constants/constant';

@Component({
  selector: 'app-localisation',
  templateUrl: './localisation.component.html',
  styleUrls: ['./localisation.component.scss']
})
export class LocalisationComponent implements OnInit {
@Input() Localisations = [];
@Input() height: any;
@Input() width: any;
@Input() hr: boolean;

public index = 0;
public state = 0 ;
urlSafe: SafeResourceUrl;
image = ['assets/images/Andex_Paris.jpg', 'assets/images/Andex_Paris.jpg', 'assets/images/Andex_Tunis.jpg'];

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.Localisations[this.index].link);
  }

  public buttonaffichage(i: number) {
    this.index = i ;
    this.state = i ;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.Localisations[this.index].link);
  }

  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }


}
