import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';
import { Constant } from '../../_constants/constant';
import { LoadingPagesService } from '../../_services/loading-pages.service';
import { Meta, Title } from '@angular/platform-browser';
import { Page  } from 'src/app/rest';
@Component({
  selector: 'app-suiteinn4',
  templateUrl: './suiteinn4.component.html',
  styleUrls: ['./suiteinn4.component.scss']
})
export class Suiteinn4Component implements OnInit {
  isWorking: boolean;
  @Input() SuiteInn4;
  @Input() SuiteInn4Titres;
  @Input() NosSolutionsPage;
  tabs = [];
  @Input() hr: boolean;
  isFrench: boolean;
  @Input() Width;
  @Input() height;
  @Input() CardHeight;

  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private title: Title,
    private meta: Meta,
    public loadingPagesService: LoadingPagesService
  ) { }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.hr = true;
    this.Width = window.innerWidth;
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.setMeta(this.NosSolutionsPage);
    this.isWorking = true;
    this.height = this.height - 70;
    this.CardHeight = this.height / 2.2;
  }
  getString(tab: any, index: number) {
    if (tab === null || tab === undefined) {
      return '';
    } else { return tab[index]; }
  }
  setMeta(page: Page) {
    if (this.isFrench) {
      this.title.setTitle(page.metaTiltle);
      let motsCles = '';
      if (page !== undefined && page.motsCle !== undefined) {
        motsCles = page.motsCle.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com/produits/suiteinn4' });
    } else {
      this.title.setTitle(page.metaTiltleEN);
      let motsCles = '';
      if (page !== undefined && page.motsCleEN !== undefined) {
        motsCles = page.motsCleEN.replace(/\*/gi, ',');
      }
      this.meta.updateTag({ property: 'og:title', content: page.metaTiltle });
      this.meta.updateTag({ name: 'description', content: page.metaDescription });
      this.meta.updateTag({ property: 'og:description', content: page.metaDescription });
      this.meta.updateTag({ name: 'keywords', content: motsCles });
      this.meta.updateTag({ property: 'og:url', content: 'https://www.aiventu.com/produits/suiteinn4' });
    }
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
  GetImage(path: string) {
    return 'url(' + Constant.ApiRoot + '/' + path + ')';
  }


}

