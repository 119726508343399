import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class GlobalLanguageEventService {

  @Output()
  languageChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  public setLanguage(lang: string) {
    this.languageChanged.emit(lang);
  }
}
