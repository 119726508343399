import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ModifUtilisateurComponent } from './modif-utilisateur/modif-utilisateur.component';
import { ModifpwdComponent } from './modifpwd/modifpwd.component';
import { ParametresComponent } from './parametres/parametres.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';
import { VueensembleComponent } from './vueensemble/vueensemble.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'vueensemble', component: VueensembleComponent },
  { path: 'modifutilisateur', component: ModifUtilisateurComponent },
  { path: 'modifpwd', component: ModifpwdComponent },
  { path: 'utilisateur', component: UtilisateurComponent },
  { path: 'parametres', component: ParametresComponent },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages-routing.module').then(m => m.PagesRoutingModule)
  },
  {
    path: 'articles',
    loadChildren: () => import('./articles/articles-routing.module').then(m => m.ArticlesRoutingModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackOfficeRoutingModule { }
