<div style="background-image: url({{Image}});height:100% ;width: 100%;object-fit: cover;" [ngStyle]="{'margin-bottom': (IsMobile) ? '0%' : '5%'}">
    <div class="col-12" style="padding-right: 0;" [style.min-height]="height+'px'"
        [style.width]="width+'px'">
        <div class="row" [style.min-height]="height+'px'">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-black-center">
                <h2 *ngIf="isFrench" [innerHTML]="getString(TitleArray,0).contenu | safeHtml"></h2>
                <h2 *ngIf="!isFrench" [innerHTML]="getString(TitleArray,0).contenuEN | safeHtml"></h2>
                <br>
                <app-hr style="margin-left: -60%;" *ngIf="hr"></app-hr>
                <p *ngIf="isFrench" [innerHTML]="getString(ParagrapheArray,0).body | safeHtml"></p>
                <p *ngIf="!isFrench" [innerHTML]="getString(ParagrapheArray,0).bodyEN | safeHtml"></p>
                <div class="row buttonredirection">
                    <button class="button fleche" role="button" routerLink="Nos-Services"
                        routerLinkActive="active">{{'EnSavoirPlus' | translate}} &rarr;</button>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div>
                    <div class="card">
                        <!-- <img  class="image-container" src="{{GetImage(ParagrapheArray[0].fichier[0].filePath)}}" alt="{{ParagrapheArray[0].fichier[0].alt}}" > -->
                        <img *ngIf="ParagrapheArray[0].fichier.length != 0" class="image-container lazyload"
                            [attr.data-src]="GetImage(ParagrapheArray[0].fichier[0].filePath)"
                            alt="{{ParagrapheArray[0].fichier[0].alt}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>