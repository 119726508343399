/**
 * SiteAndexBack API
 * API de Base
 *
 * OpenAPI spec version: v1
 * Contact: amahmoudi@aiventu.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Activity } from './activity';
import { Categorie } from './categorie';
import { Commentaire } from './commentaire';
import { Fichier } from './fichier';
import { Modele } from './modele';
import { Paragraphe } from './paragraphe';
import { Titre } from './titre';


export interface Page { 
    pageId?: string;
    resume?: string;
    motsCle?: string;
    tag?: string;
    description?: string;
    categorieId?: string;
    categorie?: Categorie;
    modeleId?: string;
    modele?: Modele;
    commentaire?: string;
    estPublie?: boolean;
    parent?: string;
    lien?: string;
    etatPage?: Page.EtatPageEnum;
    autorisationCommentaire?: boolean;
    commentaireUsr?: Array<Commentaire>;
    fichier?: Array<Fichier>;
    titres?: Array<Titre>;
    paragraphes?: Array<Paragraphe>;
    activities?: Array<Activity>;
    order?: number;
    metaTiltle?: string;
    metaDescription?: string;
    metaDetails?: string;
    resumeEN?: string;
    motsCleEN?: string;
    tagEN?: string;
    descriptionEN?: string;
    metaTiltleEN?: string;
    metaDescriptionEN?: string;
    metaDetailsEN?: string;
    utilisateurCreation?: string;
    utilisateurModification?: string;
    dateCreation?: Date;
    dateModification?: Date;
}
export namespace Page {
    export type EtatPageEnum = 'Brouillon' | 'EnAttenteDeLecture' | 'Prive' | 'Publie';
    export const EtatPageEnum = {
        Brouillon: 'Brouillon' as EtatPageEnum,
        EnAttenteDeLecture: 'EnAttenteDeLecture' as EtatPageEnum,
        Prive: 'Prive' as EtatPageEnum,
        Publie: 'Publie' as EtatPageEnum
    };
}
