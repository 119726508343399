import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';

@Component({
  selector: 'app-chiffre',
  templateUrl: './chiffre.component.html',
  styleUrls: ['./chiffre.component.scss']
})
export class ChiffreComponent implements AfterViewInit {

  setAnimation = false;
  uniqueID: number;
  circleSize = 20;
  circleX = '';
  circleY = '';
  SVGWidth = 0;
  mobile = false;

  @Input() chiffre: number;
  @Input() unit: string;
  @Input() Index: number;
  @Input() duration: number;
  @Input() startAnimation: number;
  @Input() title: string;
  @Input() page;
  isFrench: boolean;
  @ViewChild('animationContainer') animationContainer: ElementRef;

  constructor(
    private translate: TranslateService,
    private changeRef: ChangeDetectorRef,
    public globalEventService: GlobalLanguageEventService,

  ) {
    this.uniqueID = this.randomInt(1, 8000);
  }

  ngAfterViewInit(): void {
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.circleSize = (this.animationContainer.nativeElement.offsetWidth - 15) / 2;
    if (window.innerWidth < 769) {
      this.mobile = true;
      this.circleSize = (this.animationContainer.nativeElement.offsetWidth - 15) / 5;
      this.circleX = '50%';
      this.circleY = '50%';
      this.SVGWidth = 200;
    }

    this.changeRef.detectChanges();
    const obj = document.getElementById('chiffreToAnimate_' + this.uniqueID);
    this.animateValue(obj, this.startAnimation, this.chiffre, this.duration);

  }

  // @HostListener('window:resize', ['$event']) resize() {
  //   this.circleSize = (this.animationContainer.nativeElement.offsetWidth - 15) / 2;
  //   //  var obj = document.getElementById('chiffreToAnimate_' + this.uniqueID);
  //   // this.animateValue(obj, this.startAnimation, this.chiffre, this.duration);

  // }

  @HostListener('window:scroll', ['$event']) scrolling() {
    const max = document.documentElement.scrollHeight;
    // pos max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if ((!this.setAnimation) && max > 8000) {
      this.setAnimation = true;
      if (this.setAnimation) {
        const obj = document.getElementById('chiffreToAnimate_' + this.uniqueID);
        this.delay(5000);
        this.animateValue(obj, this.startAnimation, this.chiffre, this.duration);
        // this.animateValue('chiffreToAnimate_' + this.uniqueID, this.startAnimation, this.chiffre, this.duration);
      }
    }
  }


  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));

  }

  // animateValue(id, start, end, duration) {
  //   const range = end - start;
  //   let current = start;
  //   const increment = end > start ? 1 : -1;
  //   const stepTime = Math.abs(Math.floor(duration));
  //   const obj = document.getElementById(id);
  //   const timer = setInterval(function(){
  //     current += increment;
  //     obj.innerHTML = current;
  //     if (current === end) {
  //       clearInterval(timer);
  //     }
  //   }, duration);

  // }

  animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  // const obj = document.getElementById("value");
  // animateValue(obj, 0, 100, 5000);



  randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getFontSize() {
    return (this.circleSize / 3) + 10;
  }
  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
