import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './back-office/back-office.module';
// import { HttpLoaderFactory } from './app.module';

import { LoginComponent } from './back-office/login/login.component';
import { ArticlesComponent } from './shared/components/articles/articles.component';
import { CandidatureSpontaneeComponent } from './shared/components/candidature-spontanee/candidature-spontanee.component';
import { CarrieresComponent } from './shared/components/carrieres/carrieres.component';
import { FormulaireComponent } from './shared/components/formulaire/formulaire.component';
import { ListoffresComponent } from './shared/components/listoffres/listoffres.component';
import { QuiSommesNousComponent } from './shared/components/qui-sommes-nous/qui-sommes-nous.component';
import { NosReferencesComponent } from './shared/components/nos-references/nos-references.component';
import { NosSolutionComponent } from './shared/components/nos-solution/nos-solution.component';
import { NosSolutionDetailsComponent } from './shared/components/nos-solution-details/nos-solution-details.component';
import { AuthGuard } from './shared/_guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./front-office/front-office-routing.module').then(m => m.FrontOfficeRoutingModule),
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'Error',
    loadChildren: () => import('./error/error-routing.module').then(m => m.ErrorRoutingModule)
  },
  {
    path: 'back',
    loadChildren: () => import('./back-office/back-office-routing.module').then(m => m.BackOfficeRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'article', component: ArticlesComponent
  },
  {
    path: 'article/:link', component: ArticlesComponent
  },
  {
    path: 'produits/:link', component: NosSolutionDetailsComponent
  },
  {
    path: 'produits/:categorie/:link', component: NosSolutionDetailsComponent
  },
  {
    path: 'Nos-Services/:link', component: QuiSommesNousComponent
  },
  {
    path: 'Nos-Services', component: QuiSommesNousComponent
  },
  {
    path: 'Nos-Services/:categorie/:link', component: NosSolutionDetailsComponent
  },
  {
    path: 'nos-references', component: NosReferencesComponent
  },
  {
    path: 'produits', component: NosSolutionComponent
  },
  {
    path: 'nous-contacter', component: FormulaireComponent
  },
  {
    path: 'carrieres', component: CarrieresComponent
  },
  {
    path: 'carrieres/offres', component: ListoffresComponent
  },
  {
    path: 'carrieres/candidature-spontanee', component: CandidatureSpontaneeComponent
  },
  {
    path: 'carrieres/offres/:link', component: ListoffresComponent
  },
  {
    path: '**', redirectTo: 'Error/NotFound'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
