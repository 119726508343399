import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EntrepriseComponent } from './entreprise/entreprise.component';


const routes: Routes = [
  { path: '', component: EntrepriseComponent },
  { path: 'index/:category', component: EntrepriseComponent },
  { path: 'index/:category/:page', component: EntrepriseComponent },
  { path: 'category/:page', component: EntrepriseComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontOfficeRoutingModule { }
