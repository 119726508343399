
<div class="site-section" [style.min-height]="height+'px'" [style.width]="width+'px'">
    <div class="row mb-5 justify-content-center" style="padding-top: 8vh;">
        <div class="col-md-7 text-center">
            <h1 [innerHTML]="TitleArrayS[0].contenu | safeHtml"></h1>
            <app-hr *ngIf="hr" ></app-hr> 
        </div>
    </div>
    <div *ngIf="BackgroundImageS.length != 0" class="card"
        style="background-image: linear-gradient(rgba(33, 49, 69, 0.95),rgba(33, 49, 69, 0.95)),url({{Image}});">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-5" *ngFor="let paragraphe of ParagrapheArrayS; let i=index" style="padding-bottom: 1rem !important;">
                <figure>
                    <img *ngIf="paragraphe.fichier.length != 0" class="lazyload" [attr.data-src]="GetImage(paragraphe.fichier[0].filePath)" alt="{{paragraphe.fichier[0].alt}}" class="img-fluid">
                </figure>
                <h2 [innerHTML]="paragraphe.titre | safeHtml"></h2>
                <hr class="ligne">

                <p class="mb-4 text-center" [innerHTML]="paragraphe.body | safeHtml"></p>

                <div *ngIf="hr" class="text-white "
                    [innerHTML]="paragraphe.link | safeHtml"></div>
                <div *ngIf="!hr" class="text-white "  [innerHTML]="paragraphe.link | safeHtml"></div>
            </div>
        </div>
    </div>
</div>
