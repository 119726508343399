import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { Categorie, Utilisateurs, CategorieService, UtilisateurService } from 'src/app/rest';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { faListOl, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.scss']
})
export class CategorieComponent implements OnInit {
  @ViewChild('order', { static: false }) order;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  faPencil = faPencilAlt;
  faList = faListOl;
  categorie: Categorie;
  categorieId;
  currentUser: Utilisateurs;
  displayedColumns: string[] = ['Check', 'Nom', 'Auteur', 'Modificateur', 'Actions'];
  dataSource = new MatTableDataSource();
  isLoading = true;
  element: Categorie[];
  categories: Categorie[];
  categoriesInit: Categorie[];
  catItems: any;
  listCatParent: any;
  idCat = '';
  nom = '';
  description = '';
  checked = false;
  actions;
  parent;
  currentElement;
  isUpdate: boolean;
  maj: boolean;
  selectedItems;
  isOrder: boolean;
  isUpdOrder: boolean;
  isAdding: boolean;
  isUpdating: boolean;
  nomEN = '';
  descriptionEN = '';

  selection = new SelectionModel<Categorie>(true, []);
  constructor(
    private categorieService: CategorieService,
    private toastr: ToastrService,
    private utilisateur: UtilisateurService,
    public loadingPagesService: LoadingPagesService
    ) {
  }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.categories = [];
    this.categoriesInit = [];
    this.getUser();
    this.actions = 0;
    this.isUpdate = false;
    this.MatTableTranslate();
    this.load_data();
    this.nom = '';
    this.description = '';
    this.idCat = '';
    this.parent = '';
    this.checked = false;
    this.maj = false;
    this.isUpdOrder = false;
    this.isAdding = false;
    this.isUpdating = false;

    this.nomEN = '';
    this.descriptionEN = '';
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
  }

  async ranger() {
    this.isUpdOrder = true;
    let i = 0;
    let j = 0;
    const leng = this.categories.length;
    this.categories.forEach(element => {
      element.order = i;
      i++;
      this.categorieService.putCategorie(element.categorieId, element).subscribe(() => {
        if (j === leng - 1) {
          this.isUpdOrder = false;
          this.load_data();
          this.order.nativeElement.click();
          this.toastr.success('', 'Succès',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
        }
        j++;
      }, () => {
        this.isUpdOrder = false;
        this.toastr.error('Erreur', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
    });
  }

  CancelOrder() {
    this.categories = this.categoriesInit;
  }

  getUser() {
    if (localStorage.getItem('MyApp_Auth') !== undefined) {
      const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
      this.utilisateur.getUtilisateur(userId).subscribe(data => {
        this.currentUser = data;
      });
    }
  }

  public load_data() {
    this.isLoading = true;
    this.dataSource.data = [];

    this.categorieService.getCategories().subscribe((data) => {
      if (data !== undefined) {
        this.categories = data;
        this.categoriesInit = data;
        this.element = data;
        this.catItems = data;
        this.isLoading = false;
        this.dataSource.data = this.element;
      }
    },
      () => {
        this.isLoading = false;
        this.toastr.error('', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.paginator._changePageSize(this.paginator.pageSize);
  }

  MatTableTranslate() {
    this.paginator._intl.itemsPerPageLabel = 'Lignes par page';
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.lastPageLabel = 'Dernière page';
    this.paginator._intl.previousPageLabel = 'Page précédente';
    this.paginator._intl.firstPageLabel = 'Première page';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 / ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  }

  appliquer() {
    this.actions = this.actions * 1;
    if (this.actions === 1) {
      this.maj = true;
      this.selectedItems = [];
      this.selectedItems = this.selection.selected;
      if (this.selectedItems === 0) {
        this.maj = false;
      }

      this.setListParent();
    }
  }

  setListParent() {
    this.listCatParent = [];
    this.catItems.forEach(element => {
      if (this.catIsExist(element.nom) === false) {
        this.listCatParent.push(element);
      }
    });
  }

  catIsExist(nom) {
    let res = false;
    if (this.selectedItems !== undefined) {
      this.selectedItems.forEach(element => {
        if (element.nom === nom) {
          res = true;
        }
      });
    }
    return res;
  }

  remove(item) {
    this.selection.deselect(item);
    this.appliquer();
    this.setListParent();
  }

  cancel() {
    this.selectedItems = [];
    this.selection.selected.forEach(element => {
      this.selection.deselect(element);
    });
    this.maj = false;
  }

  miseAJour() {
    if (this.parent !== '') {
      this.selectedItems.forEach(element => {
        element.parent = this.parent;
        element.dateModification = new Date();
        element.utilisateurModification = this.currentUser.userName;
        element.nomEN = this.nomEN;
        element.descriptionEN = this.descriptionEN;
        this.categorieService.putCategorie(element.categorieId, element).subscribe(() => {
          this.toastr.success('', 'Succès',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          this.cancel();
          this.ngOnInit();
        },
          () => {
            this.toastr.error('', 'Erreur',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
          }
        );
      });
    }
  }

  onSubmit() {

    const name = this.nom;
    const description = this.description;
    const IDCategorie = Guid.create().toString();
    if (name === '') {
      this.toastr.error('Veuillez renseigner le nom de la catégorie', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else {
      this.isAdding = true;
      this.categorie = {
        categorieId: IDCategorie,
        nom: name,
        description,
        parent: this.idCat,
        nomEN: this.nomEN,
        descriptionEN: this.descriptionEN,
        utilisateurCreation: 'Admin',
        utilisateurModification: 'Admin',
        dateCreation: new Date(),
        dateModification: new Date()
      };

      this.categorieService.postCategorie(this.categorie).subscribe(data => {
        this.toastr.success('', 'Succès',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.ngOnInit();
        this.isAdding = false;
      },
        () => {
          this.toastr.error('', 'Erreur',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          this.isAdding = false;
        }
      );
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Categorie): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  setElement(element) {
    this.isUpdate = true;
    this.currentElement = element;
    this.nom = element.nom;
    this.description = element.description;
    if (element.parent !== undefined) {
      this.idCat = element.parent;
    } else {
      this.idCat = '';
    }
    this.nomEN = element.nomEN;
    this.descriptionEN = element.descriptionEN;
  }

  annuler() {
    this.isUpdate = false;
    this.nom = '';
    this.description = '';
    this.idCat = '';
    this.nomEN = '';
    this.descriptionEN = '';
  }

  update() {
    if (this.nom === '') {
      this.toastr.error('Veuillez renseigner le nom de la catégorie', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else {
      this.isUpdating = true;
      this.currentElement.utilisateurModification = this.currentUser.userName;
      this.currentElement.dateModification = new Date();
      this.currentElement.nom = this.nom;
      this.currentElement.description = this.description;
      this.currentElement.parent = this.idCat;
      this.currentElement.nomEN = this.nomEN;
      this.currentElement.descriptionEN = this.descriptionEN;
      this.categorieService.putCategorie(this.currentElement.categorieId, this.currentElement).subscribe(() => {
        this.toastr.success('', 'Succès',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.ngOnInit();
        this.isUpdating = false;
      },
        () => {
          this.toastr.error('', 'Erreur',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
          this.isUpdating = false;
        });
    }
  }
}
