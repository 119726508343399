import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Constant } from '../../_constants/constant';

@Component({
  selector: 'app-blocs',
  templateUrl: './blocs.component.html',
  styleUrls: ['./blocs.component.scss']
})
export class BlocsComponent implements OnInit {

  @Input() TitreArrayB = [];
  @Input() ParagrapheArrayB = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;
  public index = 1;
  public state = 0;

  public buttonaffichage(i: number) {
    this.index = i + 1;
    this.state = i;
  }


  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // this.state = 0
    this.height = this.height - 70;

    for (let i = 1; i < this.ParagrapheArrayB.length; i++) {
      if (this.ParagrapheArrayB[i].fichier.length !== 0) {
        this.ParagrapheArrayB[i].fichier = this.ParagrapheArrayB[i].fichier.sort((a, b) => a.isBackground > b.isBackground ? 1 : -1);
      }
    }
  }

  GetImage(path: string) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
    return `${Constant.ApiRoot}/${path}`;
  }

  slideshow() {
    if (this.index === 1) {
      this.delay(4000).then(() => {
        this.index = 2;
        this.state = 1;
      });
    } else {
      this.delay(4000).then(() => {
        this.index = 1;
        this.state = 0;
      });
    }
  }
  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));

  }


}

