<div class="card">
    <img *ngIf="Job.fichier.length != 0" class="lazyload" [attr.data-src]="GetImage(Job.fichier[0].filePath)" alt="{{Job.fichier[0].alt}}"
        style="height:45%;">
    <h4 *ngIf="isFrench" [innerHTML]="Job.name | safeHtml"></h4>
    <h4 *ngIf="!isFrench" [innerHTML]="Job.nameEN | safeHtml"></h4>
    <div class="card-body">
        <div class="text-center card-title" *ngIf="isFrench" [innerHTML]="Job.titre | safeHtml"></div>
        <div class="text-center card-title" *ngIf="!isFrench" [innerHTML]="Job.titreEN | safeHtml"></div>
        <div class="text-black" *ngIf="isFrench" [innerHTML]="Job.body | safeHtml"></div>
        <div class="text-black" *ngIf="!isFrench" [innerHTML]="Job.bodyEN | safeHtml"></div>
        <a *ngIf="Job.id !== '00000000-0000-0000-0000-000000000000'"
            routerLink="{{url}}offres/{{Job.link}}" [href]="" routerLinkActive="active"
            class="more-link" translate>DecouvreOffre &nbsp;
            <a routerLink="{{url}}offres/{{Job.link}}" [href]="" routerLinkActive="active" class="fleche">
                &rarr;</a>
        </a>
        <a *ngIf="Job.id === '00000000-0000-0000-0000-000000000000'" routerLink="article/{{Job.link}}"
            routerLinkActive="active" class="more-link" [href]="" translate>EnSavoirPlus &nbsp;
            <a routerLink="article/{{Job.link}}" [href]="" routerLinkActive="active" class="fleche"> &rarr;</a>
        </a>
    </div>
</div>