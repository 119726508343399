import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reseau-sociaux',
  templateUrl: './reseau-sociaux.component.html',
  styleUrls: ['./reseau-sociaux.component.scss']
})
export class ReseauSociauxComponent implements OnInit {

  @Input() TitreArrayRS = [];
  @Input() ParagrapheArrayRS = [];
  @Input() PublicationsArrayRS = [];
  @Input() hr: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
