<nav id="sidebar" class="active" [ngClass]="{'large':isLargeScreen}">
  <h1>
    <img data-src="{{logo.url}}" class="lazyload" width="40%" class="text-center logo" alt="{{logo.alt}}">
  </h1>
  <ul class="list-unstyled components mb-5">
    <li class="active" *ngFor="let treeElement of menuTree">
      <a [routerLink]="treeElement.url" [href]="" routerLinkActive="active"
        [ngClass]="{'large-item': isLargeScreen,'medium-item': isMediumScreen}">
        <span><img data-src="{{treeElement.img}}" class="lazyload" alt="navbar" [style.width.px]="sizeIcons" [style.heigth.px]="sizeIcons"></span>
        <span *ngIf="isLargeScreen || isMediumScreen">{{treeElement.title}}</span>
        <span *ngIf="treeElement.children.length>0" (click)="toggleSubMenu(treeElement.title)">
          <fa-icon [icon]="faIconCaret" class="dropbtn"></fa-icon>
        </span>
      </a>
      <ul class="collapse list-unstyled" style="text-align: left;" id="{{treeElement.title}}" *ngIf="treeElement.children.length>0">
        <li *ngFor="let child of treeElement.children">
          <a [routerLink]="child.url" [href]="" routerLinkActive="active" style="padding-left: 13.5%;">{{child.title}}</a>
        </li>
      </ul>
    </li>
  </ul>

  <div class="footer" *ngIf="copyright">
    <p>
      {{copyright}}
    </p>
  </div>
</nav>