<div class="site-section text-center "
  style="background-image: linear-gradient(hsla(214,35%,20%,0.6),hsla(214,35%,20%,0.6)),url({{Image}}); margin-top: 10%;"
  [style.min-height]="height+'px'" [style.width]="width+'px'">
  <h1 *ngIf="isFrench" [innerHTML]="title[0].contenu | safeHtml" class="row bloc-heading-1 justify-content-center"></h1>
  <h1 *ngIf="!isFrench" [innerHTML]="title[0].contenuEN | safeHtml" class="row bloc-heading-1 justify-content-center">
  </h1>
  <app-hr *ngIf="hr"></app-hr>
  <div class="row Container" style="margin-top: 5%;">
    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 InnerContainer" style="margin-right: 4%;margin-left: 4%;"
      *ngFor="let value of values; let i = index">
      <app-chiffre (mouseover)="setligne(i)" [chiffre]="getInt(value.body)" [startAnimation]="StartAnimation[i]"
      [page]="value"
        [duration]="Duration[i]" [title]="value.titre" [unit]="value.link" [Index]="i"></app-chiffre>
      <app-hr *ngIf=" setline == i && hr"></app-hr>
    </div>
    <br>
  </div>
</div>