
<div [style.height]="height+'px'" [style.width]="width+'px'">
    <div id="carouselExampleIndicators" class="carousel slide"  data-interval="3000"  data-ride="carousel">
        <ol class="carousel-indicators">
            <li *ngFor="let images of EPageAccueilParagraphes; let i = index;" data-target="#carouselExampleIndicators" 
                attr.data-slide-to="{{i}}" [ngClass]="{active: i==0}"></li>
        </ol>
        <div class="carousel-inner" [style.height]="'100%'" [style.width]="width+'px'">

            <div *ngFor="let images of EPageAccueilParagraphes; let i = index;" class="carousel-item slideshow-wrapper" [ngClass]="{active: i==0}">
                <img *ngIf="mobile" width="100%" height="100%" class="d-block w-100 lazyload" data-src="{{GetImage(EPageAccueilParagraphes[i].fichier[0].filePath)}}" alt="{{EPageAccueilParagraphes[i].fichier[0].alt}}" [style.height]="height+'px'" [style.object-fit]="'cover'">
                <img *ngIf="!mobile" width="100%" height="100%" class="d-block w-100 lazyload"  data-src="{{GetImage(EPageAccueilParagraphes[i].fichier[0].filePath)}}" alt="{{EPageAccueilParagraphes[i].fichier[0].alt}}">
                <div class="carousel-caption" style="bottom: 35%;">
                    <h1 *ngIf="isFrench" [innerHTML]="EPageAccueilParagraphes[i].titre | safeHtml"></h1>
                    <h1 *ngIf="!isFrench" [innerHTML]="EPageAccueilParagraphes[i].titreEN | safeHtml"></h1>
                    <div class="button-container">
                        <a class="button-redirection" href="{{EPageAccueilParagraphes[i].link}}">{{'EnSavoirPlus' | translate}}  &rarr; </a>
                    </div>
                </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a  class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next" id="next">
            <span  class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>