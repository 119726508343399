import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterModule } from '@angular/router';
import { PageService } from 'src/app/rest/api/page.service';
import { CategorieService } from 'src/app/rest/api/categorie.service';
import { ToastrService } from 'ngx-toastr';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UtilisateurService } from 'src/app/rest/api/utilisateur.service';
import { FichierService } from 'src/app/rest/api/fichier.service';
import { Guid } from 'guid-typescript';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  Activity,
  Fichier,
  Page,
  Paragraphe,
  Titre,
} from 'src/app/rest/model/models';
import {
  ActivityService,
  ModeleService,
  ParagrapheService,
  TitreService,
} from 'src/app/rest';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { faListOl, faUpload, faEye, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ImageUploaded } from 'src/app/rest/model/imageUploaded';
import { UserService } from 'src/app/shared/_services/user.service';
import { Constant } from 'src/app/shared/_constants/constant';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-modifierpage',
  templateUrl: './modifierpage.component.html',
  styleUrls: ['./modifierpage.component.scss'],
})
export class ModifierpageComponent implements OnInit {

  @ViewChild('myModal', { static: false }) preview;
  @ViewChild('exampleModalLong', { static: false }) save;

  faEye = faEye;
  faUpload = faUpload;
  faList = faListOl;
  faCopy = faCopy;
  config: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text ...',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  configtitre: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '2rem',
    minHeight: '5rem',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    toolbarHiddenButtons: [],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  apiKeys: { [key: string]: string };
  catItems: any;
  idCat = '';
  titre = '';
  sousTitre = '';
  resume = '';
  body = '';
  motCle = '';
  tag = '';
  description = '';
  commentaire = '';
  estPublie = false;
  Docu: boolean;
  url;
  metaTitle;
  metaDescription;
  metaDetails;
  baliseEntete;
  taillePolice;
  currentPath;
  currentUser;
  etat;
  lien: boolean;
  isUpdating = false;
  mod: any[];

  modeles;
  polices;
  mc: any[] = [];
  tags: any[] = [];
  mcEN: any[] = [];
  tagsEN: any[] = [];
  selectable = true;
  removable = true;
  addOnBlur = true;
  ctrlimage = 0;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fileToUpload: any[];

  id: string;
  private items: any;
  isWorking: boolean;

  listTitres = [{ contenu: 'test' }];
  listParagraphes = [];
  background = [];

  titreEN = '';
  bodyEN = '';
  nameEN = '';

  // Paragraphes
  public paragraphes: any[] = [
    {
      idpar: '',
      titre: '',
      body: '',
      link: '',
      name: '',
      titreEN: '',
      bodyEN: '',
      nameEN: ''
    },
  ];

  public titres: Titre[] = [];
  // tslint:disable-next-line: variable-name
  nb_i: number;
  // tslint:disable-next-line: variable-name
  nb_p: number;
  // tslint:disable-next-line: variable-name
  nb_t: number;

  isModelRxJob: boolean;
  isOpened: boolean;
  retour: string;

  descriptionEN = '';

  metaTiltleEN = '';
  metaDescriptionEN = '';
  metaDetailsEN = '';

  isImage: boolean;
  alt: string;
  src: string;

  private animations = 'assets/images/Modele/animations.png';
  private blocs = 'assets/images/Modele/Blocs.png';
  private Description = 'assets/images/Modele/Description.png';
  private Formulaire = 'assets/images/Modele/Formulaire.png';
  private landingPage = 'assets/images/Modele/landing-page.png';
  private lienRed = 'assets/images/Modele/Lien de redirection.png';
  private OffresEmploi = 'assets/images/Modele/offres d’emplois.png';
  private parllaxSc = 'assets/images/Modele/parallax-scrolling.png';
  private superposition = 'assets/images/Modele/superposition-entre-image-et-texte.png';
  private texte = 'assets/images/Modele/texte.png';
  private texteImages = 'assets/images/Modele/Textes-images.png';
  private videoTexte = 'assets/images/Modele/Video-texte.png';
  private acceuil = 'assets/images/Modele/accueil.png';
  private paragraphesM = 'assets/images/Modele/Paragraphe.png';
  private imageVideoTexte = 'assets/images/Modele/image-video-texte.png';
  private fenetres = 'assets/images/Modele/fenetres-informatives.png';
  private candidatureSp = 'assets/images/Modele/CandidatureSpontanée.png';
  private popUp = 'assets/images/Modele/PopUp.png';

  srcModele = '';

  constructor(
    private categorie: CategorieService,
    private toastr: ToastrService,
    private router: Router,
    private routing: RouterModule,
    private route: ActivatedRoute,
    private page: PageService,
    private paragrapheService: ParagrapheService,
    private utilisateur: UtilisateurService,
    private fichier: FichierService,
    private titreService: TitreService,
    private userService: UserService,
    private activity: ActivityService,
    private modele: ModeleService,
    public loadingPagesService: LoadingPagesService
  ) {
    this.paragraphes.splice(0, 1);
    this.route.queryParams.subscribe((params) => {
      this.id = params.Pageid;
      if (this.id === undefined) {
        this.router.navigate(['/back/pages']);
      }
      this.getPage(this.id);
      this.getTitre(this.id);
      this.getParagraphes(this.id);
      this.getCategorie();
    });
  }

  ngOnInit() {
    this.loadingPagesService.setPageStatus(true);
    this.idCat = '';
    this.titre = '';
    this.sousTitre = '';
    this.resume = '';
    this.body = '';
    this.titreEN = '';
    this.bodyEN = '';
    this.nameEN = '';
    this.motCle = '';
    this.tag = '';
    this.description = '';
    this.commentaire = '';
    this.estPublie = false;
    this.lien = false;
    this.url = '';
    (this.metaTitle = ''), (this.metaDescription = '');
    this.metaTiltleEN = '';
    this.metaDescriptionEN = '';
    this.metaDetailsEN = '';
    this.metaDetails = '';
    this.modeles = '';
    this.polices = '';
    this.baliseEntete = 'h1';
    this.taillePolice = 20;
    this.nb_i = 0;
    this.nb_p = 0;
    this.nb_t = 0;
    this.mc = [];
    this.tags = [];
    this.mcEN = [];
    this.tagsEN = [];
    this.selectable = true;
    this.removable = true;
    this.addOnBlur = true;
    this.currentPath = window.location.origin + '/';
    this.fileToUpload = [];
    this.currentUser = [];
    this.etat = 'Brouillon';
    this.isWorking = false;

    this.descriptionEN = '';

    this.getModels();
    this.Docu = true;
    this.getCategorie();
    this.getUser();
  }

  editpage() {
    this.listParagraphes = [];
    this.background = [];
  }
  returnmodif() {
    this.isOpened = false;
    this.listParagraphes = [];
    this.background = [];
  }
  openModal() {
    this.isOpened = true;
    this.retour = this.id;
    this.paragraphes.forEach(element => {
      this.listParagraphes.push({
        body: element.body,
        link: element.link,
        titre: element.titre,
        idpar: element.idpar,
        titreEN: element.titreEN,
        bodyEN: element.bodyEN,
        nameEN: element.nameEN,
        fichier: []
      });

    });

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.listParagraphes.length; i++) {
      this.fileToUpload.forEach(file => {
        if (file.paragraphe === this.listParagraphes[i].idpar) {
          this.listParagraphes[i].fichier.push({ filePath: file.file });
        }
      });
    }

    this.fileToUpload.forEach(file => {
      if (file.paragraphe === '') {
        this.background.push({ filePath: file.file });
      }
    });

  }



  AddTitre() {
    this.titres.push({
      id: Guid.create().toString(),
      contenu: '',
      contenuEN: ''
    });
  }

  removeTitre(i) {
    this.titres.splice(i, 1);
  }

  addEN(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.mcEN.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeEN(fruit: any): void {
    const index = this.mcEN.indexOf(fruit);
    if (index >= 0) {
      this.mcEN.splice(index, 1);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.paragraphes, event.previousIndex, event.currentIndex);
  }

  dropTitres(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.titres, event.previousIndex, event.currentIndex);
  }

  getParagrapheNumber(id) {
    let i = 0;
    let r = -1;
    // tslint:disable-next-line: no-shadowed-variable
    this.paragraphes.forEach((element) => {
      if (element.idpar === id) {
        r = i;
      }
      i++;
    });
    return r;
  }

  getParagraphes(id) {
    this.paragrapheService.getByPage(id).subscribe((data) => {
      // tslint:disable-next-line: no-shadowed-variable
      data.forEach((element) => {
        this.paragraphes.push({
          idpar: element.id,
          titre: element.titre,
          body: element.body,
          link: element.link,
          name: element.name,
          titreEN: element.titreEN,
          bodyEN: element.bodyEN,
          nameEN: element.nameEN
        });

        // tslint:disable-next-line: no-shadowed-variable
        this.fichier.getByParagraphe(element.id).subscribe(
          // tslint:disable-next-line: no-shadowed-variable
          (data) => {
            if (data.length > 0) {
              // tslint:disable-next-line: no-shadowed-variable
              data.forEach((element) => {
                const parag = {
                  name: element.name,
                  alt: element.alt,
                  altEN: element.altEN,
                  file: element.filePath,
                  type: element.type,
                  IsBackground: element.isBackground,
                  paragraphe: element.idParagraphe,
                };
                this.fileToUpload.push(parag);
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      });
    });
  }

  getPage(id) {
    this.page.get_1(id).subscribe(
      (data) => {
        this.items = data;
        this.nb_i = this.items.modele.nbImage;
        if (this.items === undefined) {
          this.router.navigate(['/back/pages']);
        }

        if (this.items.titres[0] !== undefined) {
          this.titre = this.items.titres[0].contenu;
        }

        this.idCat = this.items.categorie.categorieId;
        this.sousTitre = this.items.sousTitre;
        this.resume = this.items.resume;
        this.metaTitle = this.items.metaTiltle;
        this.metaDescription = this.items.metaDescription;
        this.metaDetails = this.items.metaDetails;
        this.body = this.items.body;
        this.titreEN = this.items.titreEN;
        this.nameEN = this.items.nameEN;
        this.bodyEN = this.items.bodyEN;
        this.motCle = this.items.motsCle;
        this.tag = this.items.tag;
        this.modeles = this.items.modeleId;
        this.description = this.items.description;
        this.commentaire = this.items.commentaire;
        this.estPublie = this.items.estPublie;
        this.url = this.items.lien;
        this.etat = this.getEtat(this.items.etatPage);
        this.descriptionEN = this.items.descriptionEN;

        this.metaTiltleEN = this.items.metaTiltleEN;
        this.metaDescriptionEN = this.items.metaDescriptionEN;
        this.metaDetailsEN = this.items.metaDetailsEN;

        if (
          this.items.modeleId === 'a999ad20-84c8-4599-b75c-e075da0e0af2' ||
          this.items.modeleId === 'fe2403de-d90c-45ea-8c4e-fbe52a48c27b'
        ) {
          this.isModelRxJob = true;
        } else {
          this.isModelRxJob = false;
        }
        this.UpdatenbImage(this.items.modeleId);


        const tabTags = (this.items.tag !== undefined ? this.items.tag.split('*') : []);
        // tslint:disable-next-line: no-shadowed-variable
        tabTags.forEach((element) => {
          if (element !== '') {
            this.tags.push({ name: element.trim() });
          }
        });

        const tabTagsEN = (this.items.tagEN !== undefined ? this.items.tagEN.split('*') : []);
        // tslint:disable-next-line: no-shadowed-variable
        tabTagsEN.forEach((element) => {
          if (element !== '') {
            this.tagsEN.push({ name: element.trim() });
          }
        });

        const tabMotCle = (this.items.motsCle !== undefined ? this.items.motsCle.split('*') : []);
        // tslint:disable-next-line: no-shadowed-variable
        tabMotCle.forEach((element) => {
          if (element !== '') {
            this.mc.push({ name: element.trim() });
          }
        });

        const tabMotCleEN = (this.items.motsCleEN !== undefined ? this.items.motsCleEN.split('*') : []);
        // tslint:disable-next-line: no-shadowed-variable
        tabMotCleEN.forEach((element) => {
          if (element !== '') {
            this.mcEN.push({ name: element.trim() });
          }
        });

        // tslint:disable-next-line: no-shadowed-variable
        this.fichier.getByPage(id).subscribe((data) => {
          if (data != null) {
            if (data.length > 0) {
              // tslint:disable-next-line: no-shadowed-variable
              data.forEach((element) => {
                const parag = {
                  name: element.name,
                  alt: element.alt,
                  altEN: element.altEN,
                  file: element.filePath,
                  type: element.type,
                  IsBackground: element.isBackground,
                  paragraphe: '',
                };
                this.fileToUpload.push(parag);
              });
            }
          }
        });
      },
      () => { }
    );
  }

  getTitre(id) {
    this.titreService.getByPage(id).subscribe((data) => {
      data = data.sort((a, b) => (a.order > b.order ? 1 : -1));
      data.forEach((el) => {
        this.titres.push({
          id: el.id,
          contenu: el.contenu,
          contenuEN: el.contenuEN
        });

        // tslint:disable-next-line: no-shadowed-variable
        this.fichier.getByTitre(el.id).subscribe(
          // tslint:disable-next-line: no-shadowed-variable
          (data) => {
            if (data.length > 0) {
              // tslint:disable-next-line: no-shadowed-variable
              data.forEach((element) => {
                const parag = {
                  name: element.name,
                  alt: element.alt,
                  altEN: element.altEN,
                  file: element.filePath,
                  type: element.type,
                  IsBackground: element.isBackground,
                  paragraphe: element.idTitre,
                };
                this.fileToUpload.push(parag);
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      });
    });
  }

  getModels() {
    this.mod = [];
    this.modele.get().subscribe(
      (data) => {
        this.mod = data;
      },
      // tslint:disable-next-line: no-shadowed-variable
      (error) => {
        console.log('erreur models : ' + error);
      }
    );
  }
  UpdatenbImage(m) {
    this.mod.forEach((element) => {
      if (element.id === m) {
        this.nb_i = element.nbImage;
        this.nb_t = element.nbTitre;
        this.nb_p = element.nbParagraphe;
      }
    });
  }

  UpdateParagraphe(m) {
    if (
      m === 'a999ad20-84c8-4599-b75c-e075da0e0af2' ||
      m === 'fe2403de-d90c-45ea-8c4e-fbe52a48c27b'
    ) {
      this.isModelRxJob = true;
    } else {
      this.isModelRxJob = false;
    }
    // tslint:disable-next-line: no-shadowed-variable
    this.mod.forEach((element) => {
      if (element.id === m) {
        this.nb_i = element.nbImage;
        this.nb_t = element.nbTitre;
        this.nb_p = element.nbParagraphe;
      }
    });
   // console.log(this.paragraphes.length);
    //this.paragraphes = [];
    for (let i = this.paragraphes.length; i < this.nb_p; i++) {
      this.paragraphes.push({
        idpar: Guid.create().toString(),
        titre: '',
        body: '',
        link: '',
        name: '',
        titreEN: '',
        bodyEN: '',
        nameEN: ''
      });
    }
  }

  getEtat(etat) {
    if (etat === 0) {
      return 'Brouillon';
    }

    if (etat === 1) {
      return 'EnAttenteDeLecture';
    }

    if (etat === 2) {
      return 'Prive';
    }

    if (etat === 3) {
      return 'Publie';
    }
  }

  getCategorie() {
    this.categorie.getCategories().subscribe(
      (data) => {
        this.catItems = data;
      },
      // tslint:disable-next-line: no-shadowed-variable
      (error) => {
        this.toastr.error(error, 'Erreur');
      }
    );
  }

  async UpdateTitle() {
    this.titreService.deleteByPage(this.id).subscribe(() => {
      const dt = new Date();
      let ord = 0;
      this.titres.forEach((ele) => {
        const titrePage: Titre = {
          id: ele.id,
          contenu: ele.contenu,
          contenuEN: ele.contenuEN,
          pageId: this.id,
          order: ord,
          utilisateurCreation: this.currentUser.userName,
          utilisateurModification: this.currentUser.userName,
          dateCreation: dt,
          dateModification: dt,
        };
        ord++;
        this.titreService.post(titrePage).subscribe(
          async () => { },
          () => {
            console.log('title : Not ok');
          }
        );
      });
    });
  }

  async SaveParagaphes() {
    let i = 0;
    let j = 0;
    await this.paragraphes.forEach(async element => {
      const paragraphePage: Paragraphe = {
        id: element.idpar,
        titre: element.titre,
        body: element.body,
        link: element.link,
        pageId: this.id,
        order: j,
        titreEN: element.titreEN,
        bodyEN: element.bodyEN,
        nameEN: element.nameEN,
        utilisateurCreation: this.currentUser.userName,
        utilisateurModification: this.currentUser.userName,
        dateCreation: new Date(),
        dateModification: new Date(),
      };

      if (this.isModelRxJob === true) {
        paragraphePage.name = element.name;
      }
      j++;
      await this.paragrapheService.post(paragraphePage).subscribe(async () => {
        i++;
        if (i === this.paragraphes.length) {
          await this.UpdateFiles();
        }
      },
        // tslint:disable-next-line: no-shadowed-variable
        error => {
          console.log(error);
        });
    });
  }

  async deleteFiles(PageId) {
    this.fichier.deleteByPage(PageId).subscribe(
      async () => {
        await this.updatePage();
        await this.titreService.getByPage(PageId).subscribe(async (data) => {
          await data.forEach((el) => {
            this.fichier.deleteByTitle(el.id).subscribe(() => { });
          });
        });
      },
      () => {
        console.log('Delete File By Page : NOT OK');
      }
    );
  }

  async Update() {
    this.isWorking = true;

    await this.UpdateTitle();
    this.deleteFiles(this.id);
  }

  async updatePage() {
    if (this.nb_i === this.fileToUpload.length) {
      const user = this.currentUser.userName;
      this.ctrlimage = 0;
      await this.paragrapheService.deleteByPage(this.id).subscribe(() => {
        let motCles = '';
        let tags = '';
        let motClesEN = '';
        let tagsEN = '';
        if (this.mc.length !== 0) {
          // tslint:disable-next-line: no-shadowed-variable
          this.mc.forEach((element) => {
            motCles = motCles + element.name + '*';
          });
        }

        if (this.tags.length !== 0) {
          // tslint:disable-next-line: no-shadowed-variable
          this.tags.forEach((element) => {
            tags = tags + element.name + '*';
          });
        }

        if (this.mcEN.length !== 0) {
          // tslint:disable-next-line: no-shadowed-variable
          this.mcEN.forEach((element) => {
            motClesEN = motClesEN + element.name + '*';
          });
        }

        if (this.tagsEN.length !== 0) {
          // tslint:disable-next-line: no-shadowed-variable
          this.tagsEN.forEach((element) => {
            tagsEN = tagsEN + element.name + '*';
          });
        }

        const dt = new Date();
        const page: Page = {
          pageId: this.id,
          resume: this.resume,
          motsCle: motCles,
          tag: tags,
          motsCleEN: motClesEN,
          tagEN: tagsEN,
          description: this.description,
          categorieId: this.idCat,
          commentaire: this.commentaire,
          estPublie: this.estPublie,
          lien: this.url,
          modeleId: this.modeles,
          etatPage: this.etat,
          autorisationCommentaire: this.items.autorisationCommentaire,
          order: this.items.order,
          metaTiltle: this.metaTitle,
          metaDescription: this.metaDescription,
          metaDetails: this.metaDetails,
          descriptionEN: this.descriptionEN,
          metaTiltleEN: this.metaTiltleEN,
          metaDescriptionEN: this.metaDescriptionEN,
          metaDetailsEN: this.metaDetailsEN,
          utilisateurCreation: this.items.utilisateurCreation,
          utilisateurModification: this.currentUser.userName,
          dateCreation: this.items.dateCreation,
          dateModification: dt,
        };

        this.page.put(this.id, page).subscribe(
          async () => {
            this.activity.getByPage(this.id).subscribe((data) => {
              if (data.length > 0) {
                const activite = this.getActivityByStatut(data, '2');
                if (activite != null) {
                  activite.dateModification = new Date();
                  activite.utilisateurModification = user;
                  this.activity.put(activite.idActivity, activite).subscribe(
                    () => { },
                    // tslint:disable-next-line: no-shadowed-variable
                    (error) => {
                      console.log(error);
                    }
                  );
                } else {
                  // Add Activity
                  const act: Activity = {
                    idActivity: Guid.create().toString(),
                    description: 'Modification Page',
                    statut: 'Modifié',
                    pageId: this.id,
                    utilisateurCreation: user,
                    utilisateurModification: user,
                    dateCreation: new Date(),
                    dateModification: new Date(),
                  };

                  this.activity.post(act).subscribe(
                    () => { },
                    // tslint:disable-next-line: no-shadowed-variable
                    (error) => {
                      console.log(error);
                    }
                  );
                }
              } else {
                // Add Activity
                const act: Activity = {
                  idActivity: Guid.create().toString(),
                  description: 'Modification Page',
                  statut: 'Modifié',
                  pageId: this.id,
                  utilisateurCreation: user,
                  utilisateurModification: user,
                  dateCreation: new Date(),
                  dateModification: new Date(),
                };

                this.activity.post(act).subscribe(
                  () => { },
                  // tslint:disable-next-line: no-shadowed-variable
                  (error) => {
                    console.log(error);
                  }
                );
              }
              this.isWorking = false;
            });


            await this.SaveParagaphes();
          },
          // tslint:disable-next-line: no-shadowed-variable
          (error) => {
            this.isWorking = false;
            console.log(error);
          }
        );
        this.isWorking = false;
      });
    } else {
      const manquant = this.nb_i - this.fileToUpload.length;
      this.toastr.error(
        `${manquant} Images/Vidèos Manquant(e)(s)`,
        'Erreur',
        {
          timeOut: 5000,
          progressBar: false,
          closeButton: true,
        }
      );
      this.isWorking = false;
    }
  }

  async UpdateFiles() {
    // tslint:disable-next-line: no-shadowed-variable
    this.fileToUpload.forEach((element) => {
      if (this.isTitle(element.paragraphe)) {
        const filePage: Fichier = {
          idFile: Guid.create().toString(),
          filePath: 'Files/' + element.name.replace(/[^a-zA-Z0-9.]/g, ''),
          type: element.type,
          name: element.name,
          alt: element.alt,
          altEN: element.altEN,
          idTitre: element.paragraphe,
          isBackground: element.IsBackground,
          utilisateurCreation: this.currentUser.userName,
          utilisateurModification: this.currentUser.userName,
          dateCreation: new Date(),
          dateModification: new Date(),
        };
        this.fichier.post(filePage).subscribe(
          () => {
            this.ctrlimage++;
          },
          () => {
            this.isUpdating = true;
            this.toastr.error(
              'Erreur survenue lors le chargement des fichiers.Réssayer de nouveau!',
              'Erreur',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              }
            );
          }
        );
      } else if (this.isParagraphe(element.paragraphe)) {
        const filePage = {
          idFile: Guid.create().toString(),
          filePath: 'Files/' + element.name.replace(/[^a-zA-Z0-9.]/g, ''),
          type: element.type,
          name: element.name,
          alt: element.alt,
          altEN: element.altEN,
          idParagraphe: element.paragraphe,
          isBackground: element.IsBackground,
          utilisateurCreation: this.currentUser.userName,
          utilisateurModification: this.currentUser.userName,
          dateCreation: new Date(),
          dateModification: new Date(),
        };
        this.fichier.post(filePage).subscribe(
          () => {
            this.ctrlimage++;
          },
          () => {
            this.isUpdating = true;
            this.toastr.error(
              'Erreur survenue lors le chargement des fichiers.Réssayer de nouveau!',
              'Erreur',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              }
            );
          }
        );
      } else {
        const filePage: Fichier = {
          idFile: Guid.create().toString(),
          filePath: 'Files/' + element.name.replace(/[^a-zA-Z0-9.]/g, ''),
          type: element.type,
          name: element.name,
          alt: element.alt,
          altEN: element.altEN,
          pageId: this.id,
          isBackground: element.IsBackground,
          utilisateurCreation: this.currentUser.userName,
          utilisateurModification: this.currentUser.userName,
          dateCreation: new Date(),
          dateModification: new Date(),
        };
        this.fichier.post(filePage).subscribe(
          () => {
            this.ctrlimage++;
          },
          () => {
            this.isUpdating = true;
            this.toastr.error(
              'Erreur survenue lors le chargement des fichiers.Réssayer de nouveau!',
              'Erreur',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true,
              }
            );
          }
        );
      }
    });
    this.isUpdating = true;
    this.toastr.success('', 'Succès', {
      timeOut: 5000,
      progressBar: true,
      closeButton: true,
    });
    this.preview.nativeElement.click();
    this.save.nativeElement.click();
    this.router.navigate(['/back/pages']);
    this.isWorking = false;
  }

  getActivityByStatut(data, statut: string) {
    if (data.length > 0) {
      const table: Activity[] = data;
      let act: Activity;
      // tslint:disable-next-line: no-shadowed-variable
      table.forEach((element) => {
        if (element.statut.toString() === statut) {
          act = element;
        }
      });
      return act;
    } else {
      return null;
    }
  }

  getUser() {
    if (localStorage.getItem('MyApp_Auth') !== undefined) {
      const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
      this.utilisateur.getUtilisateur(userId).subscribe((data) => {
        this.currentUser = data;
      });
    }
  }

  handleFileInput(file) {
    // tslint:disable-next-line: variable-name
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4', 'image/avif'];
    if (file !== undefined) {
      if (allowed_types.indexOf(file.item(0).type) === -1) {
        this.toastr.error('Ce type de fichier est introuvable', 'Erreur');
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (file.item(0).type === 'video/mp4') {
          const f = {
            name: file.item(0).name,
            file: file.item(0),
            type: 'video',
            src: e.target.result,
            IsBackground: false,
            paragraphe: '',
          };
          this.fileToUpload.push(f);
          const entete = f.src.substr(0, f.src.indexOf(',') + 1);
          // f.src = f.src.replace(entete, '');
          const nf = f.src.replace(entete, '');
          const test: ImageUploaded = { name: f.name, file: nf, path: 'Files/' + f.name.replace(/[^a-zA-Z0-9.]/g, '') };
          this.fichier.saveFile(test).subscribe(() => {
          });
        } else {
          const image = new Image();
          image.src = e.target.result;
          image.onload = (rs) => {
            const imgBase64Path = e.target.result;
            const f = {
              name: file.item(0).name,
              file: file.item(0),
              type: 'image',
              src: imgBase64Path,
              IsBackground: false,
              paragraphe: '',
            };
            this.fileToUpload.push(f);
            const entete = f.src.substr(0, f.src.indexOf(',') + 1);
            const nf = f.src.replace(entete, '');
            this.fichier
              .saveFile({ name: f.name, file: nf, path: 'Files/' + f.name.replace(/[^a-zA-Z0-9.]/g, '') })
              .subscribe(() => {
              });
          };
        }
      };
      reader.readAsDataURL(file.item(0));
    }
  }

  removeFile(file) {
    const index = this.fileToUpload.indexOf(file);
    if (index >= 0) {
      this.fileToUpload.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.mc.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: any): void {
    const index = this.mc.indexOf(fruit);
    if (index >= 0) {
      this.mc.splice(index, 1);
    }
  }

  addTags(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeTags(fruit: any): void {
    const index = this.tags.indexOf(fruit);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  addTagsEN(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tagsEN.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeTagsEN(fruit: any): void {
    const index = this.tagsEN.indexOf(fruit);
    if (index >= 0) {
      this.tagsEN.splice(index, 1);
    }
  }

  setDialog(b) {
    this.Docu = b;
  }

  changeEtat() {
    if (this.estPublie === true) {
      this.etat = 'Publie';
    } else {
      this.etat = 'Brouillon';
    }
  }

  getLink() {
    if (this.lien === true) {
      if (this.titre !== '') {
        let link = this.titre.replace(/<[^>]+>/g, '');
        link = link.replace(/[^a-zA-Z0-9]/g, '-');

        this.page.getURL(link).subscribe((data) => {
          const list: Page[] = data;
          if (list.length === 0) {
            this.url = link;
          } else {
            const dt = new Date();
            const nb =
              dt.getHours().toString() +
              '' +
              dt.getMinutes().toString() +
              '' +
              dt.getSeconds().toString() +
              '' +
              dt.getMilliseconds().toString();
            this.url = link + nb;
          }
        });
      }
    }
  }

  AddParagraphe() {
    this.paragraphes.push({
      idpar: Guid.create().toString(),
      titre: '',
      body: '',
      link: '',
      name: '',
      titreEN: '',
      bodyEN: '',
      nameEN: ''
    });
  }

  removeParagraphe(i) {
    this.paragraphes.splice(i, 1);
  }
  selParagraphe(event) {
  }

  isTitle(id: string) {
    let res = false;
    this.titres.forEach((el) => {
      if (el.id === id) {
        res = true;
      }
    });
    return res;
  }

  isParagraphe(id: string) {
    let res = false;
    this.paragraphes.forEach((el) => {
      if (el.idpar === id) {
        res = true;
      }
    });
    return res;
  }

  setFile(file) {
    this.isImage = file.type === 'image';
    this.src = file.src !== undefined ? file.src : Constant.ApiRoot + '/' + file.file;
    this.alt = file.alt;
  }

  SetElement() {
    this.srcModele = '';
    switch (this.modeles) {
      case '2e76f2ee-51ed-4fff-b2a5-06f7f9902244':
        this.srcModele = this.blocs;
        break;
      case '2e76f2ee-51ed-4fff-b2a5-06f7f9902245':
        this.srcModele = this.candidatureSp;
        break;
      case '5e11d23f-6fbc-45f2-b4ea-0af1fc3fdbc1':
        this.srcModele = this.texteImages;
        break;
      case '2b2a1647-6f61-4c1c-8809-2bc1d5741bab':
        this.srcModele = this.lienRed;
        break;
      case '9856a4a4-d881-46a5-b7d9-2cd1753bc652':
        this.srcModele = this.parllaxSc;
        break;
      case '2c36dbe8-d9eb-4f87-89fe-320221554ae0':
        this.srcModele = this.superposition;
        break;
      case '9513edd7-c6a9-428d-b99e-521d4b07fed6':
        this.srcModele = this.videoTexte;
        break;
      case '31e243d6-4ad9-435c-b4d4-5a7a1678dabd':
        this.srcModele = this.animations;
        break;
      case '909ca735-c180-47ce-aa30-5b3f37745a69':
        this.srcModele = this.acceuil;
        break;
      case 'cfe8f724-c666-4245-b3e8-7db76898c9ed':
        this.srcModele = this.Formulaire;
        break;
      case 'd4c6e33a-db79-4c85-ba7e-821fe7137183':
        this.srcModele = this.texteImages;
        break;
      case 'edd0f7b8-4076-47bf-88d4-83e3ec08114d':
        this.srcModele = this.popUp;
        break;
      case '0778b267-9b17-499b-9089-892133c30d2b':
        this.srcModele = this.paragraphesM;
        break;
      case 'e0641c05-5f3e-4af3-91e1-8ffd45090773':
        this.srcModele = this.Description;
        break;
      case 'cfe165f0-c4b7-425c-bc7b-a1f719673426':
        this.srcModele = this.imageVideoTexte;
        break;
      case '70fb039f-7cfa-48ac-aaee-ca7ca11b4265':
        this.srcModele = this.fenetres;
        break;
      case '1bb028b8-2471-4837-bac4-de2adf02fe34':
        this.srcModele = this.landingPage;
        break;
      case 'a999ad20-84c8-4599-b75c-e075da0e0af2':
        this.srcModele = '';
        break;
      case 'fe2403de-d90c-45ea-8c4e-fbe52a48c27b':
        this.srcModele = this.OffresEmploi;
        break;
      default:
        this.srcModele = 'Error';
        break;
    }
  }

  copyInputMessage(inputElement) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.id));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    // tslint:disable-next-line: deprecation
    document.execCommand('copy');
  }
}
