<div class="container-fluid "  [style.min-height]="height+'px'" [style.width]="width+'px'" style="margin-left: 0;">
    <div class="row" style="padding-top: 10vh;">

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="margin-top: 1em;">
            <div class="col-12 btnL row">
                <div id="localisation{{i}}" class="btn col-lg-3 col-md-3 col-sm-3 col-xs-3"
                    *ngFor="let Localisation of Localisations; let i = index;" [ngStyle]="{'color': (state==i) ? '#f7e721' : 'white','border-color': (state==i) ? '#f7e721' : 'white' }" (click)="buttonaffichage(i)"
                    [innerHTML]="Localisation.titre | safeHtml"></div>
            </div>
            <img *ngIf="Localisations[index].fichier.length != 0"  class="col-12 lazyload" [attr.data-src]="GetImage(Localisations[index].fichier[0].filePath)" alt="{{Localisations[index].fichier[0].alt}}">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 card-container">
            <div class="card">
                <div class="text-white" [innerHTML]="Localisations[index].body | safeHtml"></div>
            </div>
            <br>
            <iframe width="100%" height="100%" frameBorder="0" class="lazyload" [attr.data-src]="urlSafe"></iframe>
            
            
        </div>
    </div>
</div>
