import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { faListOl, faUpload, faEye } from '@fortawesome/free-solid-svg-icons';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  PageService, UtilisateurService, TitreService,
  ParagrapheService, ModeleService, ActivityService, Paragraphe, Activity, Page, Titre, Fichier
} from 'src/app/rest';
import { CategorieService } from 'src/app/rest/api/categorie.service';
import { FichierService } from 'src/app/rest/api/fichier.service';
import { Constant } from 'src/app/shared/_constants/constant';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-creerpage',
  templateUrl: './creerpage.component.html',
  styleUrls: ['./creerpage.component.scss']
})
export class CreerpageComponent implements OnInit {
  @ViewChild('myModal', { static: false }) preview;
  @ViewChild('exampleModalLong', { static: false }) save;

  faEye = faEye;
  faUpload = faUpload;
  faList = faListOl;
  isOpened: boolean;


  config: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text ...',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['undo', 'redo', 'subscript', 'superscript', 'customClasses', 'fontSize'],
    ],
  };
  configtitre: AngularEditorConfig = {
    sanitize: false,
    editable: true,
    spellcheck: true,
    height: '2rem',
    minHeight: '5rem',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    toolbarHiddenButtons: [
      ['undo', 'redo', 'subscript', 'superscript', 'customClasses', 'fontSize'],
    ],
  };

  apiKeys: { [key: string]: string };
  catItems: any;

  idCat = '';
  titre = '';
  sousTitre = '';
  resume = '';
  body = '';
  motCle = '';
  tag = '';
  description = '';
  commentaire = '';
  estPublie = false;
  Docu: boolean;
  url;
  metaTitle;
  metaDescription;
  metaDetails;
  baliseEntete;
  taillePolice;
  currentPath;
  currentUser;
  etat;
  lien: boolean;
  modeles;
  mod: any[];
  polices;
  mc: any[] = [];
  tags: any[] = [];
  mcEN: any[] = [];
  tagsEN: any[] = [];
  models;
  selectable = true;
  removable = true;
  addOnBlur = true;
  ctrlimage = 0;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fileToUpload: any[];
  // tslint:disable-next-line: variable-name
  nb_t: number;
  // tslint:disable-next-line: variable-name
  nb_p: number;
  // tslint:disable-next-line: variable-name
  nb_i: number;
  // Paragraphes
  public paragraphes: any[] = [
    {
      idpar: Guid.create().toString(),
      titre: '',
      body: '',
      link: '',
      name: ''
    },
  ];

  public titres: Titre[] = [
    {
      id: Guid.create().toString(),
      contenu: '',
      contenuEN: ''
    }
  ];

  isUpdating = false;
  isModelRxJob: boolean;
  isWorking: boolean;

  listTitres = [];
  listParagraphes = [];
  background = [];
  pg: Page;
  retour: any;

  // english fields
  resumeEN = '';
  motsCleEN = '';
  tagEN = '';
  descriptionEN = '';
  metaTiltleEN = '';
  metaDescriptionEN = '';
  metaDetailsEN = '';

  isImage: boolean;
  alt: string;
  src: string;

  private animations = 'assets/images/Modele/animations.png';
  private blocs = 'assets/images/Modele/Blocs.png';
  private Description = 'assets/images/Modele/Description.png';
  private Formulaire = 'assets/images/Modele/Formulaire.png';
  private landingPage = 'assets/images/Modele/landing-page.png';
  private lienRed = 'assets/images/Modele/Lien de redirection.png';
  private OffresEmploi = 'assets/images/Modele/offres d’emplois.png';
  private parllaxSc = 'assets/images/Modele/parallax-scrolling.png';
  private superposition = 'assets/images/Modele/superposition-entre-image-et-texte.png';
  private texte = 'assets/images/Modele/texte.png';
  private texteImages = 'assets/images/Modele/Textes-images.png';
  private videoTexte = 'assets/images/Modele/Video-texte.png';
  private acceuil = 'assets/images/Modele/accueil.png';
  private paragraphesM = 'assets/images/Modele/Paragraphe.png';
  private imageVideoTexte = 'assets/images/Modele/image-video-texte.png';
  private fenetres = 'assets/images/Modele/fenetres-informatives.png';
  private candidatureSp = 'assets/images/Modele/CandidatureSpontanée.png';
  private popUp = 'assets/images/Modele/PopUp.png';

  srcModele = '';

  constructor(
    private page: PageService,
    private categorie: CategorieService,
    private toastr: ToastrService,
    private utilisateur: UtilisateurService,
    private router: Router,
    private fichier: FichierService,
    private titreService: TitreService,
    private paragrapheService: ParagrapheService,
    private modele: ModeleService,
    private activityService: ActivityService,
    public loadingPagesService: LoadingPagesService
  ) { }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.isOpened = false;
    this.isModelRxJob = false;
    this.idCat = '';
    this.titre = '';
    this.sousTitre = '';
    this.resume = '';
    this.body = '';
    this.motCle = '';
    this.tag = '';
    this.description = '';
    this.commentaire = '';
    this.estPublie = false;
    this.lien = false;
    this.url = '';
    (this.metaTitle = ''), (this.metaDescription = '');
    this.metaDetails = '';
    this.modeles = '';
    this.polices = '';
    this.baliseEntete = 'h1';
    this.taillePolice = 20;
    this.nb_i = 0;
    this.nb_p = 0;
    this.nb_t = 0;

    this.mc = [];
    this.tags = [];
    this.mcEN = [];
    this.tagsEN = [];
    this.selectable = true;
    this.removable = true;
    this.addOnBlur = true;
    this.currentPath = window.location.origin + '/';
    this.fileToUpload = [];
    this.currentUser = [];
    this.etat = 'Brouillon';
    this.paragraphes = [];
    this.isWorking = false;

    this.resumeEN = '';
    this.motsCleEN = '';
    this.tagEN = '';
    this.descriptionEN = '';
    this.metaTiltleEN = '';
    this.metaDescriptionEN = '';
    this.metaDetailsEN = '';

    this.Docu = true;
    this.getCategorie();
    this.getUser();
    this.getModels();

  }
  editpage() {
    this.listParagraphes = [];
    this.background = [];

  }
  returnmodif() {
    this.isOpened = false;
    this.listParagraphes = [];
    this.background = [];
  }
  openModal() {
    if (this.modeles !== '') {
      this.isOpened = true;
      this.paragraphes.forEach(element => {
        this.listParagraphes.push({
          body: element.body,
          link: element.link,
          titre: element.titre,
          idpar: element.idpar,
          fichier: []
        });

      });

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.listParagraphes.length; i++) {
        this.fileToUpload.forEach(file => {
          if (file.paragraphe === this.listParagraphes[i].idpar) {
            this.listParagraphes[i].fichier.push({ filePath: file.file });
          }
        });
      }

      this.fileToUpload.forEach(file => {
        if (file.paragraphe === '') {
          this.background.push({ filePath: file.file });
        }
      });

    } else {

      this.toastr.error('Veuillez indiquer le modéle et remplir les données nécessaires', 'Erreur');
    }

  }

  AddTitre() {
    this.titres.push({
      id: Guid.create().toString(),
      contenu: '',
    });
  }

  removeTitre(i) {
    this.titres.splice(i, 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.paragraphes, event.previousIndex, event.currentIndex);
  }

  dropTitres(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.titres, event.previousIndex, event.currentIndex);
  }

  getUser() {
    if (localStorage.getItem('MyApp_Auth') !== undefined) {
      const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
      this.utilisateur.getUtilisateur(userId).subscribe((data) => {
        this.currentUser = data;
      });
    }
  }

  getModels() {
    this.mod = [];
    this.modele.get().subscribe(
      (data) => {
        this.mod = data;
      },
      (error) => {
        console.log('erreur models : ' + error);
      }
    );
  }

  UpdateParagraphe(m) {
    if (m === 'a999ad20-84c8-4599-b75c-e075da0e0af2' || m === 'fe2403de-d90c-45ea-8c4e-fbe52a48c27b') {
      this.isModelRxJob = true;
    } else {
      this.isModelRxJob = false;
    }
    this.mod.forEach((element) => {
      if (element.id === m) {
        this.nb_i = element.nbImage;
        this.nb_t = element.nbTitre;
        this.nb_p = element.nbParagraphe;
      }
    });
    this.paragraphes = [];
    for (let i = 0; i < this.nb_p; i++) {
      this.paragraphes.push({
        idpar: Guid.create().toString(),
        titre: '',
        body: '',
        link: '',
        name: '',
        titreEN: '',
        bodyEN: '',
        nameEN: ''
      });
    }
  }

  handleFileInput(file) {
    // tslint:disable-next-line: variable-name
    const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4', 'image/avif'];
    if (file !== undefined) {
      if (allowed_types.indexOf(file.item(0).type) === -1) {
        this.toastr.error('Ce type de fichier est introuvable', 'Erreur');
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (file.item(0).type === 'video/mp4') {
          const f = {
            name: file.item(0).name,
            file: file.item(0),
            type: 'video',
            alt: '',
            altEN: '',
            // tslint:disable-next-line: no-angle-bracket-type-assertion
            src: (<FileReader>e.target).result.toString(),
            IsBackground: false,
            paragraphe: ''
          };

          this.fileToUpload.push(f);
          const entete = f.src.substr(
            0,
            f.src.indexOf(',') + 1
          );
          // f.src = f.src.replace(entete, '');
          const nf = f.src.replace(entete, '');
          this.fichier.saveFile({ name: f.name, file: nf, path: 'Files/' + f.name.replace(/[^a-zA-Z0-9.]/g, '') }).subscribe(() => { });
          /*this.fich.push({
            paragraphe: -1,
            IsBackground: false
          });*/
        } else {
          const image = new Image();
          image.src = e.target.result;
          image.onload = rs => {
            const imgBase64Path = e.target.result;
            const f = {
              name: file.item(0).name,
              file: file.item(0),
              type: 'image',
              src: imgBase64Path,
              alt: '',
              altEN: '',
              IsBackground: false,
              paragraphe: ''
            };
            this.fileToUpload.push(f);
            const entete = f.src.substr(
              0,
              f.src.indexOf(',') + 1
            );
            // f.src = f.src.replace(entete, '');
            const nf = f.src.replace(entete, '');
            this.fichier.saveFile({ name: f.name, file: nf, path: 'Files/' + f.name.replace(/[^a-zA-Z0-9.]/g, '') }).subscribe(() => { });
            /*this.fich.push({
              paragraphe: -1,
              IsBackground: false
            });*/
          };
        }
      };
      reader.readAsDataURL(file.item(0));
    }
  }

  removeFile(file) {
    const index = this.fileToUpload.indexOf(file);
    if (index >= 0) {
      this.fileToUpload.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.mc.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: any): void {
    const index = this.mc.indexOf(fruit);
    if (index >= 0) {
      this.mc.splice(index, 1);
    }
  }

  addEN(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.mcEN.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeEN(fruit: any): void {
    const index = this.mcEN.indexOf(fruit);
    if (index >= 0) {
      this.mcEN.splice(index, 1);
    }
  }

  addTags(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeTags(fruit: any): void {
    const index = this.tags.indexOf(fruit);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  addTagsEN(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tagsEN.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }

  removeTagsEN(fruit: any): void {
    const index = this.tagsEN.indexOf(fruit);
    if (index >= 0) {
      this.tagsEN.splice(index, 1);
    }
  }

  getCategorie() {
    this.categorie.getCategories().subscribe(
      (data) => {
        this.catItems = data;
      },
      (error) => {
        this.toastr.error(error, 'Erreur');
      }
    );
  }

  async SaveParagaphes(idpage) {
    let i = 0;
    this.paragraphes.forEach((element) => {
      const paragraphePage: Paragraphe = {
        id: element.idpar,
        titre: element.titre,
        body: element.body,
        link: element.link,
        pageId: idpage,
        order: i,
        titreEN: element.titreEN,
        bodyEN: element.bodyEN,
        utilisateurCreation: this.currentUser.userName,
        utilisateurModification: this.currentUser.userName,
        dateCreation: new Date(),
        dateModification: new Date(),
      };
      if (this.isModelRxJob === true) {
        paragraphePage.name = element.name;
        paragraphePage.nameEN = element.nameEN;
      }
      i++;
      this.paragrapheService.post(paragraphePage).subscribe(
        () => {
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  publier() {
    if (this.idCat === '' || this.idCat === null || this.idCat === undefined) {
      this.toastr.error('Merci de renseigner la catégorie', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else {
      this.isWorking = true;
      this.isUpdating = true;
      this.ctrlimage = 0;
      let motCles = '';
      let tags = '';
      let motClesEN = '';
      let tagsEN = '';
      if (this.mc.length !== 0) {
        this.mc.forEach((element) => {
          motCles = motCles + element.name + '*';
        });
      }

      if (this.tags.length !== 0) {
        this.tags.forEach((element) => {
          tags = tags + element.name + '*';
        });
      }
      if (this.mcEN.length !== 0) {
        this.mcEN.forEach((element) => {
          motClesEN = motClesEN + element.name + '*';
        });
      }

      if (this.tagsEN.length !== 0) {
        this.tagsEN.forEach((element) => {
          tagsEN = tagsEN + element.name + '*';
        });
      }

      const dt = new Date();
      const idpage = Guid.create().toString();
      const idt = Guid.create().toString();
      const page: Page = {
        pageId: idpage,
        resume: this.resume,
        motsCle: motCles,
        tag: tags,
        description: this.description,
        categorieId: this.idCat,
        commentaire: this.commentaire,
        estPublie: this.estPublie,
        modeleId: this.modeles,
        lien: this.url,
        etatPage: this.etat,
        autorisationCommentaire: true,
        metaTiltle: this.metaTitle,
        metaDescription: this.metaDescription,
        metaDetails: this.metaDetails,
        resumeEN: this.resumeEN,
        motsCleEN: motClesEN,
        tagEN: tagsEN,
        descriptionEN: this.descriptionEN,
        metaTiltleEN: this.metaTiltleEN,
        metaDescriptionEN: this.metaDescriptionEN,
        metaDetailsEN: this.metaDetailsEN,
        utilisateurCreation: this.currentUser.userName,
        utilisateurModification: this.currentUser.userName,
        dateCreation: dt,
        dateModification: dt,
      };

      this.page.postPage(page).subscribe(
        async () => {
          // Add Activity
          const act: Activity = {
            idActivity: Guid.create().toString(),
            description: 'Création Page',
            statut: 'Brouillon',
            pageId: idpage,
            utilisateurCreation: this.currentUser.userName,
            utilisateurModification: this.currentUser.userName,
            dateCreation: new Date(),
            dateModification: new Date(),
          };

          this.activityService.post(act).subscribe(
            () => {
            },
            (error) => {
              console.log(error);
              this.isWorking = false;
            }
          );
          this.toastr.success('', 'Succès', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true,
          });
          let ord = 0;
          this.titres.forEach(element => {
            const titrep: Titre = {
              id: element.id,
              contenu: element.contenu,
              pageId: idpage,
              order: ord,
              contenuEN: element.contenuEN,
              utilisateurCreation: this.currentUser.userName,
              utilisateurModification: this.currentUser.userName,
              dateCreation: dt,
              dateModification: dt,
            };
            ord++;
            this.titreService.post(titrep).subscribe(
              async () => {
              }, error => {
                console.log('title : ' + error);
                this.isWorking = false;
              });
          });

          await this.SaveParagaphes(idpage);

          this.fileToUpload.forEach((element) => {
            if (this.isTitle(element.paragraphe)) {
              // Title
              const entete = element.src.substr(
                0,
                element.src.indexOf(',') + 1
              );
              element.src = element.src.replace(entete, '');
              const filePage = {
                idFile: Guid.create().toString(),
                filePath: 'Files/' + element.name.replace(/[^a-zA-Z0-9.]/g, ''),
                type: element.type,
                name: element.name,
                alt: element.alt,
                altEN: element.altEN,
                // PageId: idpage,
                // idTitre: this.titres[Number(element.paragraphe.replace('t',''))].id,
                idTitre: element.paragraphe,
                isBackground: element.IsBackground,
                utilisateurCreation: this.currentUser.userName,
                utilisateurModification: this.currentUser.userName,
                dateCreation: new Date(),
                dateModification: new Date(),
              };
              this.fichier.post(filePage).subscribe(
                () => {
                  this.ctrlimage++;
                },
                () => {
                  this.isUpdating = true;
                  this.toastr.error(
                    'Erreur survenue lors le chargement des fichiers',
                    'Erreur',
                    {
                      timeOut: 5000,
                      progressBar: true,
                      closeButton: true,
                    }
                  );
                }
              );
            } else if (this.isParagraphe(element.paragraphe)) {
              // Paragraphe
              const entete = element.src.substr(
                0,
                element.src.indexOf(',') + 1
              );
              element.src = element.src.replace(entete, '');
              const filePage = {
                idFile: Guid.create().toString(),
                filePath: 'Files/' + element.name.replace(/[^a-zA-Z0-9.]/g, ''),
                type: element.type,
                name: element.name,
                alt: element.alt,
                altEN: element.altEN,
                // idParagraphe: this.paragraphes[element.paragraphe].idpar,
                idParagraphe: element.paragraphe,
                // idPage: idpage,
                isBackground: element.IsBackground,
                utilisateurCreation: this.currentUser.userName,
                utilisateurModification: this.currentUser.userName,
                dateCreation: new Date(),
                dateModification: new Date(),
              };
              this.fichier.post(filePage).subscribe(
                () => {
                  this.ctrlimage++;
                },
                () => {
                  this.isUpdating = true;
                  this.toastr.error(
                    'Erreur survenue lors le chargement des fichiers',
                    'Erreur',
                    {
                      timeOut: 5000,
                      progressBar: true,
                      closeButton: true,
                    }
                  );
                }
              );
            } else {
              // Page
              const entete = element.src.substr(
                0,
                element.src.indexOf(',') + 1
              );
              element.src = element.src.replace(entete, '');
              const filePage: Fichier = {
                idFile: Guid.create().toString(),
                filePath: 'Files/' + element.name.replace(/[^a-zA-Z0-9.]/g, ''),
                type: element.type,
                name: element.name,
                pageId: idpage,
                alt: element.alt,
                altEN: element.altEN,
                isBackground: element.IsBackground,
                utilisateurCreation: this.currentUser.userName,
                utilisateurModification: this.currentUser.userName,
                dateCreation: new Date(),
                dateModification: new Date(),
              };
              this.fichier.post(filePage).subscribe(
                () => {
                  this.ctrlimage++;
                },
                () => {
                  this.isUpdating = true;
                  this.toastr.error(
                    'Erreur survenue lors le chargement des fichiers',
                    'Erreur',
                    {
                      timeOut: 5000,
                      progressBar: true,
                      closeButton: true,
                    }
                  );
                }
              );
            }
          });
          this.isUpdating = true;
          // this.preview.nativeElement.click();
          this.save.nativeElement.click();
          this.router.navigate(['/back/pages']);
          /*},
          (error) => {
            this.isUpdating = true;
          }
        );*/
        },
        (error) => {
          console.log(error);
          this.toastr.error('', 'Erreur', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true,
          });
          this.isWorking = false;
        }
      );
    }
  }

  setDialog(b) {
    this.Docu = b;
  }

  changeEtat() {
    if (this.estPublie === true) {
      this.etat = 'Publie';
    } else { this.etat = 'Brouillon'; }
  }

  getLink() {
    if (this.lien === true) {
      if (this.titre !== '') {
        let link = this.titre.replace(/<[^>]+>/g, '');
        link = link.replace(/[^a-zA-Z0-9]/g, '-');

        this.page.getURL(link).subscribe((data) => {
          const list: Page[] = data;
          if (list.length === 0) {
            this.url = link;
          } else {
            const dt = new Date();
            const nb =
              dt.getHours().toString() +
              '' +
              dt.getMinutes().toString() +
              '' +
              dt.getSeconds().toString() +
              '' +
              dt.getMilliseconds().toString();
            this.url = link + nb;
          }
        });
      }
    }
  }

  AddParagraphe() {
    this.paragraphes.push({
      idpar: Guid.create().toString(),
      titre: '',
      body: '',
      link: '',
      name: ''
    });
  }

  removeParagraphe(i) {
    this.paragraphes.splice(i, 1);
  }

  selParagraphe(event) {
  }

  isTitle(id: string) {
    let res = false;
    this.titres.forEach(element => {
      if (element.id === id) {
        res = true;
      }
    });
    return res;
  }

  isParagraphe(id: string) {
    let res = false;
    this.paragraphes.forEach(element => {
      if (element.idpar === id) {
        res = true;
      }
    });
    return res;
  }

  setFile(file) {
    this.isImage = file.type === 'image';
    this.src = file.src !== undefined ? file.src : Constant.ApiRoot + '/' + file.file;
    this.alt = file.alt;
  }

  SetElement() {
    this.srcModele = '';
    switch (this.modeles) {
      case '2e76f2ee-51ed-4fff-b2a5-06f7f9902244':
        this.srcModele = this.blocs;
        break;
      case '2e76f2ee-51ed-4fff-b2a5-06f7f9902245':
        this.srcModele = this.candidatureSp;
        break;
      case '5e11d23f-6fbc-45f2-b4ea-0af1fc3fdbc1':
        this.srcModele = this.texteImages;
        break;
      case '2b2a1647-6f61-4c1c-8809-2bc1d5741bab':
        this.srcModele = this.lienRed;
        break;
      case '9856a4a4-d881-46a5-b7d9-2cd1753bc652':
        this.srcModele = this.parllaxSc;
        break;
      case '2c36dbe8-d9eb-4f87-89fe-320221554ae0':
        this.srcModele = this.superposition;
        break;
      case '9513edd7-c6a9-428d-b99e-521d4b07fed6':
        this.srcModele = this.videoTexte;
        break;
      case '31e243d6-4ad9-435c-b4d4-5a7a1678dabd':
        this.srcModele = this.animations;
        break;
      case '909ca735-c180-47ce-aa30-5b3f37745a69':
        this.srcModele = this.acceuil;
        break;
      case 'cfe8f724-c666-4245-b3e8-7db76898c9ed':
        this.srcModele = this.Formulaire;
        break;
      case 'd4c6e33a-db79-4c85-ba7e-821fe7137183':
        this.srcModele = this.texteImages;
        break;
      case 'edd0f7b8-4076-47bf-88d4-83e3ec08114d':
        this.srcModele = this.popUp;
        break;
      case '0778b267-9b17-499b-9089-892133c30d2b':
        this.srcModele = this.paragraphesM;
        break;
      case 'e0641c05-5f3e-4af3-91e1-8ffd45090773':
        this.srcModele = this.Description;
        break;
      case 'cfe165f0-c4b7-425c-bc7b-a1f719673426':
        this.srcModele = this.imageVideoTexte;
        break;
      case '70fb039f-7cfa-48ac-aaee-ca7ca11b4265':
        this.srcModele = this.fenetres;
        break;
      case '1bb028b8-2471-4837-bac4-de2adf02fe34':
        this.srcModele = this.landingPage;
        break;
      case 'a999ad20-84c8-4599-b75c-e075da0e0af2':
        this.srcModele = '';
        break;
      case 'fe2403de-d90c-45ea-8c4e-fbe52a48c27b':
        this.srcModele = this.OffresEmploi;
        break;
      default:
        this.srcModele = 'Error';
        break;
    }
  }
}
