import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-toolbar',
  templateUrl: './back-toolbar.component.html',
  styleUrls: ['./back-toolbar.component.scss']
})
export class BackToolbarComponent implements OnInit {
  @Input() userName: string;
  @Input() imgURL = '';
  @Input() dropdownMenu = [];

  constructor() { }

  ngOnInit(): void {
    this.userName = (localStorage.getItem('userName') !== undefined ? localStorage.getItem('userName') : '');
  }

}
