import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages/pages.component';
import { CategorieComponent } from './categorie/categorie.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreerpageComponent } from './creerpage/creerpage.component';
import { ModifierpageComponent } from './modifierpage/modifierpage.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ModeleComponent } from './modele/modele.component';


@NgModule({
  declarations: [PagesComponent, CategorieComponent, CreerpageComponent, ModifierpageComponent, ModeleComponent],
  imports: [
    CommonModule,
    RouterModule,
    PagesRoutingModule,
    SharedModule,
    BrowserModule,
    MatTableModule,
    MatSortModule
  ]
})
export class PagesModule { }
