export * from './activity.service';
import { ActivityService } from './activity.service';
export * from './article.service';
import { ArticleService } from './article.service';
export * from './categorie.service';
import { CategorieService } from './categorie.service';
export * from './commentaire.service';
import { CommentaireService } from './commentaire.service';
export * from './configurationMail.service';
import { ConfigurationMailService } from './configurationMail.service';
import { CRMService } from './cRM.service';
export * from './fichier.service';
import { FichierService } from './fichier.service';
export * from './formulaire.service';
import { FormulaireService } from './formulaire.service';
export * from './googleAnayticsAPI.service';
import { GoogleAnayticsAPIService } from './googleAnayticsAPI.service';
export * from './historique.service';
import { HistoriqueService } from './historique.service';
export * from './jWT.service';
import { JWTService } from './jWT.service';
export * from './mail.service';
import { MailService } from './mail.service';
export * from './modele.service';
import { ModeleService } from './modele.service';
export * from './page.service';
import { PageService } from './page.service';
export * from './paragraphe.service';
import { ParagrapheService } from './paragraphe.service';
export * from './titre.service';
import { TitreService } from './titre.service';
export * from './utilisateur.service';
import { UtilisateurService } from './utilisateur.service';
export const APIS = [ActivityService, ArticleService, CategorieService, CommentaireService, ConfigurationMailService, FichierService, FormulaireService, GoogleAnayticsAPIService, HistoriqueService, JWTService, MailService, ModeleService, PageService, ParagrapheService, TitreService, UtilisateurService, CRMService];
