<!-- Error Page -->
<div class="text-center">
    <br />
    <br />
    <br />
    <img data-src="assets/images/page404.jpg" width="287" height="500" class="lazyload"alt="404 Aiventu" style="width: 50%;" />
    <h3 translate>PageNonTrouvee</h3>
    <h4><a href="" translate>RetourPageAccueil</a></h4>
    <h4><a href="#" onclick="window.history.go(-1); return false;" translate>RetourPageDer</a></h4>
</div>
<br />
<br />
<br />
<br />
<!-- Error Page -->