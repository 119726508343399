<div (mouseover)="setPagination()"  *ngIf="backgroundImageURL.length != 0"  class="ftco-cover-1"
  style="background-image: linear-gradient(rgba(7, 88, 161, 0.8),rgba(7, 88, 161, 0.2)),url({{Image}});" >
  <!--<div  (mouseover)="setPagination()"  *ngIf="backgroundImageURL.length != 0"  class="ftco-cover-1"
  style="background-image: linear-gradient(rgba(7, 88, 161, 0.8),rgba(7, 88, 161, 0.2))" 
  [defaultImage]="defaultImage" [lazyLoad]="Image">-->
  <div  class="slideshow">
    <div class="row slides">
      <div class="col-12" *ngFor="let slide of data; let index = index">
        <div class="mySlides" *ngIf="index == currentIndex">
          <div *ngIf="hr">
          <div class="slide-container slide-half" [innerHTML]="slide.titres.contenu | safeHtml"></div>
          <div class="slide-container slide-half text-white" [innerHTML]="slide.paragraphes.titre | safeHtml"> </div>
          </div>
          <div  *ngIf="!hr">
            <div class="slide-container slide-half" [innerHTML]="slide.titres.contenu | safeHtml"></div>
            <div class="slide-container slide-half text-white" [innerHTML]="slide.paragraphes.titre | safeHtml"> </div>
            </div>
          <p [innerHTML]="slide.paragraphes.body | safeHtml"></p>
          
        </div>
      </div>
    </div>
    <div class="slide-pagination">
      <table class="slide-container" style="margin-bottom: 2em;">
        <tr class="slide-col" *ngFor="let indexS of slidesIndex ; let i = index;" >
          <a [href]="" class="pagination" (click)="showSlide(indexS)" [ngStyle]="{'color': (state==i) ? '#f7e721' : 'white'}">{{indexS}}
            <hr [ngStyle]="{'color': (state==i) ? '#f7e721' : 'white'}"></a>
        </tr>
      </table>
    </div>
    <div class="slide-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>