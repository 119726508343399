import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategorieComponent } from './categorie/categorie.component';
import { CreerpageComponent } from './creerpage/creerpage.component';
import { ModeleComponent } from './modele/modele.component';
import { ModifierpageComponent } from './modifierpage/modifierpage.component';
import { PagesComponent } from './pages/pages.component';

const routes: Routes = [
  { path: '', component: PagesComponent },
  { path: 'categorie', component: CategorieComponent },
  { path: 'creerpage', component: CreerpageComponent },
  { path: 'modifierpage', component: ModifierpageComponent },
  { path: 'modele', component: ModeleComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
