import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Modele, ModeleService } from 'src/app/rest';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-modele',
  templateUrl: './modele.component.html',
  styleUrls: ['./modele.component.scss']
})
export class ModeleComponent implements OnInit {
  @ViewChild('PrimaryModalhdbgcl', { static: false }) closebutton;
  @ViewChild('userUpd', { static: false }) ModifModele;

  displayedColumns: string[] = ['name', 'nbImage', 'nbParagraphe', 'nbTitre', 'Actions'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isLoading = true;
  isWorking: boolean;
  items: any;
  faEye = faEye;
  faPencil = faPencilAlt;

  private animations = 'assets/images/Modele/animations.png';
  private blocs = 'assets/images/Modele/Blocs.png';
  private Description = 'assets/images/Modele/Description.png';
  private Formulaire = 'assets/images/Modele/Formulaire.png';
  private landingPage = 'assets/images/Modele/landing-page.png';
  private lienRed = 'assets/images/Modele/Lien de redirection.png';
  private OffresEmploi = 'assets/images/Modele/offres d’emplois.png';
  private parllaxSc = 'assets/images/Modele/parallax-scrolling.png';
  private superposition = 'assets/images/Modele/superposition-entre-image-et-texte.png';
  private texte = 'assets/images/Modele/texte.png';
  private texteImages = 'assets/images/Modele/Textes-images.png';
  private videoTexte = 'assets/images/Modele/Video-texte.png';
  private acceuil = 'assets/images/Modele/accueil.png';
  private paragraphes = 'assets/images/Modele/Paragraphe.png';
  private imageVideoTexte = 'assets/images/Modele/image-video-texte.png';
  private fenetres = 'assets/images/Modele/fenetres-informatives.png';
  private candidatureSp = 'assets/images/Modele/CandidatureSpontanée.png';
  private popUp = 'assets/images/Modele/PopUp.png';

  src = '';
  modeleItem: Modele;

  constructor(
    private toastr: ToastrService,
    private modele: ModeleService,
    public loadingPagesService: LoadingPagesService
  ) {
  }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.isWorking = false;
    this.modeleItem = {};
    this.load_data();
    this.MatTableTranslate();
  }

  MatTableTranslate() {
    this.paginator._intl.itemsPerPageLabel = 'Lignes par page';
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.lastPageLabel = 'Dernière page';
    this.paginator._intl.previousPageLabel = 'Page précédente';
    this.paginator._intl.firstPageLabel = 'Première page';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 / ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
  }

  public load_data() {
    this.isLoading = true;
    this.dataSource.data = [];
    this.modele.get().subscribe((data) => {
      if (data !== undefined) {
        this.items = data;
        this.isLoading = false;
        this.dataSource.data = this.items;
      }
    },
      () => {
        this.isLoading = false;
        this.toastr.error('Erreur lors de chargemenet de la page', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  SetElement(element) {
    switch (element.name) {
      case 'blocs':
        this.src = this.blocs;
        break;
      case 'candidature spontanée':
        this.src = this.candidatureSp;
        break;
      case 'textes image':
        this.src = this.texteImages;
        break;
      case 'lien de redirection':
        this.src = this.lienRed;
        break;
      case 'parallax scrolling':
        this.src = this.parllaxSc;
        break;
      case 'superposition entre image et texte':
        this.src = this.superposition;
        break;
      case 'vidéo texte':
        this.src = this.videoTexte;
        break;
      case 'animation':
        this.src = this.animations;
        break;
      case 'page d\'accueil':
        this.src = this.acceuil;
        break;
      case 'formulaire':
        this.src = this.Formulaire;
        break;
      case 'texte image':
        this.src = this.texteImages;
        break;
      case 'fenetre pop up':
        this.src = this.popUp;
        break;
      case 'paragraphes':
        this.src = this.paragraphes;
        break;
      case 'description':
        this.src = this.Description;
        break;
      case 'image vidéo texte':
        this.src = this.imageVideoTexte;
        break;
      case 'fenetres informatives':
        this.src = this.fenetres;
        break;
      case 'landing page':
        this.src = this.landingPage;
        break;
      case 'réseaux sociaux':
        this.src = '';
        break;
      case 'offres d\'emplois':
        this.src = this.OffresEmploi;
        break;
      default:
        this.src = 'Error';
        break;
    }
  }

  SetId(element) {
    this.modeleItem = element;
  }

  save() {
    if (this.modeleItem.nbImage < 0 || this.modeleItem.nbImage === null) {
      this.toastr.error('Nombre d\'images doit être supérieur ou égal à zéro', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else if (this.modeleItem.nbParagraphe < 0 || this.modeleItem.nbParagraphe === null) {
      this.toastr.error('Nombe des paragraphes doit être supérieur ou égal à zéro', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else if (this.modeleItem.nbTitre < 0 || this.modeleItem.nbTitre === null) {
      this.toastr.error('Nombe des titres doit être supérieur ou égal à zéro', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else {
      this.isWorking = true;
      this.modele.put(this.modeleItem.id, this.modeleItem).subscribe(() => {
        this.toastr.success('', 'Succès',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.ngOnInit();
        this.isWorking = false;
        this.ModifModele.nativeElement.click();
      }, () => {
        this.toastr.error('', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.ngOnInit();
        this.isWorking = false;
        this.ModifModele.nativeElement.click();
      });
    }
  }
}
