import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilisateurService } from 'src/app/rest';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-modifpwd',
  templateUrl: './modifpwd.component.html',
  styleUrls: ['./modifpwd.component.scss']
})
export class ModifpwdComponent implements OnInit {

  lastPwd = '';
  newPwd = '';
  confirmPwd = '';
  item;
  isWorking: boolean;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private utilisateur: UtilisateurService,
    public loadingPagesService: LoadingPagesService
    ) { }

  ngOnInit() {
    this.loadingPagesService.setPageStatus(true);
    this.getCurrentUser();
  }
  cancelModifpwd() {
    this.router.navigate(['/back/dashboard']);
  }

  getCurrentUser() {

    const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
    if (userId !== null) {
    this.utilisateur.getUtilisateur(userId)
      .subscribe(
        data => {
          if (data != null) {
            this.item = data;
          }
        },
        () => {
          this.toastr.error('ErreurInnconu', 'Erreur',
            {
              timeOut: 5000,
              progressBar: true,
              closeButton: true
            });
        });
      }
  }

  validateModifpwd() {
    const md = new Md5();
    const hpwd = md.appendStr(this.lastPwd).end().toString();
    if (hpwd === this.item.password) {
      if (this.newPwd === '') {
        this.toastr.error('Nouveau Mot de passe doit etre non vide', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      } else if (this.newPwd !== this.confirmPwd) {
        this.toastr.error('Les mots de passe ne sont pas identiques', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      } else {
        this.isWorking = true;
        const md5 = new Md5();
        const hnewPwd = md5.appendStr(this.newPwd).end().toString();
        this.utilisateur.changePwd(this.item.id, hnewPwd)
          .subscribe(
            data => {
              if (data != null) {
                this.toastr.success('Traitement effecuté avec succès', 'Succès',
                  {
                    timeOut: 5000,
                    progressBar: true,
                    closeButton: true
                  });
                localStorage.clear();
                this.isWorking = true;
                this.router.navigate(['login']);
              }
            },
            () => {
              this.toastr.error('Erreur Changement Mot de passe', 'Erreur',
                {
                  timeOut: 5000,
                  progressBar: true,
                  closeButton: true
                });
              this.isWorking = true;
            });
      }
    } else {
      this.toastr.error('l\'ancien Mot de passe incorrect', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    }
  }

}
