<div (mouseover)="setMeta(OffresPage)" (click)="setMeta(OffresPage)">
<div *ngIf="BackgroundImageDescription.length != 0" class="card"
    style="background-image: linear-gradient(rgba(7,88,161, 0.8),rgba(7,88,161, 0.8)),url({{Image}});">
    <h2 *ngIf="isFrench" [innerHTML]="TitleDescription[0].contenu | safeHtml"></h2>
    <h2 *ngIf="!isFrench" [innerHTML]="TitleDescription[0].contenuEN | safeHtml"></h2>
    <img alt="left arrow" class="imageArrow lazyload" data-src="assets/images/icones/Left-Arrow.png">
</div>
<div class="m-4" *ngFor="let paragraphe of ParagrapheArrayDescription ; let i=index">
    <div class="row" *ngIf="i<2">
        <div *ngIf="isFrench" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
            <div class="h2-title mb-5" [innerHTML]="paragraphe.titre | safeHtml"></div>
            <div class="text-black mb-2" [innerHTML]="paragraphe.body | safeHtml"></div>
        </div>
        <div *ngIf="!isFrench" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
            <div class="h2-title mb-5" [innerHTML]="paragraphe.titreEN | safeHtml"></div>
            <div class="text-black mb-2" [innerHTML]="paragraphe.bodyEN | safeHtml"></div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 image-container" *ngIf="paragraphe.titre == 'DESCRIPTION DE LA MISSION' || 'PROFIL'">
            <img *ngIf="paragraphe.fichier.length != 0" class="image-description lazyload" [attr.data-src]="GetImage(paragraphe.fichier[0].filePath)" alt="{{paragraphe.fichier[0].alt}}">
        </div>
    </div>
</div>
<div class="row" style="margin-bottom: 3%;">
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 p-4 description-details">
        <div *ngFor="let paragraphe of ParagrapheArrayDescription | slice:2" class="details-paragraph">
            <div *ngIf="isFrench" class="h2-titles mb-2" [innerHTML]="paragraphe.titre | safeHtml"></div>
            <div *ngIf="isFrench" class="text-black-second" [innerHTML]="paragraphe.body | safeHtml"></div>
            <div *ngIf="!isFrench" class="h2-titles mb-2" [innerHTML]="paragraphe.titreEN | safeHtml"></div>
            <div *ngIf="!isFrench" class="text-black-second" [innerHTML]="paragraphe.bodyEN | safeHtml"></div>
        </div>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12">
        <div class="formulaire">
            <app-form-postuler></app-form-postuler>
        </div>
    </div>
</div>
</div>