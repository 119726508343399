import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from '../../_constants/constant';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  @Input() backgroundImageURL = [];
  @Input() TitleArray = [];
  @Input() ParagrapheArray = [];
  @Input() height: any;
  @Input() width: any;
  @Input() hr: boolean;

  Image;
  isFrench;
  IsMobile = false;

  constructor(private translate: TranslateService, public globalEventService: GlobalLanguageEventService) { }

  ngOnInit(): void {
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    if (window.innerWidth < 1200 && window.innerWidth > 768 ) {
      this.height = 700;
      this.IsMobile = true ;
    }
    this.height = this.height - 70;
    this.Image = Constant.ApiRoot + '/' + this.backgroundImageURL[0].filePath;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

  if (event.target.innerWidth< 1200 && event.target.innerWidth > 768) {
    this.height = 700;
    this.IsMobile = true ;

  }

}

  getString(tab: any, index: number) {
    if (tab === null || tab === undefined) {
      return '';
    } else { return tab[index]; }
  }

  GetImage(path: string) {
    return `${Constant.ApiRoot}/${path}`;
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
