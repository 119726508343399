import { Component, OnInit } from '@angular/core';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  constructor(public loadingPagesService: LoadingPagesService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadingPagesService.setPageStatus(true);
  }

}
