<div class="container-fluid" [style.min-height]="height+'px'" [style.width]="width+'px'">
    <div class="site-section">
        <div class="justify-content-center">
            <div class="text-center">
                <h2 [innerHTML]="CTitleArrayCI[0].contenu | safeHtml"></h2>
                <app-hr *ngIf="hr" ></app-hr> 
            </div>

            <div class="row" *ngFor="let paragraphe of CParagrapheArrayCI">
                <div class="image-containers"> 
                <div style="width: 100%;">
                <img  *ngIf="paragraphe.fichier.length != 0" class="image-container lazyload"
                [attr.data-src]="GetImage(paragraphe.fichier[0].filePath)" alt="{{paragraphe.fichier[0].alt}}">
                </div>
                <div  *ngIf="paragraphe.body"
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 text-center paragraph-container">
                    <div  [innerHTML]="paragraphe.body | safeHtml">
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>