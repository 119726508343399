import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { BackOfficeRoutingModule } from './back-office-routing.module';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ModifUtilisateurComponent } from './modif-utilisateur/modif-utilisateur.component';
import { ModifpwdComponent } from './modifpwd/modifpwd.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';
import { VueensembleComponent } from './vueensemble/vueensemble.component';
import { ParametresComponent } from './parametres/parametres.component';
import { TextInputAutocompleteModule } from 'angular-text-input-autocomplete';
import { MentionModule } from 'angular-mentions';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// keyboardEventKeyPolyfill();

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [LoginComponent, DashboardComponent, ModifUtilisateurComponent, ModifpwdComponent,
  UtilisateurComponent, VueensembleComponent, ParametresComponent],
  imports: [
    CommonModule,
    BackOfficeRoutingModule,
    SharedModule,
    TextInputAutocompleteModule,
    MentionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    TextInputAutocompleteModule
  ],
  providers: [
    {provide: 'googleTagManagerId', useValue: 'GTM-NR89LCX'}
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class BackOfficeModule { }
