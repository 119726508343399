import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Utilisateurs, UtilisateurService } from 'src/app/rest';
import { Md5 } from 'ts-md5';
import { first } from 'rxjs/operators';
import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-modif-utilisateur',
  templateUrl: './modif-utilisateur.component.html',
  styleUrls: ['./modif-utilisateur.component.scss']
})
export class ModifUtilisateurComponent implements OnInit {
  @ViewChild('userUpd', { static: false }) ModifierUtilisateur;
  faFloppy = faSave;
  faUndo = faUndo;
  user: Utilisateurs;
  userName: string;
  userEmail: string;
  auth: string;
  item: any;
  pwd;
  isWorking: boolean;


  constructor(
    private router: Router,
    private toastr: ToastrService,
    private utilisateur: UtilisateurService,
    public loadingPagesService: LoadingPagesService
    ) { }

  ngOnInit() {
    this.loadingPagesService.setPageStatus(true);
    this.pwd = '';
    this.auth = localStorage.getItem('MyApp_Auth');
    if (this.auth == null) {

      this.router.navigate(['login']);
    } else {
      const userId = localStorage.getItem('MyApp_Auth').replace(/\"/gi, '');
      this.utilisateur.getUtilisateur(userId)
        .subscribe(
          data => {
            if (data != null) {
              this.item = data;
              this.userName = data.userName;
              this.userEmail = data.email;
            }
          },
          () => {
            this.toastr.error('Erreur', 'Erreur',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
          });
    }

  }
  cancelEditUserInfos() {
    this.router.navigate(['/back/dashboard']);
  }

  validateEditUserInfos() {
    const md = new Md5();
    const hpwd = md.appendStr(this.pwd).end().toString();
    this.pwd = '';
    if (hpwd !== this.item.password) {
      this.toastr.error('Mot de passe incorrect', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else {
      if (this.userName === '') {
        this.toastr.error('UserNameError', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      } else if (this.userEmail === '') {
        this.toastr.error('EmailError', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      } else if (this.validateEmail(this.userEmail) === false) {
        this.toastr.error('invalidEmail', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      } else if (this.userName === this.item.userName && this.userEmail === this.item.email) {
        this.ModifierUtilisateur.nativeElement.click();
        this.toastr.warning('les champs ne sont pas changés', 'Attention',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      } else {
        this.isWorking = true;
        this.utilisateur.getAdminByUser(this.userName).pipe(first())
          .subscribe(
            data => {
              if (data != null && this.userName !== this.item.userName) {
                this.toastr.error('UserExist', 'Erreur',
                  {
                    timeOut: 5000,
                    progressBar: true,
                    closeButton: true
                  });
                this.isWorking = false;
              } else {
                this.utilisateur.getUserByEmail(this.userEmail).pipe(first())
                  .subscribe(
                    // tslint:disable-next-line: no-shadowed-variable
                    data => {
                      if (data != null && this.userEmail !== this.item.email) {
                        this.toastr.error('EmailExist', 'Erreur',
                          {
                            timeOut: 5000,
                            progressBar: true,
                            closeButton: true
                          });
                        this.isWorking = false;
                      } else {
                        const NewUser: Utilisateurs = {
                          id: this.item.id,
                          userName: this.userName,
                          email: this.userEmail,
                          emailConfirmed: this.userEmail,
                          normalizedUserName: this.userName.toUpperCase(),
                          normalizedEmail: this.userEmail.toUpperCase(),
                          passwordHash: this.item.passwordHash,
                          securityStamp: this.item.securityStamp,
                          concurrencyStamp: this.item.concurrencyStamp,
                          phoneNumber: this.item.phoneNumber,
                          phoneNumberConfirmed: false,
                          twoFactorEnabled: false,
                          lockoutEnabled: this.item.lockoutEnabled,
                          accessFailedCount: this.item.accessFailedCount,
                          lockoutEnd: this.item.lockoutEnd,
                          password: this.item.password,
                          language: this.item.language,
                          isActif: this.item.isActif,
                          isAdmin: this.item.isAdmin
                        };

                        this.utilisateur.putUtilisateur(this.item.id, NewUser).subscribe(() => {
                          // this.router.navigate(['tableaudebord']);
                          this.ModifierUtilisateur.nativeElement.click();
                          this.toastr.success('Les données sont à jour', 'Succès',
                            {
                              timeOut: 5000,
                              progressBar: true,
                              closeButton: true
                            });
                          this.ngOnInit();
                          this.isWorking = false;
                        },
                          () => {
                            this.isWorking = false;
                          });
                      }
                    },
                    () => {
                      this.isWorking = false;
                      this.toastr.error('ErreurInnconu', 'Erreur',
                        {
                          timeOut: 5000,
                          progressBar: true,
                          closeButton: true
                        });
                    });
              }
            },
            () => {
              this.isWorking = false;
              this.toastr.error('ErreurInnconu', 'Erreur',
                {
                  timeOut: 5000,
                  progressBar: true,
                  closeButton: true
                });
            });
      }
    }
  }

  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
