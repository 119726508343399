import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Paragraphe } from 'src/app/rest';
import { Constant } from '../../_constants/constant';
import { GlobalLanguageEventService } from '../../_services/global-language-event.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
  @Input() Job: Paragraphe = { };
  CardHeight: any;
  image: any;
  isFrench: boolean;

  constructor(
    private translate: TranslateService,
    public globalEventService: GlobalLanguageEventService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getLanguage();
    this.globalEventService.languageChanged.subscribe(() => {
      setTimeout(() => {
        this.getLanguage();
      }, 100);
    });
    this.CardHeight = 271;
    this.image = this.GetImage(this.Job.fichier[0].filePath);
  }

  GetImage(path: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${Constant.ApiRoot}/${path}`);
  }

  getLanguage() {
    if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
      localStorage.setItem('language','fr');

    }
    const lg = localStorage.getItem('language');
    this.translate.use(lg);
    // this.globalEventService.setLanguage(lg);
    this.isFrench = (lg === 'fr');
  }
}
