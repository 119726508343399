<div [style.min-height]="height+'px'" style="margin-bottom: 5%;" [style.width]="width+'px'">
    <div class="text-center" style="padding-top: 10vh;">
        <h2 [innerHTML]="STitleArrayDM[0].contenu | safeHtml"></h2>
        <app-hr *ngIf="hr" ></app-hr> 
    </div>
    <div class="container-fluid" style="padding-top: 8vh;">
        <div class="row paragraph-container" *ngFor="let paragraphe of SParagrapheArrayDM">
            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-12">
                <div class="text-black" [innerHTML]="paragraphe.body | safeHtml"></div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12">
                <img *ngIf="paragraphe.fichier.length != 0" class="image-container lazyload"
                    [attr.data-src]="GetImage(paragraphe.fichier[0].filePath)" alt="{{paragraphe.fichier[0].alt}}" >
            </div>
        </div>
    </div>
</div>