import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conditions-utilisation',
  templateUrl: './conditions-utilisation.component.html',
  styleUrls: ['./conditions-utilisation.component.scss']
})
export class ConditionsUtilisationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
