/**
 * SiteAndexBack API
 * API de Base
 *
 * OpenAPI spec version: v1
 * Contact: amahmoudi@aiventu.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Article } from './article';
import { Page } from './page';


export interface Activity { 
    idActivity?: string;
    description?: string;
    statut?: Activity.StatutEnum;
    pageId?: string;
    idArticle?: string;
    page?: Page;
    article?: Article;
    utilisateurCreation?: string;
    utilisateurModification?: string;
    dateCreation?: Date;
    dateModification?: Date;
}
export namespace Activity {
    export type StatutEnum = 'Brouillon' | 'Publié' | 'Modifié' | 'Archivé';
    export const StatutEnum = {
        Brouillon: 'Brouillon' as StatutEnum,
        Publi: 'Publié' as StatutEnum,
        Modifi: 'Modifié' as StatutEnum,
        Archiv: 'Archivé' as StatutEnum
    };
}
