import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Utilisateurs } from 'src/app/rest/model/utilisateurs';
import { UtilisateurService } from 'src/app/rest/api/utilisateur.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Md5 } from 'ts-md5';
import { Guid } from 'guid-typescript';
import { first } from 'rxjs/operators';
import { faPencilAlt, faSave, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { ConfigurationMailService } from 'src/app/rest';
import { User } from 'src/app/rest/model/user';
import { LoadingPagesService } from 'src/app/shared/_services/loading-pages.service';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.scss']
})
export class UtilisateurComponent implements OnInit {

  @ViewChild('PrimaryModalhdbgcl', { static: false }) closebutton;
  @ViewChild('userUpd', { static: false }) ModifierUtilisateur;
  @ViewChild('userDel', { static: false }) SupprimerUtilisateur;
  faPencil = faPencilAlt;
  faTrash = faTrash;
  faUndo = faUndo;
  faFloppy = faSave;
  displayedColumns: string[] = ['userName', 'email', 'phoneNumber', 'isActif', 'lockoutEnabled', 'isAdmin', 'Actions'];
  dataSource = new MatTableDataSource();
  userName: string;
  email: string;
  phoneNumber: string;
  isActif = true;
  isAdmin = false;
  item: any;
  lockoutEnabled: boolean;
  user: Utilisateurs;
  items: any;
  isLoading = true;
  idemp: number;
  pass: string;
  fichier: any;
  emps: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  event: any;
  currentUser: {
    accessFailedCount: number,
    concurrencyStamp: string,
    email: string,
    emailConfirmed: string,
    id: string,
    isActif: boolean,
    isAdmin: boolean,
    language: string,
    lockoutEnabled: boolean,
    lockoutEnd: any,
    normalizedEmail: string,
    normalizedUserName: string,
    password: string,
    passwordHash: string,
    phoneNumber: string,
    phoneNumberConfirmed: boolean,
    securityStamp: string,
    twoFactorEnabled: boolean,
    userName: string
  };
  menuitem;
  isAdmin2: boolean;
  isWorking: boolean;

  constructor(
    private toastr: ToastrService,
    private utilisateur: UtilisateurService,
    public datepipe: DatePipe,
    public settings: ConfigurationMailService,
    public loadingPagesService: LoadingPagesService
  ) {
  }

  ngOnInit(): void {
    this.loadingPagesService.setPageStatus(true);
    this.load_data();
    this.MatTableTranslate();
  }

  MatTableTranslate() {
    this.paginator._intl.itemsPerPageLabel = 'Lignes par page';
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.lastPageLabel = 'Dernière page';
    this.paginator._intl.previousPageLabel = 'Page précédente';
    this.paginator._intl.firstPageLabel = 'Première page';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 / ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
  }

  public load_data() {
    this.isLoading = true;
    this.dataSource.data = [];
    this.utilisateur.getUtilisateurs().subscribe((data) => {
      if (data !== undefined) {
        this.items = data;

        this.isLoading = false;
        this.dataSource.data = this.items;
      }
    },
      error => {
        this.isLoading = false;
        this.toastr.error('test', 'Erreur',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
      });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  generateRandomPass() {
    return Math.random().toString(36).slice(-8);
  }
  Delete() {
    this.isWorking = true;
    this.utilisateur.deleteUtilisateur(this.event).subscribe(() => {
      this.load_data();
      this.toastr.info('suppression faite', 'Info',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
      this.SupprimerUtilisateur.nativeElement.click();
      this.isWorking = false;
    },
      () => {
        this.load_data();
        this.toastr.info('suppression faite', 'Info',
          {
            timeOut: 5000,
            progressBar: true,
            closeButton: true
          });
        this.SupprimerUtilisateur.nativeElement.click();
        this.isWorking = false;
      });
  }

  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  reset() {
    this.userName = '';
    this.email = '';
    this.phoneNumber = '';
    this.isActif = true;
    this.isAdmin = false;
    this.lockoutEnabled = false;
  }
  Save() {

    if (this.userName === '') {
      this.toastr.error('le nom d\'utilisateur est vide', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else if (this.email === '') {
      this.toastr.error('l\'email est vide', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else if (this.validateEmail(this.email) === false) {
      this.toastr.error('Email invalid', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else {
      this.isWorking = true;
      this.utilisateur.getAdminByUser(this.userName).pipe(first())
        .subscribe(
          data => {
            if (data != null) {
              this.toastr.error('le nom d\'utilisateur est déjà existe', 'Erreur',
                {
                  timeOut: 5000,
                  progressBar: true,
                  closeButton: true
                });
              this.isWorking = false;
            } else {
              this.utilisateur.getUserByEmail(this.email).pipe(first())
                .subscribe(
                  // tslint:disable-next-line: no-shadowed-variable
                  data => {
                    if (data != null) {
                      this.toastr.error('emailExist', 'Erreur',
                        {
                          timeOut: 5000,
                          progressBar: true,
                          closeButton: true
                        });
                      this.isWorking = false;
                    } else {

                      const pass = this.generateRandomPass();
                      const md = new Md5();
                      const NewUser: Utilisateurs = {
                        id: Guid.create().toString(),
                        userName: this.userName,
                        email: this.email,
                        emailConfirmed: this.email,
                        normalizedUserName: this.userName.toUpperCase(),
                        normalizedEmail: this.email.toUpperCase(),
                        passwordHash: 'ABC',
                        securityStamp: 'ABC',
                        concurrencyStamp: 'ABC',
                        phoneNumber: this.phoneNumber,
                        phoneNumberConfirmed: false,
                        twoFactorEnabled: false,
                        lockoutEnabled: this.lockoutEnabled,
                        accessFailedCount: 0,
                        lockoutEnd: new Date('27/05/2020'),
                        password: md.appendStr(pass).end().toString(),
                        language: 'FR',
                        isActif: this.isActif,
                        isAdmin: this.isAdmin

                      };

                      const user: User = {
                        userName: this.userName,
                        email: this.email,
                        password: pass,
                        link: window.location.origin + '/login'
                      };

                      this.utilisateur.postAdmin(NewUser).subscribe(() => {
                        /*this.utilisateur.sendEmailUser
                          (this.email, this.userName, pass, 'Bonjour, pour y accéder au site Andex,', 'INN4MEDICAL- ACCESS')*/
                        this.settings.sendPWD(user)
                          .subscribe(() => {
                          });
                        this.toastr.success('', 'success',
                          {
                            timeOut: 5000,
                            progressBar: true,
                            closeButton: true
                          });
                        this.closebutton.nativeElement.click();
                        this.load_data();
                        this.isWorking = false;
                      },
                        () => {
                          console.log('not ok');
                          this.isWorking = false;
                        });
                    }
                  },
                  error => {
                    this.isWorking = false;
                    console.log(error);
                    this.toastr.error('Erreur Innconue', 'Erreur',
                      {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                      });
                  });
            }
          },
          error => {
            this.isWorking = false;
            console.log(error);
            this.toastr.error('Erreur Innconue', 'Erreur',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
          });

    }
  }
  Edit() {
    if (this.userName === '') {
      this.toastr.error('le champs nom d\'utilisateur est obligatoire', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else if (this.email === '') {
      this.toastr.error('le champs Email est obligatoire', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else if (this.validateEmail(this.email) === false) {
      this.toastr.error('Email invalid', 'Erreur',
        {
          timeOut: 5000,
          progressBar: true,
          closeButton: true
        });
    } else {
      this.isWorking = true;
      this.utilisateur.getAdminByUser(this.userName).pipe(first())
        .subscribe(
          data => {
            if (data != null && this.userName !== this.item.userName) {
              this.toastr.error('l\'utilisateur déjà existe', 'Erreur',
                {
                  timeOut: 5000,
                  progressBar: true,
                  closeButton: true
                });
              this.isWorking = false;
            } else {
              this.utilisateur.getUserByEmail(this.email).pipe(first())
                .subscribe(
                  // tslint:disable-next-line: no-shadowed-variable
                  data => {
                    if (data != null && this.email !== this.item.email) {
                      this.toastr.error('Email déjà existe', 'Erreur',
                        {
                          timeOut: 5000,
                          progressBar: true,
                          closeButton: true
                        });
                      this.isWorking = false;
                    } else {
                      const NewUser: Utilisateurs = {
                        id: this.item.id,
                        userName: this.userName,
                        email: this.email,
                        emailConfirmed: this.email,
                        normalizedUserName: this.userName.toUpperCase(),
                        normalizedEmail: this.email.toUpperCase(),
                        passwordHash: this.item.passwordHash,
                        securityStamp: this.item.securityStamp,
                        concurrencyStamp: this.item.concurrencyStamp,
                        phoneNumber: this.phoneNumber,
                        phoneNumberConfirmed: false,
                        twoFactorEnabled: false,
                        lockoutEnabled: this.lockoutEnabled,
                        accessFailedCount: this.item.accessFailedCount,
                        lockoutEnd: this.item.lockoutEnd,
                        password: this.item.password,
                        language: this.item.language,
                        isActif: this.isActif,
                        isAdmin: this.isAdmin
                      };
                      this.utilisateur.putUtilisateur(this.item.id, NewUser).subscribe(() => {
                        this.ModifierUtilisateur.nativeElement.click();
                        this.toastr.success('', 'success',
                          {
                            timeOut: 5000,
                            progressBar: true,
                            closeButton: true
                          });
                        this.load_data();
                        this.isWorking = false;
                      },
                        () => {
                          console.log('not update');
                          this.isWorking = false;
                        });
                    }
                  },
                  () => {
                    this.toastr.error('Erreur Innconue', 'Erreur',
                      {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                      });
                    this.isWorking = false;
                  });
            }
          },
          () => {
            this.toastr.error('Erreur Innconue', 'Erreur',
              {
                timeOut: 5000,
                progressBar: true,
                closeButton: true
              });
            this.isWorking = false;
          });

    }
  }
  SetElement(element) {
    this.item = element;
    this.userName = element.userName;
    this.email = element.email;
    this.phoneNumber = element.phoneNumber;
    this.isActif = element.isActif;
    this.isAdmin = element.isAdmin;
    this.lockoutEnabled = element.lockoutEnabled;
  }
  SetId(event) {
    this.event = event;
  }
}
